import { Accordion, AccordionTab } from 'primereact/accordion';
import { Checkbox } from 'primereact/checkbox';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { feedService } from 'src/api/services/feed';
import { IFeedsStatus } from 'src/api/types/feed';
import DashboardTitle from 'src/components/App/DashboardTitle';
import InitLoading from 'src/components/App/Loading/InitLoading';
import Button from 'src/components/Kit/Button';
import { TOAST_STATUS } from 'src/constants/toast-status';
import DashboardContentWrapper from 'src/layouts/DashboardContentWrapper';
import { getAxiosError } from 'src/utils/get-axios-error';
import { toast } from 'src/utils/toast';
import style from './Feeds.module.scss';
import { IParams } from 'src/types/params';

const Feeds = () => {
    const { companyId } = useParams<IParams>();
    const isCompanyFeedsConfig = Boolean(companyId);
    const navigate = useNavigate();
    const [statuses, setStatuses] = useState<IFeedsStatus[]>();
    const [loading, setLoading] = useState(true);
    const [updateLoading, setUpdateLoading] = useState(false);

    const fetchData = async () => {
        try {
            setLoading(true);
            const response = await feedService.getStatuses(isCompanyFeedsConfig ? { company_id: Number(companyId) } : undefined);
            setStatuses(response.data.data.status);
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: message,
            });
        } finally {
            setLoading(false);
        }
    };
    const onSubmit = async () => {
        try {
            setUpdateLoading(true);
            const flatStatuses = statuses!.flatMap(({ items }) => items.map(({ id, is_active }) => ({ id, is_active })));
            const response = await feedService.updateStatuses({ status: flatStatuses, ...(isCompanyFeedsConfig ? { company_id: Number(companyId) } : {}) });
            toast.fire({
                icon: TOAST_STATUS.SUCCESS,
                title: response.data.message,
            });
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: message,
            });
        } finally {
            setUpdateLoading(false);
        }
    };

    const onChangeFeedStatus = (parentId: number, id: number) => {
        setStatuses((prev) =>
            prev?.map((parentItem) =>
                parentItem.id === parentId
                    ? { ...parentItem, items: parentItem.items.map((item) => (item.id === id ? { ...item, is_active: !item.is_active } : item)) }
                    : parentItem
            )
        );
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (loading) return <InitLoading />;

    return (
        <>
            {!isCompanyFeedsConfig && (
                <DashboardTitle
                    title="Feeds config"
                    titleSuffix={
                        <div style={{ marginLeft: 'auto' }}>
                            <Button size="s" type="button" onClick={() => navigate(-1)}>
                                Cancel
                            </Button>
                            <Button size="s" disabled={updateLoading} loading={updateLoading} color="primary" onClick={onSubmit}>
                                Update
                            </Button>
                        </div>
                    }
                />
            )}

            <DashboardContentWrapper
                {...(isCompanyFeedsConfig
                    ? {
                          title: 'Company Feeds config',
                          titleSuffix: (
                              <Button size="s" disabled={updateLoading} loading={updateLoading} color="primary" onClick={onSubmit}>
                                  Update Company Feeds Config
                              </Button>
                          ),
                      }
                    : {})}
            >
                {statuses?.map(({ id: parentId, items, name }) => (
                    <Accordion key={parentId} title={name} style={{ width: '100%', padding: '0' }}>
                        <AccordionTab header={name}>
                            {items.map(({ id, is_active, name }) => (
                                <div key={id} className={style.accordionItem} onClick={() => onChangeFeedStatus(parentId, id)}>
                                    <Checkbox value={id} checked={is_active} />
                                    <label>{name}</label>
                                </div>
                            ))}
                        </AccordionTab>
                    </Accordion>
                ))}
            </DashboardContentWrapper>
        </>
    );
};

export default Feeds;
