import { ReactNode } from 'react';
import { IFunctionalComponentProps } from 'src/types/functional-component';
import styles from './DashboardContentWrapper.module.scss';
import Tab from '../../components/Kit/Tab/index';

export interface ITabConfig {
    id: number | string;
    title: string;
    content: ReactNode;
    disabled?: boolean;
}
interface IDashboardContentWrapperProps extends IFunctionalComponentProps {
    title?: string;
    tabs?: ITabConfig[];
    mode?: 'base' | 'inner';
    titleSuffix?: ReactNode;
    tabSuffix?: ReactNode;
    queryName?: string;
    customStyle?: any;
    noPadding?: boolean
}

const DashboardContentWrapper: React.FC<IDashboardContentWrapperProps> = ({
    title = '',
    tabs = [],
    titleSuffix = null,
    mode = 'base',
    children,
    queryName,
    tabSuffix = null,
    customStyle,
    noPadding
}) => {
    return (
        <div style={{...customStyle, padding: noPadding && '0px'}} className={`${styles['dashboard-box']} ${mode === 'inner' ? styles['dashboard-box--inner'] : ''}`}>
            {title && (
                <div className={styles['dashboard-box__title']}>
                    <span>{title}</span>
                    <div className={styles['dashboard-box__title-suffix']}>{titleSuffix}</div>
                </div>
            )}
            {tabs.length ? (
                <Tab config={tabs} queryName={queryName} tabSuffix={tabSuffix} />
            ) : (
                <div className={styles['dashboard-box__content']}>{children}</div>
            )}
        </div>
    );
};

export default DashboardContentWrapper;
