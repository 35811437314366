import { API_ROUTES } from 'src/constants/api-routes';
import { AxiosInstance } from '../index';
import { FINANCIAL_INFORMATION_FILTER_TYPE } from 'src/constants/financial-information-filter-type';
import {
    IAssignPersonToCompanyRequest,
    IBaseCompany,
    IBaseFinancialInformationValueByTypeResponse,
    IBaseFinancialInformationValueResponse,
    IChangeCompanyStatusPayload,
    ICommentResponse,
    ICommentShowResponse,
    ICreateCommentPayload,
    ICreateCompanyPayload,
    ICreateFinancialInformationResponse,
    ICreateFinancialReportPayload,
    ICreateParentCompanyFinancialPayload,
    IEditCompanyPayload,
    IExcelResponse,
    IFinancialDateResponse,
    IFinancialInformationCreateItemPayload,
    IFinancialInformationFormulaUsageResponse,
    IFinancialInformationRatioDatesResponse,
    IFinancialInformationRatioResponse,
    IGetAssignPositionTypesResponse,
    IGetCompanyDividendsResponse,
    IGetCompanyParentFinancialResponse,
    IGetFinancialReportLanguagesResponse,
    IGetFinancialReportResponse,
    IGetPeopleResponse,
    IPaginatedCompanyResponse,
    IParentCompanyFinancialShowResponse,
    ISearchedItem,
    ISheetData,
    ISheetType,
    ISheetTypePayloadRequest,
    IShowPersonResponse,
    ITemplatesResponse,
    IUpdateFinancialReportPayload,
} from 'src/api/types/company';
import {
    IBaseCompanyList,
    IImportExcelPayloadRequest,
    IQuarterPayloadRequest,
    ISearchItemQueryParams,
    ISheetsPayloadRequest,
    ISheetsResponse,
    IYearsPayloadRequest,
} from '../types/company';

import { ICreateDividendsPayload } from '../types/dividends';
import { IDataTablePayload, IDataTableResponse } from 'src/api/types/table';
import { IAxiosResponse } from '../types/axios-response';
import { getAxiosError } from '../../utils/get-axios-error';
import { financialActions } from '../../context/companies';
import { IFinancialInformationTypesResponse } from '../types/financial-information';
import { COMPANY_TYPE_ROUTE } from 'src/pages/Company/Add/enums/company-type-route';
import { companyTypeParam } from 'src/constants/company/company-type-param';
import { ISelectBox } from '../types/base-types';

export const companyService = (companyTypeRoute: COMPANY_TYPE_ROUTE) => {
    const companyType = companyTypeParam[companyTypeRoute];

    // Function to get route with company type appended
    function getRouteWithCompanyType(route: string) {
        let typeRoute = `/companies/${companyType}`;
        const ignoreList = ['financial-information/items', 'languages', 'assign-position-types'];

        const hasIgnore = ignoreList.find((word) => route.includes(word));
        if (hasIgnore) return route;

        if (route.includes('/companies/financial-information/')) {
            typeRoute = `/companies/financial-information/${companyType}`;
            return route.replace('/companies/financial-information', typeRoute);
        }

        return route.replace('/companies', typeRoute);
    }

    return {
        getAll(data?: IDataTablePayload) {
            return AxiosInstance.get<IDataTableResponse<IBaseCompanyList>>(getRouteWithCompanyType(API_ROUTES.COMPANY.ROOT), {
                params: { ...data },
            });
        },
        show(id: number) {
            return AxiosInstance.get<IAxiosResponse<IBaseCompany>>(getRouteWithCompanyType(API_ROUTES.COMPANY.SHOW(id)));
        },
        uploadExcel(id: number, data: IImportExcelPayloadRequest) {
            return AxiosInstance.post<IAxiosResponse<string>>(getRouteWithCompanyType(API_ROUTES.COMPANY.UPLOAD_EXCEL(id)), data);
        },
        sheets(id: number) {
            return AxiosInstance.get<IAxiosResponse<ISheetsResponse[]>>(getRouteWithCompanyType(API_ROUTES.COMPANY.SHEETS(id)));
        },
        annualSheet(id: number, sheetId: number, data?: Omit<ISheetsPayloadRequest, 'filter_type'>) {
            return AxiosInstance.get<IAxiosResponse<ISheetData[]>>(getRouteWithCompanyType(API_ROUTES.COMPANY.SHEET(id, sheetId)), {
                params: { ...data, filter_type: data ? FINANCIAL_INFORMATION_FILTER_TYPE.ANNUAL : undefined },
            });
        },
        quarterSheet(id: number, sheetId: number, data?: Omit<ISheetsPayloadRequest, 'filter_type'>) {
            return AxiosInstance.get<IAxiosResponse<ISheetData[]>>(getRouteWithCompanyType(API_ROUTES.COMPANY.SHEET(id, sheetId)), {
                params: { ...data, filter_type: data ? FINANCIAL_INFORMATION_FILTER_TYPE.QUARTER : undefined },
            });
        },
        ratios(id: number, data?: ISheetsPayloadRequest) {
            return AxiosInstance.get<IAxiosResponse<ISheetData[]>>(getRouteWithCompanyType(API_ROUTES.COMPANY.RATIOS(id)), { params: data });
        },
        marketMultipleRatios(id: number, data?: ISheetsPayloadRequest) {
            return AxiosInstance.get<IAxiosResponse<ISheetData[]>>(getRouteWithCompanyType(API_ROUTES.COMPANY.MARKET_RATIOS(id)), { params: data });
        },
        excels(id: number) {
            return AxiosInstance.get<IAxiosResponse<IExcelResponse[]>>(getRouteWithCompanyType(API_ROUTES.COMPANY.EXCELS(id)));
        },
        annualValidYears(id: number, data?: IYearsPayloadRequest) {
            return AxiosInstance.get<IAxiosResponse<number[]>>(getRouteWithCompanyType(API_ROUTES.COMPANY.ANNUAL_VALID_YEARS(id)), { params: data });
        },
        quarterValidYears(id: number, data?: IYearsPayloadRequest) {
            return AxiosInstance.get<IAxiosResponse<number[]>>(getRouteWithCompanyType(API_ROUTES.COMPANY.QUARTER_VALID_YEARS(id)), { params: data });
        },
        validQuarters(id: number, data?: IQuarterPayloadRequest) {
            return AxiosInstance.get<IAxiosResponse<number[]>>(getRouteWithCompanyType(API_ROUTES.COMPANY.VALID_QUARTERS(id)), { params: data });
        },
        sheetTypes() {
            return AxiosInstance.get<IAxiosResponse<ISheetType[]>>(getRouteWithCompanyType(API_ROUTES.COMPANY.SHEET_TYPES));
        },
        storeSheetTypes(data?: ISheetTypePayloadRequest) {
            return AxiosInstance.post<IAxiosResponse<any>>(getRouteWithCompanyType(API_ROUTES.COMPANY.STORE_SHEET_TYPES), data);
        },
        getFinancialInformationSearchedItems(data: ISearchItemQueryParams) {
            return AxiosInstance.get<IAxiosResponse<ISearchedItem>>(getRouteWithCompanyType(API_ROUTES.COMPANY.FINANCIAL_INFORMATION_SEARCHED_ITEM), {
                params: data,
            });
        },
        getListOfCategories(data: any) {
            return AxiosInstance.get<IAxiosResponse<any>>(getRouteWithCompanyType(API_ROUTES.COMPANY.FINANCIAL_INFORMATION_SEARCHED_CATEGORIES), {
                params: data,
            });
        },
        getListOfSubcategories(data: any) {
            return AxiosInstance.get<IAxiosResponse<any>>(getRouteWithCompanyType(API_ROUTES.COMPANY.FINANCIAL_INFORMATION_SEARCHED_SUB_CATEGORIES), {
                params: data,
            });
        },
        financialInformationCreateItem(data: IFinancialInformationCreateItemPayload) {
            return AxiosInstance.post<IAxiosResponse<ICreateFinancialInformationResponse>>(
                getRouteWithCompanyType(API_ROUTES.COMPANY.FINANCIAL_INFORMATION_CREATE_ITEM),
                data
            );
        },
        createNewCompany(data: ICreateCompanyPayload) {
            return AxiosInstance.post<IAxiosResponse<ICreateCompanyPayload>>(getRouteWithCompanyType(API_ROUTES.COMPANY.CREATE_NEW_COMPANY), data);
        },
        editCompany(companyId: number, data: IEditCompanyPayload) {
            return AxiosInstance.put<IAxiosResponse>(getRouteWithCompanyType(API_ROUTES.COMPANY.EDIT(companyId)), data);
        },
        getTemplates() {
            return AxiosInstance.get<IAxiosResponse<ITemplatesResponse[]>>(getRouteWithCompanyType(API_ROUTES.COMPANY.GET_TEMPLATES));
        },
        sendSelectedFinancialTemplate(companyId: number, selectedTemplateId: number) {
            return AxiosInstance.post<IAxiosResponse<{ id: number }>>(
                getRouteWithCompanyType(API_ROUTES.COMPANY.SAVE_TEMPLATES(companyId, selectedTemplateId)),
                {}
            );
        },
        saveSelectedYearEndAndCurrency(companyId: number, tempData: { month: number; currency_id: number }) {
            return AxiosInstance.put<IAxiosResponse<{ currency_id: number; month: number }>>(
                getRouteWithCompanyType(API_ROUTES.COMPANY.SAVE_CURRENCY_AND_END_YEAR(companyId)),
                tempData
            );
        },
        getFinancialTypes(companyId: number) {
            // @TODO error and information handling is errored
            financialActions.setFinancialLoading(true);
            return AxiosInstance.get<IAxiosResponse<any>>(getRouteWithCompanyType(API_ROUTES.COMPANY.GET_FINANCIAL_TYPES_OF_THIS_COMPANY(companyId)))
                .then((res) => {
                    const financialTypesResult = res.data.data;
                    financialActions.addTypes(financialTypesResult.map((type: IFinancialInformationTypesResponse) => ({ id: type.id, name: type.name })));
                    financialActions.setSelectedFinancialType(financialTypesResult[0]?.id);
                    return financialTypesResult;
                })
                .catch((err) => getAxiosError(err).message)
                .finally(() => financialActions.setFinancialLoading(false));
        },
        getSelectedTypeDates(companyId: number, selectedType: number) {
            return AxiosInstance.get<IAxiosResponse<any>>(getRouteWithCompanyType(API_ROUTES.COMPANY.GET_SELECTED_TYPE_DATES(companyId, selectedType)));
        },
        getAllDates(companyId: number, params: { financialTypeId?: number }) {
            return AxiosInstance.get<IAxiosResponse<IFinancialDateResponse[]>>(getRouteWithCompanyType(API_ROUTES.COMPANY.GET_ALL_DATES(companyId)), {
                params,
            });
        },
        addFinancialInformationDates(companyId: number, data: any) {
            return AxiosInstance.post<IAxiosResponse<any>>(getRouteWithCompanyType(API_ROUTES.COMPANY.ADD_COMPANY_DATES(companyId)), data);
        },
        getRows(companyId: number, selectedType: number | undefined, params: any) {
            if (selectedType)
                return AxiosInstance.get<IAxiosResponse<IBaseFinancialInformationValueResponse[]>>(
                    getRouteWithCompanyType(API_ROUTES.COMPANY.GET_ROWS(companyId, selectedType)),
                    {
                        params,
                    }
                );
            else return AxiosInstance.get<IAxiosResponse<any>>(getRouteWithCompanyType(API_ROUTES.COMPANY.GET_ROWS_COMPACT(companyId)), { params });
        },
        getRowsByType(companyId: number, selectedType: number | undefined) {
            if (selectedType)
                return AxiosInstance.get<IAxiosResponse<IBaseFinancialInformationValueByTypeResponse[]>>(
                    getRouteWithCompanyType(API_ROUTES.COMPANY.GET_ROWS_BY_TYPE(companyId, selectedType))
                );
            else
                return AxiosInstance.get<IAxiosResponse<IBaseFinancialInformationValueByTypeResponse[]>>(
                    getRouteWithCompanyType(API_ROUTES.COMPANY.GET_ROWS_BY_TYPE_COMPACT(companyId))
                );
        },
        saveRows(companyId: number, selectedType: any, data: any) {
            if (selectedType)
                return AxiosInstance.post<IAxiosResponse<any>>(getRouteWithCompanyType(API_ROUTES.COMPANY.SAVE_ROWS(companyId, selectedType)), data);
            else return AxiosInstance.post<IAxiosResponse<{ id: number }>>(getRouteWithCompanyType(API_ROUTES.COMPANY.SAVE_ROWS_COMPACT(companyId)), data);
        },
        deleteDate(companyId: number, data: any) {
            return AxiosInstance.deleteByBody<IAxiosResponse<any>>(getRouteWithCompanyType(API_ROUTES.COMPANY.DELETE_DATE(companyId)), data);
        },
        deleteRow(companyId: number, itemId: number) {
            return AxiosInstance.delete<IAxiosResponse<any>>(getRouteWithCompanyType(API_ROUTES.COMPANY.DELETE_ROW(companyId, itemId)));
        },
        formulaUsage(companyId: number, itemId: number) {
            return AxiosInstance.get<IAxiosResponse<IFinancialInformationFormulaUsageResponse[]>>(
                getRouteWithCompanyType(API_ROUTES.COMPANY.FORMULA_USAGE(companyId, itemId))
            );
        },
        getAllFinancialReport(companyId: number, data?: IDataTablePayload) {
            return AxiosInstance.get<IDataTableResponse<IGetFinancialReportResponse>>(
                getRouteWithCompanyType(API_ROUTES.COMPANY.FINANCIAL_REPORTS(companyId)),
                { params: data }
            );
        },
        updateFinancialReport(companyId: number, id: number, data: IUpdateFinancialReportPayload) {
            return AxiosInstance.put<IAxiosResponse>(getRouteWithCompanyType(API_ROUTES.COMPANY.FINANCIAL_REPORTS_EDIT(companyId, id)), data);
        },
        deleteFinancialReport(companyId: number, id: number) {
            return AxiosInstance.delete<IAxiosResponse>(getRouteWithCompanyType(API_ROUTES.COMPANY.FINANCIAL_REPORTS_EDIT(companyId, id)));
        },
        getFinancialReport(companyId: number, id: number) {
            return AxiosInstance.get<IAxiosResponse<Omit<IGetFinancialReportResponse, 'id'>>>(
                getRouteWithCompanyType(API_ROUTES.COMPANY.FINANCIAL_REPORTS_EDIT(companyId, id))
            );
        },
        createNewFinancialReport(companyId: number, data: ICreateFinancialReportPayload) {
            return AxiosInstance.post<IAxiosResponse>(getRouteWithCompanyType(API_ROUTES.COMPANY.FINANCIAL_REPORTS(companyId)), data);
        },
        getFinancialReportLanguages() {
            return AxiosInstance.get<IAxiosResponse<IGetFinancialReportLanguagesResponse>>(
                getRouteWithCompanyType(API_ROUTES.COMPANY.FINANCIAL_REPORTS_GET_LANGUAGES)
            );
        },
        changeFinancialInformationStatus(body: { financialInformationId: number; status: number }) {
            return AxiosInstance.put<IAxiosResponse<any>>(getRouteWithCompanyType(API_ROUTES.COMPANY.FINANCIAL_INFORMATION_CHANGE_STATUS), body);
        },
        getAllPeople(companyId: number, data?: IDataTablePayload) {
            return AxiosInstance.get<IDataTableResponse<IGetPeopleResponse>>(getRouteWithCompanyType(API_ROUTES.COMPANY.PEOPLE_LIST(companyId)), {
                params: data,
            });
        },
        getAssignPositionTypes() {
            return AxiosInstance.get<IAxiosResponse<IGetAssignPositionTypesResponse>>(getRouteWithCompanyType(API_ROUTES.COMPANY.PEOPLE_ASSIGN_POSITION_TYPES));
        },
        showPerson(companyId: number, personId: number) {
            return AxiosInstance.get<IAxiosResponse<IShowPersonResponse>>(getRouteWithCompanyType(API_ROUTES.COMPANY.PEOPLE_SHOW_PERSON(companyId, personId)));
        },
        deleteAssignedPersonFromCompany(companyId: number, personId: number) {
            return AxiosInstance.delete<IAxiosResponse>(
                getRouteWithCompanyType(API_ROUTES.COMPANY.PEOPLE_REMOVE_ASSIGNED_PERSON_FROM_COMPANY(companyId, personId))
            );
        },
        assignPersonToCompany(companyId: number, data: IAssignPersonToCompanyRequest) {
            return AxiosInstance.post<IAxiosResponse>(getRouteWithCompanyType(API_ROUTES.COMPANY.PEOPLE_ASSIGN_PERSON_TO_COMPANY(companyId)), data);
        },
        updateAssignedPersonFromCompany(companyId: number, personId: number, body: Omit<IAssignPersonToCompanyRequest, 'person_id'>) {
            return AxiosInstance.put<IAxiosResponse>(
                getRouteWithCompanyType(API_ROUTES.COMPANY.PEOPLE_UPDATE_ASSIGNED_PERSON_FROM_COMPANY(companyId, personId)),
                body
            );
        },
        createDividends(companyId: number, data: ICreateDividendsPayload) {
            return AxiosInstance.post<IAxiosResponse>(getRouteWithCompanyType(API_ROUTES.COMPANY.CREATE_DIVIDENDS(companyId)), data);
        },
        updateDividends(companyId: number, dividendsID: number, data: ICreateDividendsPayload) {
            return AxiosInstance.put<IAxiosResponse>(getRouteWithCompanyType(API_ROUTES.COMPANY.UPDATE_DIVIDENDS(companyId, dividendsID)), data);
        },
        getAllDividends(companyId: number, data?: IDataTablePayload) {
            return AxiosInstance.get<IDataTableResponse<IGetCompanyDividendsResponse>>(getRouteWithCompanyType(API_ROUTES.COMPANY.ALL_DIVIDENDS(companyId)), {
                params: data,
            });
        },
        changeStatus(companyId: number, data?: IChangeCompanyStatusPayload) {
            return AxiosInstance.put<IAxiosResponse>(getRouteWithCompanyType(API_ROUTES.COMPANY.CHANGE_STATUS(companyId)), data);
        },
        getFinancialInformationRatios(companyId: number, data: { year: number; quarter?: number }) {
            return AxiosInstance.get<IAxiosResponse<IFinancialInformationRatioResponse[]>>(
                getRouteWithCompanyType(API_ROUTES.COMPANY.FINANCIAL_INFORMATION_RATIOS(companyId)),
                {
                    params: data,
                }
            );
        },
        getFinancialInformationRatiosDates(companyId: number) {
            return AxiosInstance.get<IAxiosResponse<IFinancialInformationRatioDatesResponse[]>>(
                getRouteWithCompanyType(API_ROUTES.COMPANY.FINANCIAL_INFORMATION_RATIOS_DATES(companyId))
            );
        },
        getAllParentCompanyFinancial(companyId: number, data?: IDataTablePayload) {
            return AxiosInstance.get<IDataTableResponse<IGetCompanyParentFinancialResponse>>(
                getRouteWithCompanyType(API_ROUTES.COMPANY.PARENT_COMPANY_FINANCIAL(companyId)),
                { params: data }
            );
        },
        deleteParentCompanyFinancial(companyId: number, id: number) {
            return AxiosInstance.delete<IAxiosResponse>(getRouteWithCompanyType(API_ROUTES.COMPANY.DELETE_PARENT_COMPANY_FINANCIAL(companyId, id)));
        },
        selectBox(data?: IPaginatedCompanyResponse) {
            return AxiosInstance.get<IAxiosResponse<ISelectBox[]>>(getRouteWithCompanyType(API_ROUTES.COMPANY.DROPDOWN), { params: data });
        },
        createParentCompanyFinancial(companyId: number, data: ICreateParentCompanyFinancialPayload) {
            return AxiosInstance.post<IAxiosResponse>(getRouteWithCompanyType(API_ROUTES.COMPANY.CREATE_PARENT_COMPANY_FINANCIAL(companyId)), data);
        },
        updateParentCompanyFinancial(companyId: number, id: number, data: ICreateParentCompanyFinancialPayload) {
            return AxiosInstance.put<IAxiosResponse>(getRouteWithCompanyType(API_ROUTES.COMPANY.UPDATE_PARENT_COMPANY_FINANCIAL(companyId, id)), data);
        },
        getOneParentCompanyFinancial(companyId: number, id: number) {
            return AxiosInstance.get<IAxiosResponse<IParentCompanyFinancialShowResponse>>(
                getRouteWithCompanyType(API_ROUTES.COMPANY.GET_ONE_PARENT_COMPANY_FINANCIAL(companyId, id))
            );
        },
        getAllComments(companyId: number, params?: IDataTablePayload) {
            return AxiosInstance.get<IDataTableResponse<ICommentResponse>>(getRouteWithCompanyType(API_ROUTES.COMPANY.COMMENTS(companyId)), {
                params,
            });
        },
        showComment(companyId: number, id: number) {
            return AxiosInstance.get<IAxiosResponse<ICommentShowResponse>>(getRouteWithCompanyType(API_ROUTES.COMPANY.SHOW_COMMENT(companyId, id)));
        },
        createComment(companyId: number, data?: ICreateCommentPayload) {
            return AxiosInstance.post<IAxiosResponse>(getRouteWithCompanyType(API_ROUTES.COMPANY.CERATE_COMMENT(companyId)), data);
        },
        updateComment(companyId: number, id: number, data?: ICreateCommentPayload) {
            return AxiosInstance.put<IAxiosResponse>(getRouteWithCompanyType(API_ROUTES.COMPANY.UPDATE_COMMENT(companyId, id)), data);
        },
        deleteComment(companyId: number, id: number) {
            return AxiosInstance.delete<IAxiosResponse>(getRouteWithCompanyType(API_ROUTES.COMPANY.DELETE_COMMENT(companyId, id)));
        },
    };
};
