import React, { useState } from 'react';
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { createValidationSchema } from './validation-schema';
import Button from 'src/components/Kit/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { getAxiosError } from 'src/utils/get-axios-error';
import { toast } from 'src/utils/toast';
import { TOAST_STATUS } from 'src/constants/toast-status';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import FormFieldWrapper from 'src/components/Kit/FormFieldWrapper';
import { Dropdown, DropdownChangeParams } from 'primereact/dropdown';
import { companyService } from 'src/api/services/company';
import { useGetFinancialDataTypes } from 'src/hooks/company/useGetFinancialDataTypes';
import { IFormData } from './types';
import { ITEM_TYPE_OPTION, ITEM_VALUE_TYPE_OPTIONS, NUMERIC_TYPE_OPTIONS } from 'src/constants/company/financialInformation/financialItem';
import { ITEM_TYPE_ENUM, ITEM_VALUE_TYPE_OPTIONS_ENUM } from 'src/enums/company/financialInformation/financialItem';
import { IFinancialInformationCreateItemPayload } from 'src/api/types/company';
import GeneralFormErrorMessage from 'src/components/App/GeneralFormErrorMessage';
import SearchableDropdown from 'src/components/Kit/SearchableDropdown';
import { DROPDOWNS_FILTER } from 'src/components/App/Table/enums';
import { COMPANY_TYPE_ROUTE } from 'src/pages/Company/Add/enums/company-type-route';
import { IParams } from 'src/types/params';

const AddUpdate: React.FC = () => {
    const navigate = useNavigate();
    const { companyType } = useParams<IParams>();
    const { financialDataTypes } = useGetFinancialDataTypes();
    const [loading, setLoading] = useState<boolean>(false);
    const [activeItemDataType, setActiveItemDataType] = useState<number>();
    const [activeItemType, setActiveItemType] = useState<number>();
    const [activeItemValueType, setActiveItemValueType] = useState<ITEM_VALUE_TYPE_OPTIONS_ENUM>();
    const [apiError, setApiError] = useState<string>('');

    const {
        control,
        formState: { errors },
        handleSubmit,
        setValue,
    } = useForm<IFormData>({
        defaultValues: {
            name: '',
            arabic_name: '',
            item_type: undefined,
            item_value_type: null,
            item_data_type: undefined,
            number_type: undefined,
            category: undefined,
            subcategory: undefined,
        },
        mode: 'all',
        resolver: yupResolver(createValidationSchema()),
    });

    const onSubmit = async (_val: IFormData) => {
        const payloadData = {
            name: _val.name,
            arabic_name: _val.arabic_name,
            type: _val.item_type,
            type_id: _val.item_data_type,
        } as IFinancialInformationCreateItemPayload;

        switch (Number(_val.item_type)) {
            case ITEM_TYPE_ENUM.TITLE:
            case ITEM_TYPE_ENUM.SUBTITLE:
                payloadData.value_type = null;
                break;
            case ITEM_TYPE_ENUM.TOTAL:
            case ITEM_TYPE_ENUM.SUBTOTAL:
            case ITEM_TYPE_ENUM.ITEM:
                payloadData.value_type = _val.item_value_type;
                if (_val.category) payloadData.category_id = _val.category;
                if (_val.subcategory) payloadData.sub_category_id = _val.subcategory;
                if (Number(_val.item_value_type) === ITEM_VALUE_TYPE_OPTIONS_ENUM.NUMERIC && _val.number_type) payloadData.number_type = _val.number_type;
                break;
            default:
                break;
        }
        try {
            setLoading(true);
            const response = await companyService(companyType as COMPANY_TYPE_ROUTE).financialInformationCreateItem(payloadData);
            if (response.data.success) {
                toast.fire({
                    icon: TOAST_STATUS.SUCCESS,
                    title: response.data.message,
                });
                navigate(-1);
            }
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            setApiError(message);
        } finally {
            setLoading(false);
        }
    };

    const handleChangeItemDataType = (e: DropdownChangeParams, field: ControllerRenderProps<IFormData, 'item_data_type'>) => {
        field.onChange(e.value);
        setValue('category', '');
        setValue('subcategory', '');
        setActiveItemDataType(e.value);
    };

    const handleChangeItemType = (e: DropdownChangeParams, field: ControllerRenderProps<IFormData, 'item_type'>) => {
        field.onChange(e.value);
        setValue('item_value_type', null);
        setActiveItemType(e.value);
    };

    const handleChangeItemValueType = (e: DropdownChangeParams, field: ControllerRenderProps<IFormData, 'item_value_type'>) => {
        field.onChange(e.value);
        setActiveItemValueType(e.value);
        setValue('number_type', null);
    };

    return (
        <div className="grid justify-content-center my-8">
            <div className="xs:col:12 md:col-8 xl:col-5">
                <span className="block text-2xl font-semibold">Add New Item</span>
                <div className="mt-5">
                    {apiError && <GeneralFormErrorMessage message={apiError} />}
                    <form noValidate data-cy="login-form" onSubmit={handleSubmit(onSubmit)}>
                        <FormFieldWrapper>
                            <label className="mt-4" htmlFor="name">
                                Item Name<span className="required">*</span>
                            </label>
                            <Controller
                                name="name"
                                control={control}
                                render={({ field }) => (
                                    <>
                                        <InputText
                                            type="name"
                                            {...field}
                                            id="name"
                                            data-cy="name-input"
                                            style={{ width: '100%' }}
                                            placeholder="Item Name"
                                            className={classNames(
                                                'p-inputtext',
                                                { 'form-input--has-error': !!errors.name },
                                                { 'form-input--fill': field.value.length }
                                            )}
                                        />
                                        <p className="form-input-error" data-cy="name-error">
                                            {errors?.name?.message}
                                        </p>
                                    </>
                                )}
                            />
                        </FormFieldWrapper>
                        <FormFieldWrapper>
                            <label className="mt-4" htmlFor="arabic_name">
                                Arabic Name
                            </label>
                            <Controller
                                name="arabic_name"
                                control={control}
                                render={({ field }) => (
                                    <>
                                        <InputText
                                            type="arabic_name"
                                            {...field}
                                            id="arabic_name"
                                            data-cy="arabic_name-input"
                                            style={{ width: '100%' }}
                                            placeholder="Item Arabic Name"
                                            className={classNames(
                                                'p-inputtext',
                                                { 'form-input--has-error': !!errors.name },
                                                { 'form-input--fill': field.value.length }
                                            )}
                                        />
                                        <p className="form-input-error" data-cy="arabic_name-error">
                                            {errors?.arabic_name?.message}
                                        </p>
                                    </>
                                )}
                            />
                        </FormFieldWrapper>
                        <FormFieldWrapper>
                            <label className="mt-4" htmlFor="item_data_type">
                                Financial Data Type <span className="required">*</span>
                            </label>
                            <Controller
                                name="item_data_type"
                                control={control}
                                render={({ field }) => (
                                    <>
                                        <Dropdown
                                            optionLabel="label"
                                            optionValue="value"
                                            value={field.value}
                                            options={financialDataTypes}
                                            onChange={(e: DropdownChangeParams) => handleChangeItemDataType(e, field)}
                                            placeholder="Item Data Type"
                                        />
                                        <p className="form-input-error" data-cy="item_data_type-error">
                                            {errors?.item_data_type?.message}
                                        </p>
                                    </>
                                )}
                            />
                        </FormFieldWrapper>
                        <FormFieldWrapper>
                            <label className="mt-4" htmlFor="item_type">
                                Item Type <span className="required">*</span>
                            </label>
                            <Controller
                                name="item_type"
                                control={control}
                                render={({ field }) => (
                                    <>
                                        <Dropdown
                                            optionLabel="label"
                                            optionValue="value"
                                            value={field.value}
                                            options={ITEM_TYPE_OPTION}
                                            onChange={(e) => handleChangeItemType(e, field)}
                                            placeholder="Item Type"
                                        />
                                        <p className="form-input-error" data-cy="item_type-error">
                                            {errors?.item_type?.message}
                                        </p>
                                    </>
                                )}
                            />
                        </FormFieldWrapper>
                        {[ITEM_TYPE_ENUM.ITEM, ITEM_TYPE_ENUM.TOTAL, ITEM_TYPE_ENUM.SUBTOTAL].includes(activeItemType as any) && (
                            <>
                                <FormFieldWrapper>
                                    <label className="mt-4" htmlFor="item_value_type">
                                        Item Value Type <span className="required">*</span>
                                    </label>
                                    <Controller
                                        name="item_value_type"
                                        control={control}
                                        render={({ field }) => (
                                            <>
                                                <Dropdown
                                                    optionLabel="label"
                                                    optionValue="value"
                                                    value={field.value}
                                                    options={ITEM_VALUE_TYPE_OPTIONS}
                                                    onChange={(e) => handleChangeItemValueType(e, field)}
                                                    placeholder="Item Value Type"
                                                />
                                                <p className="form-input-error" data-cy="item_value_type-error">
                                                    {errors?.item_value_type?.message}
                                                </p>
                                            </>
                                        )}
                                    />
                                </FormFieldWrapper>
                                {activeItemValueType === ITEM_VALUE_TYPE_OPTIONS_ENUM.NUMERIC && (
                                    <FormFieldWrapper>
                                        <label className="mt-4" htmlFor="number_type">
                                            Numeric Type <span className="required">*</span>
                                        </label>
                                        <Controller
                                            name="number_type"
                                            control={control}
                                            render={({ field }) => (
                                                <>
                                                    <Dropdown
                                                        optionLabel="label"
                                                        optionValue="value"
                                                        value={field.value}
                                                        options={NUMERIC_TYPE_OPTIONS}
                                                        onChange={(e) => field.onChange(e.value)}
                                                        placeholder="Numeric Type"
                                                    />
                                                    {errors.number_type && <p className="form-input-error">{errors.number_type.message}</p>}
                                                </>
                                            )}
                                        />
                                    </FormFieldWrapper>
                                )}
                            </>
                        )}
                        {activeItemType !== ITEM_TYPE_ENUM.TITLE && activeItemType !== ITEM_TYPE_ENUM.SUBTITLE && (
                            <>
                                <FormFieldWrapper>
                                    <label className="mt-4" htmlFor="category">
                                        Category
                                    </label>
                                    <Controller
                                        name="category"
                                        control={control}
                                        render={({ field }) => (
                                            <>
                                                <SearchableDropdown
                                                    config={{
                                                        reference: DROPDOWNS_FILTER.FINANCIAL_INFORMATION_CATEGORY,
                                                        searchable: true,
                                                        ...(activeItemDataType && {
                                                            params: {
                                                                company_financial_information_type_id: activeItemDataType,
                                                            },
                                                        }),
                                                        dropdownProps: {
                                                            disabled: !activeItemDataType,
                                                        },
                                                    }}
                                                    defaultValue={field.value}
                                                    label="Category"
                                                    name="category"
                                                    onChange={(e) => field.onChange(e)}
                                                />
                                                {errors?.category?.message && (
                                                    <p className="form-input-error" data-cy="category-error">
                                                        {errors?.category?.message.toString()}
                                                    </p>
                                                )}
                                            </>
                                        )}
                                    />
                                </FormFieldWrapper>
                                <FormFieldWrapper>
                                    <label className="mt-4" htmlFor="subcategory">
                                        Subcategory
                                    </label>
                                    <Controller
                                        name="subcategory"
                                        control={control}
                                        render={({ field }) => (
                                            <>
                                                <SearchableDropdown
                                                    config={{
                                                        reference: DROPDOWNS_FILTER.FINANCIAL_INFORMATION_SUB_CATEGORY,
                                                        searchable: true,
                                                        ...(activeItemDataType && {
                                                            params: {
                                                                company_financial_information_type_id: activeItemDataType,
                                                            },
                                                        }),
                                                        dropdownProps: {
                                                            disabled: !activeItemDataType,
                                                        },
                                                    }}
                                                    defaultValue={field.value}
                                                    label="Sub Category"
                                                    name="subcategory"
                                                    onChange={(e) => field.onChange(e)}
                                                />
                                                {errors?.subcategory?.message && (
                                                    <p className="form-input-error" data-cy="subcategory-error">
                                                        {errors?.subcategory?.message.toString()}
                                                    </p>
                                                )}
                                            </>
                                        )}
                                    />
                                </FormFieldWrapper>
                            </>
                        )}
                        <div className="mt-4">
                            <Button disabled={loading} loading={loading} color="primary">
                                Create
                            </Button>
                            <Button type="button" onClick={() => navigate(-1)}>
                                Cancel
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AddUpdate;
