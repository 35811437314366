import { FORM_INPUTS } from 'src/types/form';
import { IFormGeneratorSchemaType } from 'src/types/form-generator-schema-type';
import { companyTypeOptions } from '../Add/constants/company-type-options';
import { companyListingStructureOptions } from '../Add/constants/company-listing-structure-options';
import { DATE_FORMAT } from '../enums/date-format';
import { DATE_PICKER_VIEW } from '../enums/date-picker-view';
import { authorityDropdownOptions } from '../List/constants/authority-dropdown-options';
import { chamberDropdownOptions } from '../List/constants/chamber-dropdown-options';

export type OtherInformationSchemaFields =
    | 'type'
    | 'legal_structure_id'
    | 'listing_status'
    | 'ISIN'
    | 'issued_shared'
    | 'inception_date'
    | 'stock_exchange_id'
    | 'sector_id'
    | 'field_set_1'
    | 'chamber_id'
    | 'chamber_number'
    | 'registration_website'
    | 'registration_date'
    | 'authority_id'
    | 'license_number'
    | 'license_website'
    | 'license_date';

export type IGetNewOptionsFunction = (search: string) => Promise<{ label: string; value: string }[]>;

export type OtherInformationSchemaType = Record<OtherInformationSchemaFields, IFormGeneratorSchemaType>;
export const otherInformationSchema: OtherInformationSchemaType = {
    type: {
        label: 'Type',
        placeholder: 'What is your type of company?',
        type: FORM_INPUTS.RADIO,
        props: {
            required: true,
        },
    },
    legal_structure_id: {
        label: 'Legal Structure',
        placeholder: 'Select Legal Structure',
        type: FORM_INPUTS.SELECT,
        className: 'lg:col-6',
        props: {
            required: true,
            filter: true,
        },
    },
    listing_status: {
        label: 'Listing Status',
        placeholder: 'Select Listing Status',
        type: FORM_INPUTS.SELECT,
        className: 'lg:col-6',
        data: companyListingStructureOptions,
        props: {
            required: true,
        },
    },
    ISIN: {
        label: 'ISIN',
        placeholder: 'ISIN',
        type: FORM_INPUTS.TEXT,
        className: 'lg:col-6',
    },
    issued_shared: {
        label: 'Issued Shared',
        placeholder: 'Issued Shared',
        type: FORM_INPUTS.NUMBER,
        className: 'lg:col-6',
    },
    inception_date: {
        label: 'Founded Year',
        placeholder: 'Founded Year',
        type: FORM_INPUTS.DATE,
        className: 'lg:col-6',
        props: {
            maxDate: new Date(),
            dateFormat: DATE_FORMAT.YEAR_FOUR_DIGITS,
            view: DATE_PICKER_VIEW.YEAR,
        },
    },
    stock_exchange_id: {
        label: 'Stock Exchange',
        placeholder: 'Select Stock Exchange',
        type: FORM_INPUTS.SELECT,
        className: 'lg:col-6',
        props: {
            disabled: true,
            filter: true,
        },
    },
    sector_id: {
        label: 'Sector',
        placeholder: 'Select Sector',
        type: FORM_INPUTS.SELECT,
        className: 'lg:col-6',
        props: {
            disabled: true,
            filter: true,
        },
    },
    field_set_1: {
        placeholder: 'License and Registration Details',
        type: FORM_INPUTS.FIELD_SET,
    },
    chamber_id: {
        label: 'Chamber Name',
        placeholder: 'Chamber Name',
        type: FORM_INPUTS.SELECT,
        className: 'lg:col-6',
        data: chamberDropdownOptions,
        props: {
            filter: true,
        },
    },
    authority_id: {
        label: 'Authority',
        placeholder: 'Authority',
        type: FORM_INPUTS.SELECT,
        className: 'lg:col-6',
        data: authorityDropdownOptions,
        props: {
            filter: true,
        },
    },
    chamber_number: {
        label: 'Chamber Number',
        placeholder: 'Chamber Number',
        type: FORM_INPUTS.NUMBER,
        className: 'lg:col-6',
        props: {
            useGrouping: false
        }
    },
    license_number: {
        label: 'License Number',
        placeholder: 'License Number',
        type: FORM_INPUTS.NUMBER,
        className: 'lg:col-6',
        props: {
            useGrouping: false
        }
    },
    registration_website: {
        label: 'Registration Website',
        placeholder: 'Registration Website',
        type: FORM_INPUTS.TEXT,
        className: 'lg:col-6',
    },
    license_website: {
        label: 'License Website',
        placeholder: 'License Website',
        type: FORM_INPUTS.TEXT,
        className: 'lg:col-6',
    },
    registration_date: {
        label: 'Registration Date',
        placeholder: 'Registration Date',
        type: FORM_INPUTS.DATE,
        className: 'lg:col-6',
        props: {
            maxDate: new Date(),
        },
    },
    license_date: {
        label: 'License Date',
        placeholder: 'License Date',
        type: FORM_INPUTS.DATE,
        className: 'lg:col-6',
        props: {
            maxDate: new Date(),
        },
    },
};
