import { useEffect, useState } from 'react';
import { TOAST_STATUS } from 'src/constants/toast-status';
import { IFinancialInformationRow } from 'src/pages/Company/Show/CompanyFinancialInformation/types';
import { toast } from 'src/utils/toast';
import ExpressionTokenizer from '../../../logic/Tokenizers/ExpressionTokenizer';
import { useTotalSubtotalContext } from '../../../../../context';

const useFormulaTextBox = () => {
    const { selectedTotalSubtotal, formulaString, setFormulaString, setRelatedItems, setFinalResult, itemMapping, setItemMapping } = useTotalSubtotalContext();
    const [targetItems, setTargetItems] = useState<IFinancialInformationRow[]>([]);
    const [formulaJSON, setFormulaJSON] = useState<string>('');
    const [formulaError, setFormulaError] = useState<string>();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const textareaRef: { current: HTMLTextAreaElement | null } = { current: null };
    const cursorPos = textareaRef.current?.selectionStart;

    const handlePurgeFormula = () => {
        setFinalResult(undefined);
        setRelatedItems(undefined);
        setFormulaJSON('');
        setTargetItems([]);
        setItemMapping({});
        setFormulaError(undefined);
    };

    const handleSelectItem = (child: IFinancialInformationRow) => {
        let childNameObject = child.name && child.name[0];
        const cursorPosition = cursorPos || formulaString.length;
        const beforeAt = formulaString.substring(0, cursorPosition).replace(/@(.+)$/, '');
        const afterAt = formulaString.substring(cursorPosition);

        let tempFormulaString = `{${childNameObject?.name}} `;
        let tempItemMapping = structuredClone(itemMapping);

        const handleUpdateFormulaString = () => {
            setFormulaString(beforeAt + tempFormulaString + afterAt);
            setTargetItems([]);
        };

        const handleUpdateRealJson = () => {
            if (child.value === null) {
                throw new Error(`Item ${child.name && child.name[0].name} does not have value!`);
            }

            let itemInput = {
                name: childNameObject?.name,
                value: child.value,
                meta: {
                    id: childNameObject?.id,
                },
            };

            let itemJson = JSON.stringify(itemInput) + ' ';

            tempItemMapping = { ...tempItemMapping, [JSON.stringify(childNameObject?.name)]: itemJson };
            setItemMapping(tempItemMapping);
            handleFindTokens(beforeAt + tempFormulaString + afterAt, tempItemMapping);
        };

        try {
            handleUpdateRealJson();
            handleUpdateFormulaString();
            textareaRef.current?.focus();
        } catch (error: any) {
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: error,
            });
        }
    };

    const handleShowChildItems = () => {
        if (formulaString === '') handlePurgeFormula();

        let tempItemMapping = structuredClone(itemMapping);
        if (formulaString.includes('@')) {
            const searchTerm = formulaString.substring(0, cursorPos!).match(/@(.+)$/);
            let matchedItems = [] as IFinancialInformationRow[];

            if (searchTerm && searchTerm[1]) {
                try {
                    const regex = new RegExp(searchTerm[1], 'i');
                    matchedItems = selectedTotalSubtotal.children.filter(
                        (child: IFinancialInformationRow) => child.value !== null && child.name && regex.test(child.name[0].name)
                    );
                } catch (error) {
                    console.log(error);
                }
            }
            setTargetItems(matchedItems);
        }
        handleFindTokens(formulaString, tempItemMapping);
    };

    const handleFindTokens = (expression: any, tempItemMapping: any) => {
        if (expression === '') {
            return false;
        }
        let userDoNotSeeStatement = '';
        try {
            let tokenizer = new ExpressionTokenizer();
            let tokens = tokenizer.tokenize(expression);

            tokens.forEach((token: string) => {
                let tokenAlias = tempItemMapping[JSON.stringify(token)];
                let result = typeof tokenAlias == 'undefined' ? token : tokenAlias;
                userDoNotSeeStatement = userDoNotSeeStatement + ' ' + result;
            });
        } catch (error) {
            setFinalResult(undefined);
            userDoNotSeeStatement = expression;
        } finally {
            setFormulaJSON(userDoNotSeeStatement);
        }
    };

    // TODO: REMOVE THIS AFTER REFACTOR
    useEffect(() => {
        if (textareaRef.current) {
            // textareaRef.current.selectionStart = textareaRef.current.value.length;
            // textareaRef.current.selectionEnd = textareaRef.current.value.length;
            textareaRef.current.focus();
        }
    }, [textareaRef]);

    return {
        formulaString,
        setFormulaString,
        textareaRef,
        targetItems,
        setTargetItems,
        itemMapping,
        setItemMapping,
        formulaJSON,
        setFormulaJSON,
        handleSelectItem,
        handleFindTokens,
        handleShowChildItems,
        formulaError,
        setFormulaError,
    };
};

export default useFormulaTextBox;
