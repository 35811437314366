import { useRef } from 'react';
import { mediaService } from 'src/api/services/media';
import { IGetCompanyParentFinancialResponse } from 'src/api/types/company';
import { TOAST_STATUS } from 'src/constants/toast-status';
import { getAxiosError } from 'src/utils/get-axios-error';
import { timestampToDateFormat } from 'src/utils/timestamp-to-date-format';
import { toast } from 'src/utils/toast';
import pdfIcon from 'src/assets/Images/pdf-icon.svg';
import { Link } from 'react-router-dom';
import { truncateString } from 'src/utils/string-truncator';
import { Tooltip } from 'primereact/tooltip';

export const parentCompanyFinancialCellRender = {
    source_link: (data: IGetCompanyParentFinancialResponse) => (
        <>
            <a href={data.source_link} target="_blank" rel="noreferrer" className={data.source_link.length > 20 ? `parent-company-link-${data.id}` : ''}>
                {truncateString(data.source_link, 20)}
            </a>
            <Tooltip
                target={`.parent-company-link-${data.id}`}
                hideDelay={100}
                content={data.source_link}
                position="top"
                disabled={data.source_link.length <= 20}
                showDelay={250}
            />
        </>
    ),
    updated_at: (data: IGetCompanyParentFinancialResponse) => <span>{timestampToDateFormat(data.updated_at)}</span>,
    pages: (data: IGetCompanyParentFinancialResponse) => (
        <>
            <span className={String(data.pages).length > 20 ? `parent-company-pages-${data.id}` : ''}>{truncateString(String(data.pages), 20)}</span>

            <Tooltip
                target={`.parent-company-pages-${data.id}`}
                hideDelay={100}
                content={String(data.pages)}
                position="top"
                disabled={String(data.pages).length <= 20}
                showDelay={250}
            />
        </>
    ),
    created_at: (data: IGetCompanyParentFinancialResponse) => <span>{timestampToDateFormat(data.created_at)}</span>,
    year_end: (data: IGetCompanyParentFinancialResponse) => <span>{timestampToDateFormat(data.year_end, 'MMM-YYYY')}</span>,
    pdf_file: (data: IGetCompanyParentFinancialResponse) => (data.pdf_file ? <SlugComponent targetUUID={data.pdf_file} /> : <span>-</span>),
};

const SlugComponent: React.FC<{ targetUUID: string }> = ({ targetUUID }) => {
    const elementRef = useRef<HTMLAnchorElement>(null);

    const handleGetLink = async () => {
        try {
            let res = await mediaService.getViewPDFLink(targetUUID);
            const link: string = res.data.data;

            if (elementRef.current) {
                elementRef.current.href = link;
                elementRef.current.target = '_blank';
            }
            elementRef.current?.click();
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: message,
            });
        }
    };

    return (
        <>
            <div className="fileIcon cursor-pointer" onClick={handleGetLink}>
                <img src={pdfIcon} alt="pdf" width={32} height={32} />
            </div>
            <Link ref={elementRef} className="fileIcon hidden" to={''} target="_blank">
                <img src={pdfIcon} alt="pdf" width={32} height={32} />
            </Link>
        </>
    );
};
