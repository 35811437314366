export enum CHAMBER {
    CHAMBER_OF_COMMERCE_KUWAIT = 'Chamber of Commerce - Kuwait',
    BAHRAIN_CHAMBER_OF_COMMERCE_SIJILAT = 'Bahrain Chamber of Commerce - Sijilat',
    SAUDI_CHAMBER_OF_COMMERCE = 'Saudi Chamber of Commerce',
    DUBAI_CHAMBER_OF_COMMERCE = 'Dubai Chamber of Commerce',
    ABU_DHABI_CHAMBER_OF_COMMERCE = 'Abu Dhabi Chamber of Commerce',
    SHARJAH_CHAMBER = 'Sharjah Chamber',
    FUJAIRAH_CHAMBER = 'Fujairah Chamber',
    AJMAN_CHAMBER = 'Ajman Chamber',
    UM_ALQAIWAIN_CHAMBER_OF_COMMERCE = 'Um Alqaiwain Chamber of Commerce',
    RIYADH_CHAMBER_OF_COMMERCE = 'Riyadh Chamber of Commerce',
    JEDDAH_CHAMBER_OF_COMMERCE = 'Jeddah Chamber of Commerce',
    MAKKAH_CHAMBER = 'Makkah Chamber',
    JORDAN_CHAMBER_OF_COMMERCE = 'Jordan Chamber of Commerce',
    QATAR_CHAMBER_OF_COMMERCE = 'Qatar Chamber of Commerce',
    OMAN_CHAMBER_OF_COMMERCE = 'Oman Chamber of Commerce',
    AMMAN_CHAMBER = 'Amman Chamber',
}
