import { CSSProperties, ReactNode } from 'react';
import { classNames } from 'primereact/utils';
import { createPortal } from 'react-dom';
import CloseIcon from 'src/assets/Icons/CloseIcon';
import { SIDE_PANEL_SIZES } from 'src/enums/side-panel-size';
import { sidePanelSize } from 'src/constants/components/sidePanel/side-panel-size';
import styles from './SidePanel.module.scss';
import { useMenu } from 'src/context/menu';

interface ISidePanelProps {
    children: ReactNode;
    show: boolean;
    onHide?: () => void;
    title?: string;
    size?: SIDE_PANEL_SIZES;
    overlay?: boolean;
    showHeader?: boolean;
    wrapperCustomStyles?: CSSProperties;
}

const SidePanel: React.FC<ISidePanelProps> = ({ children, show, onHide, title, size, overlay = true, showHeader = true, wrapperCustomStyles }) => {
    const { menuView } = useMenu();
    if (!show) {
        return null;
    }
    const wrapperStyles = {
        ...(size && { width: sidePanelSize[size](menuView) }),
        ...wrapperCustomStyles,
    };

    return createPortal(
        <>
            {show && overlay && <div className={styles.sidePanelBackground} onClick={onHide} />}
            <div style={wrapperStyles} className={classNames(show ? styles.sidePanelWrapper : styles.sidePanelWrapperHide)}>
                {showHeader && Boolean(title) && (
                    <div className={styles.sidePanelHeader}>
                        <span>{title}</span>
                        <div className={styles.closeButtonWrapper} onClick={onHide}>
                            <CloseIcon />
                        </div>
                    </div>
                )}
                {children}
            </div>
        </>,
        document.body
    );
};

export default SidePanel;
