import { SVGProps } from 'react';

const PopoverIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.5359 4.28509C18.6795 3.01565 21.3205 3.01565 23.4641 4.28509L31.5359 9.06521C33.6795 10.3347 35 12.6807 35 15.2195V24.7798C35 27.3187 33.6795 29.6647 31.5359 30.9341L23.4641 35.7143C21.3205 36.9837 18.6795 36.9837 16.5359 35.7143L8.4641 30.9341C6.32051 29.6647 5 27.3187 5 24.7798V15.2195C5 12.6807 6.32051 10.3347 8.4641 9.06521L16.5359 4.28509ZM21.6667 11.6663C21.6667 12.5868 20.9205 13.333 20 13.333C19.0795 13.333 18.3333 12.5868 18.3333 11.6663C18.3333 10.7459 19.0795 9.99967 20 9.99967C20.9205 9.99967 21.6667 10.7459 21.6667 11.6663ZM18.3333 15.4163C17.643 15.4163 17.0833 15.976 17.0833 16.6663C17.0833 17.3567 17.643 17.9163 18.3333 17.9163H18.75V28.333C18.75 29.0234 19.3096 29.583 20 29.583C20.6904 29.583 21.25 29.0234 21.25 28.333V16.6663C21.25 15.976 20.6904 15.4163 20 15.4163H18.3333Z"
                fill={props.fill || 'currentColor' || '#ccc'}
            />
        </svg>
    );
};

export default PopoverIcon;
