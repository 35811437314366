import { IFunctionalComponentProps } from 'src/types/functional-component';
import { ICompanyDetails } from '../types';
import { useNavigate, useParams } from 'react-router-dom';
import { useBreadcrumbDynamicName } from 'src/hooks/useBreadcrumbDynamicName';
import { companyService } from 'src/api/services/company';
import ROUTE_CONSTANTS from 'src/Routes/constants/route-constants';
import { getAxiosError } from 'src/utils/get-axios-error';
import { toast } from 'src/utils/toast';
import { TOAST_STATUS } from 'src/constants/toast-status';
import { useAsync } from '../../../../hooks/useAsync';
import { financialActions } from '../../../../context/companies';
import { isNumberValid } from '../../../../utils/checkers';
import { COMPANY_TYPE_ROUTE } from '../../Add/enums/company-type-route';
import { IParams } from 'src/types/params';


const CompanyProvider: React.FC<IFunctionalComponentProps> = ({ children }) => {
    const { companyId, companyType } = useParams<IParams>();
    const setDynamicBreadcrumbName = useBreadcrumbDynamicName();
    const navigate = useNavigate();

    useAsync<ICompanyDetails, number>(
        () =>
            new Promise((resolve, reject) => {
                if (!companyId || !isNumberValid(parseInt(companyId))) return reject('CompanyIdIsNotValid');
                financialActions.addCompanyId(parseInt(companyId));
                return companyService(companyType as COMPANY_TYPE_ROUTE)
                    .show(parseInt(companyId))
                    .then((res) => {
                        const companyData = res.data.data;
                        const can_change = companyData.can_change_template_or_currency_or_year_end_date;
                        const selectedCurrency = companyData.company_financial_information_currency;
                        setDynamicBreadcrumbName(res.data.data.name);
                        resolve({ ...companyData, selectedCurrency, can_change });
                    })
                    .catch((err) => {
                        navigate(ROUTE_CONSTANTS.NOT_FOUND.ABSOLUTE, { replace: true });
                        reject(getAxiosError(err));
                    });
            }),
        {
            onSuccess: (company) => {
                financialActions.addCompany(company as ICompanyDetails);
            },
            onError: (error) => {
                toast.fire({
                    icon: TOAST_STATUS.ERROR,
                    title: error?.message,
                });
            },
            immediate: true,
        }
    );

    return <>{children}</>;
};

export { CompanyProvider };
