import { SVGProps } from 'react';

const AddLogoIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none" {...props}>
            <path
                d="M31.5054 16.4996V24.0028C31.5054 28.1466 28.1461 31.5059 24.0023 31.5059H11.9973C7.8534 31.5059 4.49414 28.1466 4.49414 24.0028V11.9978C4.49414 7.85389 7.8534 4.49463 11.9973 4.49463H19.5004"
                stroke={props.stroke || '#323232'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M21.8205 14.1796C23.9297 16.2895 23.9293 19.7097 21.8197 21.8191C19.71 23.9285 16.2898 23.9285 14.1801 21.8191C12.0705 19.7097 12.0701 16.2895 14.1793 14.1796C15.1925 13.1661 16.5668 12.5967 17.9999 12.5967C19.433 12.5967 20.8073 13.1661 21.8205 14.1796"
                stroke={props.stroke || '#323232'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M25.502 6.74543H33.0051" stroke={props.stroke || '#323232'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M29.2542 10.4973V2.99414" stroke={props.stroke || '#323232'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default AddLogoIcon;
