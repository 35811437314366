import { arabicCharactersRegex } from 'src/constants/regex';
import { getMaxError, getMinError } from 'src/utils/get-yup-errors';
import { string, object, array } from 'yup';

export const createValidationSchema = () =>
    object().shape({
        name: string()
            .required('Name is required')
            .matches(/^[A-Za-z\d_-]+$/, 'The name field only accepts English chars'),
        arabic_name: string()
            .when({
                is: (arabic_name: string) => arabic_name && arabic_name.length > 0,
                then:
                    string()
                        .min(3, getMinError(3, 'Arabic Name'))
                        .max(255, getMaxError(3, 'Arabic Name'))
                        .matches(arabicCharactersRegex, 'Arabic Name must only accept Arabic characters'),
            }),
        financial_information_type: array().of(string().required('Financial Information Type is required')),
    });