import { API_ROUTES } from 'src/constants/api-routes';
import { AxiosInstance } from '../index';
import { IAxiosResponse } from '../types/axios-response';
import { ICompanyTypeResponse, ISectorListResponse, ISectorSelectBoxPayload, TUpdateSectorPayload } from '../types/sectors';
import { TCreateSectorPayload } from '../types/sectors';
import { ISelectBox } from '../types/base-types';
import { IDataTableResponse, IDataTablePayload } from 'src/api/types/table';

export const sectorService = {
    getAllSectors(params?: IDataTablePayload) {
        return AxiosInstance.get<IDataTableResponse<ISectorListResponse>>(API_ROUTES.SECTOR.GET_ALL_SECTORS, { params });
    },
    getOne(id: number) {
        return AxiosInstance.get<IAxiosResponse<ISectorListResponse>>(API_ROUTES.SECTOR.GET_ONE(id));
    },
    getAllCompanyTypes() {
        return AxiosInstance.get<IAxiosResponse<ICompanyTypeResponse[]>>(API_ROUTES.SECTOR.GET_ALL_COMPANY_TYPES);
    },
    createNew(data: TCreateSectorPayload) {
        return AxiosInstance.post<IAxiosResponse<ISectorListResponse>>(API_ROUTES.SECTOR.ADD_NEW, data);
    },
    update(id: number, data: TUpdateSectorPayload) {
        return AxiosInstance.put<IAxiosResponse<{}>>(API_ROUTES.SECTOR.UPDATE(id), data);
    },
    delete(id: number) {
        return AxiosInstance.delete<IAxiosResponse<{}>>(API_ROUTES.SECTOR.DELETE(id));
    },
    selectBox(params?: Partial<ISectorSelectBoxPayload>) {
        return AxiosInstance.get<IAxiosResponse<ISelectBox[]>>(API_ROUTES.SECTOR.GET_ALL_SECTOR_DROPDOWN_OPTIONS, { params });
    },
};
