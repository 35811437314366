import { MENU_VIEW } from 'src/enums/menu';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

type MenuState = {
    menuView: MENU_VIEW;
    toggleMenuView: () => void;
};

const initialState = (localStorage.getItem('menuView') as MENU_VIEW) || MENU_VIEW.MAXIMIZE;

export const useMenu = create(
    devtools<MenuState>(
        (set) => ({
            menuView: initialState,
            toggleMenuView: () =>
                set((prev) => {
                    const newMenuViewState = prev.menuView === MENU_VIEW.MAXIMIZE ? MENU_VIEW.MINIMIZE : MENU_VIEW.MAXIMIZE;
                    localStorage.setItem('menuView', newMenuViewState);
                    return { menuView: newMenuViewState };
                }),
        }),
        {
            name: 'Menu',
        }
    )
);

export const menuActions = {
    toggleMenuView() {
        useMenu.setState((prev) => {
            const newMenuViewState = prev.menuView === MENU_VIEW.MAXIMIZE ? MENU_VIEW.MINIMIZE : MENU_VIEW.MAXIMIZE;
            localStorage.setItem('menuView', newMenuViewState);
            return { menuView: newMenuViewState };
        });
    },
};
