import { API_ROUTES } from 'src/constants/api-routes';
import { AxiosInstance } from '..';
import { IGetNewsSourceResponse, ICreateNewsSourcePayload, INewsSourceShowResponse } from '../types/news-source';
import { IDataTableResponse, IDataTablePayload } from '../types/table';
import { IAxiosResponse } from '../types/axios-response';
import { ISelectBox, IGetListPayload } from '../types/base-types';

export const newsSourceService = {
    getAll(data?: IDataTablePayload) {
        return AxiosInstance.get<IDataTableResponse<IGetNewsSourceResponse>>(API_ROUTES.NEWS_SOURCE.GET_ALL, { params: data });
    },
    delete(id: number) {
        return AxiosInstance.delete<IAxiosResponse>(API_ROUTES.NEWS_SOURCE.DELETE(id));
    },
    create(data?: ICreateNewsSourcePayload) {
        return AxiosInstance.post<IAxiosResponse>(API_ROUTES.NEWS_SOURCE.ADD_NEW, data);
    },
    update(id: number, data?: ICreateNewsSourcePayload) {
        return AxiosInstance.put<IAxiosResponse>(API_ROUTES.NEWS_SOURCE.UPDATE(id), data);
    },
    getOne(id: number) {
        return AxiosInstance.get<IAxiosResponse<INewsSourceShowResponse>>(API_ROUTES.NEWS_SOURCE.GET_ONE(id));
    },
    list(params?: IGetListPayload) {
        return AxiosInstance.get<IAxiosResponse<ISelectBox[]>>(API_ROUTES.NEWS_SOURCE.LIST, {params});
    },
};
