import { ISelectBox } from "src/api/types/base-types";

export const dropDownDate: ISelectBox[] = [
    {
        label: '31 January',
        value: '1',
    },
    {
        label: '28 February',
        value: '2',
    },
    {
        label: '31 March',
        value: '3',
    },
    {
        label: '30 April',
        value: '4',
    },
    {
        label: '31 May',
        value: '5',
    },
    {
        label: '30 June',
        value: '6',
    },
    {
        label: '31 July',
        value: '7',
    },
    {
        label: '31 August',
        value: '8',
    },
    {
        label: '31 September',
        value: '9',
    },
    {
        label: '31 October',
        value: '10',
    },
    {
        label: '30 November',
        value: '11',
    },
    {
        label: '31 December',
        value: '12',
    },
];
