export enum AUTHORITY {
    KUWAIT_MINISTRY_OF_COMMERCE = 'Kuwait Ministry of Commerce',
    SECURITIES_AND_COMMODITIES_AUTHORITY_UAE = 'Securities and Commodities Authority- UAE',
    DUBAI_INTERNATIONAL_FINANCIAL_CENTER = 'Dubai International Financial Center',
    DUBAI_MULTI_COMMODITIES_CENTRE = 'Dubai Multi Commodities Centre',
    CAPITAL_MARKET_AUTHORITY_KUWAIT = 'Capital Market Authority - Kuwait',
    CAPITAL_MARKET_AUTHORITY_OMAN = 'Capital Market Authority - Oman',
    CAPITAL_MARKET_AUTHORITY_SAUDI_ARABIA = 'Capital Market Authority - Saudi Arabia',
    SAUDI_ARABIAN_GENERAL_INVESTMENT_AUTHORITY = 'Saudi Arabian General Investment Authority',
    CENTRAL_BANK_OF_BAHRAIN = 'Central Bank of Bahrain',
    CENTRAL_BANK_OF_KUWAIT = 'Central Bank of Kuwait',
    JEBEL_ALI_FREE_ZONE = 'Jebel Ali Free Zone',
    HAMRIYAH_FREE_ZONE = 'Hamriyah Free Zone',
    AJMAN_FREE_ZONE_AUTHORITY = 'Ajman Free Zone Authority',
    SHARJAH_AIRPORT_INTERNATIONAL_FREE_ZONE = 'Sharjah Airport International Free Zone',
    ABU_DHABI_AIRPORT_FREE_ZONE = 'Abu Dhabi Airport Free Zone',
    MASDAR_CITY_FREE_ZONE = 'Masdar City Free Zone',
    RAK_MARITIME_CITY_FREE_ZONE = 'RAK Maritime City Free Zone',
    ABU_DHABI_GLOBAL_MARKET_FINANCIAL_SERVICES_REGULATORY_AUTHORITY = 'Abu Dhabi Global Market Financial Services Regulatory Authority',
    QATAR_FINANCIAL_CENTER = 'Qatar Financial Center',
    RAS_AL_KHAIMAH_INVESTMENT_AUTHORITY = 'Ras Al Khaimah Investment Authority',
    DEPARTMENT_OF_ECONOMIC_DEVELOPMENT_OF_THE_GOVERNMENT_OF_DUBAI_AND_CENTRAL_BANK_OF_UAE = 'Department of Economic Development of the Government of Dubai and Central Bank of UAE',
    QATAR_FINANCIAL_CENTRE_REGULATORY_AUTHORITY = 'Qatar Financial Centre Regulatory Authority',
    DUBAI_DEVELOPMENT_AUTHORITY = 'Dubai Development Authority',
    MINISTER_OF_INDUSTRY_COMMERCE_AND_TOURISM = 'Minister of Industry, Commerce and Tourism',
    LABOUR_MARKET_REGULATORY_AUTHORITY = 'Labour Market Regulatory Authority',
    CAPITAL_MARKETS_SUPERVISION_DIRECTORATE = 'Capital Markets Supervision Directorate',
    PUBLIC_AUTHORITY_FOR_INDUSTRY = 'Public Authority for Industry',
    KUWAIT_DIRECT_INVESTMENT_PROMOTION_AUTHORITY = 'Kuwait Direct Investment Promotion Authority',
    PUBLIC_AUTHORITY_FOR_SOCIAL_INSURANCE_SCHEME = 'Public Authority for Social Insurance Scheme',
    QATAR_INVESTMENT_AUTHORITY = 'Qatar Investment Authority',
    GENERAL_AUTHORITY_OF_ZAKAT_AND_TAX_CUSTOMS_AUTHORITY = 'General Authority of Zakat and Tax /  Zakat, Tax and Customs Authority',
    SAUDI_CENTRAL_BANK = 'Saudi Central Bank',
    ECONOMIC_CITY_AND_SPECIAL_ZONES_AUTHORITY = 'Economic City and Special Zones Authority',
    SMALL_AND_MEDIUM_ENTERPRISES_GENERAL_AUTHORITY = 'Small and Medium Enterprises General Authority',
    GENERAL_AUTHORITY_FOR_STATISTICS = 'General Authority for Statistics',
    DIRIYAH_GATE_DEVELOPMENT_AUTHORITY = 'Diriyah Gate Development Authority',
    GENERAL_AUTHORITY_FOR_INVESTMENT = 'General Authority for Investment',
    THE_HIGHER_SHARIAH_AUTHORITY = 'The Higher Shari’ah Authority',
    DUBAI_FINANCIAL_SERVICES_AUTHORITY = 'Dubai Financial Services Authority',
    FINANCIAL_SERVICES_REGULATORY_AUTHORITY = 'Financial Services Regulatory Authority',
    DUBAI_LAND_DEPARTMENT_AUTHORITY = 'Dubai Land Department Authority',
    INSURANCE_AUTHORITY = 'Insurance Authority',
    ABU_DHABI_INVESTMENT_AUTHORITY = 'Abu Dhabi Investment Authority',
    ABU_DHABI_ACCOUNTABILITY_AUTHORITY = 'Abu Dhabi Accountability Authority',
    SHARJAH_INVESTMENT_AND_DEVELOPMENT_AUTHORITY = 'Sharjah Investment and Development Authority',
    FUJAIRAH_FREE_ZONE_AUTHORITY = 'Fujairah Free Zone Authority',
    DUBAI_AIRPORT_FREE_ZONE_AUTHORITY = 'Dubai Airport Free Zone Authority',
}
