import React, { useCallback, useEffect, useState } from 'react';
import DashboardTitle from 'src/components/App/DashboardTitle';
import Button from 'src/components/Kit/Button';
import { Helmet } from 'react-helmet-async';
import { Column } from 'primereact/column';
import { userService } from 'src/api/services/user';
import { toast } from 'src/utils/toast';
import { TOAST_STATUS } from 'src/constants/toast-status';
import { IUserRequest } from 'src/api/types/user';
import DashboardContentWrapper from 'src/layouts/DashboardContentWrapper';
import { getAxiosError } from 'src/utils/get-axios-error';
import { useCheckPermissionAccess } from 'src/hooks/useCheckPermissionAccess';
import { PERMISSION_TYPES } from 'src/enums/permissions';
import { useNavigate, useParams } from 'react-router-dom';
import { IParams } from 'src/types/params';
import { USER_REQUEST_STATUS_NUMBER } from 'src/context/user/request-status';
import { USER_REQUEST_STATUS_NAME } from 'src/enums/user/request-status-name';
import Table from 'src/components/App/Table';
import { IDataTablePayload } from 'src/api/types/table';
import { USER_REQUEST_STATUS } from 'src/enums/user/request-status';
import CloseIcon from 'src/assets/Icons/CloseIcon';
import CheckIcon from 'src/assets/Icons/CheckIcon';
import { USER_REQUEST_STATUS_PERMISSION } from 'src/context/user/request-status-permission';
import ROUTE_CONSTANTS from 'src/Routes/constants/route-constants';
import { userRequestCellRender } from './cellRender';
import { Tooltip } from 'primereact/tooltip';

const UserRequestList: React.FC = () => {
    const { status } = useParams<IParams>();
    const navigate = useNavigate();
    const { checkPermissionAccess } = useCheckPermissionAccess();

    const [tableKey, setTableKey] = useState(status!);

    const statusNumber = USER_REQUEST_STATUS_NUMBER[status as USER_REQUEST_STATUS_NAME];
    const requestListPermission = USER_REQUEST_STATUS_PERMISSION[status as USER_REQUEST_STATUS_NAME];
    const statusTitle = status!.charAt(0).toUpperCase() + status!.slice(1);

    const service = useCallback(async (queryParams?: IDataTablePayload) => userService.requests(statusNumber, queryParams), [statusNumber]);

    const handleChangeStatus = async (id: number, status: USER_REQUEST_STATUS) => {
        try {
            const response = await userService.changeRequestStatus({ request_id: id, status });
            toast.fire({
                icon: response.data.success ? TOAST_STATUS.SUCCESS : TOAST_STATUS.ERROR,
                title: response.data.message,
            });

            setTimeout(() => {
                setTableKey(new Date().toDateString());
            }, 500);
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: message,
            });
        }
    };

    const suffixCols = () => {
        return (
            <Column
                key="Action"
                body={(rowData: IUserRequest) => (
                    <>
                        {checkPermissionAccess(PERMISSION_TYPES.USER_REQUEST_APPROVE) && status !== USER_REQUEST_STATUS_NAME.APPROVED && (
                            <>
                                <Button
                                    color="secondary"
                                    size="m"
                                    iconOnly
                                    className={`approve-button-${rowData.id}`}
                                    onClick={() => handleChangeStatus(rowData.id, USER_REQUEST_STATUS.APPROVED)}
                                >
                                    <CheckIcon />
                                </Button>
                                <Tooltip target={`.approve-button-${rowData.id}`} hideDelay={100} content={'Approve'} position="top" showDelay={250} />
                            </>
                        )}
                        {checkPermissionAccess(PERMISSION_TYPES.USER_REQUEST_CANCEL) && status !== USER_REQUEST_STATUS_NAME.CANCELED && (
                            <>
                                <Button
                                    color="secondary"
                                    iconOnly
                                    size="m"
                                    className={`cancel-button-${rowData.id}`}
                                    onClick={() => handleChangeStatus(rowData.id, USER_REQUEST_STATUS.CANCELED)}
                                >
                                    <CloseIcon />
                                </Button>
                                <Tooltip target={`.cancel-button-${rowData.id}`} hideDelay={100} content={'Cancel'} position="top" showDelay={250} />
                            </>
                        )}
                    </>
                )}
            />
        );
    };

    useEffect(() => {
        if (status) {
            setTableKey(status);
        }

        if (!status || (status && !Object.keys(USER_REQUEST_STATUS_NAME).includes(status.toUpperCase()))) {
            navigate(ROUTE_CONSTANTS.ROOT.ABSOLUTE);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status]);

    return (
        <>
            <Helmet>
                <title>Teaser | User {statusTitle} Request List</title>
            </Helmet>
            <DashboardTitle title={`User ${statusTitle} Request List`} />
            <DashboardContentWrapper>
                {checkPermissionAccess(requestListPermission) && (
                    <Table key={tableKey} getTableData={service} cellRender={userRequestCellRender} suffixCols={suffixCols} />
                )}
            </DashboardContentWrapper>
        </>
    );
};

export default UserRequestList;
