import { ISelectBox } from 'src/api/types/base-types';
import { COMPANY_LISTING_STATUS } from '../enums/company-listing-status';

export const companyListingStructureOptions: ISelectBox[] = [
    {
        label: COMPANY_LISTING_STATUS.PUBLIC,
        value: COMPANY_LISTING_STATUS.PUBLIC,
    },
    {
        label: COMPANY_LISTING_STATUS.DELISTED,
        value: COMPANY_LISTING_STATUS.DELISTED,
    },
    {
        label: COMPANY_LISTING_STATUS.DISSOLVED,
        value: COMPANY_LISTING_STATUS.DISSOLVED,
    },
    {
        label: COMPANY_LISTING_STATUS.PRIVATE,
        value: COMPANY_LISTING_STATUS.PRIVATE,
    },
    {
        label: COMPANY_LISTING_STATUS.DELISTED_AND_DISSOLVED,
        value: COMPANY_LISTING_STATUS.DELISTED_AND_DISSOLVED,
    },
    {
        label: COMPANY_LISTING_STATUS.SPV,
        value: COMPANY_LISTING_STATUS.SPV,
    },
    {
        label: COMPANY_LISTING_STATUS.GOVERNMENT_BODY,
        value: COMPANY_LISTING_STATUS.GOVERNMENT_BODY,
    },
];
