import { ChangeEvent, useState } from 'react';
import { useTotalSubtotalContext } from '../../../../context';
import RelatedItemCard from '../RelatedItemCard';
import { IFinancialInformationRow } from 'src/pages/Company/Show/CompanyFinancialInformation/types';
import styles from './SelectedItemsBox.module.scss';
import { useFinancialDataContext } from '../../../../../../hooks/useFinancialDataContext';

const SelectedItemsBox: React.FC = () => {
    const { selectedTotalSubtotal } = useTotalSubtotalContext();
    const { financialDataRows } = useFinancialDataContext();
    const [searchValue, setSearchValue] = useState('');

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value);
    };

    const regex = new RegExp(searchValue, 'i');
    const childrenItemIds = selectedTotalSubtotal?.children?.map((item: IFinancialInformationRow) => item.itemId) as Array<number>;
    const listByFinancialRows = financialDataRows?.filter((item) => childrenItemIds?.includes(item.itemId));
    const filteredList = listByFinancialRows?.filter((item: any) => regex.test(item.name[0].name));

    return (
        <div className={styles.selectedItemsBoxWrapper} data-cy={'formula-related-items'}>
            <div className={styles.selectedItemsBoxSearch}>
                <input placeholder="Search" onChange={handleSearch} />
            </div>
            <div className={styles.selectedItemsBoxItems}>
                {filteredList?.map((child: IFinancialInformationRow, index: number) => <RelatedItemCard key={index} {...child} />)}
            </div>
        </div>
    );
};

export default SelectedItemsBox;
