import { useEffect } from "react"
import { useSetCurrentDynamicDirectoryName } from 'src/context/global-context';

export const useBreadcrumbDynamicName = () => {
    const setCurrentDynamicDirectoryName = useSetCurrentDynamicDirectoryName();

    const setDynamicBreadcrumbName = (name: string) => {
        setCurrentDynamicDirectoryName(name)
    }

    useEffect(() => {
        return () => {
            setCurrentDynamicDirectoryName('');
        };
    }, [setCurrentDynamicDirectoryName]);
    return setDynamicBreadcrumbName
}