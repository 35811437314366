import { COMPANY_STATUS } from 'src/api/types/company';
import { DROPDOWN_STATUS } from 'src/components/Kit/StatusDropDown/enums/dropdown-status';
import { IStatusDropDownOptions } from 'src/components/Kit/StatusDropDown/types/status-drop-down-options';

export const statusDropDownOptions: IStatusDropDownOptions[] = [
    { type : "status" , label: 'Approved', value: String(COMPANY_STATUS.APPROVED), status: DROPDOWN_STATUS.SUCCESS },
    { type : "status" , label: 'Not Approved', value: String(COMPANY_STATUS.NOT_APPROVED), status: DROPDOWN_STATUS.WARNING },
];

export const approvedCompanyStatusDropDownOptions = (slug : string) : IStatusDropDownOptions[] => [
    ...statusDropDownOptions,
    { type : "button" , label: 'See Website', value: slug, status: DROPDOWN_STATUS.WARNING },
];
