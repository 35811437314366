interface Config {
    renderUi?: boolean;
    isAbs?: boolean;
    prefix?: string | number;
    suffix?: string | number;
}
export const financialValuesStyle = (number: string | null, { renderUi = true, prefix = '', suffix = '', isAbs = false }: Config = {}) => {
    if (number === null) return <span>-</span>;

    const isNegative = +number < 0;
    const [integerPart, decimalPart] = String(number).split('.');
    const integer = Number(integerPart).toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });

    const resultNumber = integer + (decimalPart !== undefined ? '.' + decimalPart : '');

    // handle ui
    let renderResultNumber = isNegative ? (
        <span style={{ color: '#F25325' }}>
            ({resultNumber.replace('-', '')}){suffix}
        </span>
    ) : (
        <span>{resultNumber + suffix}</span>
    );
    renderResultNumber = isAbs ? <span>|&nbsp;{renderResultNumber}&nbsp;|</span> : renderResultNumber;

    // handle no ui
    let noUiResultNumber = isNegative ? `(${resultNumber.replace('-', '')})` : resultNumber;
    noUiResultNumber = isAbs ? `| ${noUiResultNumber} |` : noUiResultNumber;

    return renderUi ? (
        <>
            {prefix ? <small className="prefix">{prefix}</small> : ''}
            {renderResultNumber}
        </>
    ) : (
        prefix + ' ' + noUiResultNumber + suffix
    );
};
