import style from './FileDialog.module.scss';
import Button from '../Button';
import React from 'react';
import { showFileSelector } from '../../../utils/file';
import { toast } from '../../../utils/toast';
import { FILE_UPLOAD_TYPE } from './constants';
import { useCheckPermissionAccess } from '../../../hooks/useCheckPermissionAccess';
import { PERMISSION_TYPES } from '../../../enums/permissions';

type Props = {
    onUpload: (file: File) => void;
    fileType: FILE_UPLOAD_TYPE;
    isLoading: boolean;
};
export const Uploader = ({ onUpload, isLoading, fileType }: Props) => {
    const { checkPermissionAccess } = useCheckPermissionAccess();
    const getFileFromOs = async () => {
        try {
            const file = await showFileSelector();
            if (file) {
                return onUpload(file);
            } else {
                toast.fire({
                    icon: 'error',
                    title: 'You must be select file !',
                });
            }
        } catch (error) {
            toast.fire({
                icon: 'error',
                title: 'Error ! Please check browser file permissions',
            });
        }
    };

    return (
        <>
            <div className={style.uploaderTitle}>
                <span>Upload File</span>
                <div className={style.uploaderFormats}>
                    <span>Supported Formats: PDF, PNG, JPEG</span>
                    <span>Max size: {fileType === FILE_UPLOAD_TYPE.DOCUMENT ? '50' : '2'}MB</span>
                </div>
            </div>
            <div>
                <Button color={'primary'} disabled={!checkPermissionAccess(PERMISSION_TYPES.SETTING_MEDIA_LIBRARY_CREATE) || isLoading} onClick={getFileFromOs}>
                    {fileType === FILE_UPLOAD_TYPE.DOCUMENT ? '+ Add Document' : '+ Add Image'}
                </Button>
            </div>
        </>
    );
};
