import { ReactNode } from 'react';
import style from './DashboardTitle.module.scss';

interface IProps {
    title: string;
    subTitle?: string;
    titleSuffix?: ReactNode;
}

const DashboardTitle: React.FC<IProps> = ({ title, subTitle = '', titleSuffix = null }) => {
    return (
        <div className={style.dashboardTitle}>
            <h1>
                {title}
                {subTitle && ':'}
                {subTitle && <small>{subTitle}</small>}
            </h1>
            {titleSuffix}
        </div>
    );
};

export default DashboardTitle;
