import { useCallback, useEffect, useState } from 'react';
import { ModalsList } from './modalsList';
import { modalActions, useModals } from 'src/context/modals';
import { useNavigate } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { ModalProps } from 'src/types/modals';

const ModalCreator = () => {
    const navigate = useNavigate();
    const modals = useModals((s) => s.modals);
    const [hardwareBackHistory, setHardwareBackHistory] = useState(true);

    const closeEvent = useCallback(() => {
        modalActions.removeLastModal();
    }, []);

    const close = useCallback(
        (clearModals?: boolean) => {
            setHardwareBackHistory(false);
            if (clearModals) {
                modalActions.clearModals();
            } else {
                navigate(-1);
                modalActions.removeLastModal();
            }
            setTimeout(() => {
                setHardwareBackHistory(true);
            }, 0);
        },
        [navigate]
    );

    useEffect(() => {
        if (modals.length && hardwareBackHistory) {
            window.addEventListener('popstate', closeEvent);
        }
        return () => window.removeEventListener('popstate', closeEvent);
    }, [modals, hardwareBackHistory, closeEvent]);

    return (
        <>
            {modals.map((modal, index) => {
                const ModalComponent = ModalsList[modal.type] as React.FC<ModalProps>;
                return (
                    <Dialog
                        key={index}
                        header={modal.data.header || undefined}
                        visible={true}
                        style={{ zIndex: 1100 + index }}
                        onHide={() => close()}
                        draggable={true}
                        resizable={false}
                    >
                        <ModalComponent closeModal={close} data={modal.data} />
                    </Dialog>
                );
            })}
        </>
    );
};
export default ModalCreator;
