import axios from 'axios';
import { ACCESS_TOKEN } from 'src/constants/access-token';
import { HTTP_STATUS_CODES } from 'src/constants/http-status-codes';
import { getAuthorization } from 'src/utils/get-authorization';
import { cacheService } from './cache-service';
import { toast } from 'src/utils/toast';
import { TOAST_STATUS } from 'src/constants/toast-status';
import { ICustomAxiosConfig } from './types/config';
import { getAxiosError } from 'src/utils/get-axios-error';

export const responseInterceptors = {
    successInterceptor(response: any) {
        if (response.config.customCache) {
            cacheService.setItem(response.config.cacheKey, response);
        }
        return response;
    },
    errorInterceptor(error: any) {
        if (axios.isCancel(error)) {
            return Promise.reject(error);
        }
        const statusCode = error?.response?.status;
        switch (statusCode) {
            case HTTP_STATUS_CODES.CACHED_RESPONSE:
                Promise.resolve(error.response.data);
                break;
            case HTTP_STATUS_CODES.UN_AUTHORIZED:
                toast.fire({
                    icon: TOAST_STATUS.ERROR,
                    title: error.response?.data?.message || error.message,
                });
                localStorage.removeItem(ACCESS_TOKEN);
                window.location.reload();
                break;
            case HTTP_STATUS_CODES.NOT_FOUND:
                break;
            case HTTP_STATUS_CODES.FORBIDDEN:
                const err = getAxiosError(error);
                err.message = "Access denied! Redirecting...";
                setTimeout(() => {
                    window.location.replace("/");
                }, 1000);
                return Promise.reject(err);
            case HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY_MANUAL:
                return Promise.reject(error.response?.data);
            default:
                toast.fire({
                    icon: TOAST_STATUS.ERROR,
                    title: error.response?.data?.message || error.message,
                });
                break;
        }
        return Promise.reject(error);
    },
};

export const requestInterceptors = {
    beforeSent(config: ICustomAxiosConfig) {
        const authorization = getAuthorization();
        if (authorization) {
            config.headers = {
                ...config.headers,
                Authorization: authorization,
            };
        }
        return cacheService.handleCached(config);
    },
    onError(error: any) {
        return Promise.reject(error);
    },
};
