import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { generateDefaultValueFromFormSchema } from '../../../../../utils/generate-default-value-from-form-schema';
import { yupResolver } from '@hookform/resolvers/yup';
import FormGenerator from '../../../../../components/Kit/FormGenerator';
import { createShareHolderSchema } from './schema';
import { shareHolderValidationSchema } from './validation-schema';
import Button from '../../../../../components/Kit/Button';
import TransactionRow from './transaction-row';
import { IShareHolderFormData } from './types';
import { useEditingMode } from './useUpdate';
import { useAsync } from '../../../../../hooks/useAsync';
import { shareHolderService } from '../../../../../api/services/share-holder-service';
import { toast } from '../../../../../utils/toast';
import { getAxiosError, getErrorsByRow } from '../../../../../utils/get-axios-error';
import { TOAST_STATUS } from '../../../../../constants/toast-status';
import { ICreateShareHolderRequest, ITransactionInformation } from '../../../../../api/types/share-holders';
import InitLoading from '../../../../../components/App/Loading/InitLoading';
import { useNavigate, useParams } from 'react-router-dom';
import ROUTE_CONSTANTS from '../../../../../Routes/constants/route-constants';
import { useFormFields } from '../../../../../hooks/useFormFields';
import { UUIDv4 } from 'src/utils/uuid-generator';
import styles from './ShareHolderAddUpdate.module.scss';
import { entityService } from 'src/api/services/entity-service';
import { ISelectBox } from 'src/api/types/base-types';
import { COMPANY_TYPE_ROUTE } from 'src/pages/Company/Add/enums/company-type-route';
import { IParams } from 'src/types/params';

const ShareHolderAddUpdate = () => {
    const { companyType } = useParams<IParams>();
    const { formFields, updateFormData, updateFormLoading, updateFormProps } = useFormFields(createShareHolderSchema);
    const { isEditingRule, completedData, isLoading: completedDataIsLoading, shareHolderId, companyId } = useEditingMode();
    const navigation = useNavigate();

    const {
        control,
        formState: { errors, isValid },
        handleSubmit,
        trigger,
        watch,
        setValue,
        setError,
        clearErrors,
        getValues,
    } = useForm<IShareHolderFormData>({
        defaultValues: generateDefaultValueFromFormSchema(formFields),
        mode: 'all',
        shouldFocusError: false,
        resolver: yupResolver(shareHolderValidationSchema),
    });

    const getShareHolderNames = async (shareHolderType: string, query?: string) => {
        try {
            updateFormLoading('share-holder-name', true);
            const result = await entityService.getShareHolderTypes({
                type: shareHolderType,
                ...(query ? { text: query } : {}),
            });

            updateFormData(
                'share-holder-name',
                result.data.data.map((data) => ({
                    label: data.label,
                    value: String(data.value),
                }))
            );
            updateFormProps('share-holder-name', {
                onFilter: (_fieldName: string, values: any) => getShareHolderNames(shareHolderType, values),
            });
            return Promise.resolve();
        } catch (e) {
            return Promise.reject();
        }
    };

    const { data: shareHolderForm, isLoading: shareHolderFormIsLoading } = useAsync<ISelectBox[] | [], undefined>(
        async () => {
            try {
                // TODO: section payload must be enum
                const response = await entityService.getAllTypes('company_shareholder');
                updateFormData(
                    'share-holder-type',
                    response.data.data.map(({ value, label }) => ({
                        label,
                        value: String(value),
                    }))
                );

                return response.data.data;
            } catch (err) {
                const error = getAxiosError(err);
                const message = error?.message || 'Server Error';
                updateFormLoading('share-holder-type', false);
                toast.fire({
                    icon: TOAST_STATUS.ERROR,
                    title: message,
                });
                return [];
            }
        },
        {
            immediate: !Boolean(isEditingRule),
            onSuccess: () => {
                if (!isEditingRule) onClickAddNewTransactionInformation();
            },
        }
    );
    const submitAsync = useAsync<unknown, ICreateShareHolderRequest>(
        (payload) => {
            return new Promise(async (resolve, reject) => {
                try {
                    const response = await (isEditingRule
                        ? shareHolderService(companyType as COMPANY_TYPE_ROUTE).update(Number(companyId), shareHolderId!, {
                              transactions: payload.transactions,
                          })
                        : shareHolderService(companyType as COMPANY_TYPE_ROUTE).create(Number(companyId), payload));
                    resolve(response);
                } catch (e) {
                    return reject(getAxiosError(e));
                }
            });
        },
        {
            onSuccess: (response) => {
                toast.fire({
                    icon: TOAST_STATUS.SUCCESS,
                    // TODO: response has no type.
                    title: (response as any)?.data.message,
                });
                navigation(ROUTE_CONSTANTS.COMPANIES.TYPE.SHOW.SHAREHOLDERS.ROOT.BY_COMPANY_ID(companyType as COMPANY_TYPE_ROUTE, +companyId).ABSOLUTE);
            },
            onError: (e) => {
                if (e?.errors) {
                    const errorsByRow = getErrorsByRow(e.errors);
                    Object.entries(errorsByRow).forEach(([index, error]) => {
                        setError(`root.transaction_row_${index}`, {
                            message: error.join('|'),
                        });
                    });
                } else if (e?.message) {
                    toast.fire({
                        icon: TOAST_STATUS.ERROR,
                        title: e.message,
                    });
                }
            },
        }
    );

    const onSubmit = async (_val: IShareHolderFormData) => {
        submitAsync.run({
            company_id: +companyId,
            entity_type: _val['share-holder-type'],
            entity_id: _val['share-holder-name'],
            transactions: _val.transaction_information,
        });
    };
    const onClickBack = () => {
        navigation(-1);
    };
    const onClickAddNewTransactionInformation = () => {
        setValue('transaction_information', [...(getValues('transaction_information') || []), { uuid: UUIDv4() }]);
        trigger('transaction_information');
    };

    const updateChildValues = (uuid: string, formData: ITransactionInformation) => {
        const current_transactions = getValues('transaction_information') || [];
        const updatedData = current_transactions.map((item) => (item.uuid === uuid ? formData : item));
        setValue('transaction_information', updatedData);
        clearErrors('root');
        trigger('transaction_information');
    };

    const removeChild = (removingUUId: string) => {
        let transactions = getValues('transaction_information') || [];
        transactions = transactions.filter((item) => removingUUId !== item.uuid);
        setValue('transaction_information', transactions);
        trigger('transaction_information');
    };

    useEffect(() => {
        if (!isEditingRule) {
            const subscription = watch((value, { name }) => {
                if (name === 'share-holder-type' && value) getShareHolderNames(value['share-holder-type'] as string);
            });

            return () => subscription.unsubscribe();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch]);

    useEffect(() => {
        if (completedData) {
            updateFormProps('share-holder-type', { disabled: true });
            updateFormProps('share-holder-name', { disabled: true });
            updateFormData('share-holder-type', [
                {
                    label: completedData.entity_type_name,
                    value: '1',
                },
            ]);
            updateFormData('share-holder-name', [
                {
                    label: completedData.entity_name,
                    value: '1',
                },
            ]);
            setValue('share-holder-type', '1');
            setValue('share-holder-name', '1');
            setValue(
                'transaction_information',
                completedData.transactions.map((item) => ({ ...item, uuid: UUIDv4() }))
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [completedDataIsLoading]);

    return (
        <div className={styles.shareHolder}>
            <div className={styles.header}>
                <div className={styles.headerTitle}>
                    <h3>{isEditingRule ? 'Edit' : 'Add'} Shareholder</h3>
                </div>
                <div className={styles.headerSuffix}>
                    <Button color={'secondary'} onClick={onClickBack}>
                        Cancel
                    </Button>
                    <Button color={'primary'} disabled={!isValid || submitAsync.isLoading} loading={submitAsync.isLoading} onClick={handleSubmit(onSubmit)}>
                        Save
                    </Button>
                </div>
            </div>

            <div className={styles.main}>
                <FormGenerator
                    onSubmit={handleSubmit(onSubmit)}
                    control={control}
                    loading={shareHolderFormIsLoading || completedDataIsLoading}
                    errors={errors}
                    schema={formFields}
                    noBackgroundLayout
                />
            </div>

            {!completedDataIsLoading &&
                (watch('transaction_information') || []).map((transactionRow, index) => (
                    <TransactionRow
                        key={'transaction_info_' + transactionRow.uuid}
                        shareHolderForm={shareHolderForm || {}}
                        updateChildValues={updateChildValues}
                        removeChild={removeChild}
                        isEditing={isEditingRule}
                        formData={completedData?.transactions.find(({ id }) => id === transactionRow.id)}
                        /*@ts-ignore*/
                        errors={(errors.root || {})[`transaction_row_${transactionRow.id}`] || []}
                        index={index}
                        uuid={transactionRow.uuid}
                    />
                ))}

            <div className={styles.addBtn}>
                {shareHolderFormIsLoading ? (
                    <InitLoading />
                ) : (
                    <span onClick={isValid ? onClickAddNewTransactionInformation : undefined} className={!isValid ? styles.disabled : ''}>
                        + Add New Transaction Information
                    </span>
                )}
            </div>
        </div>
    );
};

export default ShareHolderAddUpdate;
