import TokenParser from "./TokenParser.js";

export default class ExpressionTokenizer {
    constructor() {
        const formulaRegex = /\{(?:[^{}]|(?:\{(?:[^{}]|[^])*?\}))*?\}|(?:\b(?:-?\d+(\.\d+)?|[a-zA-Z]+(?: [a-zA-Z]+)*)\b|\(|\)|[+\/*]|(?:^| )-\d*\.?\d+\b(?![^}]*\s*=>)|-)/g;
        this.tokenRegex = formulaRegex;
        this.numberRegex = /\d+(\.\d+)?|\(|\)|[+\-\/.*]/g;
    }

    removeConsecutiveSpaces(expression) {
        if(expression) {
            return expression.replace(/\s+/g, ' ');
        }
    }

    extractTokensByRegex(asFormula, expression) {
        if(expression) {
            const regex = asFormula ? this.tokenRegex : this.numberRegex;
            const tokens = expression.match(regex);
            return tokens ? tokens.filter(token => token.trim() !== '') : [];
        }
    }

    trimmingTokens(tokens) {
        tokens.map(value => value.trim());

        return tokens;
    }

    isValidJson(jsonString) {
        try {
            JSON.parse(jsonString);
            return true;
        } catch (error) {
            return false;
        }
    }

    validateNonVariableTokens(normalizedTokens) {
        normalizedTokens.forEach(normalizedToken => {
            const isVariable = normalizedToken.includes('{') && normalizedToken.includes('}');
            const hasMultipleSegments = normalizedToken.split(' ').length !== 1;
            if (!isVariable && hasMultipleSegments) {
                throw new Error('Ambiguous input. Missing operator between variables!');
            }
        });
    }

    sanitizeAndValidateTokens(trimmedTokens) {
        return trimmedTokens.map((trimmedToken) => {
            // handle the tokens that parsed as json
            if (!this.isValidJson(trimmedToken)) {
                return trimmedToken.replace(/[{}]/g, '');
            }

            return trimmedToken;
        });
    }

    isSingleValidOperand(tokens){
        return tokens.length === 1 && typeof tokens[0] === 'string' && this.isValidJson(tokens[0]);
    }

    validateOrderingOfOperandAndOperators(tokens) {
        tokens = (new TokenParser).normalizingRawTokens(tokens);

        // ignore single valid token (like {name:total-asset})
        if(this.isSingleValidOperand(tokens)){
            return tokens[0];
        }

        for (let key = 0; key < tokens.length; key++) {
            let token = tokens[key];

            if (typeof token == 'object') {
                // If token is statement, perform this functionality recursively
                this.validateOrderingOfOperandAndOperators(token.tokens);
            }

            let nextToken = tokens[key + 1] || false;

            // Ignore next token validation if it is a statement
            if (typeof nextToken == 'object') {
                continue;
            }

            this.validateTokenAndNextToken(token, nextToken);
        }
    }

    getTokenType(normalizedToken) {
        if (['*', '/', '+', '-'].includes(normalizedToken)) {
            return 'OPERATION';
        }

        return 'ITEM';
    }

    validateTokenAndNextToken(currentToken, nextToken) {
        let tokenType = this.getTokenType(currentToken);
        let isLastToken = this.isSureLastTokenValidate(nextToken, tokenType, currentToken);

        if (isLastToken) {
            return;
        }

        this.validateNextTokenAndThrowExceptionIfFail(currentToken, tokenType, nextToken);
    }

    getExpectedNextTokenTypeWithErrorMessage(currentToken, tokenType) {
        let currentTokenName = typeof currentToken == 'object' ? (currentToken.name || JSON.stringify(currentToken)) : currentToken;
        let errorMessage, nextTokenMustBeType;

        switch (tokenType) {
            case 'OPERATION':
                errorMessage = `Missing Operation: Missing operation for operand '${currentTokenName}'.`;
                nextTokenMustBeType = 'ITEM';
                break;
            case 'ITEM':
                errorMessage = `Missing Values: Missing operand for operation '${currentTokenName}'.`;
                nextTokenMustBeType = 'OPERATION';
                break;
        }

        return [errorMessage, nextTokenMustBeType];
    }

    validateNextTokenAndThrowExceptionIfFail(currentToken, tokenType, nextToken) {
        let [errorMessage, nextTokenMustBeType] = this.getExpectedNextTokenTypeWithErrorMessage(currentToken, tokenType);

        let nextTokenType = this.getTokenType(nextToken);

        if (nextTokenType !== nextTokenMustBeType) {
            throw new Error(errorMessage);
        }
    }

    isSureLastTokenValidate(nextToken, tokenType, currentToken) {
        let isLastToken = nextToken === false;

        if (!isLastToken) {
            return false;
        }

        // Ensure the last token is an item
        if (tokenType !== 'ITEM') {
            let [errorMessage] = this.getExpectedNextTokenTypeWithErrorMessage(currentToken, 'ITEM');
            throw new Error(errorMessage);
        }

        return true;
    }

    
    tokenize(expression, asFormula = true) {

        expression = this.removeConsecutiveSpaces(expression);
        
        const rawTokens = this.extractTokensByRegex(asFormula, expression);

        const trimmedTokens = this.trimmingTokens(rawTokens);
        
        this.validateNonVariableTokens(trimmedTokens);

        const normalizedTokens = this.sanitizeAndValidateTokens(trimmedTokens);
        
        this.validateOrderingOfOperandAndOperators(normalizedTokens);

        return normalizedTokens;
    }
}
