import { COMPANY_STATUS, IBaseCompanyList } from 'src/api/types/company';
import { timestampToDateFormat } from '../../../utils/timestamp-to-date-format';
import { statusColor } from 'src/components/Kit/StatusDropDown/constants/status-color';
import { DROPDOWN_STATUS } from 'src/components/Kit/StatusDropDown/enums/dropdown-status';
import { CSSProperties } from 'react';
import { Tooltip } from 'primereact/tooltip';
import FinancialInformationIcon from '../../../assets/Icons/FinancialInformationIcon';
import FinancialReportIcon from '../../../assets/Icons/FinancialReportIcon';
import styles from './CompanyList.module.scss';
import { COMPANY_TYPE_ROUTE } from '../Add/enums/company-type-route';
import ParentCompanyIcon from 'src/assets/Icons/ParentCompanyIcon';

const statusValue = {
    [COMPANY_STATUS.APPROVED]: DROPDOWN_STATUS.SUCCESS,
    [COMPANY_STATUS.NOT_APPROVED]: DROPDOWN_STATUS.WARNING,
};

const ellipsisStyle: CSSProperties = {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: 240,
};

const CompanyTags = ({ company }: { company: IBaseCompanyList }) => {
    return (
        <>
            <h3 className={`${styles.companyName} company_${company.id}`} style={{ ...ellipsisStyle }}>
                {company.name}
            </h3>
            <div className={styles.companyTag} style={ellipsisStyle}>
                <span className={styles.shortName} style={{ paddingRight: company.short_name ? 12 : 0 }}>
                    {company.short_name}
                </span>
                <div className={styles.tagWrapper}>
                    {(company.financial_information_count || 0) > 0 && <FinancialInformationIcon className="financial-information-tag" />}
                    {(company.financial_reports_count || 0) > 0 && <FinancialReportIcon className="financial-report-tag" />}
                    {company.has_company_parent_financial && <ParentCompanyIcon className="parent-company-tag" />}
                </div>
                <Tooltip
                    target={`.financial-information-tag`}
                    className={styles.tooltip}
                    hideDelay={100}
                    content={'Financial Information'}
                    position="bottom"
                    showDelay={250}
                />
                <Tooltip
                    target={`.financial-report-tag`}
                    className={styles.tooltip}
                    hideDelay={100}
                    content={'Financial Report'}
                    position="bottom"
                    showDelay={250}
                />
                <Tooltip
                    target={`.parent-company-tag`}
                    className={styles.tooltip}
                    hideDelay={100}
                    content={'Parent Company Financial'}
                    position="bottom"
                    showDelay={250}
                />
            </div>
        </>
    );
};

export const companyDataCellRender = (companyType: COMPANY_TYPE_ROUTE) => ({
    name: (data: IBaseCompanyList) => (
        <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {companyType !== COMPANY_TYPE_ROUTE.NON_PROFILE && (
                    <span
                        style={{
                            width: 8,
                            height: 8,
                            borderRadius: '50%',
                            display: 'block',
                            marginRight: 16,
                            backgroundColor: statusColor[statusValue[data.company_status]],
                        }}
                    />
                )}
                <div style={{ display: 'flex', alignItems: 'center', marginRight: 12 }}>
                    {data.logo ? (
                        <img width={32} height={32} style={{ borderRadius: '8px' }} src={data.logo} alt={data.name} />
                    ) : (
                        <span
                            style={{
                                fontSize: '9px',
                                color: '#666',
                                width: '32px',
                                height: '32px',
                                display: 'inline-block',
                                textAlign: 'center',
                                lineHeight: '16px',
                                background: '#f5f5f5',
                                borderRadius: '8px',
                            }}
                        >
                            No Avatar
                        </span>
                    )}
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {data.name.length > 37 && <Tooltip target={`.company_${data.id}`} content={data.name} position="top" showDelay={250} />}
                    <CompanyTags company={data} />
                </div>
            </div>
        </>
    ),
    arabic_name: (data: IBaseCompanyList) => (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span
                style={{
                    display: 'block',
                    lineHeight: '18px',
                    fontSize: 14,
                    fontWeight: 400,
                    ...ellipsisStyle,
                }}
            >
                {data.arabic_name}
            </span>
            {data.arabic_short_name?.length ? (
                <span
                    style={{
                        lineHeight: '15px',
                        fontSize: 12,
                        fontWeight: 400,
                        color: '#888',
                        marginTop: 4,
                        ...ellipsisStyle,
                    }}
                >
                    {data.arabic_short_name}
                </span>
            ) : (
                ''
            )}
        </div>
    ),
    updated_at: (data: IBaseCompanyList) => (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span style={{ display: 'block', lineHeight: '18px', fontSize: 14, fontWeight: 400 }}>{data.updated_by}</span>
            <span
                style={{
                    display: 'block',
                    lineHeight: '15px',
                    fontSize: 12,
                    fontWeight: 400,
                    color: '#888',
                    marginTop: 4,
                }}
            >
                {timestampToDateFormat(data.updated_at)}
            </span>
        </div>
    ),
    created_at: (data: IBaseCompanyList) => (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span style={{ display: 'block', lineHeight: '18px', fontSize: 14, fontWeight: 400 }}>{data.created_by}</span>
            <span
                style={{
                    display: 'block',
                    lineHeight: '15px',
                    fontSize: 12,
                    fontWeight: 400,
                    color: '#888',
                    marginTop: 4,
                }}
            >
                {timestampToDateFormat(data.created_at)}
            </span>
        </div>
    ),
});
