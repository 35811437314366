import { useEffect, useRef, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { ControllerRenderProps, FieldError, FieldValues } from 'react-hook-form';
import { classNames } from 'primereact/utils';
import { ILocation } from 'src/types/form-generator-schema-type';
import { defaultProps } from './constants';
import { RedError } from 'src/assets/Icons/RedError';

interface IProps {
    field: ControllerRenderProps<FieldValues, string>;
    label: string;
    placeholder: string;
    dateFormat: string;
    className: string;
    errorMessage: FieldError;
    props?: { required?: boolean; disabled?: boolean; preview?: ILocation };
}

const LocationPicker = ({ field, label, placeholder, className, errorMessage, props = {} }: IProps) => {
    const mapRef = useRef<any>(null);
    const markerRef = useRef<any>(null);
    const [selectedLocation, setSelectedLocation] = useState<ILocation | undefined>();

    const handleMapClick = ({ lat, lng }: ILocation) => {
        setSelectedLocation({ lat, lng });
        field.onChange({ lat, lng });
    };

    const renderMarkers = (map: any, maps: any, position: ILocation | undefined) => {
        return new maps.Marker({ position, map, title: 'Company location' });
    };

    useEffect(() => {
        if (props.preview) setSelectedLocation({ lat: props.preview.lat, lng: props.preview.lng });
    }, [props]);

    useEffect(() => {
        if (mapRef.current && markerRef.current) {
            const marker = markerRef.current;

            marker.setPosition(selectedLocation);
        }
    }, [selectedLocation]);

    return (
        <div className={classNames('col-12', className ? className : '')}>
            <div className="form-field" style={{ height: '300px' }}>
                <label className={field.name}>
                    {label} {props.required ? <span className="required">*</span> : ''}
                </label>
                <p>{placeholder}</p>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: 'AIzaSyAmFJM7W7v0_VLAkz4tOJUY-B2DBjlaHsc' }}
                    defaultCenter={selectedLocation || defaultProps.center}
                    defaultZoom={defaultProps.zoom}
                    draggable={!props?.disabled}
                    onGoogleApiLoaded={({ map, maps }) => {
                        mapRef.current = { map, maps };
                        markerRef.current = renderMarkers(map, maps, selectedLocation);
                    }}
                    onClick={({ lat, lng }) => !props?.disabled && handleMapClick({ lat, lng })}
                />
                {errorMessage ? (
                    <div className={'form-input-error'}>
                        <RedError />
                        <span>{errorMessage.message}</span>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default LocationPicker;
