import { useCallback, useState } from 'react';
import { UPLOAD_URL } from 'src/constants/api-routes';
import { TOAST_STATUS } from 'src/constants/toast-status';
import { getAuthorization } from 'src/utils/get-authorization';
import { toast } from 'src/utils/toast';
import axios, { AxiosProgressEvent } from 'axios';

export interface IAxiosResponse<T> {
    payload?: T;
    meta?: {
        message?: string;
        code?: number;
        messageType?: string;
    };
}

export interface IPaginationData {
    currentPage: number;
    itemCount: number;
    itemsPerPage: number;
    totalItems: number;
    totalPages: number;
}

export interface IPaginatedPayload<T> {
    items: T[];
    pagination: IPaginationData;
}

export type PaginatedResponse<T> = IAxiosResponse<IPaginatedPayload<T>>;

export interface IUploadedMediaPayload {
    id: number;
}

export type IUploadMediaResponse = IAxiosResponse<IUploadedMediaPayload>;

interface IUploaderHookArgs {
    onComplete: (data: IUploadedMediaPayload, file?: File) => void;
    onError: (err: unknown) => void;
    setUploading?: (value: boolean) => void;
    acceptedRatio?: number;
}

function useUploadMedia(data: IUploaderHookArgs) {
    const { onComplete, onError, setUploading: setParentUploading, acceptedRatio } = data;
    const [progress, setProgress] = useState<number>(0);
    const [uploading, setUploading] = useState<boolean>(false);
    const [uploadingError, setUploadingError] = useState<string>();
    const [uploadingSuccess, setUploadingSuccess] = useState<boolean>();
    const [file, setFile] = useState<File>();

    const upload = useCallback(
        (file: File) => {
            setFile(file);
            setUploadingError(undefined);
            setUploadingSuccess(undefined);
            try {
                if (false) {
                    if (file.size > 1024 * 1024) {
                        // Hint: whole platform accept images less than 1Mb, so its hardcoded for now. but we can change it to some dynamic property later on.
                        toast.fire({
                            icon: TOAST_STATUS.ERROR,
                            title: 'Image size should be less than 1 Mb',
                        });
                        throw new Error('Image size should be less than 1 Mb');
                    }

                    const img = new Image();
                    img.src = URL.createObjectURL(file);

                    img.onload = () => {
                        try {
                            if (acceptedRatio) {
                                // Check the aspect ratio of the image
                                const ratio = img.width / img.height;
                                const threshold = acceptedRatio - 1;
                                const upperThreshold = 1 + threshold;
                                const belowThreshold = 1 - threshold;

                                if (ratio < upperThreshold && ratio > belowThreshold) {
                                    // The image has a ratio of 1:1
                                    // Proceed with the upload process
                                    uploaderProcessor(file);
                                } else {
                                    toast.fire({
                                        icon: TOAST_STATUS.ERROR,
                                        title: 'Image ratio should be 1 * 1',
                                    });
                                    throw new Error('Image ratio should be 1 * 1');
                                }
                            } else {
                                uploaderProcessor(file);
                            }
                        } catch (err) {
                            onError(err);
                        }
                    };
                } else uploaderProcessor(file);
            } catch (err) {
                onError(err);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [onComplete, onError, setParentUploading]
    );

    const uploaderProcessor = (file: File) => {
        const source = axios.CancelToken.source();

        setUploading(true);
        setProgress(0);

        const data = new FormData();
        data.append('file', file);

        axios
            .post(UPLOAD_URL, data, {
                onUploadProgress: (progressEvent: AxiosProgressEvent) => {
                    if (!progressEvent.total) return;
                    const percentage = Math.floor((progressEvent.loaded / progressEvent.total) * 100);
                    setUploading(true);
                    setParentUploading && setParentUploading(true);
                    setProgress(percentage);
                },
                cancelToken: source.token,
                headers: {
                    Authorization: getAuthorization() || '',
                },
            })
            .then((response) => {
                setProgress(100);
                setUploading(false);
                setParentUploading && setParentUploading(false);
                setUploadingSuccess(true);
                onComplete(response.data, file);
            })
            .catch((error) => {
                const errorMessage = error.response.data.message as string;
                if (!axios.isCancel(error)) {
                    setUploadingError(errorMessage);
                    setUploadingSuccess(false);
                    onError(errorMessage);
                    setUploading(false);
                }
            });
    };

    const reset = () => {
        setUploading(false);
        setUploadingError(undefined);
        setUploadingSuccess(undefined);
    };

    return {
        progress,
        upload,
        uploading,
        uploadingError,
        uploadingSuccess,
        setUploadingError,
        reset,
        file,
        setFile,
    };
}

export default useUploadMedia;
