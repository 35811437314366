import style from './FileDialog.module.scss';
import { InputText } from 'primereact/inputtext';
import { Dropdown, DropdownChangeParams } from 'primereact/dropdown';
import { FILE_SORTING_OPTIONS } from './constants';
import Button from '../Button';
import React, { useRef, useState } from 'react';

type Props = {
    onClickApply: (searchQuery: string, sortBy?: number) => void;
    isLoading: boolean;
};
export const Serachbar = ({ onClickApply, isLoading }: Props) => {
    const [sortBy, setSortBy] = useState<number>();
    const searchRef = useRef<HTMLInputElement>(null);
    const onChangeSortBy = (e: DropdownChangeParams) => setSortBy(e.target.value);

    const apply = () => onClickApply(searchRef.current?.value || '', sortBy);

    return (
        <div className={style.searchWrapper}>
            <div className={style.searchWrapperInputs}>
                <InputText ref={searchRef} style={{ height: 46 }} type={'text'} placeholder={'Search By Description'} />
                <Dropdown
                    style={{ height: 46 }}
                    optionLabel="label"
                    optionValue="value"
                    placeholder={'Sort By'}
                    value={sortBy}
                    className={style.searchWrapperDropdown}
                    options={FILE_SORTING_OPTIONS}
                    onChange={onChangeSortBy}
                />
            </div>
            <Button color={'tertiary'} className={style.searchWrapperApply} disabled={isLoading} onClick={apply}>
                Apply
            </Button>
        </div>
    );
};
