import { FORM_INPUTS } from 'src/types/form';
import { IFormGeneratorSchemaType } from 'src/types/form-generator-schema-type';

export type createPersonSchemaFields = 'media' | 'name' | 'arabic_name' | 'phone' | 'email' | 'description' | 'address' | 'linkedin' | 'gender' | 'country_id'
export type createPersonSchemaType = Record<createPersonSchemaFields, IFormGeneratorSchemaType>;
export const createPersonSchema: createPersonSchemaType = {
    media: {
        label: 'Person Logo',
        placeholder: 'Attach Your Person Picture',
        type: FORM_INPUTS.IMAGE_UPLOADER,
    },
    name: {
        placeholder: 'Name',
        type: FORM_INPUTS.TEXT,
        props: {
            required: true
        }
    },
    arabic_name: {
        placeholder: 'Arabic Name',
        type: FORM_INPUTS.TEXT,
        props: {
            required: true
        }
    },
    gender: {
        placeholder: 'Gender',
        type: FORM_INPUTS.SELECT,
        props: {
            required: true
        }
    },
    country_id: {
        placeholder: 'Country',
        type: FORM_INPUTS.SELECT,
        props: {
            filter: true
        },
    },
    phone: {
        placeholder: 'Phone',
        type: FORM_INPUTS.TEXT,
    },
    email: {
        placeholder: 'Email',
        type: FORM_INPUTS.TEXT,
    },
    description: {
        placeholder: 'Description',
        type: FORM_INPUTS.TEXTAREA,
    },
    address: {
        placeholder: 'Address',
        type: FORM_INPUTS.TEXT,
    },
    linkedin: {
        placeholder: 'Linkedin',
        type: FORM_INPUTS.TEXT,
    },
};
