export default class ArrayToExpressionParser {
    generateValue(valueData, showKey='value') {

        if (valueData == null) {
            return null;
        }

        if (valueData.type === 'item') {
            return this.parseItem(valueData, showKey);
        }

        if (valueData.type === 'statement') {
            return this.parseStatement(valueData, showKey);
        }

        return '';
    }

    getOperationSymbol(operation) {
        switch (operation) {
            case 'plus':
                return '+';
            case 'minus':
                return '-';
            case 'times':
                return '*';
            case 'division':
                return '/';
            default:
                return operation;
        }
    }

    parseItem(valueData, showKey) {
        const context = valueData.context;

        if(showKey === 'context') {
            return JSON.stringify(context)
        }
        
        return (context[showKey] !== null) ? context[showKey] : '0';
    }

    parseStatement(valueData, showKey) {
        const operations = valueData.context.operations.map((operation) => this.getOperationSymbol(operation));

        const values = valueData.context.values.map((value) => this.generateValue(value, showKey));

        const hasParentheses = valueData.context.has_parentheses;

        // generating statement by mixing values and operations exists between them

        var statement = [];

        for (let key = 0; key < values.length; key++) {

            // put value into statement
            statement.push(values[key]);

            // put operations after value
            if (key in operations) {
                statement.push(operations[key]);
            }
        }

        // merge the values and operations together based on space
        statement = statement.join(' ');

        if (hasParentheses) {
            statement = `(${statement})`;
        }

        return statement;
    }
}
