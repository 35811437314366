import { MODAL_TYPES, ModalData, ModalState } from 'src/types/modals';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

const initialState = { modals: [] };

export const useModals = create(devtools<{ modals: ModalState }>(() => initialState));

export const modalActions = {
    addModal<T extends MODAL_TYPES, D extends ModalData<T>>(type: T, data?: D) {
        useModals.setState((prev) => ({ modals: [...prev.modals, { type, data }] }));
        window.history.pushState(null, '', `#${type}`);
    },
    removeLastModal() {
        useModals.setState((prev) => ({ modals: prev.modals.filter((_, index) => index + 1 !== prev.modals.length) }));
    },
    clearModals() {
        useModals.setState(initialState, true);
    },
};
