import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { generateDefaultValueFromFormSchema } from 'src/utils/generate-default-value-from-form-schema';
import { yupResolver } from '@hookform/resolvers/yup';
import FormGenerator from 'src/components/Kit/FormGenerator/index';
import { IFormGeneratorGeneralSchemaType } from 'src/types/form-generator-schema-type';
import { ContactInformationSchemaFields, contactInformationSchema } from '../../../types/contact-information-form-fields-schema';
import { contactInformationValidationSchema } from '../../../validationSchemas/contact-information-validation-schema';
import { IContactInformationFormSchema } from '../../../types/contact-information-form-schema';
import { useEditCompanyContext } from '../../context';
import { IEditCompanyFormValues } from '../../types/form-values';

const ContactInformationCompanyForm: React.FC = () => {
    const { setFormValues, formValues, setSaveDisabled } = useEditCompanyContext();
    const [formSchema] = useState<Record<ContactInformationSchemaFields, IFormGeneratorGeneralSchemaType>>(contactInformationSchema);

    const {
        control,
        setValue,
        watch,
        formState: { errors },
    } = useForm<IContactInformationFormSchema>({
        defaultValues: generateDefaultValueFromFormSchema(formSchema),
        mode: 'all',
        resolver: yupResolver(contactInformationValidationSchema),
    });

    const setValues = async () => {
        if (Object.keys(formValues).length) {
            // Hint: this condition is for those moments we are coming back from next steps.
            Object.entries(formValues).forEach(([key, value]) =>
                setValue(key as keyof IContactInformationFormSchema, value as string | undefined, { shouldValidate: true })
            );
        }
    };

    const handleCheckLatAndLng = () => {
        const lat = formValues['latitude'];
        const lng = formValues['longitude'];

        if (!lat && !lng) {
            setSaveDisabled(false);
        } else if (lat && lng) {
            setSaveDisabled(false);
        } else {
            setSaveDisabled(true);
        }
    };

    useEffect(() => {
        handleCheckLatAndLng();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formValues]);

    useEffect(() => {
        setValues();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const subscription = watch((value, { name }) => {
            if (name) {
                let newData = {};

                newData = { ...newData, [name as keyof IEditCompanyFormValues]: value[name] };
                setFormValues((prev) => ({ ...prev, ...newData }));
            }
        });
        return () => subscription.unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch]);

    return (
        <div className="grid justify-content-center">
            <div className="xs:col:12 md:col-6">
                <FormGenerator control={control} errors={errors} schema={formSchema} noBackgroundLayout />
            </div>
        </div>
    );
};

export default ContactInformationCompanyForm;
