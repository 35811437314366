import { SVGProps } from 'react';

const CloseIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg className={props.className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M7.5 7.5L16.5 16.5" stroke={props.stroke || 'currentColor' || '#000000'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.5 16.5L16.5 7.5" stroke={props.stroke || 'currentColor' || '#000000'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default CloseIcon;
