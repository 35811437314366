import { API_ROUTES } from 'src/constants/api-routes';
import { AxiosInstance } from '../index';
import { IAxiosResponse } from '../types/axios-response';
import { IFeedsResponse, IFeedsPayload, IUpdateFeedsPayload } from '../types/feed';

export const feedService = {
    getStatuses(params?: IFeedsPayload) {
        return AxiosInstance.get<IAxiosResponse<IFeedsResponse>>(API_ROUTES.COMPANY.FEEDS, { params });
    },
    updateStatuses(params?: IUpdateFeedsPayload) {
        return AxiosInstance.put<IAxiosResponse>(API_ROUTES.COMPANY.FEEDS, params);
    },
};
