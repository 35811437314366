import Item from './Item.js';
import FormulaComponent from '../Abstract/FormulaComponent.js';
import { FormulaComponentEnum } from '../Abstract/FormulaComponent.js';

export const MathematicalOperationEnum = {
    PLUS: 'plus',
    MINUS: 'minus',
    TIMES: 'times',
    DIVISION: 'division'
};

export default class Statement extends FormulaComponent {
    constructor(name = null, withParentheses = false, values = [], operations = []) {
        super();
        this.name = name;
        this.withParentheses = withParentheses;
        this.values = values;
        this.operations = operations;
        this.allValues = [];
    }

    static fillFromArray(statementArray) {
        return new Statement(
            statementArray.name,
            statementArray.has_parentheses,
            statementArray.values,
            statementArray.operations
        );
    }

    item(itemEnum) {
        if (typeof itemEnum === 'object') {
            itemEnum = new Item(itemEnum);
        }

        return this.addValues(FormulaComponentEnum.ITEM, itemEnum);
    }

    statement(statement) {
        return this.addValues(FormulaComponentEnum.STATEMENT, statement);
    }

    addValues(type, ratioFormulaComponent) {
        this.values.push({
            type: type,
            context: ratioFormulaComponent.getContext()
        });
        return this;
    }

    withParentheses() {
        this.withParentheses = true;
        return this;
    }

    getContext() {
        return {
            name: this.name || null,
            has_parentheses: this.withParentheses,
            has_abs: this.has_abs || false,
            values: this.values,
            operations: this.getOperations()
        };
    }

    getComponentType() {
        return FormulaComponentEnum.STATEMENT;
    }

    getOperations() {
        return this.operations.map((value) => value)
    }

    collectAllItemValuesRecursively(values) {
        values = values || this.values;

        for (const value of values) {
            const componentType = value.type;

            if (componentType === FormulaComponentEnum.ITEM) {
                const itemName = value.context.name;
                this.allValues.push({
                    name: itemName,
                    value: this.getItemValueIfExists(itemName) // Implement logic to get item value based on itemName
                });
            }

            if (componentType === FormulaComponentEnum.STATEMENT) {
                this.collectAllItemValuesRecursively(value.context.values);
            }
        }
    }

    getAllValues() {
        this.collectAllItemValuesRecursively();
        return this.allValues;
    }

    getItemValueIfExists(itemName) {
        // Implement logic to get item value based on itemName
        return null; // Placeholder value, replace this with actual logic
    }

    setOperation(operation) {
        this.operations.push(operation);
        return this;
    }

    // with validate method you can check your current statement
    validate() {

        const valuesCount = this.values.length;

        if (valuesCount < 2) {
            throw new Error("A mathematical statement must have at least two values.");
        }

        // For every 2 values, there must be one operation.
        // In other words, the count of operations in the statement should be equal to the count of values minus one (N-1).
        const expectedOperationsCount = valuesCount - 1;
        const actualOperationsCount = this.operations.length;

        if (expectedOperationsCount > actualOperationsCount) {
            throw new Error(`Missing Operations: You must set operations between values!`);
        }

        if (expectedOperationsCount < actualOperationsCount) {
            throw new Error(`Unexpected Operations: Using operations without defining values for them`);
        }

        return this;
    }

    plus() {
        return this.setOperation(MathematicalOperationEnum.PLUS);
    }

    minus() {
        return this.setOperation(MathematicalOperationEnum.MINUS);
    }

    times() {
        return this.setOperation(MathematicalOperationEnum.TIMES);
    }

    dividedBy() {
        return this.setOperation(MathematicalOperationEnum.DIVISION);
    }
}
