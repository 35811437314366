export enum SORT_ORDER_NAME {
    DESC = 'desc',
    ASC = 'asc',
}

export enum FILTER_TYPES {
    TEXT = 'text',
    DATE = 'date',
    SELECT = 'select',
    CHECKBOX = 'checkbox',
}
