import { FORM_INPUTS } from 'src/types/form';
import { IFormGeneratorSchemaType } from 'src/types/form-generator-schema-type';

export type CreateFileSchemaFields = 'description';
export type CreateFileSchemaType = Record<CreateFileSchemaFields, IFormGeneratorSchemaType>;

export const createFileSchema: CreateFileSchemaType = {
    description: {
        placeholder: 'Description',
        type: FORM_INPUTS.TEXT,
        props: {
            required: false,
            showLabel: false,
        },
    },
};
