import { API_ROUTES } from 'src/constants/api-routes';
import { AxiosInstance } from '../index';
import { IAxiosResponse } from '../types/axios-response';
import { FileSearchPayload, ISearchFileResponse } from '../types/file';
import { IDataTablePayload, IDataTableResponse } from '../types/table';

export const mediaService = {
    getViewPDFLink(UUID: string) {
        return AxiosInstance.get<IAxiosResponse<string>>(API_ROUTES.MEDIA.VIEW_PDF(UUID));
    },
    getSignedDeleteUrl(UUID: string) {
        return AxiosInstance.get<IAxiosResponse<string>>(API_ROUTES.MEDIA.DELETE_FILE_URL(UUID));
    },
    invokeSignedDeleteUrl(url: string) {
        return AxiosInstance.delete<IAxiosResponse<string>>(url);
    },
    getSignedDownloadFileUrl(UUID: string) {
        return AxiosInstance.get<IAxiosResponse<string>>(API_ROUTES.MEDIA.DOWNLOAD_FILE_URL(UUID));
    },
    search(params: FileSearchPayload) {
        return AxiosInstance.get<IAxiosResponse<ISearchFileResponse>>(API_ROUTES.MEDIA.SEARCH, { params });
    },
    getMedias(queryParams?: IDataTablePayload) {
        return AxiosInstance.get<IDataTableResponse<ISearchFileResponse>>(API_ROUTES.MEDIA.MEDIAS, { params: queryParams });
    },
    updateDescription(UUID: string, payload?: { description: string }) {
        return AxiosInstance.path<IAxiosResponse<string>>(API_ROUTES.MEDIA.UPDATE_DESCRIPTION(UUID), payload);
    },
};
