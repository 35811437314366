import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { generateDefaultValueFromFormSchema } from '../../../../../utils/generate-default-value-from-form-schema';
import { yupResolver } from '@hookform/resolvers/yup';
import FormGenerator from '../../../../../components/Kit/FormGenerator';
import { createHoldingSchema } from './schema';
import { holdingValidationSchema } from './validation-schema';
import Button from '../../../../../components/Kit/Button';
import TransactionRow from './transaction-row';
import { IHoldingFormData } from './types';
import { useEditingMode } from './useUpdate';
import { useAsync } from '../../../../../hooks/useAsync';
import { toast } from '../../../../../utils/toast';
import { getAxiosError, getErrorsByRow } from '../../../../../utils/get-axios-error';
import { TOAST_STATUS } from '../../../../../constants/toast-status';
import { ICreateHoldingRequest, ITransactionInformation } from '../../../../../api/types/holdings';
import { useNavigate, useParams } from 'react-router-dom';
import ROUTE_CONSTANTS from '../../../../../Routes/constants/route-constants';
import { useFormFields } from '../../../../../hooks/useFormFields';
import { UUIDv4 } from 'src/utils/uuid-generator';
import styles from './HoldingAddUpdate.module.scss';
import { holdingService } from 'src/api/services/holding-service';
import { entityService } from 'src/api/services/entity-service';
import { ISelectBox } from 'src/api/types/base-types';
import { COMPANY_TYPE_ROUTE } from 'src/pages/Company/Add/enums/company-type-route';
import { IParams } from 'src/types/params';


const HoldingAddUpdate = () => {
    const { companyType } = useParams<IParams>();
    const { formFields, updateFormData, updateFormLoading, updateFormProps } = useFormFields(createHoldingSchema);
    const { isEditingRule, completedData, isLoading: completedDataIsLoading, holdingId, companyId } = useEditingMode();
    const navigation = useNavigate();

    const {
        control,
        formState: { errors, isValid },
        handleSubmit,
        trigger,
        watch,
        setValue,
        setError,
        clearErrors,
        getValues,
    } = useForm<IHoldingFormData>({
        defaultValues: generateDefaultValueFromFormSchema(formFields),
        mode: 'all',
        shouldFocusError: false,
        resolver: yupResolver(holdingValidationSchema),
    });

    useAsync<ISelectBox[] | [], undefined>(
        async () => {
            try {
                // TODO: section payload must be enum
                const response = await entityService.getAllTypes('company_holding');
                updateFormData(
                    'entity_type',
                    response.data.data.map(({ value, label }) => ({
                        label,
                        value: String(value),
                    }))
                );

                return response.data.data;
            } catch (err) {
                const error = getAxiosError(err);
                const message = error?.message || 'Server Error';
                toast.fire({
                    icon: TOAST_STATUS.ERROR,
                    title: message,
                });
                return [];
            } finally {
                updateFormLoading('entity_type', false);
            }
        },
        {
            immediate: !Boolean(isEditingRule),
            onSuccess: () => {
                if (!isEditingRule) onClickAddNewTransactionInformation();
            },
        }
    );

    const getHoldingNames = async (entityType: string, query?: string) => {
        try {
            updateFormLoading('entity_id', true);
            const result = await entityService.getShareHolderTypes({
                type: entityType,
                ...(query ? { text: query } : {}),
            });
            updateFormData(
                'entity_id',
                result.data.data.map((data) => ({
                    label: data.label,
                    value: String(data.value),
                }))
            );
            updateFormProps('entity_id', {
                onFilter: (_fieldName: string, values: any) => getHoldingNames(entityType, values),
            });
            return Promise.resolve();
        } catch (e) {
            return Promise.reject();
        }
    };

    const submitAsync = useAsync<unknown, ICreateHoldingRequest>(
        (payload) => {
            return new Promise(async (resolve, reject) => {
                try {
                    const response = await (isEditingRule
                        ? holdingService(companyType as COMPANY_TYPE_ROUTE).update(Number(companyId), Number(holdingId)!, {
                              transactions: payload.transactions,
                          })
                        : holdingService(companyType as COMPANY_TYPE_ROUTE).create(Number(companyId), payload));
                    resolve(response);
                } catch (e) {
                    return reject(getAxiosError(e));
                }
            });
        },
        {
            onSuccess: (response) => {
                toast.fire({
                    icon: TOAST_STATUS.SUCCESS,
                    // TODO: response has no type.
                    title: (response as any)?.data.message,
                });
                navigation(ROUTE_CONSTANTS.COMPANIES.TYPE.SHOW.HOLDINGS.ROOT.BY_COMPANY_ID(companyType as COMPANY_TYPE_ROUTE, +companyId).ABSOLUTE);
            },
            onError: (e) => {
                if (e?.errors) {
                    const errorsByRow = getErrorsByRow(e.errors);
                    Object.entries(errorsByRow).forEach(([index, error]) => {
                        setError(`root.transaction_row_${index}`, {
                            message: error.join('|'),
                        });
                    });
                } else if (e?.message) {
                    toast.fire({
                        icon: TOAST_STATUS.ERROR,
                        title: e.message,
                    });
                }
            },
        }
    );
    const onSubmit = async (_val: IHoldingFormData) => {
        submitAsync.run({
            company_id: +companyId,
            entity_type: _val.entity_type,
            entity_id: _val.entity_id,
            transactions: _val.transaction_information,
        });
    };
    const onClickBack = () => {
        navigation(-1);
    };
    const onClickAddNewTransactionInformation = () => {
        setValue('transaction_information', [...(getValues('transaction_information') || []), { uuid: UUIDv4() }]);
        trigger('transaction_information');
    };

    const updateChildValues = (uuid: string, formData: ITransactionInformation) => {
        const current_transactions = getValues('transaction_information') || [];
        const updatedData = current_transactions.map((item) => (item.uuid === uuid ? formData : item));
        setValue('transaction_information', updatedData);
        clearErrors('root');
        trigger('transaction_information');
    };

    const removeChild = (removingUUId: string) => {
        let transactions = getValues('transaction_information') || [];
        transactions = transactions.filter((item) => removingUUId !== item.uuid);
        setValue('transaction_information', transactions);
        trigger('transaction_information');
    };

    useEffect(() => {
        if (!isEditingRule) {
            const subscription = watch((value, { name }) => {
                if (name === 'entity_type' && value) getHoldingNames(value.entity_type as string);
            });

            return () => subscription.unsubscribe();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch]);

    useEffect(() => {
        if (completedData) {
            updateFormProps('entity_type', { disabled: true });
            updateFormProps('entity_id', { disabled: true });
            updateFormData('entity_type', [
                {
                    label: completedData.entity_type_name,
                    value: '1',
                },
            ]);
            updateFormData('entity_id', [
                {
                    label: completedData.entity_name,
                    value: '1',
                },
            ]);

            setValue('entity_type', '1');
            setValue('entity_id', '1');

            setValue(
                'transaction_information',
                completedData.transactions.map((item) => ({ ...item, uuid: UUIDv4() }))
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [completedDataIsLoading]);

    return (
        <div className={styles.holding}>
            <div className={styles.header}>
                <div className={styles.headerTitle}>
                    <h3>{isEditingRule ? 'Edit' : 'Add'} Holding</h3>
                </div>
                <div className={styles.headerSuffix}>
                    <Button color={'secondary'} onClick={onClickBack}>
                        Cancel
                    </Button>
                    <Button color={'primary'} disabled={!isValid || submitAsync.isLoading} loading={submitAsync.isLoading} onClick={handleSubmit(onSubmit)}>
                        Save
                    </Button>
                </div>
            </div>

            <div className={styles.main}>
                <FormGenerator
                    onSubmit={handleSubmit(onSubmit)}
                    control={control}
                    loading={completedDataIsLoading}
                    errors={errors}
                    schema={formFields}
                    noBackgroundLayout
                />
            </div>

            {!completedDataIsLoading &&
                (watch('transaction_information') || []).map((transactionRow, index) => (
                    <TransactionRow
                        key={'transaction_info_' + transactionRow.uuid}
                        updateChildValues={updateChildValues}
                        removeChild={removeChild}
                        isEditing={isEditingRule}
                        formData={completedData?.transactions.find(({ id }) => id === transactionRow.id)}
                        /*@ts-ignore*/
                        errors={(errors.root || {})[`transaction_row_${transactionRow.id}`] || []}
                        index={index}
                        uuid={transactionRow.uuid}
                    />
                ))}

            <div className={styles.addBtn}>
                <span onClick={isValid ? onClickAddNewTransactionInformation : undefined} className={!isValid ? styles.disabled : ''}>
                    + Add New Transaction Information
                </span>
            </div>
        </div>
    );
};

export default HoldingAddUpdate;
