import { FORM_INPUTS } from 'src/types/form';
import { IFormGeneratorSchemaType } from 'src/types/form-generator-schema-type';
import { DATE_FORMAT } from '../../../enums/date-format';
import { DATE_PICKER_VIEW } from '../../../enums/date-picker-view';
import { parentCompanyFinancialLanguages } from './constants/parent-company-financial-languages-dropdown';
import { parentCompanyFinancialSource } from './constants/parent-company-financial-source-dropdown';
import { parentCompanyFinancialReportDate } from './constants/parent-company-financial-report-date-dropdown';
import { parentCompanyFinancialCompanySource } from './constants/parent-company-financial-company-source';

export type CreateParentCompanyFinancialSchemaFields =
    | 'company_source'
    | 'company_name'
    | 'report_date'
    | 'financial_year_end'
    | 'pages'
    | 'language'
    | 'source'
    | 'source_link'
    | 'pdf_file';
export type CreateParentCompanyFinancialSchemaType = Record<CreateParentCompanyFinancialSchemaFields, IFormGeneratorSchemaType>;

export const createParentCompanyFinancialSchema: CreateParentCompanyFinancialSchemaType = {
    company_source: {
        placeholder: 'Company Source',
        type: FORM_INPUTS.SELECT,
        data: parentCompanyFinancialCompanySource,
        props: {
            required: true,
        },
    },
    company_name: {
        placeholder: 'Company Name',
        type: FORM_INPUTS.SELECT,
        props: {
            required: true,
            disabled: true,
            filter: true,
            lazy: true,
        },
    },
    report_date: {
        placeholder: 'Report Date',
        type: FORM_INPUTS.SELECT,
        data: parentCompanyFinancialReportDate,
        props: {
            required: true,
        },
    },
    financial_year_end: {
        placeholder: 'Financial Year End',
        type: FORM_INPUTS.DATE,
        props: {
            required: true,
            view: DATE_PICKER_VIEW.MONTH,
            dateFormat: DATE_FORMAT.YEAR_FOUR_DIGITS_MONTH,
            maxDate: new Date(),
        },
    },
    pages: {
        label: 'Pages',
        placeholder: 'Separate pages with comma(,)',
        type: FORM_INPUTS.CHIPS,
        props: {
            required: false,
            allowDuplicate: false,
            keyfilter: 'int',
        },
    },
    language: {
        placeholder: 'Select Language',
        type: FORM_INPUTS.MULTI_SELECT,
        data: parentCompanyFinancialLanguages,
        label: 'Language',
        props: {
            required: true,
            showClear: false,
            selectionLimit: 4,
            showSelectAll: false,
            panelHeaderTemplate: <></>,
        },
    },
    source: {
        placeholder: 'Source',
        type: FORM_INPUTS.SELECT,
        data: parentCompanyFinancialSource,
        props: {
            required: true,
            multiple: true,
        },
    },
    source_link: {
        placeholder: 'Source Link',
        type: FORM_INPUTS.TEXT,
        props: {
            required: true,
            max: 1000
        },
    },
    pdf_file: {
        placeholder: 'PDF file',
        type: FORM_INPUTS.FILE,
        props: {
            required: true,
            accept: 'application/pdf',
            auto: true,
            chooseLabel: 'Upload PDF file ...',
        },
    },
};
