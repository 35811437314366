import { Checkbox } from 'primereact/checkbox';
import { ITableBaseFilterData } from 'src/api/types/table';
import { FILTER_TYPES } from 'src/enums/table';

const CheckboxFilter: React.FC<Omit<ITableBaseFilterData<FILTER_TYPES.CHECKBOX>, 'config'>> = ({
    defaultValue,
    onChange,
    name,
    label,
}) => {
    return (
        <div className="flex align-items-center ml-2">
            <Checkbox
                inputId={name}
                name={name}
                value={defaultValue !== 'true' ? 'true' : ''}
                onChange={onChange}
                checked={defaultValue === 'true'}
            />
            <label htmlFor={name}>{label}</label>
        </div>
    );
};

export default CheckboxFilter;
