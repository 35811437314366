import { ReactNode } from 'react';
import { InputNumber, InputNumberChangeParams, InputNumberProps } from 'primereact/inputnumber';
import { classNames } from 'primereact/utils';
import { ControllerRenderProps, FieldError, FieldValues } from 'react-hook-form';
import { RedError } from 'src/assets/Icons/RedError';
interface IProps {
    field: ControllerRenderProps<FieldValues, string>;
    label: string;
    placeholder: string;
    className: string;
    inputType: string;
    errorMessage: FieldError;
    props?: InputNumberProps;
    prefix?: ReactNode;
}
const FormNumber = ({ field, label, placeholder, className, errorMessage, prefix, props = {} }: IProps) => {
    return (
        <div className={classNames('col-12', className ? className : '')}>
            <div className="form-field">
                <label className={field.name}>
                    {label || placeholder} {props.required ? <span className="required">*</span> : ''}
                </label>
                <div className="p-inputgroup">
                    {prefix && <span className="p-inputgroup-addon">{prefix}</span>}
                    <InputNumber
                        id={field.name}
                        placeholder={placeholder}
                        {...field}
                        {...props}
                        value={field.value || null}
                        onChange={(e: InputNumberChangeParams) => field.onChange(e.value)}
                    />
                </div>
                {errorMessage ? (
                    <div className={'form-input-error'}>
                        <RedError />
                        <span>{errorMessage.message}</span>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default FormNumber;
