import React, { Dispatch, SetStateAction } from 'react';
import styles from './List.module.scss';
import DashboardTitle from 'src/components/App/DashboardTitle';
import Table from 'src/components/App/Table/index';
import Button from 'src/components/Kit/Button';
import { Helmet } from 'react-helmet-async';
import { Column } from 'primereact/column';
import ROUTE_CONSTANTS from 'src/Routes/constants/route-constants';
import { toast } from 'src/utils/toast';
import { TOAST_STATUS } from 'src/constants/toast-status';
import DashboardContentWrapper from 'src/layouts/DashboardContentWrapper';
import DeleteIcon from 'src/assets/Icons/DeleteIcon';
import EditIcon from 'src/assets/Icons/EditIcon';
import { getAxiosError } from 'src/utils/get-axios-error';
import { modalActions } from 'src/context/modals';
import { MODAL_TYPES } from 'src/types/modals';
import { useCheckPermissionAccess } from 'src/hooks/useCheckPermissionAccess';
import { PERMISSION_TYPES } from 'src/enums/permissions';
import { IStockExchangeListResponse } from 'src/api/types/stock-exchanges';
import { useNavigate } from 'react-router-dom';
import { generateQueryString } from 'src/utils/urlUtils';
import { stockExchangeService } from 'src/api/services/stock-exchanges-service';
import { cellRender } from './cellRender';
import { IDataTablePayload } from 'src/api/types/table';

const service = async (queryParams?: IDataTablePayload) => stockExchangeService.getAllStockExchange(queryParams);

const StockExchangeList: React.FC = () => {
    const navigate = useNavigate();
    const { checkPermissionAccess } = useCheckPermissionAccess();
    const handleRemove = (rowData: IStockExchangeListResponse, setTableData: Dispatch<SetStateAction<IStockExchangeListResponse[]>>) => {
        const handleRemoveItem = async () => {
            try {
                const deleteResult = await stockExchangeService.delete(rowData.id);
                if (deleteResult.data.success) {
                    setTableData((tableData: IStockExchangeListResponse[]) => tableData.filter((item: IStockExchangeListResponse) => item.id !== rowData.id));
                    toast.fire({
                        icon: TOAST_STATUS.SUCCESS,
                        title: deleteResult.data.message,
                    });
                } else {
                    toast.fire({
                        icon: TOAST_STATUS.ERROR,
                        title: deleteResult.data.message,
                    });
                }
            } catch (err) {
                const error = getAxiosError(err);
                const message = error?.message || 'Server Error';
                toast.fire({
                    icon: TOAST_STATUS.ERROR,
                    title: message,
                });
            }
        };

        const handleGoToCompaniesList = () => {
            modalActions.clearModals();
            navigate(ROUTE_CONSTANTS.COMPANIES.ROOT.ABSOLUTE + '?' + generateQueryString({ stock_exchange_id: String(rowData.name) }));
        };

        if (rowData.companies_count > 0) {
            modalActions.addModal(MODAL_TYPES.CONFIRMATION_MODAL, {
                header: 'Delete is prohibited',
                children: (
                    <div className={styles.deleteModal}>
                        <span className={styles.mainText}>
                            {`This stock exchange is used in ${rowData.companies_count} ${
                                rowData.companies_count < 2 ? 'company' : 'companies'
                            } . To delete this stock exchange first edit the ${
                                rowData.companies_count < 2 ? 'company' : 'companies'
                            } and remove this stock exchange from it.`}
                        </span>
                        <span className={styles.secondaryText}>
                            By clicking on this button I will be redirected to the company list page, which is based on the companies that have used our desired
                            stock exchange.
                        </span>
                        <div className={styles.actionWrapper}>
                            <Button color="primary" onClick={handleGoToCompaniesList}>
                                <span>Go to companies list</span>
                            </Button>
                        </div>
                    </div>
                ),
                text: '',
            });
        } else {
            modalActions.addModal(MODAL_TYPES.CONFIRMATION_MODAL, {
                header: 'Delete Confirm',
                text: 'Are you sure to delete this stock exchange?',
                handleAction: handleRemoveItem,
            });
        }
    };

    const suffixCols = (data: IStockExchangeListResponse[], setTableData: Dispatch<SetStateAction<IStockExchangeListResponse[]>>) => {
        return (
            <Column
                key="Action"
                body={(rowData: IStockExchangeListResponse) => (
                    <>
                        {checkPermissionAccess(PERMISSION_TYPES.SETTING_STOCK_EXCHANGE_EDIT) && (
                            <Button
                                link
                                color="secondary"
                                size="m"
                                iconOnly
                                to={ROUTE_CONSTANTS.SETTINGS.STOCK_EXCHANGE.UPDATE.EDIT_BY_ID(rowData.id).ABSOLUTE}
                            >
                                <EditIcon />
                            </Button>
                        )}
                        {checkPermissionAccess(PERMISSION_TYPES.SETTING_STOCK_EXCHANGE_DELETE) && (
                            <Button color="secondary" iconOnly size="m" onClick={() => handleRemove(rowData, setTableData)}>
                                <DeleteIcon />
                            </Button>
                        )}
                    </>
                )}
            />
        );
    };

    return (
        <>
            <Helmet>
                <title>Teaser | Stock Exchange List</title>
            </Helmet>
            <DashboardTitle
                title={'Stock Exchange List'}
                titleSuffix={
                    checkPermissionAccess(PERMISSION_TYPES.SETTING_STOCK_EXCHANGE_CREATE) ? (
                        <Button size="s" link color="primary" to={ROUTE_CONSTANTS.SETTINGS.STOCK_EXCHANGE.ADD_NEW.ROOT.ABSOLUTE}>
                            Add New Stock Exchange
                        </Button>
                    ) : null
                }
            />
            <DashboardContentWrapper>
                {checkPermissionAccess(PERMISSION_TYPES.SETTING_STOCK_EXCHANGE_LIST_VIEW) && (
                    <Table getTableData={service} cellRender={cellRender} suffixCols={suffixCols} />
                )}
            </DashboardContentWrapper>
        </>
    );
};

export default StockExchangeList;
