import { API_ROUTES } from 'src/constants/api-routes';
import { AxiosInstance } from '../index';
import { IAxiosResponse } from '../types/axios-response';
import { IUser, ICreateUserPayload, IUpdateUserPayload, IOneUser, IUserRequest, IUserChangeRequestStatusPayload } from '../types/user';
import { IDataTablePayload, IDataTableResponse } from '../types/table';
import { USER_REQUEST_STATUS } from 'src/enums/user/request-status';

export const userService = {
    changeActivation(id: number, active: boolean) {
        return AxiosInstance.put<IAxiosResponse<{}>>(API_ROUTES.USER.CHANGE_ACTIVATION(id), {
            active,
        });
    },
    getAll(params?: IDataTablePayload) {
        return AxiosInstance.get<IDataTableResponse<IUser>>(API_ROUTES.USER.GET_ALL, { params });
    },
    createNew(data: ICreateUserPayload) {
        return AxiosInstance.post<IAxiosResponse<{}>>(API_ROUTES.USER.ADD_NEW, data);
    },
    update(id: number, data: IUpdateUserPayload) {
        return AxiosInstance.path<IAxiosResponse<{}>>(API_ROUTES.USER.UPDATE(id), data);
    },
    delete(id: number) {
        return AxiosInstance.delete<IAxiosResponse<{}>>(API_ROUTES.USER.DELETE(id));
    },
    getOne(id: number) {
        return AxiosInstance.get<IAxiosResponse<IOneUser>>(API_ROUTES.USER.GET_ONE(id));
    },
    requests(status: USER_REQUEST_STATUS, params?: IDataTablePayload) {
        return AxiosInstance.get<IDataTableResponse<IUserRequest>>(API_ROUTES.USER.REQUESTS(status), { params });
    },
    changeRequestStatus(data: IUserChangeRequestStatusPayload) {
        return AxiosInstance.post<IAxiosResponse<{}>>(API_ROUTES.USER.CHANGE_REQUEST_STATUS, data);
    },
};
