import React, { useEffect, useState } from 'react';
import DashboardContentWrapper from 'src/layouts/DashboardContentWrapper';
import FormGenerator from 'src/components/Kit/FormGenerator/index';
import { useForm } from 'react-hook-form';
import { generateDefaultValueFromFormSchema } from 'src/utils/generate-default-value-from-form-schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { validationSchema } from './validation-schema';
import { createSchemaType, createStockExchangeSchema } from './schema';
import Button from 'src/components/Kit/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'src/utils/toast';
import { TOAST_STATUS } from 'src/constants/toast-status';
import InitLoading from 'src/components/App/Loading/InitLoading';
import { getAxiosError } from 'src/utils/get-axios-error';
import { useCheckPermissionAccess } from 'src/hooks/useCheckPermissionAccess';
import { PERMISSION_TYPES } from 'src/enums/permissions';
import { TCreateStockExchangePayload } from 'src/api/types/stock-exchanges';
import { stockExchangeService } from 'src/api/services/stock-exchanges-service';

interface IParams {
    [id: string]: string;
}

const AddUpdate: React.FC = () => {
    const { checkPermissionAccess } = useCheckPermissionAccess();
    const navigate = useNavigate();
    const { id } = useParams<IParams>();
    const isEditingRule = Boolean(id);
    const hasCreatePermission = checkPermissionAccess(PERMISSION_TYPES.SETTING_STOCK_EXCHANGE_CREATE)
    const hasUpdatePermission = checkPermissionAccess(PERMISSION_TYPES.SETTING_STOCK_EXCHANGE_EDIT)
    const [loading, setLoading] = useState<boolean>(false);
    const [initialLoading, setInitialLoading] = useState<boolean>(true);
    const [formSchema] = useState<createSchemaType>(createStockExchangeSchema);

    const {
        control,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm<TCreateStockExchangePayload>({
        defaultValues: generateDefaultValueFromFormSchema(formSchema),
        mode: 'all',
        resolver: yupResolver(validationSchema()),
    });

    const handleGetValues = async () => {
        setInitialLoading(true);
        if (isEditingRule) await getDetails();
        setInitialLoading(false);
    };

    const onSubmit = async (_val: TCreateStockExchangePayload) => {
        let targetApi;
        const { name, arabic_name } = _val;
        const tempData = {
            name,
            arabic_name,
        };

        if (isEditingRule && hasUpdatePermission) targetApi = stockExchangeService.update(Number(id), tempData);
        else if(hasCreatePermission) targetApi = stockExchangeService.createNew(tempData)
        try {
            setLoading(true);
            await targetApi;
            toast.fire({
                icon: TOAST_STATUS.SUCCESS,
                title: isEditingRule ? 'Stock Exchange has been updated successfully' : 'Stock Exchange has been created successfully',
            });
            setLoading(false);
            navigate(-1);
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: message,
            });
            setLoading(false);
        }
    };

    const getDetails = async () => {
        try {
            let res = await stockExchangeService.getOne(Number(id));
            reset({
                name: res.data.data.name,
                arabic_name: res.data.data.arabic_name || '',
            });
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: message,
            });
            setInitialLoading(false)
            navigate(-1);
            throw new Error('Could not get details')
        }
    };

    useEffect(() => {
        handleGetValues();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if(isEditingRule && !hasUpdatePermission) return <></>;
    if(!isEditingRule && !hasCreatePermission) return <></>;

    if (initialLoading) return <InitLoading />;
    
    return (
        <DashboardContentWrapper>
            <div className="grid justify-content-center my-8">
                <div className="xs:col:12 md:col-8 xl:col-5">
                    <span className="block text-2xl font-semibold">{isEditingRule ? 'Update Stock Exchange' : 'Add New Stock Exchange'}</span>
                    <div className="mt-5">
                        <FormGenerator
                            onSubmit={handleSubmit(onSubmit)}
                            control={control}
                            errors={errors}
                            schema={formSchema}
                            noBackgroundLayout
                            buttons={
                                <>
                                    <Button disabled={loading} loading={loading} color="primary">
                                        {isEditingRule ? 'Update' : 'Create'}
                                    </Button>
                                    <Button type="button" onClick={() => navigate(-1)}>
                                        Cancel
                                    </Button>
                                </>
                            }
                        />
                    </div>
                </div>
            </div>
        </DashboardContentWrapper>
    );
};

export default AddUpdate;
