import { SVGProps } from 'react';

const ArrowLeftDoubleIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
            <path
                d="M10.5 8L6.5 12L10.5 16"
                stroke={props.stroke || 'currentColor' || '#323232'}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17.5 7L12.5 12L17.5 17"
                stroke={props.stroke || 'currentColor' || '#323232'}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default ArrowLeftDoubleIcon;
