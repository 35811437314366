import { string, object } from 'yup';

export const createValidationSchema = 
    object().shape({
        full_name: string().required('Full Name is required'),
        username: string().required('Username is required'),
        password: string().required('Password is required'),
        email: string().email().required('Email is required'),
        role_id: string().required('Role is required'),
    });

export const updateValidationSchema = 
    object().shape({
        full_name: string().required('Full Name is required'),
        email: string().email().required('Email is required'),
    });
