export const FinancialInformationLandingImage = () => {
    return (
        <svg width="150" height="153" viewBox="0 0 150 153" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M75 150C116.421 150 150 116.421 150 75C150 33.5786 116.421 0 75 0C33.5786 0 0 33.5786 0 75C0 116.421 33.5786 150 75 150Z"
                fill="url(#paint0_linear_1526_76627)"
            />
            <g filter="url(#filter0_d_1526_76627)">
                <mask id="mask0_1526_76627" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="150" height="150">
                    <path
                        d="M75 150C116.421 150 150 116.421 150 75C150 33.5786 116.421 0 75 0C33.5786 0 0 33.5786 0 75C0 116.421 33.5786 150 75 150Z"
                        fill="url(#paint1_linear_1526_76627)"
                    />
                </mask>
                <g mask="url(#mask0_1526_76627)">
                    <path
                        d="M118 43H32C29.2386 43 27 45.2386 27 48V153C27 155.761 29.2386 158 32 158H118C120.761 158 123 155.761 123 153V48C123 45.2386 120.761 43 118 43Z"
                        fill="white"
                    />
                </g>
            </g>
            <path
                d="M66 53H40C38.3431 53 37 54.3431 37 56C37 57.6569 38.3431 59 40 59H66C67.6569 59 69 57.6569 69 56C69 54.3431 67.6569 53 66 53Z"
                fill="#E7E7E7"
            />
            <path
                d="M66 95H40C38.3431 95 37 96.3431 37 98C37 99.6569 38.3431 101 40 101H66C67.6569 101 69 99.6569 69 98C69 96.3431 67.6569 95 66 95Z"
                fill="#E7E7E7"
            />
            <path
                d="M108 68H42C39.7909 68 38 69.7909 38 72V82C38 84.2091 39.7909 86 42 86H108C110.209 86 112 84.2091 112 82V72C112 69.7909 110.209 68 108 68Z"
                stroke="#CCCCCC"
                strokeWidth="2"
            />
            <path
                d="M108 109H42C39.2386 109 37 111.239 37 114V122C37 124.761 39.2386 127 42 127H108C110.761 127 113 124.761 113 122V114C113 111.239 110.761 109 108 109Z"
                fill="#E7E7E7"
            />
            <path
                d="M53 32C55.2091 32 57 30.2091 57 28C57 25.7909 55.2091 24 53 24C50.7909 24 49 25.7909 49 28C49 30.2091 50.7909 32 53 32Z"
                fill="white"
            />
            <path
                d="M75 32C77.2091 32 79 30.2091 79 28C79 25.7909 77.2091 24 75 24C72.7909 24 71 25.7909 71 28C71 30.2091 72.7909 32 75 32Z"
                fill="#CCCCCC"
            />
            <path
                d="M97 32C99.2091 32 101 30.2091 101 28C101 25.7909 99.2091 24 97 24C94.7909 24 93 25.7909 93 28C93 30.2091 94.7909 32 97 32Z"
                fill="white"
            />
            <path
                d="M86 88C88.7614 88 91 85.7614 91 83C91 80.2386 88.7614 78 86 78C83.2386 78 81 80.2386 81 83C81 85.7614 83.2386 88 86 88Z"
                fill="#E7E7E7"
            />
            <path
                d="M89.907 104.37C89.107 104.37 88.36 104.37 87.68 104.327C86.8424 104.27 86.0367 103.984 85.3514 103.499C84.6662 103.014 84.128 102.349 83.796 101.578L79.577 93.24C79.2676 92.8797 79.1131 92.4117 79.1472 91.938C79.1813 91.4643 79.4012 91.0233 79.759 90.711C80.0522 90.4754 80.4179 90.3485 80.794 90.352C81.0709 90.3601 81.3428 90.4281 81.5909 90.5513C81.8389 90.6746 82.0573 90.8502 82.231 91.066L84.147 93.681L84.176 93.715V83.78C84.176 83.2871 84.3718 82.8144 84.7204 82.4659C85.0689 82.1173 85.5416 81.9215 86.0345 81.9215C86.5274 81.9215 87.0001 82.1173 87.3487 82.4659C87.6972 82.8144 87.893 83.2871 87.893 83.78V90.28C87.8714 90.0408 87.8999 89.7998 87.9766 89.5722C88.0534 89.3446 88.1767 89.1355 88.3387 88.9582C88.5007 88.781 88.6978 88.6394 88.9176 88.5425C89.1373 88.4456 89.3749 88.3956 89.615 88.3956C89.8552 88.3956 90.0927 88.4456 90.3125 88.5425C90.5322 88.6394 90.7294 88.781 90.8914 88.9582C91.0534 89.1355 91.1767 89.3446 91.2534 89.5722C91.3301 89.7998 91.3586 90.0408 91.337 90.28V91.635C91.3154 91.3958 91.3439 91.1548 91.4206 90.9272C91.4974 90.6996 91.6207 90.4905 91.7827 90.3132C91.9447 90.136 92.1418 89.9944 92.3616 89.8975C92.5813 89.8006 92.8189 89.7506 93.059 89.7506C93.2992 89.7506 93.5367 89.8006 93.7565 89.8975C93.9762 89.9944 94.1734 90.136 94.3354 90.3132C94.4974 90.4905 94.6207 90.6996 94.6974 90.9272C94.7741 91.1548 94.8026 91.3958 94.781 91.635V92.679C94.7594 92.4398 94.7879 92.1988 94.8646 91.9712C94.9414 91.7436 95.0647 91.5345 95.2267 91.3572C95.3887 91.18 95.5858 91.0384 95.8056 90.9415C96.0253 90.8446 96.2629 90.7946 96.503 90.7946C96.7432 90.7946 96.9807 90.8446 97.2005 90.9415C97.4202 91.0384 97.6174 91.18 97.7794 91.3572C97.9414 91.5345 98.0647 91.7436 98.1414 91.9712C98.2181 92.1988 98.2466 92.4398 98.225 92.679V99.016C98.191 100.965 97.31 104.251 94.211 104.251C93.986 104.261 92.08 104.371 89.911 104.371L89.907 104.37Z"
                fill="#CCCCCC"
                stroke="white"
            />
            <defs>
                <filter id="filter0_d_1526_76627" x="21" y="34" width="108" height="119" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="-3" />
                    <feGaussianBlur stdDeviation="3" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.788235 0 0 0 0 0.803922 0 0 0 0 0.85098 0 0 0 0.349 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1526_76627" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1526_76627" result="shape" />
                </filter>
                <linearGradient id="paint0_linear_1526_76627" x1="75" y1="0" x2="75" y2="150" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F9F9F9" />
                    <stop offset="0.0001" stopColor="#F9F9F9" />
                    <stop offset="1" stopColor="#F9F9F9" />
                </linearGradient>
                <linearGradient id="paint1_linear_1526_76627" x1="75" y1="0" x2="75" y2="150" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#E3ECFA" />
                    <stop offset="1" stopColor="#DAE7FF" />
                </linearGradient>
            </defs>
        </svg>

    )
}

export const AddNewItemInSheetImage = () => {
    return (
        <svg width="200" height="140" viewBox="0 0 200 140" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M162 0H42C36.4772 0 32 4.47715 32 10V130C32 135.523 36.4772 140 42 140H162C167.523 140 172 135.523 172 130V10C172 4.47715 167.523 0 162 0Z" fill="url(#paint0_linear_2685_32027)" />
            <g filter="url(#filter0_d_2685_32027)">
                <path d="M146 10H11C8.23858 10 6 12.2386 6 15V40C6 42.7614 8.23858 45 11 45H146C148.761 45 151 42.7614 151 40V15C151 12.2386 148.761 10 146 10Z" fill="white" />
            </g>
            <path d="M75 18H49C47.3431 18 46 19.3431 46 21C46 22.6569 47.3431 24 49 24H75C76.6569 24 78 22.6569 78 21C78 19.3431 76.6569 18 75 18Z" fill="#888888" fillOpacity="0.21" />
            <path d="M93 31H49C47.3431 31 46 32.3431 46 34C46 35.6569 47.3431 37 49 37H93C94.6569 37 96 35.6569 96 34C96 32.3431 94.6569 31 93 31Z" fill="#C5C5C5" fillOpacity="0.4" />
            <path d="M33 13H14C11.2386 13 9 15.2386 9 18V37C9 39.7614 11.2386 42 14 42H33C35.7614 42 38 39.7614 38 37V18C38 15.2386 35.7614 13 33 13Z" fill="#C5C5C5" fillOpacity="0.54" />
            <path d="M21.222 30.7798H23.222L23.244 30.0098C23.244 28.9978 23.882 28.7338 24.784 28.4478C25.6888 28.1904 26.4852 27.6454 27.0528 26.8952C27.6204 26.1451 27.9283 25.2305 27.93 24.2898C27.9498 23.7154 27.8502 23.1432 27.6374 22.6094C27.4246 22.0755 27.1033 21.5916 26.6939 21.1884C26.2844 20.7851 25.7957 20.4713 25.2586 20.2667C24.7216 20.0621 24.148 19.9712 23.574 19.9998C22.9674 19.9865 22.3644 20.0967 21.8017 20.3235C21.2389 20.5503 20.7281 20.889 20.3002 21.3192C19.8723 21.7493 19.5363 22.2619 19.3124 22.8258C19.0886 23.3897 18.9816 23.9933 18.998 24.5998H21.066C21.0581 24.2649 21.1172 23.9318 21.2397 23.62C21.3623 23.3083 21.5458 23.0241 21.7796 22.7842C22.0133 22.5443 22.2926 22.3535 22.6011 22.2229C22.9096 22.0923 23.241 22.0246 23.576 22.0238C23.8781 22.0063 24.1804 22.054 24.4623 22.1637C24.7443 22.2734 24.9994 22.4426 25.2102 22.6596C25.421 22.8767 25.5826 23.1366 25.684 23.4217C25.7855 23.7068 25.8243 24.0103 25.798 24.3118C25.7847 24.7997 25.615 25.2704 25.314 25.6546C25.013 26.0388 24.5965 26.3161 24.126 26.4458L23.4 26.7338C22.6892 26.905 22.0686 27.337 21.6612 27.9441C21.2538 28.5511 21.0891 29.2891 21.2 30.0118L21.222 30.7798ZM22.212 35.5338C22.4078 35.5407 22.6031 35.5083 22.7862 35.4385C22.9693 35.3687 23.1366 35.2629 23.2781 35.1274C23.4196 34.9918 23.5325 34.8293 23.6101 34.6493C23.6877 34.4694 23.7285 34.2757 23.73 34.0798C23.7282 33.884 23.6873 33.6905 23.6095 33.5108C23.5318 33.3311 23.4189 33.1688 23.2774 33.0334C23.1359 32.8981 22.9687 32.7925 22.7857 32.7228C22.6027 32.6531 22.4076 32.6208 22.212 32.6278C22.0163 32.6208 21.8212 32.6531 21.6382 32.7228C21.4552 32.7925 21.2881 32.8981 21.1466 33.0334C21.0051 33.1688 20.8921 33.3311 20.8144 33.5108C20.7367 33.6905 20.6957 33.884 20.694 34.0798C20.6955 34.2757 20.7362 34.4694 20.8138 34.6493C20.8914 34.8293 21.0044 34.9918 21.1459 35.1274C21.2874 35.2629 21.4547 35.3687 21.6378 35.4385C21.8209 35.5083 22.0161 35.5407 22.212 35.5338Z" fill="white" />
            <g filter="url(#filter1_d_2685_32027)">
                <path d="M54 53H189C190.326 53 191.598 53.5268 192.536 54.4645C193.473 55.4021 194 56.6739 194 58V83C194 84.3261 193.473 85.5979 192.536 86.5355C191.598 87.4732 190.326 88 189 88H54C52.6739 88 51.4021 87.4732 50.4645 86.5355C49.5268 85.5979 49 84.3261 49 83V58C49 56.6739 49.5268 55.4021 50.4645 54.4645C51.4021 53.5268 52.6739 53 54 53V53Z" fill="white" />
            </g>
            <path d="M118 61H92C90.3431 61 89 62.3431 89 64C89 65.6569 90.3431 67 92 67H118C119.657 67 121 65.6569 121 64C121 62.3431 119.657 61 118 61Z" fill="#888888" fillOpacity="0.21" />
            <path d="M136 74H92C90.3431 74 89 75.3431 89 77C89 78.6569 90.3431 80 92 80H136C137.657 80 139 78.6569 139 77C139 75.3431 137.657 74 136 74Z" fill="#C5C5C5" fillOpacity="0.4" />
            <path d="M76 56H57C54.2386 56 52 58.2386 52 61V80C52 82.7614 54.2386 85 57 85H76C78.7614 85 81 82.7614 81 80V61C81 58.2386 78.7614 56 76 56Z" fill="#C5C5C5" fillOpacity="0.54" />
            <path d="M64.222 73.7798H66.222L66.244 73.0098C66.244 71.9978 66.882 71.7338 67.784 71.4478C68.6888 71.1904 69.4852 70.6454 70.0528 69.8952C70.6204 69.1451 70.9283 68.2305 70.93 67.2898C70.9498 66.7154 70.8502 66.1432 70.6374 65.6094C70.4246 65.0755 70.1033 64.5916 69.6939 64.1884C69.2844 63.7851 68.7957 63.4713 68.2586 63.2667C67.7216 63.0621 67.148 62.9712 66.574 62.9998C65.9674 62.9865 65.3644 63.0967 64.8017 63.3235C64.2389 63.5503 63.7281 63.889 63.3002 64.3192C62.8723 64.7493 62.5363 65.2619 62.3124 65.8258C62.0886 66.3897 61.9816 66.9933 61.998 67.5998H64.066C64.0581 67.2649 64.1172 66.9318 64.2397 66.62C64.3623 66.3083 64.5458 66.0241 64.7796 65.7842C65.0133 65.5443 65.2926 65.3535 65.6011 65.2229C65.9096 65.0923 66.241 65.0246 66.576 65.0238C66.8781 65.0063 67.1804 65.054 67.4623 65.1637C67.7443 65.2734 67.9994 65.4426 68.2102 65.6596C68.421 65.8767 68.5826 66.1366 68.684 66.4217C68.7855 66.7068 68.8243 67.0103 68.798 67.3118C68.7847 67.7997 68.615 68.2704 68.314 68.6546C68.013 69.0388 67.5965 69.3161 67.126 69.4458L66.4 69.7338C65.6892 69.905 65.0686 70.337 64.6612 70.9441C64.2538 71.5511 64.0891 72.2891 64.2 73.0118L64.222 73.7798ZM65.212 78.5338C65.4078 78.5407 65.6031 78.5083 65.7862 78.4385C65.9693 78.3687 66.1366 78.2629 66.2781 78.1274C66.4196 77.9918 66.5325 77.8293 66.6101 77.6493C66.6877 77.4694 66.7285 77.2757 66.73 77.0798C66.7282 76.884 66.6873 76.6905 66.6095 76.5108C66.5318 76.3311 66.4189 76.1688 66.2774 76.0334C66.1359 75.8981 65.9687 75.7925 65.7857 75.7228C65.6027 75.6531 65.4076 75.6208 65.212 75.6278C65.0163 75.6208 64.8212 75.6531 64.6382 75.7228C64.4552 75.7925 64.2881 75.8981 64.1466 76.0334C64.0051 76.1688 63.8921 76.3311 63.8144 76.5108C63.7367 76.6905 63.6957 76.884 63.694 77.0798C63.6955 77.2757 63.7362 77.4694 63.8138 77.6493C63.8914 77.8293 64.0044 77.9918 64.1459 78.1274C64.2874 78.2629 64.4547 78.3687 64.6378 78.4385C64.8209 78.5083 65.0161 78.5407 65.212 78.5338Z" fill="white" />
            <g filter="url(#filter2_d_2685_32027)">
                <path d="M11 96H146C147.326 96 148.598 96.5268 149.536 97.4645C150.473 98.4021 151 99.6739 151 101V126C151 127.326 150.473 128.598 149.536 129.536C148.598 130.473 147.326 131 146 131H11C9.67392 131 8.40215 130.473 7.46447 129.536C6.52678 128.598 6 127.326 6 126V101C6 99.6739 6.52678 98.4021 7.46447 97.4645C8.40215 96.5268 9.67392 96 11 96V96Z" fill="white" />
            </g>
            <path d="M75 104H49C47.3431 104 46 105.343 46 107C46 108.657 47.3431 110 49 110H75C76.6569 110 78 108.657 78 107C78 105.343 76.6569 104 75 104Z" fill="#888888" fillOpacity="0.21" />
            <path d="M93 117H49C47.3431 117 46 118.343 46 120C46 121.657 47.3431 123 49 123H93C94.6569 123 96 121.657 96 120C96 118.343 94.6569 117 93 117Z" fill="#C5C5C5" fillOpacity="0.4" />
            <path d="M33 99H14C11.2386 99 9 101.239 9 104V123C9 125.761 11.2386 128 14 128H33C35.7614 128 38 125.761 38 123V104C38 101.239 35.7614 99 33 99Z" fill="#C5C5C5" fillOpacity="0.54" />
            <path d="M21.222 116.78H23.222L23.244 116.01C23.244 114.998 23.882 114.734 24.784 114.448C25.6888 114.19 26.4852 113.645 27.0528 112.895C27.6204 112.145 27.9283 111.23 27.93 110.29C27.9498 109.715 27.8502 109.143 27.6374 108.609C27.4246 108.076 27.1033 107.592 26.6939 107.188C26.2844 106.785 25.7957 106.471 25.2586 106.267C24.7216 106.062 24.148 105.971 23.574 106C22.9674 105.987 22.3644 106.097 21.8017 106.323C21.2389 106.55 20.7281 106.889 20.3002 107.319C19.8723 107.749 19.5363 108.262 19.3124 108.826C19.0886 109.39 18.9816 109.993 18.998 110.6H21.066C21.0581 110.265 21.1172 109.932 21.2397 109.62C21.3623 109.308 21.5458 109.024 21.7796 108.784C22.0133 108.544 22.2926 108.353 22.6011 108.223C22.9096 108.092 23.241 108.025 23.576 108.024C23.8781 108.006 24.1804 108.054 24.4623 108.164C24.7443 108.273 24.9994 108.443 25.2102 108.66C25.421 108.877 25.5826 109.137 25.684 109.422C25.7855 109.707 25.8243 110.01 25.798 110.312C25.7847 110.8 25.615 111.27 25.314 111.655C25.013 112.039 24.5965 112.316 24.126 112.446L23.4 112.734C22.6892 112.905 22.0686 113.337 21.6612 113.944C21.2538 114.551 21.0891 115.289 21.2 116.012L21.222 116.78ZM22.212 121.534C22.4078 121.541 22.6031 121.508 22.7862 121.439C22.9693 121.369 23.1366 121.263 23.2781 121.127C23.4196 120.992 23.5325 120.829 23.6101 120.649C23.6877 120.469 23.7285 120.276 23.73 120.08C23.7282 119.884 23.6873 119.691 23.6095 119.511C23.5318 119.331 23.4189 119.169 23.2774 119.033C23.1359 118.898 22.9687 118.792 22.7857 118.723C22.6027 118.653 22.4076 118.621 22.212 118.628C22.0163 118.621 21.8212 118.653 21.6382 118.723C21.4552 118.792 21.2881 118.898 21.1466 119.033C21.0051 119.169 20.8921 119.331 20.8144 119.511C20.7367 119.691 20.6957 119.884 20.694 120.08C20.6955 120.276 20.7362 120.469 20.8138 120.649C20.8914 120.829 21.0044 120.992 21.1459 121.127C21.2874 121.263 21.4547 121.369 21.6378 121.439C21.8209 121.508 22.0161 121.541 22.212 121.534Z" fill="white" />
            <defs>
                <filter id="filter0_d_2685_32027" x="0" y="7" width="157" height="47" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="3" />
                    <feGaussianBlur stdDeviation="3" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2685_32027" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2685_32027" result="shape" />
                </filter>
                <filter id="filter1_d_2685_32027" x="43" y="50" width="157" height="47" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="3" />
                    <feGaussianBlur stdDeviation="3" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2685_32027" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2685_32027" result="shape" />
                </filter>
                <filter id="filter2_d_2685_32027" x="0" y="93" width="157" height="47" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="3" />
                    <feGaussianBlur stdDeviation="3" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2685_32027" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2685_32027" result="shape" />
                </filter>
                <linearGradient id="paint0_linear_2685_32027" x1="102" y1="0" x2="102" y2="140" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#C5C5C5" stopOpacity="0.4" />
                    <stop offset="1" stopColor="#C5C5C5" stopOpacity="0.4" />
                </linearGradient>
            </defs>
        </svg>
    );
};

