export const transactionRelatedParty = [
    {
        value: '1',
        label: 'and Subsidiaries',
    },
    {
        value: '2',
        label: 'and Group',
    },
    {
        value: '3',
        label: 'and Heirs',
    },
    {
        value: '4',
        label: 'and Subsidiaries, Associates And Related Parties',
    },
    {
        value: '5',
        label: 'and Others',
    },
    {
        value: '6',
        label: 'Treasury Shares',
    },
];
