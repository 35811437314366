import { API_ROUTES } from 'src/constants/api-routes';
import { AxiosInstance } from '../index';
import { IAxiosResponse } from '../types/axios-response';
import { IDropDownPayload, ISelectBox } from '../types/base-types';

export const entityService = {
    getAllTypes(section?: string) {
        return AxiosInstance.get<IAxiosResponse<ISelectBox[]>>(API_ROUTES.ENTITY.TYPES, { params: { section } });
    },
    getShareHolderTypes(params: Omit<IDropDownPayload, 'filter'> & { type: string }) {
        return AxiosInstance.get<IAxiosResponse<ISelectBox[]>>(API_ROUTES.ENTITY.SEARCH, { params });
    },
};
