import React, { AnchorHTMLAttributes, DetailedHTMLProps } from 'react';
import styles from './Link.module.scss';
import { classNames } from 'primereact/utils';


interface ILinkProps extends DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> {
    icon ?: React.ReactNode
    children : React.ReactNode
    iconClassNames ?: string
}


const Link:React.FC<ILinkProps> = ({ children , icon , iconClassNames,...props})=> {
    return (
        <a
            {...props}
            className={classNames(styles.link,props.className)}
        >
            {children}
            {icon && <div className={classNames(styles.icon , iconClassNames)}>{icon}</div>}
        </a>
    );
}

export default Link;