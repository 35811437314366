import { Calendar, CalendarProps } from 'primereact/calendar';
import { classNames } from 'primereact/utils';
import { ControllerRenderProps, FieldError, FieldValues } from 'react-hook-form';
import { RedError } from 'src/assets/Icons/RedError';
import { DATE_FORMAT } from 'src/pages/Company/enums/date-format';

interface IProps {
    field: ControllerRenderProps<FieldValues, string>;
    label: string;
    placeholder: string;
    className: string;
    errorMessage: FieldError;
    props: CalendarProps;
}
const FormDatePicker = ({ field, label, placeholder, className, errorMessage, props = {} }: IProps) => (
    <div className={classNames('col-12', className ? className : '')}>
        <div className="form-field">
            <label className={field.name}>
                {label || placeholder} {props.required ? <span className="required">*</span> : ''}
            </label>
            <Calendar placeholder={placeholder} dateFormat={DATE_FORMAT.YEAR_FOUR_DIGITS_MONTH_DAY} {...props} {...field} />
            {errorMessage ? (
                <div className={'form-input-error'}>
                    <RedError />
                    <span>{errorMessage.message}</span>
                </div>
            ) : null}
        </div>
    </div>
);

export default FormDatePicker;
