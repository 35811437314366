import { classNames } from 'primereact/utils';
import { useFinancialDataContext } from '../../hooks/useFinancialDataContext';
import { modalActions } from 'src/context/modals';
import { MODAL_TYPES } from 'src/types/modals';
import { getAxiosError } from 'src/utils/get-axios-error';
import { toast } from 'src/utils/toast';
import { TOAST_STATUS } from 'src/constants/toast-status';
import styles from './Tabs.module.scss';
import Button from 'src/components/Kit/Button';
import { financialActions, useFinancial, useFinancialSelectedType } from '../../../../../../../../../context/companies';

interface IFinancialDataTab {
    id: number;
    name: string;
}
interface IProps {
    tab: IFinancialDataTab;
}

const Tabs: React.FC<IProps> = ({ tab }) => {
    const { activeDate } = useFinancial((global) => ({
        activeDate: global.financialActiveDate,
    }));
    const selectedFinancialDataTypeId = useFinancialSelectedType();

    const { setLoadings, handleCheckSomeDataNotSaved, handleGetRowsFromApi, handleApplyChanges, handleGetAllDates } = useFinancialDataContext();

    const handleGetRowsBasedOnTypeId = async (typeId: number) => {
        if (!selectedFinancialDataTypeId) return;
        if (!activeDate) return;

        try {
            await handleGetRowsFromApi(typeId, {
                year: activeDate?.year,
                quarter: activeDate?.quarter || null,
                status: activeDate.status,
            });
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: message,
            });
        }
    };

    const handleUpdateTab = async (e: any) => {
        e.stopPropagation();
        if (selectedFinancialDataTypeId === tab.id) return;

        if (handleCheckSomeDataNotSaved()) {
            modalActions.addModal(MODAL_TYPES.CONFIRMATION_MODAL, {
                header: 'Change Confirm',
                children: (
                    <div className={styles.nullItemWarningWrapper}>
                        <span className={styles.bodyText}>Current page has unsaved data, do you want to save?</span>
                        <div className={styles.actionWrapper}>
                            <Button color="danger" customStyle={{ width: '200px' }} onClick={() => handleConfirm(true)}>
                                <span>Discard And Go</span>
                            </Button>
                            <Button color="primary" customStyle={{ width: '200px' }} onClick={() => handleConfirm(false)}>
                                <span>Save And Go</span>
                            </Button>
                        </div>
                    </div>
                ),
                text: '',
            });

            return;
        }

        try {
            financialActions.setLoadings({ financialRowsByType: true });
            financialActions.setSelectedFinancialType(tab.id);
            await handleGetAllDates(tab.id);
            await handleGetRowsBasedOnTypeId(tab.id);
            financialActions.setSelectedFinancialType(tab.id);
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: message,
            });
        } finally {
            financialActions.setLoadings({ financialRowsByType: false });
        }
    };

    const handleConfirm = async (discardChanges: boolean) => {
        setLoadings({ financialTypeDataLoading: true });
        modalActions.clearModals();
        try {
            if (!discardChanges) await handleApplyChanges();
            financialActions.setSelectedFinancialType(tab.id);
            await handleGetAllDates(tab.id);
            await handleGetRowsBasedOnTypeId(tab.id);
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: message,
            });
        } finally {
            setLoadings({ financialTypeDataLoading: false });
        }
    };

    return (
        <div className={classNames(styles.tab, tab.id === selectedFinancialDataTypeId && styles.activeTab)} onClick={(e: any) => handleUpdateTab(e)}>
            <span>{tab.name}</span>
        </div>
    );
};

export default Tabs;
