import { useParams } from 'react-router-dom';
import { useAsync } from '../../../../../hooks/useAsync';
import { IParams } from 'src/types/params';
import { newsService } from 'src/api/services/news';
import { INewsResponse } from 'src/api/types/news';

export const useEditingMode = () => {
    const { id, companyId, companyType } = useParams<IParams>();
    const isEditingRule = Boolean(id);
    const { data, isLoading, isIdle } = useAsync<INewsResponse, undefined>(
        () => {
            return new Promise(async (resolve, reject) => {
                if (!id || !isEditingRule) return reject('');
                try {
                    const response = await newsService.getOne(Number(id));
                    return resolve(response.data.data);
                } catch (e) {}
            });
        },
        {
            immediate: true,
        }
    );

    return {
        isEditingRule,
        completedData: data,
        isLoading: isLoading,
        companyType,
        companyId: String(companyId),
        id: String(id),
    };
};
