import { IPersonType } from 'src/api/types/persons';
import { timestampToDateFormat } from 'src/utils/timestamp-to-date-format';

export const personsDataCellRender = {
    updated_at: (data: IPersonType) => <span>{timestampToDateFormat(data.updated_at)}</span>,
    created_at: (data: IPersonType) => <span>{timestampToDateFormat(data.created_at)}</span>,
    avatar: (data: IPersonType) => (
        <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {data.avatar?.url ? (
                    <img width={32} height={32} style={{ borderRadius: '8px' }} src={data.avatar.url} alt={data.name} />
                ) : (
                    <span
                        style={{
                            fontSize: '9px',
                            color: '#666',
                            width: '32px',
                            height: '32px',
                            display: 'inline-block',
                            textAlign: 'center',
                            lineHeight: '16px',
                            background: '#f5f5f5',
                            borderRadius: '8px',
                        }}
                    >
                        No Avatar
                    </span>
                )}
            </div>
        </>
    ),
};
