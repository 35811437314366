import { IFinancialInformationFormulaUsageResponse } from '../api/types/company';

export function sortFormulaArray(arr: IFinancialInformationFormulaUsageResponse[]): IFinancialInformationFormulaUsageResponse[] {
    // Sort the array
    const sortedArray = arr.sort((a, b) => {
        // First, compare years
        if (a.year !== b.year) {
            return a.year - b.year;
        }

        // If years are the same, compare quarters
        if (a.quarter !== b.quarter) {
            if (a.quarter === null) {
                return 1; // Null quarters are considered larger
            } else if (b.quarter === null) {
                return -1; // Null quarters are considered larger
            } else {
                return a.quarter - b.quarter;
            }
        }

        // If years and quarters are the same, compare items
        const itemsA = a.items
            .map((item) => JSON.stringify(item))
            .sort()
            .join('');
        const itemsB = b.items
            .map((item) => JSON.stringify(item))
            .sort()
            .join('');

        return itemsA.localeCompare(itemsB);
    });

    return removeDuplicateYearQuarter(sortedArray);
}

export function removeDuplicateYearQuarter(arr: IFinancialInformationFormulaUsageResponse[]): IFinancialInformationFormulaUsageResponse[] {
    const uniqueMap = new Map<string, IFinancialInformationFormulaUsageResponse>();

    // Iterate through the array
    arr.forEach((obj) => {
        // Convert year and quarter to a string key
        const key = `${obj.year}-${obj.quarter}`;

        // If the key doesn't exist in the map, add the object
        if (!uniqueMap.has(key)) {
            uniqueMap.set(key, obj);
        }
    });

    // Convert map values back to array
    return Array.from(uniqueMap.values());
}

export function removeDuplicateItems(items: IFinancialInformationFormulaUsageResponse['items']): IFinancialInformationFormulaUsageResponse['items'] {
    const uniqueItemIds = new Set<number>();
    const uniqueItems: IFinancialInformationFormulaUsageResponse['items'] = [];

    items.forEach((item) => {
        // Check if the item's id is not already in the set
        if (!uniqueItemIds.has(item.id)) {
            // If not, add the id to the set and add the item to the unique items array
            uniqueItemIds.add(item.id);
            uniqueItems.push(item);
        }
    });

    return uniqueItems;
}
