import { API_ROUTES } from 'src/constants/api-routes';
import { AxiosInstance } from '../index';
import { IAxiosResponse } from '../types/axios-response';
import { ICheckPositionUsageResponse, ICreatePositionPayload, IPosition, IPositionSelectBoxRequest } from '../types/position';
import { IDataTableResponse } from '../types/table';
import { IDataTablePayload } from 'src/api/types/table';
import { ISelectBox } from '../types/base-types';

export const positionService = {
    getAll(params?: IDataTablePayload) {
        return AxiosInstance.get<IDataTableResponse<IPosition>>(API_ROUTES.POSITION.GET_ALL, { params });
    },
    createNew(data: ICreatePositionPayload) {
        return AxiosInstance.post<IAxiosResponse<{}>>(API_ROUTES.POSITION.ADD_NEW, data);
    },
    delete(id: number) {
        return AxiosInstance.delete<IAxiosResponse<{}>>(API_ROUTES.POSITION.DELETE(id));
    },
    checkPositionUsed(id: number) {
        return AxiosInstance.get<IAxiosResponse<ICheckPositionUsageResponse>>(API_ROUTES.POSITION.CHECK_POSITION_IS_USED(id));
    },
    selectBox(params?: Partial<IPositionSelectBoxRequest>) {
        return AxiosInstance.get<IAxiosResponse<ISelectBox[]>>(API_ROUTES.POSITION.SELECT_BOX, { params });
    },
};
