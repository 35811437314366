import ConfirmDialog from 'src/components/Kit/ConfirmDialog';
import { MODAL_TYPES } from 'src/types/modals';
import FileDialog from '../../Kit/FileDialog';
import FinancialInformationCurrencyYearEnd from 'src/components/Kit/FinancialInformationCurrencyYearEndModal';

export const ModalsList = {
    [MODAL_TYPES.CONFIRMATION_MODAL]: ConfirmDialog,
    [MODAL_TYPES.FILE_MODAL]: FileDialog,
    [MODAL_TYPES.FINANCIAL_INFORMATION_CURRENCY_YEAR_END]: FinancialInformationCurrencyYearEnd,
};
