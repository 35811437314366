import React from 'react';
import Routes from './Routes';
import { HelmetProvider } from 'react-helmet-async';
import 'primeflex/primeflex.css';
import "primereact/resources/themes/lara-light-indigo/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";  
import './assets/styles/app.scss';

const App: React.FC = () => <HelmetProvider><Routes /></HelmetProvider>;

export default App;
