export default class FormulaComponent {
    constructor() {
        this.name = null;
        this.context = {};
    }

    setName(name) {
        this.name = name;
        return this;
    }

    toArray() {

        this.validate();

        return {
            type: this.getComponentType(),
            context: this.getContext()
        };
    }


    getContext() {
        throw new Error("This method must be overridden by subclasses");
    }

    getComponentType() {
        throw new Error("This method must be overridden by subclasses");
    }

    getAllValues() {
        throw new Error("This method must be overridden by subclasses");
    }
}

export const FormulaComponentEnum = {
    ITEM: 'item',
    STATEMENT: 'statement'
};
