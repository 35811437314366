import FormulaComponent from '../Abstract/FormulaComponent.js';
import { FormulaComponentEnum } from '../Abstract/FormulaComponent.js';


export default class Item extends FormulaComponent {
    constructor(name = null, value = null, meta = []) {
        super();
        this.name = name;
        this.value = value;
        this.meta = meta;
    }

    static init(name, value, meta) {
        return new this(name, value, meta);
    }

    getComponentType() {
        return FormulaComponentEnum.ITEM;
    }

    getAllValues() {
        return [this.getContext()];
    }

    getContext() {
        return {
            name: this.name || null,
            value: this.value,
            has_abs: this.has_abs || false,
            meta : this.meta,
        };
    }

    validate() {
        // will be added validation if need
        // todo : can not name, value, and meta all empty
    }
}