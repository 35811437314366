import { FORM_INPUTS } from 'src/types/form';
import { IFormGeneratorSchemaType } from 'src/types/form-generator-schema-type';
import { DATE_FORMAT } from '../../../enums/date-format';
import { DATE_PICKER_VIEW } from '../../../enums/date-picker-view';

export type CreateFinancialReportSchemaFields = 'year_end' | 'language' | 'first_quarter' | 'half_quarter' | 'third_quarter' | 'full_year' | 'annual';
export type CreateFinancialReportSchemaType = Record<CreateFinancialReportSchemaFields, IFormGeneratorSchemaType>;

export const createFinancialReportSchema: CreateFinancialReportSchemaType = {
    year_end: {
        placeholder: 'Year End',
        type: FORM_INPUTS.DATE,
        props: {
            required: true,
            view: DATE_PICKER_VIEW.MONTH,
            dateFormat: DATE_FORMAT.YEAR_FOUR_DIGITS_MONTH,
        },
    },
    language: {
        placeholder: 'Language',
        type: FORM_INPUTS.SELECT,
        props: {
            required: true,
        },
    },
    first_quarter: {
        placeholder: 'First Quarter',
        type: FORM_INPUTS.FILE,
        props: {
            accept: 'application/pdf',
            auto: true,
            chooseLabel: 'Upload First Quarter PDF file ...',
        },
    },
    half_quarter: {
        placeholder: 'Half Quarter',
        type: FORM_INPUTS.FILE,
        props: {
            accept: 'application/pdf',
            auto: true,
            chooseLabel: 'Upload Half Quarter PDF file ...',
        },
    },
    third_quarter: {
        placeholder: 'Third Quarter',
        type: FORM_INPUTS.FILE,
        props: {
            accept: 'application/pdf',
            auto: true,
            chooseLabel: 'Upload Third Quarter PDF file ...',
        },
    },
    full_year: {
        placeholder: 'Full Year',
        type: FORM_INPUTS.FILE,
        props: {
            accept: 'application/pdf',
            auto: true,
            chooseLabel: 'Upload Full Year PDF file ...',
        },
    },
    annual: {
        placeholder: 'Annual',
        type: FORM_INPUTS.FILE,
        props: {
            accept: 'application/pdf',
            auto: true,
            chooseLabel: 'Upload Annual PDF file ...',
        },
    },
};
