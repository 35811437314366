import React, { Dispatch, SetStateAction } from 'react';
import DashboardTitle from 'src/components/App/DashboardTitle';
import Table from 'src/components/App/Table/index';
import { Helmet } from 'react-helmet-async';
import DashboardContentWrapper from 'src/layouts/DashboardContentWrapper';
import { financialInformationService } from 'src/api/services/financial-information-service';
import { itemsCellRender } from './cellRender';
import { Column } from 'primereact/column';
import Button from 'src/components/Kit/Button';
import DeleteIcon from 'src/assets/Icons/DeleteIcon';
import { modalActions } from 'src/context/modals';
import { MODAL_TYPES } from 'src/types/modals';
import DeleteItemModalBody from './components/DeleteItemModalBody.tsx';
import { IFinancialInformationItemsResponse } from 'src/api/types/financial-information';
import ROUTE_CONSTANTS from 'src/Routes/constants/route-constants';
import { useCheckPermissionAccess } from 'src/hooks/useCheckPermissionAccess';
import { PERMISSION_TYPES } from 'src/enums/permissions';
import { IDataTablePayload } from 'src/api/types/table';

const service = async (queryParams?: IDataTablePayload) => financialInformationService.getAllItems(queryParams);
const List: React.FC = () => {
    const { checkPermissionAccess } = useCheckPermissionAccess();
    const handleRemove = (id: number, name: string, itemTypeID: number, setTableData: Dispatch<SetStateAction<any>>) => {
        const updateTable = (id: number) => {
            setTableData((items: any) => items.filter((item: any) => item.id !== id));
        };

        modalActions.addModal(MODAL_TYPES.CONFIRMATION_MODAL, {
            header: 'Delete Item',
            children: <DeleteItemModalBody item={{ id, name, itemTypeID }} updateTable={updateTable} />,
            text: '',
        });
    };

    const suffixCols = (data: IFinancialInformationItemsResponse[], setTableData: Dispatch<SetStateAction<any>>) => {
        return (
            <Column
                key="Action"
                body={(rowData: IFinancialInformationItemsResponse) => (
                    <>
                        {checkPermissionAccess(PERMISSION_TYPES.SETTING_FI_ITEM_DELETE) ? (
                            <Button
                                disabled={rowData.number_of_used > 0}
                                color="secondary"
                                iconOnly
                                size="m"
                                onClick={() => handleRemove(rowData.id, rowData.name, rowData.item_type_id, setTableData)}
                            >
                                <DeleteIcon />
                            </Button>
                        ) : (
                            <></>
                        )}
                    </>
                )}
            />
        );
    };

    return (
        <>
            <Helmet>
                <title>Teaser | Financial Information Items List</title>
            </Helmet>
            <DashboardTitle
                title={'Financial Information Items List'}
                titleSuffix={
                    checkPermissionAccess(PERMISSION_TYPES.SETTING_FI_ITEM_CREATE) ? (
                        <Button size="s" link color="primary" to={ROUTE_CONSTANTS.SETTINGS.FINANCIAL_INFORMATION.ITEMS.ADD_NEW.ROOT.ABSOLUTE}>
                            Add New Item
                        </Button>
                    ) : (
                        <></>
                    )
                }
            />
            <DashboardContentWrapper>
                {checkPermissionAccess(PERMISSION_TYPES.SETTING_FI_ITEM_LIST_VIEW) && (
                    <Table getTableData={service} cellRender={itemsCellRender} suffixCols={suffixCols} />
                )}
            </DashboardContentWrapper>
        </>
    );
};

export default List;
