import React, { useEffect, useState } from 'react';
import style from './AddUpdate.module.scss';
import DashboardContentWrapper from 'src/layouts/DashboardContentWrapper';
import FormGenerator from 'src/components/Kit/FormGenerator/index';
import { useForm } from 'react-hook-form';
import { generateDefaultValueFromFormSchema } from 'src/utils/generate-default-value-from-form-schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { validationSchema } from './validation-schema';
import { createPositionSchemaType, createPositionSchema } from './schema';
import Button from 'src/components/Kit/Button';
import { useNavigate } from 'react-router-dom';
import { toast } from 'src/utils/toast';
import { TOAST_STATUS } from 'src/constants/toast-status';
import { positionService } from 'src/api/services/position-service';
import { positionType } from './constants';
import { ICreatePositionPayload } from 'src/api/types/position';
import { getAxiosError } from 'src/utils/get-axios-error';
import { useCheckPermissionAccess } from 'src/hooks/useCheckPermissionAccess';
import { PERMISSION_TYPES } from 'src/enums/permissions';

interface IFormData {
    name: string;
    type: string;
}

const AddUpdate: React.FC = () => {
    const { checkPermissionAccess } = useCheckPermissionAccess();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [formSchema, setFormSchema] = useState<createPositionSchemaType>(createPositionSchema);

    const {
        control,
        formState: { errors },
        handleSubmit,
    } = useForm<IFormData>({
        defaultValues: generateDefaultValueFromFormSchema(formSchema),
        mode: 'all',
        resolver: yupResolver(validationSchema()),
    });

    const setFieldOption = async () => {
        let tempFormSchema: createPositionSchemaType = structuredClone(formSchema);
        try {
            tempFormSchema = {
                ...tempFormSchema,
                type: { ...tempFormSchema.type, data: positionType },
            };
            setFormSchema(tempFormSchema);
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: message,
            });
        }
    };

    const onSubmit = async (_val: ICreatePositionPayload) => {
        try {
            setLoading(true);
            await positionService.createNew(_val);
            toast.fire({
                icon: TOAST_STATUS.SUCCESS,
                title: 'Position has been created successfully',
            });
            navigate(-1);
            setLoading(false);
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: message,
            });
            setLoading(false);
        }
    };

    useEffect(() => {
        setFieldOption();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <DashboardContentWrapper>
            {
                checkPermissionAccess(PERMISSION_TYPES.POSITION_CREATE) &&
                <div className={`${style.container} grid`}>
                    <div className={`${style.formWrapper} xl:col-5 md:col-8 xs:col:12`}>
                        <span className={style.title}>Add New Position</span>
                        <div className="mt-5">
                            <FormGenerator
                                onSubmit={handleSubmit(onSubmit)}
                                control={control}
                                errors={errors}
                                schema={formSchema}
                                noBackgroundLayout
                                buttons={
                                    <>
                                        <Button disabled={loading} loading={loading} color="primary">
                                            Create Position
                                        </Button>
                                        <Button type="button" onClick={() => navigate(-1)}>
                                            Cancel
                                        </Button>
                                    </>
                                }
                            />
                        </div>
                    </div>
                </div>
            }
        </DashboardContentWrapper>
    );
};

export default AddUpdate;
