import { COMPANY_TYPE_ROUTE } from 'src/pages/Company/Add/enums/company-type-route';

export const companyTitleByType = {
    [COMPANY_TYPE_ROUTE.PUBLIC]: {
        plural: 'Public Companies',
        singular: 'Public Company',
    },
    [COMPANY_TYPE_ROUTE.PRIVATE]: {
        plural: 'Private Companies',
        singular: 'Private Company',
    },
    [COMPANY_TYPE_ROUTE.NON_PROFILE]: {
        plural: 'Non Profile Companies',
        singular: 'Non Profile Company',
    },
};
