import { SVGProps } from 'react';

const YellowWarning: React.FC<SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg width="30" height="34" viewBox="0 0 30 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M11.5359 1.28509C13.6795 0.0156482 16.3205 0.0156484 18.4641 1.28509L26.5359 6.06521C28.6795 7.33465 30 9.68067 30 12.2195V21.7798C30 24.3187 28.6795 26.6647 26.5359 27.9341L18.4641 32.7143C16.3205 33.9837 13.6795 33.9837 11.5359 32.7143L3.4641 27.9341C1.32051 26.6647 0 24.3187 0 21.7798V12.2195C0 9.68067 1.32051 7.33465 3.4641 6.06521L11.5359 1.28509ZM16.6667 8.66634C16.6667 9.58682 15.9205 10.333 15 10.333C14.0795 10.333 13.3333 9.58682 13.3333 8.66634C13.3333 7.74587 14.0795 6.99967 15 6.99967C15.9205 6.99967 16.6667 7.74587 16.6667 8.66634ZM13.3333 12.4163C12.643 12.4163 12.0833 12.976 12.0833 13.6663C12.0833 14.3567 12.643 14.9163 13.3333 14.9163H13.75V25.333C13.75 26.0234 14.3096 26.583 15 26.583C15.6904 26.583 16.25 26.0234 16.25 25.333V13.6663C16.25 12.976 15.6904 12.4163 15 12.4163H13.3333Z" fill="#FDA92C" />
        </svg>
    );
};

export default YellowWarning;
