import { ISelectBox } from 'src/api/types/base-types';
import { PARENT_COMPANY_FINANCIAL_LANGUAGES } from '../enums/parent-company-financial-languages';

export const parentCompanyFinancialLanguages: ISelectBox[] = [
    { label: PARENT_COMPANY_FINANCIAL_LANGUAGES.ENGLISH, value: '1' },
    { label: PARENT_COMPANY_FINANCIAL_LANGUAGES.ARABIC, value: '2'},
    { label: PARENT_COMPANY_FINANCIAL_LANGUAGES.GERMAN, value: '3' },
    { label: PARENT_COMPANY_FINANCIAL_LANGUAGES.SPANISH, value: '4' },
    { label: PARENT_COMPANY_FINANCIAL_LANGUAGES.CHINESE, value: '5' },
    { label: PARENT_COMPANY_FINANCIAL_LANGUAGES.JAPANESE, value: '6' },
    { label: PARENT_COMPANY_FINANCIAL_LANGUAGES.INDIAN, value: '7' },
    { label: PARENT_COMPANY_FINANCIAL_LANGUAGES.RUSSIAN, value: '8' },
    { label: PARENT_COMPANY_FINANCIAL_LANGUAGES.PORTUGUESE, value: '9' },
    { label: PARENT_COMPANY_FINANCIAL_LANGUAGES.TURKISH, value: '10' },
    { label: PARENT_COMPANY_FINANCIAL_LANGUAGES.KOREAN, value: '11' },
    { label: PARENT_COMPANY_FINANCIAL_LANGUAGES.ITALIAN, value: '12' },
    { label: PARENT_COMPANY_FINANCIAL_LANGUAGES.PERSIAN, value: '13' },
    { label: PARENT_COMPANY_FINANCIAL_LANGUAGES.THAI, value: '14' },
    { label: PARENT_COMPANY_FINANCIAL_LANGUAGES.OTHER, value: '15' },
];
