import { currencyService } from 'src/api/services/currency';
import { legalStructuresService } from 'src/api/services/legal-structures';
import { personService } from 'src/api/services/person-service';
import { positionService } from 'src/api/services/position-service';
import { sectorService } from 'src/api/services/sector-service';
import { stockExchangeService } from 'src/api/services/stock-exchanges-service';
import { regionService } from 'src/api/services/region-service';
import { DROPDOWNS_FILTER } from 'src/components/App/Table/enums';
import { FILTER_TYPES, SORT_ORDER_NAME } from 'src/enums/table';
import { roleService } from 'src/api/services/roles';
import { financialInformationService } from 'src/api/services/financial-information-service';
import TextFilter from 'src/components/App/Table/Filter/TextFilter';
import CheckboxFilter from 'src/components/App/Table/Filter/CheckboxFilter';
import DateFilter from 'src/components/App/Table/Filter/DateFilter';
import SearchableDropdown from 'src/components/Kit/SearchableDropdown';
import { newsSourceService } from 'src/api/services/news-source';

export const tableOrderSymbol = {
    [SORT_ORDER_NAME.DESC]: -1,
    [SORT_ORDER_NAME.ASC]: 1,
};

export const tableOrderName = {
    '-1': SORT_ORDER_NAME.DESC,
    '1': SORT_ORDER_NAME.ASC,
};

export const filterItemComponent = {
    [FILTER_TYPES.TEXT]: TextFilter,
    [FILTER_TYPES.SELECT]: SearchableDropdown,
    [FILTER_TYPES.CHECKBOX]: CheckboxFilter,
    [FILTER_TYPES.DATE]: DateFilter,
};

export const filterService = {
    [DROPDOWNS_FILTER.COUNTRY]: regionService.selectBox,
    [DROPDOWNS_FILTER.CURRENCY]: currencyService.selectBox,
    [DROPDOWNS_FILTER.LEGAL_STRUCTURE]: legalStructuresService.selectBox,
    [DROPDOWNS_FILTER.PERSON]: personService.selectBox,
    [DROPDOWNS_FILTER.POSITION]: positionService.selectBox,
    [DROPDOWNS_FILTER.SECTOR]: sectorService.selectBox,
    [DROPDOWNS_FILTER.STOCK_EXCHANGE]: stockExchangeService.selectBox,
    [DROPDOWNS_FILTER.ROLE]: roleService.selectBox,
    [DROPDOWNS_FILTER.FINANCIAL_INFORMATION_CATEGORY]: financialInformationService.categoriesSelectBox,
    [DROPDOWNS_FILTER.FINANCIAL_INFORMATION_SUB_CATEGORY]: financialInformationService.subCategoriesSelectBox,
    [DROPDOWNS_FILTER.FINANCIAL_INFORMATION_TYPE]: financialInformationService.typesSelectBox,
    [DROPDOWNS_FILTER.NEWS_SOURCE]: newsSourceService.list,
};
