import React from 'react';
import { IBaseFinancialInformationValueByTypeResponse, RATIO_NUMBER_TYPE } from 'src/api/types/company';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Button from 'src/components/Kit/Button';
import { ITEM_TYPE_ENUM } from 'src/enums/company/financialInformation/financialItem';
import { financialRowColorDetector } from '../../../Details/constants/financialRowColorDetector';
import { financialItemTypeDictionary } from '../../../Details/constants/financialItemTypeDictionary';
import { useFinancialDataContext } from '../SelectFinancialData/hooks/useFinancialDataContext';
import { financialActions, useFinancial } from 'src/context/companies';
import { MUTATION_FINANCIAL_STEP, selectedFinancialDataTypeName } from '../../../enums';
import { FinancialDataProvider } from '../SelectFinancialData/context';
import { DraftDataType } from '../../../types';
import { financialValues } from 'src/utils/financial-values';
import { financialInformationLegitFractions } from '../../../constants';
import styles from '../../../Details/CompanyFinancialInformation.module.scss';
import { FINANCIAL_INFORMATION_DRAFT } from 'src/constants/financial-information-draft';

interface IValueType {
    [key: string]: any;
}

const CompanyFinancialInformationDraftViewModeWrapper: React.FC = () => {
    const { removeDraftData } = useFinancialDataContext();
    const { companyDetails, selectedFinancialDataTypeId, activeDate } = useFinancial((global) => ({
        companyDetails: global.company,
        selectedFinancialDataTypeId: global.financialTypeSelected,
        activeDate: global.financialActiveDate,
    }));
    const allDraftData = localStorage.getItem(FINANCIAL_INFORMATION_DRAFT);

    if (!allDraftData || !companyDetails) {
        return <>There is no data!</>;
    }

    const parsedDraftData = JSON.parse(allDraftData) as DraftDataType[];

    const draftData = parsedDraftData.find(
        (item: DraftDataType) =>
            item.companyId === companyDetails.id &&
            item.selectedFinancialDataTypeId === selectedFinancialDataTypeId &&
            item.date?.year === activeDate?.year &&
            item.date?.quarter === activeDate?.quarter
    );

    if (!draftData) {
        return <>There is no data!</>;
    }

    const dataTypeId = draftData.selectedFinancialDataTypeId;
    const activeTabName = dataTypeId ? selectedFinancialDataTypeName[dataTypeId] : undefined;

    const tableData = draftData?.financialDataRows.map((item) => ({
        company_financial_information_type_id: dataTypeId,
        name: item.name ? item.name[0].name : '',
        type: item.type,
        category: item.name ? item.name[0].category_name : '',
        sub_category: item.name ? item.name[0].sub_category_name : '',
        value: item.value,
        number_type: item.number_type,
    }));

    const getColoredRow = (type: ITEM_TYPE_ENUM) => ({
        backgroundColor: financialRowColorDetector[type],
        display: 'block',
        width: '100%',
        height: '100%',
        lineHeight: '33px',
        padding: '8px',
        justifyContent: 'center',
    });

    const renderTableValues = (data: IValueType) => {
        if (data.number_type === RATIO_NUMBER_TYPE.CURRENCY) {
            if (data.value) {
                return (
                    <span>
                        {companyDetails?.selectedCurrency?.short_name}
                        {financialValues(data.value, { fixed: financialInformationLegitFractions })}
                    </span>
                );
            } else {
                return '-';
            }
        } else {
            return data.value || '';
        }
    };

    const handleRemoveDraftData = () => {
        removeDraftData({
            companyId: draftData.companyId,
            selectedFinancialDataTypeId: draftData.selectedFinancialDataTypeId,
            year: draftData.date?.year,
            quarter: draftData.date?.quarter || undefined,
        });
        financialActions.setMutationStep(MUTATION_FINANCIAL_STEP.SELECT_FINANCIAL_DATA);
    };

    const handleUseDraftData = () => {
        financialActions.setMustUseDraft(true);
        financialActions.setMutationStep(MUTATION_FINANCIAL_STEP.SELECT_FINANCIAL_DATA);
    };

    return (
        <div className={styles.container}>
            {activeTabName ? (
                <div className={styles.financialDataType}>
                    <span>
                        <b>Financial Type:</b> {activeTabName}
                    </span>
                </div>
            ) : undefined}
            <div className={styles.financialDataWrapper}>
                <DataTable value={tableData} scrollDirection="both" scrollable scrollHeight="calc(100vh - 275px)" data-cy={'financial-values'}>
                    <Column
                        style={{ width: '40px', padding: 0, boxShadow: 'unset' }}
                        headerStyle={{ background: '#f0f2f4' }}
                        field="index"
                        header={<div style={{ padding: '10px' }}>#</div>}
                        body={(data: IBaseFinancialInformationValueByTypeResponse, props) => <span style={getColoredRow(data.type)}>{props.rowIndex + 1}</span>}
                    />
                    <Column
                        field="type"
                        header="Item"
                        headerStyle={{ padding: '0 8px', background: '#f0f2f4' }}
                        style={{ width: '90px', padding: 0, boxShadow: 'unset' }}
                        data-cy={'ITEM'}
                        body={(data: IBaseFinancialInformationValueByTypeResponse) => (
                            <span style={getColoredRow(data.type)}>{financialItemTypeDictionary[data.type]}</span>
                        )}
                    />
                    <Column
                        field="name"
                        header="Title"
                        style={{ width: 'calc(100% - 670px)', padding: '0', boxShadow: 'unset' }}
                        headerStyle={{ padding: '0 8px', background: '#f0f2f4' }}
                        body={(data: IBaseFinancialInformationValueByTypeResponse) => (
                            <div
                                style={{
                                    backgroundColor: financialRowColorDetector[data.type],
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    padding: '0 8px',
                                }}
                            >
                                {data.name}
                            </div>
                        )}
                    />
                    <Column
                        field="category"
                        header="Category"
                        style={{ width: '200px', padding: '0', boxShadow: 'unset' }}
                        headerStyle={{ padding: '0 8px', background: '#f0f2f4' }}
                        body={(data: IBaseFinancialInformationValueByTypeResponse) => (
                            <div
                                style={{
                                    backgroundColor: financialRowColorDetector[data.type],
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    padding: '0 8px',
                                }}
                            >
                                {data.category}
                            </div>
                        )}
                    />
                    <Column
                        field="sub_category"
                        header="Sub-Category"
                        style={{ width: '200px', padding: '0', boxShadow: 'inset -1px 0 0 #E6E6E9' }}
                        headerStyle={{ padding: '0 8px', background: '#f0f2f4' }}
                        body={(data: IBaseFinancialInformationValueByTypeResponse) => (
                            <div
                                style={{
                                    backgroundColor: financialRowColorDetector[data.type],
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    padding: '0 8px',
                                }}
                            >
                                {data.sub_category}
                            </div>
                        )}
                    />
                    <Column
                        field="value"
                        header="Value"
                        style={{ width: '140px', padding: '0', boxShadow: 'inset -1px 0 0 #E6E6E9' }}
                        headerStyle={{ padding: '0 8px', background: '#f0f2f4' }}
                        body={(data: IBaseFinancialInformationValueByTypeResponse) => <div style={getColoredRow(data.type)}>{renderTableValues(data)}</div>}
                    />
                </DataTable>
            </div>
            <div className={styles.footerDraftWrapper}>
                <span>Do you want to continue with this version of the information?</span>
                <div>
                    <Button data-cy={'btn-save'} onClick={handleUseDraftData} color="primary" size="s">
                        Yes
                    </Button>
                    <Button onClick={handleRemoveDraftData} color="secondary" size="s">
                        No
                    </Button>
                </div>
            </div>
        </div>
    );
};

const CompanyFinancialInformationDraftViewMode: React.FC = () => {
    return (
        <FinancialDataProvider>
            <CompanyFinancialInformationDraftViewModeWrapper />
        </FinancialDataProvider>
    );
};

export default CompanyFinancialInformationDraftViewMode;
