import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ROUTE_CONSTANTS from 'src/Routes/constants/route-constants';
import { companyService } from 'src/api/services/company';
import { ICommentShowResponse } from 'src/api/types/company';
import InitLoading from 'src/components/App/Loading/InitLoading';
import { PERMISSION_TYPES } from 'src/enums/permissions';
import { useAsync } from 'src/hooks/useAsync';
import { useCheckPermissionAccess } from 'src/hooks/useCheckPermissionAccess';
import { COMPANY_TYPE_ROUTE } from 'src/pages/Company/Add/enums/company-type-route';
import { IParams } from 'src/types/params';
import { checkCompanyType } from 'src/utils/check-company-type';
import style from './CommentShow.module.scss';

const CommentShow: React.FC = () => {
    const { commentId, companyId, companyType } = useParams<IParams>();
    const navigate = useNavigate();
    const { checkPermissionAccess } = useCheckPermissionAccess();

    const { data, isLoading } = useAsync<ICommentShowResponse, undefined>(
        () => {
            return new Promise(async (resolve, reject) => {
                if (!commentId) return reject('');
                try {
                    const response = await companyService(companyType as COMPANY_TYPE_ROUTE).showComment(Number(companyId), Number(commentId));
                    return resolve(response.data.data);
                } catch (e) {}
            });
        },
        {
            immediate: true,
        }
    );

    useEffect(() => {
        if (!checkPermissionAccess(checkCompanyType(PERMISSION_TYPES.COMPANY_COMMENT_SINGLE_VIEW, companyType as COMPANY_TYPE_ROUTE))) {
            navigate(ROUTE_CONSTANTS.ROOT.ABSOLUTE);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLoading) return <InitLoading />;

    if (!data) return <span>There is no data!</span>;

    return (
        <div className={style.commentShowWrapper}>
            <h3>Comment Detail</h3>
            <div className={style.commentShowItem}>
                <label htmlFor="">Topic</label>
                <span>{data.topic}</span>
            </div>
            <div className={style.commentShowItem}>
                <label htmlFor="">Comment</label>
                <p>{data.comment}</p>
            </div>
            {data.sources?.length ? (
                <div className={style.commentShowItems}>
                    <label htmlFor="">Source Section</label>
                    {data.sources.map((item, index) => (
                        <div key={index} className={style.commentShowInnerItem}>
                            <div className={style.commentShowItem}>
                                <label htmlFor="">Title</label>
                                <span>{item.title}</span>
                            </div>
                            <div className={style.commentShowItem}>
                                <label htmlFor="">Link</label>
                                <a href={item.link} target="_blank" rel="noreferrer">
                                    {item.link}
                                </a>
                            </div>
                        </div>
                    ))}
                </div>
            ) : null}
        </div>
    );
};

export default CommentShow;
