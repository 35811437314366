import { ModalProps } from 'src/types/modals';
import style from './FileDialog.module.scss';
import styles from './FileDialog.module.scss';
import React, { ReactNode, useCallback, useRef, useState } from 'react';
import { useAsync } from '../../../hooks/useAsync';
import { Serachbar } from './serach';
import { FileItems } from './fileItems';
import { Pagination } from './pagination';
import { Uploader } from './uploader';
import useUploadMedia from '../../../hooks/useUploadMedia';
import { ProgressBar } from 'primereact/progressbar';
import { fileToBase64, formatFileSize } from '../../../utils/file';
import { classNames } from 'primereact/utils';
import { RedError } from '../../../assets/Icons/RedError';
import { FileSearchPayload, IFileStoreResponse, ISearchFileResponse } from '../../../api/types/file';
import pdfIcon from '../../../assets/Images/pdf-icon.svg';
import Button from '../Button';
import { FILE_SORT, FILE_UPLOAD_TYPE } from './constants';
import { mediaService } from '../../../api/services/media';
import { IAxiosResponse } from '../../../api/types/axios-response';
import { toast } from '../../../utils/toast';
import { PaginatorPageState } from 'primereact/paginator';
import { useForm } from 'react-hook-form';
import { generateDefaultValueFromFormSchema } from '../../../utils/generate-default-value-from-form-schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFormFields } from '../../../hooks/useFormFields';
import { createFileSchema } from './schema';
import { createFileValidationSchema } from './validation-schema';
import FormGenerator from '../FormGenerator';
import { TOAST_STATUS } from '../../../constants/toast-status';
import { getAxiosError } from '../../../utils/get-axios-error';
import { truncateString } from 'src/utils/string-truncator';
import { Tooltip } from 'primereact/tooltip';

export interface FileDialogProps {
    text?: string;
    children?: ReactNode;
    header: string;
    onFileSelected: ({ id, uuid }: { id: IFileStoreResponse['id']; uuid: IFileStoreResponse['uuid'] }, file?: File, fileStorage?: IFileStoreResponse) => void;
    fileType: FILE_UPLOAD_TYPE;
}

const FileDialog: React.FC<ModalProps<FileDialogProps>> = ({ data: { onFileSelected, fileType }, closeModal }) => {
    const [selectedFileId, setSelectedFileId] = useState<IFileStoreResponse['id']>();
    const [selectedFileUUId, setSelectedFileUUId] = useState<IFileStoreResponse['uuid']>();
    const filter = useRef<FileSearchPayload>({
        media_type: fileType,
        sort: FILE_SORT.TIME_DESC,
        page: 1,
        text: '',
    });
    const [uploadedBase64, setUploadedBase64] = useState<string>();
    const { formFields } = useFormFields(createFileSchema);

    const {
        control,
        formState: { errors, isValid },
        handleSubmit,
        getValues,
    } = useForm<{ description: string }>({
        defaultValues: generateDefaultValueFromFormSchema(formFields),
        mode: 'all',
        shouldFocusError: false,
        resolver: yupResolver(createFileValidationSchema),
    });

    const {
        run,
        data: files,
        isLoading,
    } = useAsync<IAxiosResponse<ISearchFileResponse>, Partial<FileSearchPayload>>(
        (payload) => {
            payload = {
                ...filter.current,
                ...payload,
            };
            const { sort, text, page } = payload;
            return mediaService
                .search({
                    ...filter.current,
                    ...(sort ? { sort } : {}),
                    ...(text ? { text } : {}),
                    page: page || 1,
                })
                .then((res) => res.data as any)
                .catch(() => {
                    toast.fire({
                        icon: 'error',
                        title: 'Error in Getting Files',
                    });
                });
        },
        { immediate: true }
    );

    const submitAsync = useAsync<unknown, { closeAfterSubmit?: boolean; saveDescription?: boolean }>(({ closeAfterSubmit = true, saveDescription = false }) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                if (!selectedFileId || !selectedFileUUId) return reject();
                if (saveDescription && selectedFileUUId && getValues('description')) {
                    await mediaService.updateDescription(selectedFileUUId.toString(), { description: getValues('description') });
                }
                if (file) {
                    onFileSelected({ id: selectedFileId, uuid: selectedFileUUId }, file);
                } else {
                    const foundedFile = files?.data?.data.find((currentFile) => currentFile.id === selectedFileId);
                    if (foundedFile) {
                        onFileSelected({ id: selectedFileId, uuid: selectedFileUUId }, undefined, foundedFile);
                    }
                }
                if (closeAfterSubmit) closeModal();
                resolve();
            } catch (e) {
                toast.fire({
                    icon: TOAST_STATUS.ERROR,
                    title: getAxiosError(e).message,
                });
                return Promise.reject(getAxiosError(e));
            }
        });
    });

    const deleteAsync = useAsync(() => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                const signedDeleteResponse = await mediaService.getSignedDeleteUrl(selectedFileUUId as string);
                const signedDeleteUrl = signedDeleteResponse.data.data;
                await mediaService.invokeSignedDeleteUrl(signedDeleteUrl);
                run({});
                setSelectedFileId(undefined);
                reset();
                resolve();
            } catch (e) {
                toast.fire({
                    icon: 'error',
                    title: 'Error in deleting',
                });
                reject();
            }
        });
    });

    const { upload, file, uploadingError, uploadingSuccess, setUploadingError, uploading, progress, reset } = useUploadMedia({
        onComplete: (data: any, file) => {
            if (data.success) {
                onFileSelected({ id: data.data.id, uuid: data.data.uuid }, file);
                setSelectedFileId(data.data.id);
                setSelectedFileUUId(data.data.uuid);
                if (fileType === FILE_UPLOAD_TYPE.IMAGE && file) {
                    fileToBase64(file).then(setUploadedBase64);
                }
            } else {
                setUploadingError('Response is Empty!');
            }
        },
        onError: (err: any) => {
            setUploadingError(err);
        },
    });

    const onClickApply = (searchQuery: string, sortBy?: number) => {
        run({ sort: sortBy || FILE_SORT.NAME_ASC, text: searchQuery });
    };

    const onPageChanged = useCallback((e: PaginatorPageState) => {
        run({ page: e.page + 1 });
    }, []);

    const onClickInsert = async () => {
        submitAsync.run({ saveDescription: false });
    };

    const onClickSave = async () => {
        await submitAsync.run({ closeAfterSubmit: false, saveDescription: true });
        reset();
        await run({});
    };

    const onClickDelete = () => {
        deleteAsync.run(undefined);
    };

    if (uploading || uploadingError) {
        return (
            <div className={style.uploadingWrapper}>
                {uploadingError && (
                    <div className={style.uploader} style={{ marginBottom: 12 }}>
                        <Uploader fileType={fileType} isLoading={isLoading} onUpload={upload} />
                    </div>
                )}
                <div className={classNames(style.uploadingWrapperBox, uploadingError && style.uploadingWrapperItemsErrors)}>
                    <div className={classNames(style.uploadingWrapperItems)}>
                        {file ? (
                            <>
                                <span className={classNames(styles.uploadingWrapperItemsName, file.name.length > 64 && `dialog-name`)}>
                                    {truncateString(file.name, 64)}
                                </span>
                                {file?.name.length > 64 && (
                                    <Tooltip target={`.dialog-name`} hideDelay={100} content={file?.name} position="mouse" showDelay={250} />
                                )}
                            </>
                        ) : null}
                        <span className={style.uploadingWrapperItemsSize}>{formatFileSize(file?.size || 0)}</span>
                        {!uploadingError && <ProgressBar className={style.uploadingWrapperItemsProgress} value={progress} />}
                    </div>
                </div>
                {uploadingError && (
                    <div className={style.uploadingWrapperItemsErrorText}>
                        <span
                            style={{
                                width: 40,
                                height: 40,
                            }}
                        >
                            <RedError />
                        </span>
                        {uploadingError}
                    </div>
                )}
            </div>
        );
    }

    if (uploadingSuccess) {
        return (
            <div className={style.uploadingWrapper}>
                <div className={classNames(style.uploadingWrapperBox)}>
                    <div className={style.uploadingWrapperSuccess}>
                        <div className={styles.uploadingWrapperSuccessIcon}>
                            {fileType === FILE_UPLOAD_TYPE.DOCUMENT && <img src={pdfIcon} alt="pdf" width={32} height={38} />}
                            {fileType === FILE_UPLOAD_TYPE.IMAGE && file && <img src={uploadedBase64} alt="pdf" width={32} height={38} />}
                        </div>
                        <div className={classNames(style.uploadingWrapperItems)}>
                            {file ? (
                                <>
                                    <span className={classNames(styles.uploadingWrapperItemsName, file.name.length > 64 && `dialog-name`)}>
                                        {truncateString(file.name, 64)}
                                    </span>
                                    {file?.name.length > 64 && (
                                        <Tooltip target={`.dialog-name`} hideDelay={100} content={file?.name} position="mouse" showDelay={250} />
                                    )}
                                </>
                            ) : null}
                            <span className={style.uploadingWrapperItemsSize}>{formatFileSize(file?.size || 0)}</span>
                            <div className={style.uploadingWrapperItemsDesc}>
                                <FormGenerator
                                    onSubmit={handleSubmit(onClickSave)}
                                    control={control}
                                    loading={false}
                                    errors={errors}
                                    schema={formFields}
                                    noBackgroundLayout
                                />
                                <span className={style.uploadingWrapperItemsItemsHelp}>Custom Description File</span>
                            </div>
                            <div className={styles.uploadingWrapperSuccessButtonWrapper}>
                                <Button
                                    disabled={!isValid || deleteAsync.isLoading || submitAsync.isLoading}
                                    loading={submitAsync.isLoading}
                                    onClick={onClickSave}
                                    color={'primary'}
                                >
                                    Save
                                </Button>
                                <Button loading={deleteAsync.isLoading} disabled={submitAsync.isLoading} onClick={onClickDelete} color={'tertiary'}>
                                    Delete
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={style.dialogWrapper}>
            <div className={style.wrapper}>
                <div className={style.uploader}>
                    <Uploader isLoading={isLoading} onUpload={upload} fileType={fileType} />
                </div>
                <Serachbar onClickApply={onClickApply} isLoading={isLoading} />
                <FileItems
                    fileType={fileType}
                    files={files?.data?.data || []}
                    selectedFileId={selectedFileId}
                    selectedFileUUId={selectedFileUUId}
                    setSelectedFileId={setSelectedFileId}
                    setSelectedFileUUId={setSelectedFileUUId}
                    isLoading={isLoading}
                />
            </div>
            {files?.data && (
                <Pagination
                    isLoading={false}
                    onClickInsert={onClickInsert}
                    canInsert={Boolean(selectedFileId)}
                    onPageChanged={onPageChanged}
                    per_page={files.data.per_page}
                    first_row={(files?.data.current_page - 1) * files?.data?.per_page}
                    total_records={files?.data.total}
                />
            )}
        </div>
    );
};

export default FileDialog;
