export const transactionType = [
    {
        value: '1',
        label: 'Subsidiary',
    },
    {
        value: '2',
        label: 'Associate',
    },
    {
        value: '3',
        label: 'Joint-Venture',
    },
];
