import { useEffect } from 'react';
import Button from '../../../../../../components/Kit/Button';
import DeleteIcon from '../../../../../../assets/Icons/DeleteIcon';
import Accordion from '../../../../../../components/Kit/Accordion';
import { useForm } from 'react-hook-form';
import { generateDefaultValueFromFormSchema } from '../../../../../../utils/generate-default-value-from-form-schema';
import { yupResolver } from '@hookform/resolvers/yup';
import FormGenerator from '../../../../../../components/Kit/FormGenerator';
import { createTransactionSchema, CreateTransactionSchemaFields } from './schema';
import { transactionInformationValidationSchema } from './validation-schema';
import { ITransactionInformation } from '../../../../../../api/types/share-holders';
import { useFormFields } from '../../../../../../hooks/useFormFields';
import styles from '../ShareHolderAddUpdate.module.scss';

type Props = {
    id?: number; // id only when we are in editing mode
    uuid : string
    index: number;
    formData?: ITransactionInformation;
    updateChildValues: (uuid: string, formData: ITransactionInformation) => void;
    removeChild: (uuid: string) => void;
    isEditing: boolean;
    errors?: { message: string };
};
const TransactionRow = (props: Props) => {
    const { formFields, updateFormData } = useFormFields<CreateTransactionSchemaFields>(createTransactionSchema);
    const {
        control,
        formState: { errors },
        handleSubmit,
        watch,
        reset,
    } = useForm<ITransactionInformation>({
        defaultValues: {
            ...generateDefaultValueFromFormSchema(formFields),
            ownership_stake: null as any,
        },
        mode: 'onChange',
        shouldFocusError: false,
        resolver: yupResolver(transactionInformationValidationSchema),
    });

    const hasError = Boolean(Object.keys(errors).length) || props.errors?.message;
    const onSubmit = async (_val: ITransactionInformation) => {};
    const onClickDelete = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.stopPropagation();
        props.removeChild(props.uuid);
    };

    useEffect(() => {
        const subscription = watch((value, { name }) => {
            props.updateChildValues(props.uuid, {
                uuid: props.uuid,
                ...value,
            });
        });
        return () => subscription.unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch]);

    useEffect(() => {
        if (props.formData) {
            reset({
                holding_type: props.formData.holding_type ? Number(props.formData.holding_type) : undefined,
                id: props.formData.id ? Number(props.formData.id) : undefined,
                isValid: props.formData.isValid ? Boolean(props.formData.isValid) : undefined,
                ownership_stake: props.formData.ownership_stake ? Number(props.formData.ownership_stake) : undefined,
                related_party: props.formData.related_party ? Number(props.formData.related_party) : undefined,
                shares: props.formData.shares ? Number(props.formData.shares) : undefined,
                status: props.formData.status ? Number(props.formData.status) : undefined,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.formData]);

    return (
        <Accordion
            title={`Transaction Information-${props.index + 1}`}
            prefix={
                <Button color="secondary" iconOnly size="m" onClick={onClickDelete} disabled={props.index === 0}>
                    <DeleteIcon />
                </Button>
            }
            titleStyle={{
                color: hasError ? '#A73636' : '',
            }}
            initialExpanded={Boolean(!props.isEditing)}
        >
            <div className={styles.transactions_row}>
                <FormGenerator onSubmit={handleSubmit(onSubmit)} control={control} loading={false} errors={errors} schema={formFields} noBackgroundLayout />
            </div>
        </Accordion>
    );
};

export default TransactionRow;
