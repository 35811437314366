import ROUTE_CONSTANTS from 'src/Routes/constants/route-constants';
import { IMenuData } from './types';
import UsersIcon from '../../../assets/Icons/UsersIcon';
import { PERMISSION_TYPES } from 'src/enums/permissions';
import TeaserIcon from 'src/assets/Icons/TeaserIcon';
import SettingIcon from 'src/assets/Icons/SettingIcon';
import { checkCompanyType } from 'src/utils/check-company-type';
import { COMPANY_TYPE_ROUTE } from 'src/pages/Company/Add/enums/company-type-route';

const menuData: IMenuData[] = [
    {
        id: 1,
        title: 'User Management',
        icon: <UsersIcon />,
        href: ROUTE_CONSTANTS.USER_MANAGEMENT.ROOT.ABSOLUTE,
        permissions: [
            PERMISSION_TYPES.USER_LIST_VIEW,
            PERMISSION_TYPES.ROLE_LIST_VIEW,
            PERMISSION_TYPES.USER_REQUEST_PENDING_REQUEST,
            PERMISSION_TYPES.USER_REQUEST_APPROVED_REQUEST,
            PERMISSION_TYPES.USER_REQUEST_CANCELED_REQUEST,
        ],
        children: [
            {
                id: 11,
                title: 'Users',
                href: ROUTE_CONSTANTS.USER_MANAGEMENT.USERS.ROOT.ABSOLUTE,
                permissions: [PERMISSION_TYPES.USER_LIST_VIEW],
            },
            {
                id: 12,
                title: 'Roles',
                href: ROUTE_CONSTANTS.USER_MANAGEMENT.ROLES.ROOT.ABSOLUTE,
                permissions: [PERMISSION_TYPES.ROLE_LIST_VIEW],
            },
            {
                id: 13,
                title: 'Requests',
                href: ROUTE_CONSTANTS.USER_MANAGEMENT.USER_REQUEST.BY_STATUS('pending').ABSOLUTE,
                permissions: [
                    PERMISSION_TYPES.USER_REQUEST_PENDING_REQUEST,
                    PERMISSION_TYPES.USER_REQUEST_APPROVED_REQUEST,
                    PERMISSION_TYPES.USER_REQUEST_CANCELED_REQUEST,
                ],
                children: [
                    {
                        id: 131,
                        title: 'Pending Requests',
                        href: ROUTE_CONSTANTS.USER_MANAGEMENT.USER_REQUEST.BY_STATUS('pending').ABSOLUTE,
                        permissions: [PERMISSION_TYPES.USER_REQUEST_PENDING_REQUEST],
                    },
                    {
                        id: 132,
                        title: 'Approved Requests',
                        href: ROUTE_CONSTANTS.USER_MANAGEMENT.USER_REQUEST.BY_STATUS('approved').ABSOLUTE,
                        permissions: [PERMISSION_TYPES.USER_REQUEST_APPROVED_REQUEST],
                    },
                    {
                        id: 133,
                        title: 'Canceled Requests',
                        href: ROUTE_CONSTANTS.USER_MANAGEMENT.USER_REQUEST.BY_STATUS('canceled').ABSOLUTE,
                        permissions: [PERMISSION_TYPES.USER_REQUEST_CANCELED_REQUEST],
                    },
                ],
            },
        ],
    },
    {
        id: 2,
        title: 'Teaser',
        href: ROUTE_CONSTANTS.COMPANIES.TYPE.LIST_BY_COMPANY_TYPE(COMPANY_TYPE_ROUTE.PUBLIC).ABSOLUTE,
        permissions: [
            checkCompanyType(PERMISSION_TYPES.COMPANY_INFORMATION_LIST_VIEW, COMPANY_TYPE_ROUTE.PUBLIC),
            checkCompanyType(PERMISSION_TYPES.COMPANY_INFORMATION_LIST_VIEW, COMPANY_TYPE_ROUTE.PRIVATE),
            checkCompanyType(PERMISSION_TYPES.COMPANY_INFORMATION_LIST_VIEW, COMPANY_TYPE_ROUTE.NON_PROFILE),
        ],
        icon: <TeaserIcon />,
        children: [
            {
                id: 21,
                title: 'Public Companies',
                href: ROUTE_CONSTANTS.COMPANIES.TYPE.LIST_BY_COMPANY_TYPE(COMPANY_TYPE_ROUTE.PUBLIC).ABSOLUTE,
                permissions: [checkCompanyType(PERMISSION_TYPES.COMPANY_INFORMATION_LIST_VIEW, COMPANY_TYPE_ROUTE.PUBLIC)],
            },
            {
                id: 22,
                title: 'Private Companies',
                href: ROUTE_CONSTANTS.COMPANIES.TYPE.LIST_BY_COMPANY_TYPE(COMPANY_TYPE_ROUTE.PRIVATE).ABSOLUTE,
                permissions: [checkCompanyType(PERMISSION_TYPES.COMPANY_INFORMATION_LIST_VIEW, COMPANY_TYPE_ROUTE.PRIVATE)],
            },
            {
                id: 23,
                title: 'Non Profile Companies',
                href: ROUTE_CONSTANTS.COMPANIES.TYPE.LIST_BY_COMPANY_TYPE(COMPANY_TYPE_ROUTE.NON_PROFILE).ABSOLUTE,
                permissions: [checkCompanyType(PERMISSION_TYPES.COMPANY_INFORMATION_LIST_VIEW, COMPANY_TYPE_ROUTE.NON_PROFILE)],
            },
            {
                id: 24,
                title: 'Persons',
                href: ROUTE_CONSTANTS.PERSONS.ROOT.ABSOLUTE,
                permissions: [PERMISSION_TYPES.PERSON_LIST_VIEW, PERMISSION_TYPES.POSITION_LIST_VIEW],
                children: [
                    {
                        id: 241,
                        title: 'People',
                        href: ROUTE_CONSTANTS.PERSONS.PEOPLE.ROOT.ABSOLUTE,
                        permissions: [PERMISSION_TYPES.PERSON_LIST_VIEW],
                    },
                    {
                        id: 242,
                        title: 'Positions',
                        href: ROUTE_CONSTANTS.PERSONS.POSITION.ROOT.ABSOLUTE,
                        permissions: [PERMISSION_TYPES.POSITION_LIST_VIEW],
                    },
                ],
            },
        ],
    },
    {
        id: 3,
        title: 'Setting',
        icon: <SettingIcon />,
        href: ROUTE_CONSTANTS.SETTINGS.ROOT.ABSOLUTE,
        permissions: [
            PERMISSION_TYPES.SETTING_FI_CATEGORY_LIST_VIEW,
            PERMISSION_TYPES.SETTING_FI_SUB_CATEGORY_LIST_VIEW,
            PERMISSION_TYPES.SETTING_FI_ITEM_LIST_VIEW,
        ],
        children: [
            {
                id: 31,
                title: 'Financial Information',
                href: ROUTE_CONSTANTS.SETTINGS.FINANCIAL_INFORMATION.ROOT.ABSOLUTE,
                permissions: [
                    PERMISSION_TYPES.SETTING_FI_CATEGORY_LIST_VIEW,
                    PERMISSION_TYPES.SETTING_FI_SUB_CATEGORY_LIST_VIEW,
                    PERMISSION_TYPES.SETTING_FI_ITEM_LIST_VIEW,
                ],
                children: [
                    {
                        id: 311,
                        title: 'Categories',
                        href: ROUTE_CONSTANTS.SETTINGS.FINANCIAL_INFORMATION.CATEGORIES.ROOT.ABSOLUTE,
                        permissions: [PERMISSION_TYPES.SETTING_FI_CATEGORY_LIST_VIEW],
                    },
                    {
                        id: 312,
                        title: 'Sub-Categories',
                        href: ROUTE_CONSTANTS.SETTINGS.FINANCIAL_INFORMATION.SUB_CATEGORIES.ROOT.ABSOLUTE,
                        permissions: [PERMISSION_TYPES.SETTING_FI_SUB_CATEGORY_LIST_VIEW],
                    },
                    {
                        id: 313,
                        title: 'Items',
                        href: ROUTE_CONSTANTS.SETTINGS.FINANCIAL_INFORMATION.ITEMS.ROOT.ABSOLUTE,
                        permissions: [PERMISSION_TYPES.SETTING_FI_ITEM_LIST_VIEW],
                    },
                ],
            },
            {
                id: 32,
                title: 'Sector',
                href: ROUTE_CONSTANTS.SETTINGS.SECTORS.ROOT.ABSOLUTE,
                permissions: [PERMISSION_TYPES.SETTING_SECTOR_LIST_VIEW],
            },
            {
                id: 33,
                title: 'Stock Exchange',
                href: ROUTE_CONSTANTS.SETTINGS.STOCK_EXCHANGE.ROOT.ABSOLUTE,
                permissions: [PERMISSION_TYPES.SETTING_STOCK_EXCHANGE_LIST_VIEW],
            },
            {
                id: 34,
                title: 'Legal Structures',
                href: ROUTE_CONSTANTS.SETTINGS.LEGAL_STRUCTURES.ROOT.ABSOLUTE,
                permissions: [PERMISSION_TYPES.SETTING_LEGAL_STRUCTURES_LIST_VIEW],
            },
            {
                id: 35,
                title: 'Media Library',
                href: ROUTE_CONSTANTS.SETTINGS.MEDIA_LIBRARY.ROOT.ABSOLUTE,
                permissions: [PERMISSION_TYPES.SETTING_MEDIA_LIBRARY_LIST_VIEW],
            },
            {
                id: 36,
                title: 'News Source',
                href: ROUTE_CONSTANTS.SETTINGS.NEWS_SOURCE.ROOT.ABSOLUTE,
                permissions: [PERMISSION_TYPES.SETTING_NEWS_SOURCE_LIST_VIEW],
            },
            {
                id: 37,
                title: 'Feeds',
                href: ROUTE_CONSTANTS.SETTINGS.FEEDS_CONFIG.ROOT.ABSOLUTE,
                permissions: [PERMISSION_TYPES.SETTING_NEWS_SOURCE_LIST_VIEW],
            },
        ],
    },
];

export default menuData;
