import React, { useEffect, useState } from 'react';
import SidePanel from 'src/components/App/SidePanel';
import { SIDE_PANEL_SIZES } from 'src/enums/side-panel-size';
import { classNames } from 'primereact/utils';
import { financialValues } from 'src/utils/financial-values';
import { useFinancialDataContext } from '../../hooks/useFinancialDataContext';
import { FINANCIAL_INFORMATION_ROW_INPUT_TYPE, TABS } from '../../../../../enums';
import { ITEM_TYPE_ENUM } from 'src/enums/company/financialInformation/financialItem';
import { financialInformationLegitFractions } from '../../../../../constants';
import { IRowWithIndex } from './types';
import TotalSubtotalTabsProvider, { useTotalSubtotalContext } from './context';
import Button from 'src/components/Kit/Button';
import SelectedItemsBox from './components/FormulaComponent/components/SelectedItemsBox';
import FormulaTextBox from './components/FormulaComponent/components/FormulaTextBox';
import { IFinancialInformationRow } from '../../../../../types';
import styles from './TotalSubtotalValueSelector.module.scss';
import CustomValue from './components/CustomValueComponent';
import { useFinancial } from '../../../../../../../../../context/companies';
import { RATIO_NUMBER_TYPE } from '../../../../../../../../../api/types/company';

interface IChildFinderProps {
    row: IRowWithIndex;
    type: ITEM_TYPE_ENUM.SUBTOTAL | ITEM_TYPE_ENUM.TOTAL;
    disabled: boolean;
}

const TotalSubtotalValueSelectorWrapper: React.FC<IChildFinderProps> = (props) => {
    return (
        <TotalSubtotalTabsProvider selectedTotalSubtotal={props.row}>
            <TotalSubtotalValueSelector {...props} />
        </TotalSubtotalTabsProvider>
    );
};

const TotalSubtotalValueSelector: React.FC<IChildFinderProps> = ({ row, disabled }) => {
    const { setFinancialDataRows, financialDataRows, handleUpdateRow } = useFinancialDataContext();
    const { selectedTotalSubtotal, sidePanelShow, setSidePanelShow, relatedItems, finalResult, customValue } = useTotalSubtotalContext();
    const { currency } = useFinancial((global) => ({
        currency: global.financialSelectedCurrency,
    }));
    const [activeTab, setActiveTab] = useState<TABS>(row.is_custom_value ? TABS.CUSTOM_VALUE : TABS.FORMULA);
    const [tabOriginalState, setTabOriginalState] = useState<TABS>(row.is_custom_value ? TABS.CUSTOM_VALUE : TABS.FORMULA); // Hint: this original tab state has been created to revert the tabs state and its value on clicking cancel button.

    const handleSubmitFormula = () => {
        if (!finalResult || !relatedItems) {
            return;
        }
        const financialDataRowsCloned = (structuredClone(financialDataRows) as IFinancialInformationRow[]) || [];

        const financialDataRowsNewData = financialDataRowsCloned.map((row: IFinancialInformationRow) => {
            if (row.uniqueId === selectedTotalSubtotal.uniqueId) {
                return { ...row, value: finalResult, relatedItems: relatedItems, is_custom_value: false };
            } else {
                return row;
            }
        });

        setTabOriginalState(TABS.FORMULA);
        setFinancialDataRows(financialDataRowsNewData);
        setSidePanelShow(false);
    };

    const handleSubmitCustomValue = () => {
        setTabOriginalState(TABS.CUSTOM_VALUE);

        handleUpdateRow(FINANCIAL_INFORMATION_ROW_INPUT_TYPE.VALUE, customValue, row.rowOrder, undefined, { submittingCustomValue: true });
        setSidePanelShow(false);
    };

    const handleChangeTabs = (tab: TABS) => {
        setActiveTab(tab);
        // handleUpdateRow(FINANCIAL_INFORMATION_ROW_INPUT_TYPE.IS_CUSTOM_VALUE, tab === TABS.CUSTOM_VALUE, row.rowOrder);
    };

    const handleCancel = () => {
        setActiveTab(tabOriginalState);
        setSidePanelShow(false);
    };

    const tabContents = {
        [TABS.CUSTOM_VALUE]: CustomValue,
        [TABS.FORMULA]: FormulaTextBox,
    };

    const handleSubmit = {
        [TABS.CUSTOM_VALUE]: handleSubmitCustomValue,
        [TABS.FORMULA]: handleSubmitFormula,
    };

    const ActiveTabContent = tabContents[activeTab];

    const renderCurrency = () => {
        if (row.number_type === RATIO_NUMBER_TYPE.CURRENCY) {
            return (
                <div className={styles.prefixWrapper}>
                    <span>{currency?.short_name}</span>
                </div>
            );
        }
    };

    useEffect(() => {
        // if (sidePanelShow) handleUpdateRow(FINANCIAL_INFORMATION_ROW_INPUT_TYPE.FIND_MY_CHILDREN, '', row.rowOrder);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sidePanelShow]);

    return (
        <>
            <div className={classNames(disabled ? styles.customDropdown__disabled : styles.customDropdown)} onClick={() => !disabled && setSidePanelShow(true)}>
                {renderCurrency()}
                <div className={styles.bodyWrapper}>
                    <span style={{ fontWeight: 'bold' }}>{financialValues(row.value, { fixed: financialInformationLegitFractions }) || '0'}</span>
                </div>
            </div>
            <SidePanel
                size={SIDE_PANEL_SIZES.FULL}
                show={sidePanelShow}
                overlay={false}
                showHeader={false}
                wrapperCustomStyles={{ border: 'unset', padding: 'unset' }}
            >
                <div className={styles.sidePanelFullWrapper}>
                    <div className={styles.sidePanelFullWrapperHeader}>
                        <span>{row.name && row.name[0].name}</span>
                        <div className={styles.sidePanelFullWrapperHeaderButtons}>
                            <Button onClick={handleCancel}>Cancel</Button>
                            <Button data-cy={'btn_submit'} onClick={handleSubmit[activeTab]} color="primary">
                                Submit
                            </Button>
                        </div>
                    </div>
                    <div className={styles.sidePanelFullWrapperContent}>
                        {activeTab === TABS.FORMULA && (
                            <div className={styles.sidePanelFullWrapperContentLeft}>
                                <SelectedItemsBox />
                            </div>
                        )}
                        <div className={styles.sidePanelFullWrapperContentRight}>
                            <div className={styles.sidePanelFullWrapperTabs}>
                                <div
                                    data-cy={'tab-formula'}
                                    data-active={activeTab === TABS.FORMULA}
                                    className={classNames(activeTab === TABS.FORMULA && styles.active)}
                                    onClick={() => handleChangeTabs(TABS.FORMULA)}
                                >
                                    <span>Formula</span>
                                </div>
                                <div
                                    data-cy="tab-custom"
                                    data-active={activeTab === TABS.CUSTOM_VALUE}
                                    className={classNames(activeTab === TABS.CUSTOM_VALUE && styles.active)}
                                    onClick={() => handleChangeTabs(TABS.CUSTOM_VALUE)}
                                >
                                    <span>Custom</span>
                                </div>
                            </div>
                            <ActiveTabContent />
                        </div>
                    </div>
                </div>
            </SidePanel>
        </>
    );
};

export default TotalSubtotalValueSelectorWrapper;

// *************** this shows total of formula
//  <div className={styles.calculatedValueBox}>
//      <span className={styles.title}>Total</span>
//      <span className={styles.value}>
//          {row.must_show_currency && currency?.short_name} {financialValues(row.value, true, financialInformationLegitFractions)}
//      </span>
//  </div>
// *************** this shows total of formula

// ********* disable tab logic
// const [disabledTabs, setDisabledTabs] = useState<IDisabledTabs>({
//     formula: false,
//     customValue: false,
// });
// useEffect(() => {
//     handleDisableTabs();
//     // eslint-disable-next-line react-hooks/exhaustive-deps
// }, [financialDataRows]);

// const handleDisableTabs = () => {
//     let tempFinancialDataRows = structuredClone(financialDataRows) || []

//     try {
//         if (tempFinancialDataRows && tempFinancialDataRows.length) {
//             tempFinancialDataRows = tempFinancialDataRows.filter((tempRow: IFinancialInformationRow) => tempRow?.uniqueId !== row?.uniqueId);
//         }

//         const checkFormulaIngredientExist = (): boolean => {
//             let count = 0;

//             for (let i = 0; i < tempFinancialDataRows.length; i++) {
//                 const item = tempFinancialDataRows[i];
//                 const itemValueTypeIsNumeric = item.name && item.name[0].value_type === ITEM_VALUE_TYPE_OPTIONS_ENUM.NUMERIC;
//                 const typeOfItemIsNumber = typeof item.value === 'number';
//                 const typeOfItemIsString = typeof item.value === 'string';
//                 const itemIsNotNaN = !isNaN(parseFloat(String(item.value)!));

//                 if ((itemValueTypeIsNumeric && typeOfItemIsNumber) || (itemValueTypeIsNumeric && typeOfItemIsString && itemIsNotNaN)) {
//                     count++;

//                     if (count >= 1) return true;
//                 }
//             }

//             return false;
//         };

//         setDisabledTabs((prev) => ({
//             ...prev,
//             formula: !checkFormulaIngredientExist(),
//         }));
//     } catch (error) {
//         console.error(error)
//     }
// };

// const handleCheckTabIsActive = (state: boolean) => {
//     if (!state) {
//         // we should change its logic to handle disable custom value like formula if needed in the future.
//         toast.fire({
//             icon: TOAST_STATUS.ERROR,
//             title: 'There is no item to create formula!',
//         });
//     }

//     return state;
// };
// ********* disable tab logic
