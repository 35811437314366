interface Config {
    renderUi?: boolean;
    fixed?: number;
    prefix?: string | number;
    suffix?: string | number;
}
export const financialValues = (number: number | string | null, { renderUi = true, fixed = 2, prefix = '', suffix = '' }: Config = {}) => {
    if (number === null) return <span>-</span>;

    if (!+number) {
        return renderUi ? (
            <>
                <small className="prefix">{prefix}</small>
                <span>{String(number) + suffix}</span>
            </>
        ) : (
            prefix + String(number) + suffix
        );
    }

    number = +number;
    let isNegative = number < 0;
    if (isNegative && renderUi) {
        number *= -1;
    }

    let formattedNumber = '';
    let [integer, decimal] = String(number).split('.');

    if (decimal && decimal.length > 0 && fixed > decimal.length) {
        const zeroCountNeed = fixed - decimal.length;
        decimal += new Array(zeroCountNeed)
            .fill(0)
            .map(() => '0')
            .join('');
    }

    if (decimal && decimal.length > 0) {
        decimal = decimal.slice(0, fixed);
    }

    integer = Number(integer).toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });

    formattedNumber = integer + (decimal ? '.' + decimal : '');

    return renderUi ? (
        <>
            {prefix && <small className="prefix">{prefix}</small>}
            <span style={{ color: isNegative ? '#F25325' : '' }}> {isNegative ? `(${formattedNumber + suffix})` : formattedNumber + suffix}</span>
        </>
    ) : (
        prefix + formattedNumber + suffix
    );
};
