import { SVGProps } from 'react';

const FinancialInformationIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.66732 2H9.33398C10.0704 2 10.6673 2.59695 10.6673 3.33333V4.66667H5.33398V3.33333C5.33398 2.59695 5.93094 2 6.66732 2Z"
                stroke={props.stroke || 'currentColor' || '#888888'}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <rect
                x="2"
                y="4.6665"
                width="12"
                height="9.33333"
                rx="2"
                stroke={props.stroke || 'currentColor' || '#888888'}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.33398 10L7.00065 8.33333L8.66732 10L10.6673 8"
                stroke={props.stroke || 'currentColor' || '#888888'}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default FinancialInformationIcon;
