import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { ControllerRenderProps, FieldError, FieldValues } from 'react-hook-form';
import { RedError } from '../../../../assets/Icons/RedError';
import { TextFieldSchema } from '../../../../types/form-generator-schema-type';
interface IProps {
    field: ControllerRenderProps<FieldValues, string>;
    label: string;
    placeholder: string;
    className: string;
    inputType: string;
    errorMessage: FieldError;
    props?: TextFieldSchema['props'];
}
const FormText = ({ field, label, placeholder, className, errorMessage, props = {} }: IProps) => {
    const { showLabel = true, ...ownProps } = props;
    return (
        <div className={classNames('col-12', className ? className : '')}>
            <div className="form-field">
                {showLabel && (
                    <label className={field.name}>
                        {label || placeholder} {ownProps.required ? <span className="required">*</span> : ''}
                    </label>
                )}
                <InputText id={field.name} type={ownProps.type || 'text'} placeholder={placeholder} {...field} {...ownProps} />
                {errorMessage && (
                    <div className={'form-input-error'}>
                        <RedError />
                        <span>{errorMessage.message}</span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default FormText;
