export enum PARENT_COMPANY_FINANCIAL_LANGUAGES {
    ENGLISH = 'English',
    ARABIC = 'Arabic',
    GERMAN = 'German',
    SPANISH = 'Spanish',
    CHINESE = 'Chinese',
    JAPANESE = 'Japanese',
    INDIAN = 'Indian',
    RUSSIAN = 'Russian',
    PORTUGUESE = 'Portuguese',
    TURKISH = 'Turkish',
    KOREAN = 'Korean',
    ITALIAN = 'Italian',
    PERSIAN = 'Persian',
    THAI = 'Thai',
    OTHER = 'Other',
}
