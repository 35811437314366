import { Link } from 'react-router-dom';
import { IGetFinancialReportResponse } from 'src/api/types/company';
import { timestampToDateFormat } from 'src/utils/timestamp-to-date-format';
import pdfIcon from 'src/assets/Images/pdf-icon.svg'
import { useRef } from 'react';
import { mediaService } from 'src/api/services/media';
import { getAxiosError } from 'src/utils/get-axios-error';
import { toast } from 'src/utils/toast';
import { TOAST_STATUS } from 'src/constants/toast-status';

export const financialReportCellRender = {
    year_end: (data: IGetFinancialReportResponse) => <span>{timestampToDateFormat(data.year_end, 'MMM YYYY')}</span>,
    created_at: (data: IGetFinancialReportResponse) => <span>{timestampToDateFormat(data.created_at)}</span>,
    updated_at: (data: IGetFinancialReportResponse) => <span>{timestampToDateFormat(data.updated_at)}</span>,
    first_quarter: (data: IGetFinancialReportResponse) => data.first_quarter ? <SlugComponent targetUUID={data.first_quarter} /> : <span>-</span>,
    half_quarter: (data: IGetFinancialReportResponse) => data.half_quarter ? <SlugComponent targetUUID={data.half_quarter} /> : <span>-</span>,
    third_quarter: (data: IGetFinancialReportResponse) => data.third_quarter ? <SlugComponent targetUUID={data.third_quarter} /> : <span>-</span>,
    full_year: (data: IGetFinancialReportResponse) => data.full_year ? <SlugComponent targetUUID={data.full_year} /> : <span>-</span>,
    annual: (data: IGetFinancialReportResponse) => data.annual ? <SlugComponent targetUUID={data.annual} /> : <span>-</span>,
};

const SlugComponent: React.FC<{ targetUUID: string }> = ({ targetUUID }) => {
    const elementRef = useRef<HTMLAnchorElement>(null)

    const handleGetLink = async () => {
        try {
            let res = await mediaService.getViewPDFLink(targetUUID)
            const link: string = res.data.data

            if(elementRef.current) {
                elementRef.current.href = link
                elementRef.current.target='_blank' 
            } 
            elementRef.current?.click()

        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: message,
            });
        }
    }

    return (
        <>
            <div className='fileIcon cursor-pointer' onClick={handleGetLink}>
                <img src={pdfIcon} alt='pdf' width={32} height={32} />
            </div>
            <Link
                ref={elementRef}
                className='fileIcon hidden'
                to={''}
                target='_blank'
            >
                <img src={pdfIcon} alt='pdf' width={32} height={32} />
            </Link>
        </>
    )
}   
