import React from 'react';
import DashboardTitle from 'src/components/App/DashboardTitle';

const Dashboard: React.FC = () => {
    return (
        <>
            <DashboardTitle title="Welcome To Dashboard" />
        </>
    );
};

export default Dashboard;
