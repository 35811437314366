import { Dropdown, DropdownProps } from 'primereact/dropdown';
import { IStatusDropDownOptions } from './types/status-drop-down-options';
import StatusDropDownItem from './StatusDropDownItem';
import { classNames } from 'primereact/utils';
import style from './StatusDropDown.module.scss';
import Link from '../Link';
import { ApiConfig } from '../../../api/config';
import VisitIcon from '../../../assets/Icons/Visit';
import React from 'react';

interface IProps {
    label?: string;
    dropDownProps: DropdownProps;
    loading?: boolean;
}

const StatusDropDown: React.FC<IProps> = ({ label, dropDownProps = {}, loading }) => {
    const optionTemplate = (option: IStatusDropDownOptions) => {
        if (option.type === 'button')
            return (
                <Link
                    href={`${ApiConfig.websiteUrl}/companies/${option.value}`}
                    target={'_blank'}
                    icon={<VisitIcon />}
                    className={style.dropDownLink}
                    iconClassNames={style.dropDownLinkIcon}
                    onClick={(e) => e.stopPropagation()}
                >
                    See Website
                </Link>
            );
        else return <StatusDropDownItem item label={option.label} status={option.status} />;
    };

    const selectedTemplate = (option: IStatusDropDownOptions, props: DropdownProps) => {
        if (!option) return <div className={style.statusDropDownItem}>{props.placeholder}</div>;

        return <StatusDropDownItem label={option.label} status={option.status} />;
    };

    return (
        <div className={classNames('status-dropdown', style.statusDropDown)}>
            {Boolean(label) && <span>{label}</span>}
            <div className={classNames('status-dropdown-wrapper', style.statusDropDownWrapper, loading ? style.loading : '')}>
                <Dropdown
                    optionLabel="label"
                    panelClassName={style.statusDropDownPanel}
                    optionValue="value"
                    valueTemplate={selectedTemplate}
                    itemTemplate={optionTemplate}
                    {...dropDownProps}
                />
            </div>
        </div>
    );
};

export default StatusDropDown;
