import { FORM_INPUTS } from 'src/types/form';
import { IFormGeneratorSchemaType } from 'src/types/form-generator-schema-type';
import { DATE_FORMAT } from '../../../enums/date-format';
import { DATE_PICKER_VIEW } from '../../../enums/date-picker-view';

export type CreateDividendsSchemaFields = 'financial_year' | 'bonus_shares' | 'cash_dividends' | 'se_template' | string;
export type CreateDividendsSchemaType = Record<CreateDividendsSchemaFields, IFormGeneratorSchemaType>;

export const createDividendsSchema: CreateDividendsSchemaType = {
    financial_year: {
        placeholder: 'Financial Year',
        type: FORM_INPUTS.DATE,
        props: {
            required: true,
            view: DATE_PICKER_VIEW.YEAR,
            dateFormat: DATE_FORMAT.YEAR_FOUR_DIGITS,
            maxDate: new Date(),
        },
    },
    cash_dividends: {
        placeholder: 'Cash Dividends',
        type: FORM_INPUTS.NUMBER,
        props: {
            required: false,
            min: 0,
            max: 100,
            maxFractionDigits: 2,
        },
    },
    bonus_shares: {
        placeholder: 'Bonus Shares',
        type: FORM_INPUTS.NUMBER,
        props: {
            required: false,
            min: 0,
            max: 100,
            maxFractionDigits: 2,
        },
    },
    se_template: {
        placeholder: 'SE Template',
        type: FORM_INPUTS.SELECT,
        props: {
            required: true,
        },
    },
};
