import { useEffect, useState } from 'react';
import { financialInformationService } from 'src/api/services/financial-information-service';
import { ISelectBox } from 'src/api/types/base-types';
import { TOAST_STATUS } from 'src/constants/toast-status';
import { getAxiosError } from 'src/utils/get-axios-error';
import { toast } from 'src/utils/toast';

export const useGetFinancialDataTypes = () => {
    const [financialDataTypes, setFinancialDataTypes] = useState<ISelectBox[]>([]);

    const getFinancialDataTypes = async () => {
        try {
            let typesResponse = await financialInformationService.typesSelectBox();
            const tempFinancialInformationTypes = typesResponse.data.data.map(({ label, value }) => ({
                label,
                value: String(value),
            }));
            setFinancialDataTypes(tempFinancialInformationTypes);
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: message,
            });
        }
    };

    useEffect(() => {
        getFinancialDataTypes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { financialDataTypes };
};
