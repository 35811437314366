import { Chips, ChipsProps } from 'primereact/chips';
import { classNames } from 'primereact/utils';
import { ControllerRenderProps, FieldError, FieldValues } from 'react-hook-form';
import { RedError } from 'src/assets/Icons/RedError';

interface IProps {
    field: ControllerRenderProps<FieldValues, string>;
    label: string;
    placeholder: string;
    className: string;
    errorMessage: FieldError;
    props?: ChipsProps;
}

const FormChips = ({ field, label, placeholder, className, errorMessage, props = {} }: IProps) => {
    const error =
        errorMessage && typeof errorMessage === 'object'
            ? (errorMessage as unknown as FieldError[]).filter((error: FieldError) => error).map((error: FieldError) => error?.message)
            : (errorMessage as FieldError)?.message;
    return (
        <div className={classNames('col-12', className ? className : '')}>
            <div className="form-field">
                <label className={field.name}>
                    {label || placeholder} {props.required ? <span className="required">*</span> : ''}
                </label>
                <div className="p-inputgroup">
                    <Chips
                        id={field.name}
                        placeholder={placeholder}
                        separator=","
                        {...field}
                        {...props}
                    />
                </div>
                {error ? (
                    typeof error === 'object' ? (
                        error.map((errorItem) => (
                            <div className={'form-input-error'}>
                                <RedError />
                                <span>{errorItem}</span>
                            </div>
                        ))
                    ) : (
                        <div className={'form-input-error'}>
                            <RedError />
                            <span>{error}</span>
                        </div>
                    )
                ) : null}
            </div>
        </div>
    );
};

export default FormChips;
