import { API_ROUTES } from 'src/constants/api-routes';
import { AxiosInstance } from '../index';
import { IAxiosResponse } from '../types/axios-response';
import { IDataTablePayload, IDataTableResponse } from 'src/api/types/table';
import { ICreateHoldingRequest, IHoldingResponse, IHoldingShowResponse } from '../types/holdings';
import { companyTypeParam } from 'src/constants/company/company-type-param';
import { COMPANY_TYPE_ROUTE } from 'src/pages/Company/Add/enums/company-type-route';

export const holdingService = (companyTypeRoute: COMPANY_TYPE_ROUTE) => {
    const companyType = companyTypeParam[companyTypeRoute];

    function getRouteWithCompanyType(route: string) {
        const typeRoute = `/companies/${companyType}`;
        return route.replace('/companies', typeRoute);
    }

    return {
        getAllHoldings(companyId: number, params?: IDataTablePayload) {
            return AxiosInstance.get<IDataTableResponse<IHoldingResponse>>(getRouteWithCompanyType(API_ROUTES.HOLDINGS.GET_ALL_HOLDINGS(companyId)), {
                params,
            });
        },
        create(companyId: number, params: ICreateHoldingRequest) {
            return AxiosInstance.post<IAxiosResponse>(getRouteWithCompanyType(API_ROUTES.HOLDINGS.ADD_NEW(companyId)), params);
        },
        getOne(companyId: number, holdingId: number) {
            return AxiosInstance.get<IAxiosResponse<IHoldingShowResponse>>(getRouteWithCompanyType(API_ROUTES.HOLDINGS.GET_ONE(companyId, holdingId)));
        },
        update(companyId: number, holdingId: number, params: Pick<IHoldingResponse, 'transactions'>) {
            return AxiosInstance.put<IAxiosResponse>(getRouteWithCompanyType(API_ROUTES.HOLDINGS.UPDATE(companyId, holdingId)), params);
        },
        delete(companyId: number, holdingId: number) {
            return AxiosInstance.delete<IAxiosResponse>(getRouteWithCompanyType(API_ROUTES.HOLDINGS.DELETE(companyId, holdingId)));
        },
    };
};
