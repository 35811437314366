import { SVGProps } from 'react';

const MoreIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 4" fill="none" {...props}>
            <rect x="0.5" y="0.5" width="3" height="3" rx="1.5" stroke={props.stroke || "currentColor" || "#3861FB"} />
            <rect x="5.5" y="0.5" width="3" height="3" rx="1.5" stroke={props.stroke || "currentColor" || "#3861FB"} />
            <rect x="10.5" y="0.5" width="3" height="3" rx="1.5" stroke={props.stroke || "currentColor" || "#3861FB"} />
        </svg>
    );
};

export default MoreIcon;
