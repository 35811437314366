import React, { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import { Column } from 'primereact/column';
import { useNavigate } from 'react-router-dom';
import ROUTE_CONSTANTS from 'src/Routes/constants/route-constants';
import DeleteIcon from 'src/assets/Icons/DeleteIcon';
import EditIcon from 'src/assets/Icons/EditIcon';
import Table from 'src/components/App/Table';
import Button from 'src/components/Kit/Button';
import { TOAST_STATUS } from 'src/constants/toast-status';
import { modalActions } from 'src/context/modals';
import DashboardContentWrapper from 'src/layouts/DashboardContentWrapper';
import { MODAL_TYPES } from 'src/types/modals';
import { toast } from 'src/utils/toast';
import { useCheckPermissionAccess } from 'src/hooks/useCheckPermissionAccess';
import { PERMISSION_TYPES } from 'src/enums/permissions';
import { IDataTablePayload } from 'src/api/types/table';
import { newsSourceService } from 'src/api/services/news-source';
import { IGetNewsSourceResponse } from 'src/api/types/news-source';
import { newsSourceCellRender } from './cellRender';

const NewsSource: React.FC = () => {
    const { checkPermissionAccess } = useCheckPermissionAccess();
    const navigate = useNavigate();
    const service = useCallback(
        async (queryParams?: IDataTablePayload) => newsSourceService.getAll(queryParams),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const handleRemove = (id: number, setTableData: Dispatch<SetStateAction<IGetNewsSourceResponse[]>>) => {
        const handleRemoveItem = async () => {
            try {
                const deleteResult = await newsSourceService.delete(Number(id));
                setTableData((tData) => tData.filter((data: IGetNewsSourceResponse) => data.id !== id));
                toast.fire({
                    icon: TOAST_STATUS.SUCCESS,
                    title: deleteResult.data.message,
                });
            } catch (err) {
                const error = err as { success: string; message: string };
                modalActions.clearModals();
                modalActions.addModal(MODAL_TYPES.CONFIRMATION_MODAL, {
                    header: 'Alert',
                    text: error.message,
                    disableCancelButton: true,
                    actionButtonText: 'Ok',
                });
            }
        };
        modalActions.addModal(MODAL_TYPES.CONFIRMATION_MODAL, {
            header: 'Delete Item',
            text: 'Are you sure to delete this News Source?',
            handleAction: handleRemoveItem,
            autoClose: false,
        });
    };

    useEffect(() => {
        if (!checkPermissionAccess(PERMISSION_TYPES.SETTING_NEWS_SOURCE_LIST_VIEW)) {
            navigate(ROUTE_CONSTANTS.ROOT.ABSOLUTE);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const suffixCols = (data: IGetNewsSourceResponse[], setTableData: Dispatch<SetStateAction<IGetNewsSourceResponse[]>>) => {
        return (
            <Column
                key="Action"
                body={(rowData: IGetNewsSourceResponse) => (
                    <>
                        {checkPermissionAccess(PERMISSION_TYPES.SETTING_NEWS_SOURCE_EDIT) && (
                            <Button link color="secondary" iconOnly size="m" to={ROUTE_CONSTANTS.SETTINGS.NEWS_SOURCE.UPDATE.EDIT_BY_ID(rowData.id).ABSOLUTE}>
                                <EditIcon />
                            </Button>
                        )}
                        {checkPermissionAccess(PERMISSION_TYPES.SETTING_NEWS_SOURCE_DELETE) && (
                            <Button color="secondary" iconOnly size="m" onClick={() => handleRemove(rowData.id, setTableData)}>
                                <DeleteIcon />
                            </Button>
                        )}
                    </>
                )}
            />
        );
    };

    return (
        <DashboardContentWrapper
            title="News Source"
            titleSuffix={
                checkPermissionAccess(PERMISSION_TYPES.SETTING_NEWS_SOURCE_CREATE) ? (
                    <Button size="s" link color="primary" to={ROUTE_CONSTANTS.SETTINGS.NEWS_SOURCE.ADD_NEW.ROOT.ABSOLUTE}>
                        Add New News Source
                    </Button>
                ) : undefined
            }
        >
            <Table
                getTableData={service}
                cellRender={newsSourceCellRender}
                suffixCols={
                    checkPermissionAccess(PERMISSION_TYPES.SETTING_NEWS_SOURCE_EDIT) || checkPermissionAccess(PERMISSION_TYPES.SETTING_NEWS_SOURCE_DELETE)
                        ? suffixCols
                        : undefined
                }
            />
        </DashboardContentWrapper>
    );
};

export default NewsSource;
