import React, { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import { Column } from 'primereact/column';
import { useNavigate, useParams } from 'react-router-dom';
import ROUTE_CONSTANTS from 'src/Routes/constants/route-constants';
import { companyService } from 'src/api/services/company';
import { IGetCompanyDividendsResponse } from 'src/api/types/company';
import DeleteIcon from 'src/assets/Icons/DeleteIcon';
import EditIcon from 'src/assets/Icons/EditIcon';
import Table from 'src/components/App/Table';
import Button from 'src/components/Kit/Button';
import { TOAST_STATUS } from 'src/constants/toast-status';
import { modalActions } from 'src/context/modals';
import DashboardContentWrapper from 'src/layouts/DashboardContentWrapper';
import { MODAL_TYPES } from 'src/types/modals';
import { getAxiosError } from 'src/utils/get-axios-error';
import { toast } from 'src/utils/toast';
import { dividendsCellRender } from './cellRender';
import { dividendsService } from 'src/api/services/dividends';
import { useCheckPermissionAccess } from 'src/hooks/useCheckPermissionAccess';
import { PERMISSION_TYPES } from 'src/enums/permissions';
import { IDataTablePayload } from 'src/api/types/table';
import { useNonProfilePolicy } from '../useNonProfilePolicy';
import { COMPANY_TYPE_ROUTE } from '../../Add/enums/company-type-route';
import { IParams } from 'src/types/params';
import { checkCompanyType } from 'src/utils/check-company-type';

const CompanyDividends: React.FC = () => {
    useNonProfilePolicy();
    const { checkPermissionAccess } = useCheckPermissionAccess();
    const navigate = useNavigate();
    const { companyId, companyType } = useParams<IParams>();
    const service = useCallback(
        async (queryParams?: IDataTablePayload) => companyService(companyType as COMPANY_TYPE_ROUTE).getAllDividends(Number(companyId), queryParams),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const handleRemove = (id: number, setTableData: Dispatch<SetStateAction<IGetCompanyDividendsResponse[]>>) => {
        const handleRemoveItem = async () => {
            try {
                await dividendsService(companyType as COMPANY_TYPE_ROUTE).delete(Number(companyId), Number(id));
                setTableData((tData) => tData.filter((data: IGetCompanyDividendsResponse) => data.id !== id));
                toast.fire({
                    icon: TOAST_STATUS.SUCCESS,
                    title: 'Dividend removed successfully!',
                });
            } catch (err) {
                const error = getAxiosError(err);
                const message = error?.message || 'Server Error';
                toast.fire({
                    icon: TOAST_STATUS.ERROR,
                    title: message,
                });
            }
        };
        modalActions.addModal(MODAL_TYPES.CONFIRMATION_MODAL, {
            header: 'Delete Item',
            text: 'Are you sure to delete this Dividend?',
            handleAction: handleRemoveItem,
        });
    };

    useEffect(() => {
        if (!checkPermissionAccess(checkCompanyType(PERMISSION_TYPES.COMPANY_DIVIDEND_LIST_VIEW, companyType as COMPANY_TYPE_ROUTE))) {
            navigate(ROUTE_CONSTANTS.ROOT.ABSOLUTE);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const suffixCols = (data: IGetCompanyDividendsResponse[], setTableData: Dispatch<SetStateAction<IGetCompanyDividendsResponse[]>>) => {
        return (
            <Column
                key="Action"
                body={(rowData: IGetCompanyDividendsResponse) => (
                    <>
                        {checkPermissionAccess(checkCompanyType(PERMISSION_TYPES.COMPANY_DIVIDEND_EDIT, companyType as COMPANY_TYPE_ROUTE)) && (
                            <Button
                                link
                                color="secondary"
                                iconOnly
                                size="m"
                                to={
                                    ROUTE_CONSTANTS.COMPANIES.TYPE.SHOW.DIVIDENDS.EDIT.EDIT_BY_COMPANY_AND_DIVIDEND_ID(
                                        companyType as COMPANY_TYPE_ROUTE,
                                        Number(companyId),
                                        rowData.id
                                    ).ABSOLUTE
                                }
                            >
                                <EditIcon />
                            </Button>
                        )}
                        {checkPermissionAccess(checkCompanyType(PERMISSION_TYPES.COMPANY_DIVIDEND_DELETE, companyType as COMPANY_TYPE_ROUTE)) && (
                            <Button color="secondary" iconOnly size="m" onClick={() => handleRemove(rowData.id, setTableData)}>
                                <DeleteIcon />
                            </Button>
                        )}
                    </>
                )}
            />
        );
    };

    return (
        <DashboardContentWrapper
            title="Company Dividends"
            titleSuffix={
                checkPermissionAccess(checkCompanyType(PERMISSION_TYPES.COMPANY_DIVIDEND_CREATE, companyType as COMPANY_TYPE_ROUTE)) ? (
                    <Button
                        size="s"
                        link
                        color="primary"
                        to={
                            ROUTE_CONSTANTS.COMPANIES.TYPE.SHOW.DIVIDENDS.ADD_NEW.ADD_NEW_BY_COMPANY_ID(companyType as COMPANY_TYPE_ROUTE, Number(companyId))
                                .ABSOLUTE
                        }
                    >
                        Add New dividends
                    </Button>
                ) : undefined
            }
        >
            <Table
                getTableData={service}
                cellRender={dividendsCellRender}
                suffixCols={
                    checkPermissionAccess(checkCompanyType(PERMISSION_TYPES.COMPANY_DIVIDEND_EDIT, companyType as COMPANY_TYPE_ROUTE)) ||
                    checkPermissionAccess(checkCompanyType(PERMISSION_TYPES.COMPANY_DIVIDEND_DELETE, companyType as COMPANY_TYPE_ROUTE))
                        ? suffixCols
                        : undefined
                }
            />
        </DashboardContentWrapper>
    );
};

export default CompanyDividends;
