import { SVGProps } from 'react';

const VisitIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg viewBox="0 0 18 18" width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M8.99935 0.0419924H8.95153C7.02787 0.0419816 5.52034 0.0419731 4.3441 0.200114C3.14016 0.361981 2.19014 0.699764 1.44446 1.44544C0.698788 2.19111 0.361004 3.14113 0.199138 4.34508C0.0409966 5.52132 0.0410051 7.02885 0.0410158 8.95251V9.04814C0.0410051 10.9718 0.0409966 12.4793 0.199138 13.6556C0.361004 14.8595 0.698788 15.8095 1.44446 16.5552C2.19014 17.3009 3.14016 17.6387 4.3441 17.8005C5.52034 17.9587 7.02787 17.9587 8.95154 17.9587H9.04716C10.9708 17.9587 12.4784 17.9587 13.6546 17.8005C14.8585 17.6387 15.8086 17.3009 16.5542 16.5552C17.2999 15.8095 17.6377 14.8595 17.7996 13.6556C17.9577 12.4793 17.9577 10.9718 17.9577 9.04813V9.00032C17.9577 8.65515 17.6779 8.37532 17.3327 8.37532C16.9875 8.37532 16.7077 8.65515 16.7077 9.00032C16.7077 10.9822 16.7064 12.4057 16.5607 13.489C16.4175 14.5543 16.1451 15.1966 15.6703 15.6713C15.1956 16.146 14.5534 16.4184 13.488 16.5617C12.4047 16.7073 10.9812 16.7087 8.99935 16.7087C7.01749 16.7087 5.59398 16.7073 4.51066 16.5617C3.44533 16.4184 2.80306 16.146 2.32835 15.6713C1.85363 15.1966 1.58122 14.5543 1.43799 13.489C1.29234 12.4057 1.29102 10.9822 1.29102 9.00032C1.29102 7.01847 1.29234 5.59495 1.43799 4.51164C1.58122 3.4463 1.85363 2.80404 2.32835 2.32932C2.80306 1.85461 3.44533 1.5822 4.51066 1.43897C5.59398 1.29332 7.01749 1.29199 8.99935 1.29199C9.34453 1.29199 9.62435 1.01217 9.62435 0.666992C9.62435 0.321815 9.34453 0.0419924 8.99935 0.0419924Z"
                fill={props.fill || "currentColor" || "#3861FB" } />
            <path
                d="M9.39074 7.72505C9.14666 7.96913 9.14666 8.36485 9.39074 8.60893C9.63482 8.85301 10.0305 8.85301 10.2746 8.60893L16.7077 2.17588V5.12012C16.7077 5.46529 16.9875 5.74512 17.3327 5.74512C17.6779 5.74512 17.9577 5.46529 17.9577 5.12012V0.666992C17.9577 0.321815 17.6779 0.0419924 17.3327 0.0419924H12.8796C12.5344 0.0419924 12.2546 0.321815 12.2546 0.666992C12.2546 1.01217 12.5344 1.29199 12.8796 1.29199H15.8238L9.39074 7.72505Z"
                fill={props.fill || "currentColor" || "#3861FB" } />
        </svg>
    )
};

export default VisitIcon;
