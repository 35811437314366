import React, { useEffect, useState } from 'react';
import DashboardContentWrapper from 'src/layouts/DashboardContentWrapper';
import FormGenerator from 'src/components/Kit/FormGenerator/index';
import { useForm } from 'react-hook-form';
import { generateDefaultValueFromFormSchema } from 'src/utils/generate-default-value-from-form-schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { validationSchema } from './validation-schema';
import { createPersonSchemaType, createPersonSchema, createPersonSchemaFields } from './schema';
import Button from 'src/components/Kit/Button';
import { regionService } from 'src/api/services/region-service';
import { genderDict, genderOption } from './constants';
import { useNavigate, useParams } from 'react-router-dom';
import { personService } from 'src/api/services/person-service';
import { toast } from 'src/utils/toast';
import { TOAST_STATUS } from 'src/constants/toast-status';
import InitLoading from 'src/components/App/Loading/InitLoading';
import { ICreatePersonPayload } from 'src/api/types/persons';
import { getAxiosError } from 'src/utils/get-axios-error';
import { useCheckPermissionAccess } from 'src/hooks/useCheckPermissionAccess';
import { PERMISSION_TYPES } from 'src/enums/permissions';
import { BasicInformationSchemaFields } from '../../../Company/types/basic-information-form-fields-schema';
import { IFormGeneratorGeneralSchemaType } from '../../../../types/form-generator-schema-type';

interface IParams {
    [id: string]: string;
}

const AddUpdate: React.FC = () => {
    const { checkPermissionAccess } = useCheckPermissionAccess();
    const navigate = useNavigate();
    const { id } = useParams<IParams>();
    const isEditingRule = Boolean(id);
    const [loading, setLoading] = useState<boolean>(false);
    const [initialLoading, setInitialLoading] = useState<boolean>(true);
    const [formSchema, setFormSchema] = useState<Record<createPersonSchemaFields, IFormGeneratorGeneralSchemaType>>(createPersonSchema);

    const {
        control,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm<ICreatePersonPayload>({
        defaultValues: generateDefaultValueFromFormSchema(formSchema),
        mode: 'all',
        resolver: yupResolver(validationSchema()),
    });

    const handleUpdateDropdownOptions = async (fieldName: createPersonSchemaFields, getNewOptions: any) => {
        setFormSchema((prev) => ({
            ...prev,
            [fieldName]: {
                ...formSchema[fieldName],
                props: {
                    ...formSchema[fieldName].props,
                    loading: true,
                },
            },
        }));

        const response = await getNewOptions();
        setFormSchema((prev) => ({
            ...prev,
            [fieldName]: {
                ...prev[fieldName],
                data: response.data.data.map(({ label, value }: any) => ({
                    label,
                    value: String(value),
                })),
                props: {
                    ...prev[fieldName].props,
                    loading: false,
                    disabled: false,
                    onFilter,
                },
            },
        }));
    };

    const onFilter = (fieldName: string, value: string) => {
        switch (fieldName) {
            case 'country_id': {
                handleUpdateDropdownOptions(fieldName, () =>
                    regionService.selectBox({
                        ...(value ? { text: value } : {}),
                    })
                );
                break;
            }
        }
    };

    const handleGetValues = async () => {
        setInitialLoading(true);
        if (isEditingRule) await getDetails();
        try {
            let countryRes = await regionService.selectBox();
            const tempCountries = countryRes.data.data.map(({ label, value }) => ({
                label,
                value: String(value),
            }));
            setFormSchema(prev => ({
                ...prev,
                country_id: { ...prev.country_id, data: tempCountries , props : {
                    ...prev.country_id.props,
                    onFilter
                }},
                gender: { ...prev.gender, data: genderOption , props : {
                    ...prev.gender.props,
                }},
            }));
            setInitialLoading(false);
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: message,
            });
            setInitialLoading(false);
        }
    };

    const onSubmit = async (_val: ICreatePersonPayload) => {
        let targetApi;
        const { name, arabic_name, phone, email, description, address, linkedin, gender, country_id, media } = _val;
        const tempData = {
            name,
            country_id,
            gender,
            ...(arabic_name && { arabic_name }),
            ...(phone && { phone }),
            ...(arabic_name && { arabic_name }),
            ...(phone && { phone }),
            ...(address && { address }),
            ...(description && { description }),
            ...(email && { email }),
            ...(linkedin && { linkedin }),
            ...(media && { media_id: media.id }),
        };

        if (isEditingRule) targetApi = personService.update(Number(id), tempData);
        else targetApi = personService.createNew(tempData);
        try {
            setLoading(true);
            await targetApi;
            toast.fire({
                icon: TOAST_STATUS.SUCCESS,
                title: isEditingRule ? 'Person has been updated successfully' : 'Person has been created successfully',
            });
            setLoading(false);
            navigate(-1);
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: message,
            });
            setLoading(false);
        }
    };

    const getDetails = async () => {
        try {
            let res = await personService.getOne(Number(id));
            reset({
                name: res.data.data.name,
                gender: genderDict[res.data.data.gender],
                arabic_name: res.data.data.arabic_name || '',
                phone: res.data.data.phone || '',
                email: res.data.data.email || '',
                description: res.data.data.description || '',
                address: res.data.data.address || '',
                linkedin: res.data.data.linkedin || '',
                country_id: res.data.data.country_id || '',
                ...(res.data.data.media?.id && { media: { url: res.data.data.media?.url, id: res.data.data.media?.id } }),
            });
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: message,
            });
        }
    };

    useEffect(() => {
        handleGetValues();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isEditingRule && !checkPermissionAccess(PERMISSION_TYPES.PERSON_EDIT)) return <></>;
    if (!isEditingRule && !checkPermissionAccess(PERMISSION_TYPES.PERSON_CREATE)) return <></>;

    if (initialLoading) return <InitLoading />;

    return (
        <DashboardContentWrapper>
            <div className="grid justify-content-center my-8">
                <div className="xs:col:12 md:col-8 xl:col-5">
                    <span className="block text-2xl font-semibold">{isEditingRule ? 'Update Person' : 'Add New Person'}</span>
                    <div className="mt-5">
                        <FormGenerator
                            onSubmit={handleSubmit(onSubmit)}
                            control={control}
                            errors={errors}
                            schema={formSchema}
                            noBackgroundLayout
                            buttons={
                                <>
                                    <Button disabled={loading} loading={loading} color="primary">
                                        {isEditingRule ? 'Update' : 'Create'}
                                    </Button>
                                    <Button type="button" onClick={() => navigate(-1)}>
                                        Cancel
                                    </Button>
                                </>
                            }
                        />
                    </div>
                </div>
            </div>
        </DashboardContentWrapper>
    );
};

export default AddUpdate;
