import { FORM_INPUTS } from 'src/types/form';
import { IFormGeneratorSchemaType } from 'src/types/form-generator-schema-type';

export type CreateFinancialInformationCategorySchemaFields = 'name' | 'arabic_name' | 'financial_information_type';

export type CreateFinancialInformationCategorySchemaType = Record<CreateFinancialInformationCategorySchemaFields, IFormGeneratorSchemaType>;
export const createFinancialInformationCategorySchema: CreateFinancialInformationCategorySchemaType = {
    name: {
        placeholder: 'Name',
        type: FORM_INPUTS.TEXT,
        props: {
            required: true
        }
    },
    arabic_name: {
        label: 'Arabic Name',
        placeholder: 'Arabic Name',
        type: FORM_INPUTS.TEXT,
        props: {
            required: false,
        },
    },
    financial_information_type: {
        placeholder: 'Financial Information Type',
        type: FORM_INPUTS.MULTI_SELECT,
        props: {
            required: true,
            showClear: false,
            showSelectAll: false,
            panelHeaderTemplate: <></>,
        }
    },
};
