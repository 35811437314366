import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { DATE_TYPE } from '../types/form';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('America/New_York');

export const timestampToDateFormat = (timestamp: number, format: string = 'DD-MMM-YYYY') => dayjs(dayjs.unix(timestamp)).format(format);
export const timestampToDynamicDateFormat = (timestamp: number, dateFormat: DATE_TYPE): string => {
    let format: string;

    switch (String(dateFormat)) {
        case DATE_TYPE.YEAR:
            format = 'YYYY';
            break;
        case DATE_TYPE.MONTH:
            format = 'YYYY-MMM';
            break;
        case DATE_TYPE.DATE:
        default:
            format = 'YYYY-MMM-DD';
            break;
    }

    return dayjs(dayjs.unix(timestamp)).format(format);
};

export const dateToDynamicDateFormat = (date: Date, dateFormat: DATE_TYPE): string => {
    let format: string;

    switch (String(dateFormat)) {
        case DATE_TYPE.YEAR:
            format = 'YYYY';
            break;
        case DATE_TYPE.MONTH:
            format = 'YYYY-MM';
            break;
        case DATE_TYPE.DATE:
        default:
            format = 'YYYY-MM-DD';
            break;
    }

    return dayjs(date).format(format);
};

export function convertDateToFormatted(date: any, format: DATE_TYPE) {
    if (!date) return;
    if (format === DATE_TYPE.DATE) return date;
    let formattedDate = dayjs(date);

    if (format === DATE_TYPE.YEAR) {
        formattedDate = formattedDate.startOf('year');
    } else if (format === DATE_TYPE.MONTH) {
        formattedDate = formattedDate.startOf('month');
    }

    return formattedDate.format('YYYY/MM/DD') as any;
}
