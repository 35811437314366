import React from 'react';
import styles from './DashboardLayout.module.scss';
import { Navigate, Outlet } from 'react-router-dom';
import Header from 'src/components/App/Header';
import Sidebar from '../../components/App/Sidebar/index';
import GeneralLayoutWrapper from '../GeneralLayoutWrapper/index';
import ROUTE_CONSTANTS from 'src/Routes/constants/route-constants';
import { useUser } from 'src/context/global-context';
import ModalCreator from 'src/components/App/Modal/ModalCreator';
import { classNames } from 'primereact/utils';
import { MENU_VIEW } from 'src/enums/menu';
import { useMenu } from 'src/context/menu';

const DashboardLayoutContent: React.FC = () => {
    const user = useUser();
    const { menuView } = useMenu();

    if (!user) {
        return <Navigate to={ROUTE_CONSTANTS.AUTH.LOGIN.ABSOLUTE} />;
    }

    return (
        <main className={classNames(styles.mainContent, menuView === MENU_VIEW.MINIMIZE ? styles.minimizeMenu : '')}>
            <Sidebar />
            <div className={styles.bodyWrapper}>
                <Header />
                <div id="dashboardContent" className={styles.dashboardContent}>
                    <Outlet />
                </div>
                <ModalCreator />
            </div>
        </main>
    );
};

const DashboardLayout: React.FC = () => {
    return (
        <GeneralLayoutWrapper>
            <DashboardLayoutContent />
        </GeneralLayoutWrapper>
    );
};

export default DashboardLayout;
