import React, { Dispatch, SetStateAction } from 'react';
import DashboardTitle from 'src/components/App/DashboardTitle';
import Table from 'src/components/App/Table/index';
import Button from 'src/components/Kit/Button';
import { Helmet } from 'react-helmet-async';
import ROUTE_CONSTANTS from 'src/Routes/constants/route-constants';
import DashboardContentWrapper from 'src/layouts/DashboardContentWrapper';
import { financialInformationService } from 'src/api/services/financial-information-service';
import { IFinancialInformationSubCategoryResponse } from 'src/api/types/financial-information';
import { Column } from 'primereact/column';
import EditIcon from 'src/assets/Icons/EditIcon';
import DeleteIcon from 'src/assets/Icons/DeleteIcon';
import { getAxiosError } from 'src/utils/get-axios-error';
import { TOAST_STATUS } from 'src/constants/toast-status';
import { modalActions } from 'src/context/modals';
import { toast } from 'src/utils/toast';
import { MODAL_TYPES } from 'src/types/modals';
import { useCheckPermissionAccess } from 'src/hooks/useCheckPermissionAccess';
import { PERMISSION_TYPES } from 'src/enums/permissions';
import { cellRender } from './cellRender';
import { IDataTablePayload } from 'src/api/types/table';

const service = async (queryParams?: IDataTablePayload) => financialInformationService.getAllSubCategories(queryParams);
const List: React.FC = () => {
    const { checkPermissionAccess } = useCheckPermissionAccess();
    const handleRemove = (id: number, setTableData: Dispatch<SetStateAction<IFinancialInformationSubCategoryResponse[]>>) => {
        const handleRemoveItem = async () => {
            try {
                const deleteResult = await financialInformationService.deleteSubCategory(id);
                if (deleteResult.data.success) {
                    setTableData((tableData: IFinancialInformationSubCategoryResponse[]) =>
                        tableData.filter((item: IFinancialInformationSubCategoryResponse) => item.id !== id)
                    );
                    toast.fire({
                        icon: TOAST_STATUS.SUCCESS,
                        title: deleteResult.data.message,
                    });
                } else {
                    toast.fire({
                        icon: TOAST_STATUS.ERROR,
                        title: deleteResult.data.message,
                    });
                }
            } catch (err) {
                const error = getAxiosError(err);
                const message = error?.message || 'Server Error';
                toast.fire({
                    icon: TOAST_STATUS.ERROR,
                    title: message,
                });
            }
        };
        modalActions.addModal(MODAL_TYPES.CONFIRMATION_MODAL, {
            header: 'Delete Item',
            text: 'Are you sure to delete this Sub Category?',
            handleAction: handleRemoveItem,
        });
    };

    const suffixCols = (data: IFinancialInformationSubCategoryResponse[], setTableData: Dispatch<SetStateAction<IFinancialInformationSubCategoryResponse[]>>) => {
        return (
            <Column
                key="Action"
                header="Action"
                body={(rowData: IFinancialInformationSubCategoryResponse) => (
                    <>
                        {checkPermissionAccess(PERMISSION_TYPES.SETTING_FI_SUB_CATEGORY_EDIT) && (
                            <Button
                                link
                                color="secondary"
                                iconOnly
                                size="m"
                                to={ROUTE_CONSTANTS.SETTINGS.FINANCIAL_INFORMATION.SUB_CATEGORIES.UPDATE.EDIT_BY_SUB_CATEGORY_ID(rowData.id).ABSOLUTE}
                            >
                                <EditIcon />
                            </Button>
                        )}
                        {checkPermissionAccess(PERMISSION_TYPES.SETTING_FI_SUB_CATEGORY_DELETE) && (
                            <Button
                                color="secondary"
                                iconOnly
                                size="m"
                                disabled={!rowData.deletable}
                                onClick={() => (rowData.deletable ? handleRemove(rowData.id, setTableData) : undefined)}
                            >
                                <DeleteIcon />
                            </Button>
                        )}
                    </>
                )}
            />
        );
    };

    return (
        <>
            <Helmet>
                <title>Teaser | Financial Information Sub Category List</title>
            </Helmet>
            <DashboardTitle
                title={'Financial Information Sub Category List'}
                titleSuffix={
                    checkPermissionAccess(PERMISSION_TYPES.SETTING_FI_SUB_CATEGORY_CREATE) ? (
                        <Button
                            size="s"
                            link
                            color="primary"
                            to={ROUTE_CONSTANTS.SETTINGS.FINANCIAL_INFORMATION.SUB_CATEGORIES.ADD_NEW.ROOT.ABSOLUTE}
                        >
                            Add New Sub Category
                        </Button>
                    ) : (
                        <></>
                    )
                }
            />
            <DashboardContentWrapper>
                {checkPermissionAccess(PERMISSION_TYPES.SETTING_FI_SUB_CATEGORY_LIST_VIEW) && (
                    <Table getTableData={service} cellRender={cellRender} suffixCols={suffixCols} />
                )}
            </DashboardContentWrapper>
        </>
    );
};

export default List;
