import { SVGProps } from 'react';

const ParentCompanyIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M13.334 5.33333V4C13.334 2.89543 12.4386 2 11.334 2H3.33398C2.22941 2 1.33398 2.89543 1.33398 4V10C1.33398 11.1046 2.22941 12 3.33398 12H8.00065"
                stroke={props.stroke || 'currentColor' || '#888888'}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.33333 6.6665H5.66667C5.85076 6.6665 6 6.81574 6 6.99984V9.33317H4V6.99984C4 6.81574 4.14924 6.6665 4.33333 6.6665Z"
                stroke={props.stroke || 'currentColor' || '#888888'}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.33333 4H7.66667C7.85076 4 8 4.14924 8 4.33333V9.33333H6V4.33333C6 4.14924 6.14924 4 6.33333 4Z"
                stroke={props.stroke || 'currentColor' || '#888888'}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10 6.66683V5.66683C10 5.48273 9.85076 5.3335 9.66667 5.3335H8.33333C8.14924 5.3335 8 5.48273 8 5.66683V9.3335H8.66667"
                stroke={props.stroke || 'currentColor' || '#888888'}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.834 13.6652V13.3319C14.834 12.5955 14.237 11.9985 13.5007 11.9985H11.1673C10.4309 11.9985 9.83398 12.5955 9.83398 13.3319V13.6652C9.83398 13.8493 9.98322 13.9985 10.1673 13.9985H14.5007C14.6847 13.9985 14.834 13.8493 14.834 13.6652Z"
                stroke={props.stroke || 'currentColor' || '#888888'}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle cx="12.3333" cy="8.99984" r="1.33333" stroke={props.stroke || 'currentColor' || '#888888'} strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default ParentCompanyIcon;
