export const SidebarLogo = () => {
    return (
        <svg width="47" height="65" viewBox="0 0 47 65" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M35.2392 9.92969H11.7669C5.78384 9.92969 0.933594 14.7799 0.933594 20.763V44.2352C0.933594 50.2183 5.78384 55.0686 11.7669 55.0686H35.2392C41.2222 55.0686 46.0725 50.2183 46.0725 44.2352V20.763C46.0725 14.7799 41.2222 9.92969 35.2392 9.92969Z" fill="url(#paint0_linear_2685_32147)" />
            <path fillRule="evenodd" clipRule="evenodd" d="M29.1285 37.6484H16.5898C17.3247 38.8979 18.0821 40.0543 18.8405 41.116H27.1253C27.7927 39.9599 28.4605 38.804 29.1285 37.6484Z" fill="url(#paint1_linear_2685_32147)" />
            <path fillRule="evenodd" clipRule="evenodd" d="M30.4041 35.4428L32.4064 31.9744H13.8408V29.768H33.6776L35.7603 26.1569L11.7617 26.1523C12.6305 29.3686 13.8411 32.4826 15.3728 35.441L30.4041 35.4428Z" fill="url(#paint2_linear_2685_32147)" />
            <path fillRule="evenodd" clipRule="evenodd" d="M20.5234 43.3242C21.5421 44.5829 22.6251 45.7882 23.768 46.9353C24.4644 45.7316 25.1598 44.5279 25.8543 43.3242H20.5234Z" fill="url(#paint3_linear_2685_32147)" />
            <path fillRule="evenodd" clipRule="evenodd" d="M23.7669 46.9385C19.5444 38.5484 18.667 28.8676 21.3122 19.8551C18.7786 19.7883 16.3103 20.6639 14.3852 22.3125C13.2153 23.3589 12.3153 24.6724 11.7617 26.1412C13.8799 34.0021 18.0195 41.1714 23.7687 46.9357L23.7669 46.9385Z" fill="url(#paint4_linear_2685_32147)" />
            <defs>
                <linearGradient id="paint0_linear_2685_32147" x1="36.2322" y1="11.5095" x2="7.56901" y2="55.0686" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#E6E6E9" />
                    <stop offset="1" stopColor="#F4F4F6" />
                </linearGradient>
                <linearGradient id="paint1_linear_2685_32147" x1="36.8273" y1="27.9323" x2="35.3028" y2="43.1775" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#D01C7C" />
                    <stop offset="0.077" stopColor="#BC1C77" />
                    <stop offset="0.31" stopColor="#851C6B" />
                    <stop offset="0.527" stopColor="#5A1D61" />
                    <stop offset="0.721" stopColor="#3B1D5A" />
                    <stop offset="0.885" stopColor="#281D56" />
                    <stop offset="1" stopColor="#221E55" />
                </linearGradient>
                <linearGradient id="paint2_linear_2685_32147" x1="32.4005" y1="22.1388" x2="29.1721" y2="38.6152" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#D01C7C" />
                    <stop offset="0.077" stopColor="#BC1C77" />
                    <stop offset="0.31" stopColor="#851C6B" />
                    <stop offset="0.527" stopColor="#5A1D61" />
                    <stop offset="0.721" stopColor="#3B1D5A" />
                    <stop offset="0.885" stopColor="#281D56" />
                    <stop offset="1" stopColor="#221E55" />
                </linearGradient>
                <linearGradient id="paint3_linear_2685_32147" x1="39.7094" y1="31.7075" x2="28.9862" y2="49.5663" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#D01C7C" />
                    <stop offset="0.077" stopColor="#BC1C77" />
                    <stop offset="0.31" stopColor="#851C6B" />
                    <stop offset="0.527" stopColor="#5A1D61" />
                    <stop offset="0.721" stopColor="#3B1D5A" />
                    <stop offset="0.885" stopColor="#281D56" />
                    <stop offset="1" stopColor="#221E55" />
                </linearGradient>
                <linearGradient id="paint4_linear_2685_32147" x1="17.7652" y1="19.4994" x2="17.7652" y2="45.5029" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#D01C7C" />
                    <stop offset="1" stopColor="#221E55" />
                </linearGradient>
            </defs>
        </svg>
    );
};

