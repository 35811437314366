export function showFileSelector(): Promise<File | null> {
    return new Promise((resolve) => {
        const inputElement = document.createElement('input');
        inputElement.type = 'file';
        inputElement.style.display = 'none';

        // Listen for the change event when a file is selected
        inputElement.addEventListener('change', () => {
            const selectedFile = inputElement.files?.[0];

            // Resolve with the file name or null if no file is selected
            resolve(selectedFile ? selectedFile : null);

            // Clean up: remove the input element from the DOM
            document.body.removeChild(inputElement);
        });

        // Trigger a click on the input element to open the file dialog
        document.body.appendChild(inputElement);
        inputElement.click();
    });
}

export function formatFileSize(fileSizeInBytes: File['size']): string {
    const kilobyte: number = 1024;
    const megabyte: number = kilobyte * 1024;

    if (fileSizeInBytes < kilobyte) {
        return `${fileSizeInBytes} B`;
    } else if (fileSizeInBytes < megabyte) {
        const sizeInKB: string = (fileSizeInBytes / kilobyte).toFixed(2);
        return `${sizeInKB} KB`;
    } else {
        const sizeInMB: string = (fileSizeInBytes / megabyte).toFixed(2);
        return `${sizeInMB} MB`;
    }
}

export const fileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        if (FileReader && file) {
            let fr = new FileReader();
            fr.onload = function () {
                const image = fr.result as string;
                if (image) {
                    resolve(image);
                } else {
                    reject();
                }
            };
            fr.readAsDataURL(file);
        } else {
            reject();
        }
    });
};
