import { FC } from 'react';
import { FinancialDataProvider } from './context';
import FinancialAddUpdateMainComponent from './components/MainComponent';

const SelectFinancialDataWrapper: FC = () => {
    return (
        <FinancialDataProvider>
            <FinancialAddUpdateMainComponent />
        </FinancialDataProvider>
    );
};

export default SelectFinancialDataWrapper;
