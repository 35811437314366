import { FORM_INPUTS } from 'src/types/form';
import { IFormGeneratorSchemaType } from 'src/types/form-generator-schema-type';
import { parentCompanyFinancialLanguages } from '../../CompanyParentCompanyFinancial/AddUpdate/constants/parent-company-financial-languages-dropdown';
import { DATE_FORMAT } from 'src/pages/Company/enums/date-format';

export type CreateCompanyNewsSchemaFields = 'title' | 'date' | 'source_id' | 'source_url' | 'language' | 'media_id' | 'content';
export type CreateCompanyNewsSchemaType = Record<CreateCompanyNewsSchemaFields, IFormGeneratorSchemaType>;

export const createCompanyNewsSchema: CreateCompanyNewsSchemaType = {
    title: {
        placeholder: 'Title',
        type: FORM_INPUTS.TEXT,
        props: {
            required: true,
            maxLength: 120,
        },
    },
    date: {
        placeholder: 'Select Date',
        label: 'Date',
        type: FORM_INPUTS.DATE,
        props: {
            required: true,
            dateFormat: DATE_FORMAT.YEAR_FOUR_DIGITS_MONTH_DAY,
            maxDate: new Date(),
        },
    },
    source_id: {
        label: 'Source',
        placeholder: 'Select Source',
        type: FORM_INPUTS.SELECT,
        props: {
            required: true,
            disabled: true,
        },
    },
    source_url: {
        placeholder: 'Source URL',
        type: FORM_INPUTS.TEXT,
        props: {
            required: false,
        },
    },
    language: {
        placeholder: 'Select Language',
        label: 'Language',
        type: FORM_INPUTS.MULTI_SELECT,
        data: parentCompanyFinancialLanguages,
        props: {
            required: false,
            showClear: false,
            selectionLimit: 1,
            showSelectAll: false,
            panelHeaderTemplate: <></>,
        },
    },
    content: {
        placeholder: 'News Detail',
        type: FORM_INPUTS.EDITOR,
    },
    media_id: {
        placeholder: 'Document',
        type: FORM_INPUTS.FILE,
        props: {
            accept: 'application/pdf',
            auto: true,
            chooseLabel: 'Upload Document PDF file ...',
        },
    },
};
