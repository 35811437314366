import BasicInformationCompanyForm from './Steps/BasicInformation';
import ConfirmationCompanyForm from './Steps/Confirmation';
import ContactInformationCompanyForm from './Steps/ContactInformation';
import OtherInformationCompanyForm from './Steps/OtherInformation';
import { COMPANY_STEP_FORMS } from './enums/company-step-forms';

export const addCompanyStepsSchema = [
    {
        id: 0,
        title: COMPANY_STEP_FORMS.BASIC_INFORMATION,
        opened: false,
        disabled: false,
        hasError: false,
        errors: {},
        content: <BasicInformationCompanyForm />,
    },
    {
        id: 1,
        title: COMPANY_STEP_FORMS.OTHER_INFORMATION,
        opened: false,
        disabled: true,
        hasError: false,
        errors: {},
        content: <OtherInformationCompanyForm />,
    },
    {
        id: 2,
        title: COMPANY_STEP_FORMS.CONTACT_INFORMATION,
        opened: false,
        disabled: true,
        hasError: false,
        errors: {},
        content: <ContactInformationCompanyForm />,
    },
    {
        id: 3,
        title: COMPANY_STEP_FORMS.CONFIRMATION,
        opened: false,
        disabled: true,
        hasError: false,
        errors: {},
        content: <ConfirmationCompanyForm />,
    },
];
