import { useNavigate } from 'react-router-dom';
import Button from 'src/components/Kit/Button';
import style from './Forbidden.module.scss';

const Forbidden = () => {
    const navigate = useNavigate();

    return (
        <div className={style['wrapper']}>
            <span>Oops!</span>
            <h1>403</h1>
            <h3>You Don't have access</h3>
            <div className={style.wrapperButton}>
                <Button type="button" onClick={() => navigate(-1)}>
                    Go Back
                </Button>
            </div>
        </div>
    );
};
export default Forbidden;
