import { generateRandomNumber, UUIDv4 } from 'src/utils/uuid-generator';
import { IFinancialInformationRow } from './types';
import { IStateDropdownOptionType } from './AddUpdate/components/SelectFinancialData/components/StateManagement';
import { FINANCIAL_INFORMATION_STATUS, ITEM_TYPE_ENUM } from 'src/enums/company/financialInformation/financialItem';
import { RATIO_NUMBER_TYPE } from 'src/api/types/company';

export const ROW_COLOR_DICTIONARY: Record<ITEM_TYPE_ENUM, string> = {
    2: 'rgba(99, 197, 133, 0.13)',
    3: 'rgba(124, 179, 243, 0.10)',
    4: 'transparent',
    0: 'transparent',
    1: 'rgba(221, 177, 109, 0.08)',
};

export const FINANCIAL_INFORMATION_STATUS_COLORS: Record<FINANCIAL_INFORMATION_STATUS, string> = {
    1: '#FEBA08',
    2: '#63C585',
};

export const years = (): string[] => {
    const currentYear = new Date().getFullYear();
    const startYear = 1980; // You can change this if you want a different starting year
    const years = [];

    for (let year = startYear; year <= currentYear; year++) {
        years.push(String(year));
    }

    return years;
};


export const quarters: { label: string; value: string }[] = [
    {
        label: 'Quarter 1',
        value: '1',
    },
    {
        label: 'Quarter 2',
        value: '2',
    },
    {
        label: 'Quarter 3',
        value: '3',
    },
    {
        label: 'Quarter 4',
        value: '4',
    },
];

export const FINANCIAL_DATE_QUARTER_DICTIONARY: { [key: number]: string } = {
    1: 'Quarter 1',
    2: 'Quarter 2',
    3: 'Quarter 3',
    4: 'Quarter 4',
};

export const baseRowValueObject: IFinancialInformationRow = {
    itemId: generateRandomNumber(10, 100),
    type: undefined,
    value: null,
    name: undefined,
    calculated: false,
    uniqueId: UUIDv4(),
    notSaved: true,
    must_show_currency: true,
    number_type: RATIO_NUMBER_TYPE.CURRENCY
};

export const dropdownStatusOptions: IStateDropdownOptionType[] = [
    // {
    //     label: 'Not Approved',
    //     value: FINANCIAL_INFORMATION_FORM_STATUS.NOT_APPROVED
    // },
    {
        label: 'Approved',
        value: FINANCIAL_INFORMATION_STATUS.APPROVED,
    },
];

export const financialInformationLegitFractions = 5;
