import { FC, useEffect } from 'react';
import Button from 'src/components/Kit/Button';
import FinancialItemRow from '../FinancialItemRow';
import Tabs from '../Tabs';
import styles from './MainComponent.module.scss';
import { classNames } from 'primereact/utils';
import { IFinancialInformationTypes } from 'src/api/types/company';
import InitLoading from 'src/components/App/Loading/InitLoading';
import { useFinancialDataContext } from '../../hooks/useFinancialDataContext';
import { NEW_ITEM_POSITION } from '../../../../../enums';
import { IFinancialInformationRow } from '../../../../../types';
import PopupAlert from 'src/components/Kit/PopupAlert';
import MainComponentFooter from '../MainComponentFooter';
import { useFinancial } from '../../../../../../../../../context/companies';
import InitialMode from '../InitialMode';

const FinancialAddUpdateMainComponent: FC = () => {
    const { rowsIsLoading, financialDates, datesIsLoading, companyFinancialTypes } = useFinancial((global) => ({
        rowsIsLoading: global.loadings.financialRowsByType || global.loadings.financialRowsByDate,
        datesIsLoading: global.loadings.financialDates,
        financialDates: global.financialDates,
        companyFinancialTypes: global.financialTypes,
    }));
    const { sidePanelState, financialDataRows, financialDataDisabledAlert, handleAddNewRow, handleChangeAlertState } = useFinancialDataContext();

    const RenderFinancialDataRows = () => {
        if (rowsIsLoading || datesIsLoading) return <InitLoading />;
        if (!financialDates || !financialDates.length)
            return (
                <div className={styles.initialBody}>
                    <InitialMode />
                </div>
            );
        if (!financialDataRows.length) {
            return (
                <div className={styles.addFirstRowActionWrapper}>
                    <Button
                        onClick={() => handleAddNewRow(0, NEW_ITEM_POSITION.AFTER)}
                        color="tertiary"
                        size="s"
                        customStyle={{ display: 'flex', alignItems: 'center' }}
                    >
                        Add First Financial Data
                    </Button>
                </div>
            );
        }
        return (
            <div>
                {financialDataRows.map((row: IFinancialInformationRow, index: number) => (
                    <FinancialItemRow key={row.uniqueId} row={{ ...row, rowOrder: index }} />
                ))}
            </div>
        );
    };

    return (
        <>
            <div className={classNames(styles.mainComponentWrapper, (sidePanelState.show || financialDataDisabledAlert.show) && styles.blur)}>
                <div className={styles.financialDataWrapper}>
                    <FinancialTypes companyFinancialTypes={companyFinancialTypes} />
                    <div
                        className={styles.body}
                        style={{
                            marginTop: companyFinancialTypes?.length ? 40 : 0,
                        }}
                    >
                        <RenderFinancialDataRows />
                    </div>
                </div>
            </div>
            <MainComponentFooter />
            {financialDataDisabledAlert.show && financialDataDisabledAlert.type && (
                <PopupAlert
                    customStyle={{ width: '97%', marginLeft: '20px' }}
                    title="Access Alert"
                    type={financialDataDisabledAlert.type}
                    onButtonClick={(preventShownAgain: boolean) => handleChangeAlertState(preventShownAgain)}
                    message={
                        <>
                            {financialDataDisabledAlert.messages.map((text: string, index: number) => (
                                <p key={index}>{text}</p>
                            ))}
                        </>
                    }
                />
            )}
        </>
    );
};

const FinancialTypes = ({ companyFinancialTypes }: { companyFinancialTypes: IFinancialInformationTypes[] }) => {
    return (
        <>
            {companyFinancialTypes && (
                <div className={styles.headerTabsWrapper}>
                    {companyFinancialTypes && companyFinancialTypes.map((tab: IFinancialInformationTypes) => <Tabs key={tab.id} tab={tab} />)}
                </div>
            )}
        </>
    );
};

export default FinancialAddUpdateMainComponent;
