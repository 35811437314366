import { getMaxError, getMinError } from 'src/utils/get-yup-errors';
import { string, object, number } from 'yup';

export const dividendsValidationSchema = object().shape({
    financial_year: string().required('Financial Year is required'),
    se_template: string().required('SE Template is required'),
    cash_dividends: number()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .min(0, 'Cash Dividends minimum is 0')
        .max(100, 'Cash Dividends maximum is 100')
        .nullable(),
    bonus_shares: number()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .min(0, 'Bonus Shares minimum is 0')
        .max(100, 'Bonus Shares maximum is 100')
        .nullable(),
});
