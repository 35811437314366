/* eslint-disable jsx-a11y/alt-text */
import React, { useRef, useState } from 'react';
import style from './Avatar.module.scss';
import { toast } from 'src/utils/toast';
import { ICropper } from 'src/types/cropper';
import AddLogoIcon from 'src/assets/Icons/AddLogoIcon';
import { modalActions } from '../../../context/modals';
import { MODAL_TYPES } from '../../../types/modals';
import { fileToBase64 } from '../../../utils/file';
import { FILE_UPLOAD_TYPE } from '../FileDialog/constants';
import { IFileStoreResponse } from '../../../api/types/file';

interface IAvatarProps {
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    customStyle?: any;
    isUploader?: boolean;
    url?: string;
    onAvatarChanged?: (data: IFileStoreResponse['id'], url?: string) => void;
    cropper?: ICropper | undefined;
    acceptedRatio?: number;
}
const Avatar: React.FC<IAvatarProps> = ({ size, customStyle, isUploader, url, cropper, onAvatarChanged, acceptedRatio }) => {
    const fileUploadRef = useRef<HTMLInputElement>(null);
    const [imageBase64, setImageBase64] = useState<string>(url || '');

    const onAvatarClicked = () => {
        if (!isUploader) return;

        modalActions.addModal(MODAL_TYPES.FILE_MODAL, {
            header: 'Images Library',
            fileType: FILE_UPLOAD_TYPE.IMAGE,
            onFileSelected: (token, file, fileStorage) => {
                if (fileStorage) {
                    setImageBase64(fileStorage.url);
                    if (onAvatarChanged) onAvatarChanged(token.id, fileStorage.url);
                    return;
                }
                if (file) {
                    fileToBase64(file)
                        .then((base64Image) => {
                            setImageBase64(base64Image);
                            if (onAvatarChanged) onAvatarChanged(token.id, base64Image);
                        })
                        .catch(() => {
                            toast.fire({
                                icon: 'error',
                                title: 'Error in Converting image !',
                            });
                        });
                }
            },
        });
    };

    const avatarImageSource = () => {
        if (imageBase64) return imageBase64;
        else if (url) return url;
        else return '';
    };

    const uploaderElement = () => {
        if (!isUploader) return null;
        return (
            <div className={style.uploaderIconWrapper}>
                <i className="ts-attach"></i>
            </div>
        );
    };

    return (
        <div
            className={style.avatarWrapper}
            style={{
                width: customStyle?.width || '150px',
                height: customStyle?.height || '150px',
                cursor: isUploader ? 'pointer' : 'unset',
            }}
            onClick={onAvatarClicked}
        >
            {avatarImageSource() ? (
                <img
                    className={style.avatarImage}
                    src={avatarImageSource()}
                    style={{
                        width: customStyle?.width || '150px',
                        height: customStyle?.height || '150px',
                    }}
                />
            ) : (
                <AddLogoIcon />
            )}

            {uploaderElement()}
            <input type="file" ref={fileUploadRef} accept="image/png, image/jpeg" style={{ display: 'none' }} />
        </div>
    );
};
export default Avatar;
