import { timestampToDateFormat } from 'src/utils/timestamp-to-date-format';
import { truncateString } from 'src/utils/string-truncator';
import { Tooltip } from 'primereact/tooltip';
import { IGetNewsSourceResponse } from 'src/api/types/news-source';

export const newsSourceCellRender = {
    website: (data: IGetNewsSourceResponse) =>
        data.website ? (
            <>
                <a href={data.website} target="_blank" rel="noreferrer" id={data.website.length > 20 ? `news-source-link-${data.id}` : ''}>
                    {truncateString(data.website, 20)}
                </a>
                <Tooltip
                    target={`#news-source-link-${data.id}`}
                    hideDelay={100}
                    content={data.website}
                    position="top"
                    disabled={data.website.length <= 20}
                    showDelay={250}
                />
            </>
        ) : (
            <></>
        ),
    updated_at: (data: IGetNewsSourceResponse) => <span>{timestampToDateFormat(data.updated_at)}</span>,
    created_at: (data: IGetNewsSourceResponse) => <span>{timestampToDateFormat(data.created_at)}</span>,
    photo: (data: IGetNewsSourceResponse) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {data.photo ? (
                <img width={32} height={32} style={{ borderRadius: '8px' }} src={data.photo} alt={data.name} />
            ) : (
                <span
                    style={{
                        fontSize: '9px',
                        color: '#666',
                        width: '32px',
                        height: '32px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                        lineHeight: '16px',
                        background: '#f5f5f5',
                        borderRadius: '8px',
                        whiteSpace: 'pre-wrap',
                    }}
                >
                    No Photo
                </span>
            )}
        </div>
    ),
};
