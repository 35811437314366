import { IFinancialInformationItemsResponse } from 'src/api/types/financial-information';
import { timestampToDateFormat } from 'src/utils/timestamp-to-date-format';
import ROUTE_CONSTANTS from 'src/Routes/constants/route-constants';
import { generateQueryString } from 'src/utils/urlUtils';
import { Tooltip } from 'primereact/tooltip';
import { COMPANY_TYPE_ROUTE } from 'src/pages/Company/Add/enums/company-type-route';
import style from './FinancialInformationItemsList.module.scss';

export const itemsCellRender = {
    category_name: (data: IFinancialInformationItemsResponse) => <span>{data.category_name ?? '-'}</span>,
    sub_category_name: (data: IFinancialInformationItemsResponse) => <span>{data.sub_category_name ?? '-'}</span>,
    updated_at: (data: IFinancialInformationItemsResponse) => <span>{timestampToDateFormat(data.updated_at)}</span>,
    created_at: (data: IFinancialInformationItemsResponse) => <span>{timestampToDateFormat(data.created_at)}</span>,
    number_of_used: (data: IFinancialInformationItemsResponse) => {
        if (data.number_of_used > 0) {
            return (
                <div className={style.numberOfUsed}>
                    <span>{data.number_of_used}</span>
                    <div className={style.tooltip}>
                        {data.private_number_of_used > 0 && (
                            <div>
                                Private:
                                <a
                                    href={
                                        ROUTE_CONSTANTS.COMPANIES.TYPE.LIST_BY_COMPANY_TYPE(COMPANY_TYPE_ROUTE.PRIVATE).ABSOLUTE +
                                        '?' +
                                        generateQueryString({ financial_information_item_id: String(data.id) })
                                    }
                                    target="_blank"
                                    rel="noreferrer"
                                    className={`number-of-used-${data.id}`}
                                >
                                    {data.private_number_of_used}
                                </a>
                            </div>
                        )}
                        {data.public_number_of_used > 0 && (
                            <div>
                                Public:
                                <a
                                    href={
                                        ROUTE_CONSTANTS.COMPANIES.TYPE.LIST_BY_COMPANY_TYPE(COMPANY_TYPE_ROUTE.PUBLIC).ABSOLUTE +
                                        '?' +
                                        generateQueryString({ financial_information_item_id: String(data.id) })
                                    }
                                    rel="noreferrer"
                                    target="_blank"
                                    className={`number-of-used-${data.id}`}
                                >
                                    {data.public_number_of_used}
                                </a>
                            </div>
                        )}
                    </div>
                </div>
            );
        } else {
            return <span>-</span>;
        }
    },
};
