import Item from '../Components/Item.js';
import TokenParser from '../Tokenizers/TokenParser.js';
import Statement from '../Components/Statement.js';
import ExpressionTokenizer from '../Tokenizers/ExpressionTokenizer.js';

export default class ExpressionToArrayParser {
    constructor() {
        this.tokenizer = new ExpressionTokenizer();
    }

    static init() {
        return new this();
    }

    parseMathStatement(rawTokens, hasParentheses = false, asFormula = true) {
        const normalizedTokens = new TokenParser().normalizingRawTokens(rawTokens);
        const [values, operations] = this.parseValuesAndOperations(normalizedTokens, asFormula);

        if (values.length === 1 && operations.length === 0) {
            return values[0];
        }

        return this.generateStatement(hasParentheses, values, operations);
    }

    parseValuesAndOperations(normalizedTokens, asFormula) {
        const values = this.extractValues(normalizedTokens, asFormula);

        const operations = this.extractOperations(normalizedTokens);

        return [values, operations];
    }

    extractOperations(valuesAndOperations) {
        const operatorMapping = {
            '+': 'plus',
            '-': 'minus',
            '*': 'times',
            '/': 'division',
        };

        return valuesAndOperations
            .filter((value) => typeof value != 'object' && ['+', '-', '*', '/'].includes(value))
            .map((operator) => operatorMapping[operator]);
    }

    extractValues(tokens, asFormula) {
        let values = [];
        for (let token of tokens) {
            if (typeof token == 'object') {
                values.push(this.parseMathStatement(token['tokens'], true, asFormula));
            } else if (!['+', '-', '*', '/', '(', ')'].includes(token)) {
                if (this.isValidJson(token)) {
                    token = JSON.parse(token);
                }

                values.push(this.createItemComponent(token, asFormula));
            }
        }
        return values;
    }

    isValidJson(jsonString) {
        try {
            JSON.parse(jsonString);
            return true;
        } catch (error) {
            return false;
        }
    }

    createItemComponent(inputValue, asFormula) {
        let name = null;
        let value = null;
        let meta = null;

        let isObjectToken = typeof inputValue == 'object';

        // for object tokens like { "name":"Total Asset","value":"15000000","meta":{"id":1,"type":"Total"}}
        if (isObjectToken) {
            // TODO: number_type removed from payload api
            const { number_type, ...metaValue } = inputValue.meta;
            name = inputValue.name ?? null;
            value = inputValue.value ?? null;
            meta = metaValue ?? null;
            // hasAbs = inputValue.has_abs ?? false;
        }

        // for plain tokens like (A, B, C)
        if (!isObjectToken) {
            if (asFormula) name = inputValue;
            else value = !isNaN(inputValue) ? parseFloat(inputValue) : inputValue;
        }

        // TODO: we must handle abs
        return Item.init(name, value, meta).toArray();
    }

    getRegexForExtractingTokens(asFormula) {
        const formulaRegex = /\{([^}]+)\}|(?:\b(?:-?\d+(\.\d+)?|[a-zA-Z]+(?: [a-zA-Z]+)*)\b|\(|\)|[+\/*]|(?:^| )-\d*\.?\d+\b|-)/g;

        const numberRegex = /\d+(\.\d+)?|\(|\)|[+\-\/.*]/g;

        return asFormula ? formulaRegex : numberRegex;
    }

    parseMathExpression(expression, asFormula = true) {
        const tokens = this.tokenizer.tokenize(expression, asFormula);
        return this.parseMathStatement(tokens);
    }

    removeConsecutiveSpaces(expression) {
        if (expression) {
            return expression.replace(/\s+/g, ' ');
        }
    }

    generateStatement(hasParentheses, values, operations) {
        const statementArray = {
            name: null,
            has_parentheses: hasParentheses,
            values: values,
            operations: operations,
        };

        let statementObject = Statement.fillFromArray(statementArray);

        return statementObject.toArray();
    }
}
