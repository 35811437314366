import { Checkbox } from 'primereact/checkbox';
import React from 'react';
import styles from './FileDialog.module.scss';
import { classNames } from 'primereact/utils';
import InitLoading from '../../App/Loading/InitLoading';
import { IFileStoreResponse } from '../../../api/types/file';
import { FILE_UPLOAD_TYPE } from './constants';
import pdfIcon from '../../../assets/Images/pdf-icon.svg';
import { truncateString } from '../../../utils/string-truncator';
import { Tooltip } from 'primereact/tooltip';
import { useCheckPermissionAccess } from '../../../hooks/useCheckPermissionAccess';
import { PERMISSION_TYPES } from '../../../enums/permissions';

type Props = {
    isLoading: boolean;
    files: IFileStoreResponse[];
    selectedFileId?: IFileStoreResponse['id'];
    selectedFileUUId?: IFileStoreResponse['uuid'];
    setSelectedFileId: (fileId: IFileStoreResponse['id']) => void;
    setSelectedFileUUId: (fileUUId: IFileStoreResponse['uuid']) => void;
    fileType: FILE_UPLOAD_TYPE;
};
export const FileItems = ({ setSelectedFileId, setSelectedFileUUId, selectedFileId, selectedFileUUId, files, isLoading, fileType }: Props) => {
    const { checkPermissionAccess } = useCheckPermissionAccess();
    const FileRow = (props: { isSelected: boolean; dontShowBorder?: boolean; file: IFileStoreResponse }) => {
        return (
            <div
                className={styles.fileRow}
                onClick={() => {
                    setSelectedFileId(props.file.id === selectedFileId ? 0 : props.file.id);
                    setSelectedFileUUId(props.file.uuid === selectedFileUUId ? 0 : props.file.uuid);
                }}
            >
                <Checkbox checked={props.isSelected} className={styles.fileRowCheckbox} />

                <div className={classNames(styles.fileRowInner, !props.dontShowBorder && styles.fileRowInnerBordered)}>
                    {fileType === FILE_UPLOAD_TYPE.DOCUMENT && (
                        <div className={styles.fileRowIcon}>
                            <img src={pdfIcon} alt="pdf" width={32} height={32} />
                        </div>
                    )}
                    {fileType === FILE_UPLOAD_TYPE.IMAGE && (
                        <div className={styles.fileRowImage}>
                            <img src={props.file.url} alt={props.file.file_name} width={68} height={68} />
                        </div>
                    )}
                    <div className={styles.fileRowTitle}>
                        <span className={classNames(styles.fileRowTitleHeading, props.file.file_name.length > 64 && `dialog-name-${props.file.id}`)}>
                            {truncateString(props.file.file_name, 64)}
                        </span>
                        {props.file.file_name.length > 64 && (
                            <Tooltip target={`.dialog-name-${props.file.id}`} hideDelay={100} content={props.file.file_name} position="mouse" showDelay={250} />
                        )}
                        {props.file.description ? (
                            <>
                                <span
                                    className={classNames(
                                        styles.fileRowTitleDesc,
                                        props.file.description?.length > 45 && `dialog-description-${props.file.id}`
                                    )}
                                >
                                    {truncateString(props.file.description, 45)}
                                </span>
                                {props.file.description.length > 45 && (
                                    <Tooltip
                                        target={`.dialog-description-${props.file.id}`}
                                        hideDelay={100}
                                        content={props.file.description}
                                        position="mouse"
                                        showDelay={250}
                                    />
                                )}
                            </>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className={styles.filesWrapper}>
            {checkPermissionAccess(PERMISSION_TYPES.SETTING_MEDIA_LIBRARY_LIST_VIEW) && (
                <>
                    {isLoading ? (
                        <InitLoading height={'330px'} />
                    ) : (
                        <>
                            {files?.map((file, index) => (
                                <FileRow key={file.id} file={file} isSelected={file.id === selectedFileId} dontShowBorder={index === 3} />
                            ))}
                        </>
                    )}
                </>
            )}
        </div>
    );
};
