import { urlRegex } from 'src/constants/regex';
import { getLengthError, getRequireError } from 'src/utils/get-yup-errors';
import { string, object } from 'yup';

export const otherInformationValidationSchema = object().shape({
    type: string().required(getRequireError('Type')),
    legal_structure_id: string().required(getRequireError('Legal Structure')).nullable(),
    listing_status: string().required(getRequireError('Listing Status')),
    stock_exchange_id: string().when('type', {
        is: 'Public',
        then: string().required(getRequireError('Stock Exchange')).nullable(),
    }).nullable(),
    ISIN: string().when('type', {
        is: 'Public',
        then: string().required(getRequireError('ISIN')).length(12, getLengthError(12, 'ISIN')).nullable(),
    }).nullable(),
    issued_shared: string().when('type', {
        is: 'Public',
        then: string().required(getRequireError('Issued Shared')).nullable(),
    }).nullable(),


    registration_website: string().when({
        is: (url: string) => url && url.length > 0,
        then: string().matches(urlRegex, 'Enter correct url!'),
    }),
    license_website: string().when({
        is: (url: string) => url && url.length > 0,
        then: string().matches(urlRegex, 'Enter correct url!'),
    }),
});
