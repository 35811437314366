import { API_ROUTES } from 'src/constants/api-routes';
import { AxiosInstance } from '../index';
import { IAxiosResponse } from '../types/axios-response';
import { IDataTablePayload, IDataTableResponse } from 'src/api/types/table';
import { ICreateShareHolderRequest, IShareHolderResponse, IShareHolderShowResponse } from '../types/share-holders';
import { COMPANY_TYPE_ROUTE } from 'src/pages/Company/Add/enums/company-type-route';
import { companyTypeParam } from 'src/constants/company/company-type-param';

export const shareHolderService = (companyTypeRoute: COMPANY_TYPE_ROUTE) => {
    const companyType = companyTypeParam[companyTypeRoute];
    function getRouteWithCompanyType(route: string) {
        const typeRoute = `/companies/${companyType}`;
        return route.replace('/companies', typeRoute);
    }

    return {
        getAllShareHolders(companyId: number, params?: IDataTablePayload) {
            return AxiosInstance.get<IDataTableResponse<IShareHolderResponse>>(
                getRouteWithCompanyType(API_ROUTES.SHARE_HOLDER.GET_ALL_SHARE_HOLDERS(companyId)),
                { params }
            );
        },
        create(companyId: number, params: ICreateShareHolderRequest) {
            return AxiosInstance.post<IAxiosResponse>(getRouteWithCompanyType(API_ROUTES.SHARE_HOLDER.ADD_NEW(companyId)), params);
        },
        getOne(companyId: number, id: number) {
            return AxiosInstance.get<IAxiosResponse<IShareHolderShowResponse>>(getRouteWithCompanyType(API_ROUTES.SHARE_HOLDER.GET_ONE(companyId, id)));
        },
        update(companyId: number, shareHolderId: string, params: Pick<IShareHolderResponse, 'transactions'>) {
            return AxiosInstance.put<IAxiosResponse>(getRouteWithCompanyType(API_ROUTES.SHARE_HOLDER.UPDATE(companyId, shareHolderId)), params);
        },
        delete(companyId: number, id: number) {
            return AxiosInstance.delete<IAxiosResponse>(getRouteWithCompanyType(API_ROUTES.SHARE_HOLDER.DELETE(companyId, id)));
        },
    };
};
