import { Navigate, Outlet, useParams } from "react-router-dom";
import ROUTE_CONSTANTS from "src/Routes/constants/route-constants";
import { useCheckPermissionAccess } from "src/hooks/useCheckPermissionAccess";
import { COMPANY_TYPE_ROUTE } from "src/pages/Company/Add/enums/company-type-route";
import { IParams } from "src/types/params";
import { checkCompanyType } from "src/utils/check-company-type";

interface IProtectedRouteCompaniesProps {
    permissions: string[];
    children: JSX.Element;
    redirectPath?: string;
}

const ProtectedRouteCompanies:React.FC<IProtectedRouteCompaniesProps> = ({ 
    permissions,
    children,
    redirectPath = ROUTE_CONSTANTS.FORBIDDEN.ABSOLUTE 
}) => {
    const { companyType } = useParams<IParams>();
    const { checkPermissionAccess } = useCheckPermissionAccess();
    
    if(permissions.length === 0) {
        return children ? children : <Outlet/>;
    }

    let allPermissionsAreValid = permissions.every(permission => {
        return checkPermissionAccess(checkCompanyType(permission, companyType as COMPANY_TYPE_ROUTE));
    });

    if(!allPermissionsAreValid) {
        return <Navigate to={redirectPath} replace/>;
    }

    return children ? children : <Outlet/>;
}

export default ProtectedRouteCompanies;