import { Dropdown } from 'primereact/dropdown';
import { ISelectBox } from 'src/api/types/base-types';
import { filterService } from 'src/constants/table';
import React, { useEffect, useRef, useState } from 'react';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { useDebounce } from 'src/hooks/useDebounce';
import { getAxiosError } from 'src/utils/get-axios-error';
import { IDataTableResponse, ITableBaseFilterData } from 'src/api/types/table';
import { FILTER_TYPES } from 'src/enums/table';
import style from './SearchableDropdown.module.scss';

const SearchableDropdown: React.FC<Omit<ITableBaseFilterData<FILTER_TYPES.SELECT>, 'type'>> = ({ defaultValue, onChange, label, name, config }) => {
    const { multiple, reference, searchable, params, data, dropdownProps } = config;
    // @TODO move it to config from props
    const lazy = name === 'country_id';
    const showAll = { label: `All ${label}`, value: '' };
    const adaptedInitData = data ? data.map(({ label, value }) => ({ label, value: String(value) })) : [];

    const [loading, setLoading] = useState(false);
    const [dataOptions, setDataOptions] = useState<ISelectBox[]>(adaptedInitData.length ? [showAll, ...adaptedInitData] : []);
    const [filterValue, setFilterValue] = useState<string>('');

    const lazyResponseMeta = useRef<IDataTableResponse<unknown>['meta']['pagination']>();

    const fetchData = async (params?: { [key: string]: number | number[] | string | string[] }, lazy?: boolean) => {
        try {
            if (reference) {
                setLoading(true);
                const response = await filterService[reference](params);
                if (response.data.success) {
                    const adaptedData = response.data.data.map(({ label, value }: { label: string; value: string | number }) => ({
                        label,
                        value: String(value),
                    }));
                    lazyResponseMeta.current = (response.data as IDataTableResponse<unknown>)
                        .meta as unknown as IDataTableResponse<unknown>['meta']['pagination'];
                    setDataOptions([...(lazy ? [...dataOptions, ...adaptedData] : [showAll, ...adaptedData])]);
                } else {
                    console.error(response.data.message);
                }
            }
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            console.error(message);
        } finally {
            setLoading(false);
        }
    };

    const fetchDataDebounce = useDebounce(fetchData);
    useEffect(() => {
        let initialSearchingParams = params;
        if (lazy && params?.filter) {
            initialSearchingParams = { selected_items: params.filter as string };
        }
        setFilterValue('');
        !dropdownProps?.disabled && fetchData(initialSearchingParams);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [config?.params, dropdownProps?.disabled]);

    const filterTemplate = () => (
        <div className={classNames(style.formFieldSearch, loading ? style.loading : '')}>
            <InputText
                disabled={loading}
                value={filterValue}
                placeholder={`Search in ${label}`}
                onChange={(e) => {
                    setFilterValue(e.target.value);
                    fetchDataDebounce({ ...params, text: e.target.value });
                }}
            />
        </div>
    );

    const handleOnLazy = (e: React.UIEvent<HTMLElement>) => {
        const target = e.target as HTMLElement;
        const scrollTop = target.scrollTop;
        const scrollHeight = target.scrollHeight;
        if (lazy && lazyResponseMeta.current) {
            if (lazyResponseMeta.current.last_page === lazyResponseMeta.current.current_page) return;
        }
        if (scrollTop > scrollHeight - 250)
            fetchDataDebounce(
                {
                    ...params,
                    page: (lazyResponseMeta.current?.current_page || 0) + 1,
                },
                true
            );
    };

    return (
        <Dropdown
            optionLabel="label"
            optionValue="value"
            options={dataOptions}
            disabled={loading}
            name={name}
            id={name}
            placeholder={`Select a ${label}`}
            emptyMessage={loading ? 'Fetching Data ...' : 'No results found'}
            value={defaultValue}
            multiple={multiple}
            filter={searchable}
            filterTemplate={searchable ? filterTemplate : undefined}
            onChange={onChange}
            virtualScrollerOptions={
                lazy
                    ? {
                          itemSize: 40,
                          onScroll: handleOnLazy,
                      }
                    : undefined
            }
            {...dropdownProps}
        />
    );
};

export default SearchableDropdown;
