import { FORM_INPUTS } from 'src/types/form';
import { IFormGeneratorSchemaType } from 'src/types/form-generator-schema-type';

export type ContactInformationSchemaFields =
        'phone' 
    |   'email'
    |   'website'
    |   'twitter'
    |   'instagram'
    |   'linkedin'
    |   'youtube'
    |   'address'
    |   'arabic_address'
    |   'latitude'
    |   'longitude'

export type ContactInformationSchemaType = Record<ContactInformationSchemaFields, IFormGeneratorSchemaType>;
export const contactInformationSchema: ContactInformationSchemaType = {
    phone: {
        label: 'Phone',
        placeholder: 'Company phone number',
        className: 'lg:col-6',
        type: FORM_INPUTS.TEXT,
    },
    email: {
        label: 'Email',
        placeholder: 'Enter company email',
        type: FORM_INPUTS.TEXT,
        className: 'lg:col-6',
    },
    website: {
        label: 'Website',
        placeholder: 'Add your website address',
        type: FORM_INPUTS.TEXT,
        className: 'lg:col-6',
    },
    twitter: {
        label: 'Twitter Address',
        placeholder: 'www.twitter.com/',
        type: FORM_INPUTS.TEXT,
        className: 'lg:col-6',
    },
    instagram: {
        label: 'Instagram Address',
        placeholder: 'www.instagram.com/',
        type: FORM_INPUTS.TEXT,
        className: 'lg:col-6',
    },
    linkedin: {
        label: 'Linkedin Address',
        placeholder: 'www.linkedin.com/',
        type: FORM_INPUTS.TEXT,
        className: 'lg:col-6',
    },
    youtube: {
        label: 'Youtube Address',
        placeholder: 'www.youtube.com/',
        type: FORM_INPUTS.TEXT,
        className: 'lg:col-6',
    },
    address: {
        label: 'Address Detail',
        placeholder: 'Write address',
        type: FORM_INPUTS.TEXTAREA,
    },
    arabic_address: {
        label: 'Arabic Address Detail',
        placeholder: 'Write Arabic address',
        type: FORM_INPUTS.TEXTAREA,
    },
    latitude: {
        label: 'Latitude',
        placeholder: 'Add Latitude',
        type: FORM_INPUTS.TEXT,
        className: 'lg:col-6',
    },
    longitude: {
        label: 'Longitude',
        placeholder: 'Add Longitude',
        type: FORM_INPUTS.TEXT,
        className: 'lg:col-6',
    },
};
