import React, { useEffect, useState } from 'react';
import styles from './StateManagement.module.scss';
import { Dropdown } from 'primereact/dropdown';
import { useFinancialDataContext } from '../../hooks/useFinancialDataContext';
import { dropdownStatusOptions, FINANCIAL_INFORMATION_STATUS_COLORS } from '../../../../../constants';
import { useCheckPermissionAccess } from 'src/hooks/useCheckPermissionAccess';
import { companyService } from 'src/api/services/company';
import { ProgressSpinner } from 'primereact/progressspinner';
import { modalActions } from 'src/context/modals';
import { MODAL_TYPES } from 'src/types/modals';
import { FINANCIAL_INFORMATION_STATUS } from 'src/enums/company/financialInformation/financialItem';
import { toast } from 'src/utils/toast';
import { TOAST_STATUS } from 'src/constants/toast-status';
import { PERMISSION_TYPES } from 'src/enums/permissions';
import { financialActions, useFinancial } from '../../../../../../../../../context/companies';
import { useParams } from 'react-router-dom';
import { IParams } from 'src/types/params';
import { COMPANY_TYPE_ROUTE } from 'src/pages/Company/Add/enums/company-type-route';
import { checkCompanyType } from 'src/utils/check-company-type';

export interface IStateDropdownOptionType {
    label: string;
    value: FINANCIAL_INFORMATION_STATUS;
}

const StateManagement: React.FC = () => {
    const { companyType } = useParams<IParams>();
    const { financialInformationFormStatus, setFinancialInformationFormStatus, handleCheckSomeDataNotSaved } = useFinancialDataContext();
    const { activeDate, financialDates } = useFinancial((global) => ({
        activeDate: global.financialActiveDate,
        financialDates: global.financialDates,
    }));
    const { checkPermissionAccess } = useCheckPermissionAccess();

    const [availableActions] = useState<IStateDropdownOptionType[]>(dropdownStatusOptions);
    const [changeStatusLoading, setChangeStatusLoading] = useState<boolean>(false);
    const [activeStatus, setActiveStatus] = useState<FINANCIAL_INFORMATION_STATUS | undefined>(financialInformationFormStatus?.status);

    const handleConfirmChangeState = (value: FINANCIAL_INFORMATION_STATUS) => {
        modalActions.addModal(MODAL_TYPES.CONFIRMATION_MODAL, {
            header: 'Change State Confirm',
            text: 'Are you sure to change the state?',
            actionButtonColor: 'primary',
            handleAction: () => handleChangeState(value),
        });
    };

    const handleChangeState = async (value: FINANCIAL_INFORMATION_STATUS) => {
        if (!checkPermissionAccess(checkCompanyType(PERMISSION_TYPES.COMPANY_FI_APPROVE, companyType as COMPANY_TYPE_ROUTE))) return;
        if (!activeStatus || !financialInformationFormStatus?.id || !financialDates?.length) {
            // Hint: sometimes we are creating new financial data,
            // when giving from api it has financialInformationFormStatus in response,
            // but if we are now creating new financial data after clicking apply changes,
            // new one will create but we still has not status the id of financial data as been created,
            // so we can not approve it as we still have not the id of this financial data.
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: 'Please apply your changes first!',
            });
            return;
        }
        if (handleCheckSomeDataNotSaved()) {
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: 'Please apply your changes first!',
            });
            return;
        }

        const tempBody: { financialInformationId: number; status: number } = {
            financialInformationId: financialInformationFormStatus?.id,
            status: FINANCIAL_INFORMATION_STATUS.APPROVED,
        };

        try {
            setChangeStatusLoading(true);
            await companyService(companyType as COMPANY_TYPE_ROUTE).changeFinancialInformationStatus(tempBody);
            setChangeStatusLoading(false);
            setActiveStatus(value);
            if (activeDate) {
                const targetDate = financialDates.map((date) => {
                    if (date.quarter === activeDate?.quarter && date.year === activeDate.year) {
                        // TODO: Make this logic everywhere used as a function, then we should change it to ID when backend implement that.
                        return {
                            ...date,
                            status: FINANCIAL_INFORMATION_STATUS.APPROVED,
                        };
                    } else return date;
                });
                financialActions.addFinancialDates(targetDate);
                financialActions.setFinancialActiveDate({ ...activeDate, status: FINANCIAL_INFORMATION_STATUS.APPROVED });
            }
            setFinancialInformationFormStatus({ ...financialInformationFormStatus, status: FINANCIAL_INFORMATION_STATUS.APPROVED });
        } catch (error) {
            setChangeStatusLoading(false);
            console.error(error);
        }
    };

    const statusColorDetector = (statusType: FINANCIAL_INFORMATION_STATUS) => {
        return FINANCIAL_INFORMATION_STATUS_COLORS[statusType];
    };

    const dropdownDisableDetector = () => {
        if (activeStatus === FINANCIAL_INFORMATION_STATUS.APPROVED) return true;
        if (!checkPermissionAccess(checkCompanyType(PERMISSION_TYPES.COMPANY_FI_APPROVE, companyType as COMPANY_TYPE_ROUTE))) return true;

        return false;
    };

    useEffect(() => {
        if (financialInformationFormStatus?.status) {
            setActiveStatus(financialInformationFormStatus.status);
        }
    }, [financialInformationFormStatus]);

    return (
        <div className={styles.stateWrapper}>
            <span>State</span>
            <Dropdown
                optionLabel="label"
                optionValue="value"
                options={availableActions}
                onChange={(e) => handleConfirmChangeState(e.target.value)}
                placeholder={'Financial data state'}
                disabled={dropdownDisableDetector()}
                value={activeStatus}
                style={{
                    height: '100%',
                    minWidth: '180px',
                    display: 'flex',
                    alignItems: 'center',
                }}
                itemTemplate={(item: IStateDropdownOptionType) => (
                    <div className={styles.dropdownItemRow}>
                        <div className={'bullet-of-status'} style={{ backgroundColor: statusColorDetector(item.value) }} />
                        <span>{item.label}</span>
                    </div>
                )}
                valueTemplate={() => (
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        {availableActions.find((action) => action.value === activeStatus) ? (
                            <div className={styles.dropdownItemRow}>
                                <div
                                    className={'bullet-of-status'}
                                    style={{
                                        backgroundColor: statusColorDetector(availableActions.find((action) => action.value === activeStatus)?.value!),
                                    }}
                                ></div>
                                {availableActions.find((action) => action.value === activeStatus)?.label}
                            </div>
                        ) : (
                            <div className={styles.dropdownItemRow}>
                                <div
                                    className={'bullet-of-status'}
                                    style={{
                                        backgroundColor: statusColorDetector(FINANCIAL_INFORMATION_STATUS.NOT_APPROVED),
                                    }}
                                />
                                <span>Not Approved</span>
                            </div>
                        )}
                        {changeStatusLoading && <ProgressSpinner style={{ width: '18px', height: '18px', marginLeft: '4px' }} />}
                    </div>
                )}
            />
        </div>
    );
};

export default StateManagement;
