import React, { Dispatch, SetStateAction } from 'react';
import DashboardTitle from 'src/components/App/DashboardTitle';
import Table from 'src/components/App/Table/index';
import Button from 'src/components/Kit/Button';
import { Helmet } from 'react-helmet-async';
import { Column } from 'primereact/column';
import ROUTE_CONSTANTS from 'src/Routes/constants/route-constants';
import { personService } from 'src/api/services/person-service';
import { toast } from 'src/utils/toast';
import { TOAST_STATUS } from 'src/constants/toast-status';
import { IPersonType } from 'src/api/types/persons';
import DashboardContentWrapper from 'src/layouts/DashboardContentWrapper';
import { personsDataCellRender } from './personsDataCellRender';
import DeleteIcon from 'src/assets/Icons/DeleteIcon';
import EditIcon from 'src/assets/Icons/EditIcon';
import { getAxiosError } from 'src/utils/get-axios-error';
import { modalActions } from 'src/context/modals';
import { MODAL_TYPES } from 'src/types/modals';
import { useCheckPermissionAccess } from 'src/hooks/useCheckPermissionAccess';
import { PERMISSION_TYPES } from 'src/enums/permissions';
import ArrowRightIcon from 'src/assets/Icons/ArrowRightIcon';

const service = async (queryParams?: any) => personService.getAll(queryParams);

const PersonsList: React.FC = () => {
    const { checkPermissionAccess } = useCheckPermissionAccess();
    const handleRemove = (id: number, setTableData: Dispatch<SetStateAction<IPersonType[]>>) => {
        const handleRemoveItem = async () => {
            try {
                const deleteResult = await personService.delete(id);
                if (deleteResult.data.success) {
                    setTableData((tableData: IPersonType[]) => tableData.filter((item: IPersonType) => item.id !== id));
                    toast.fire({
                        icon: TOAST_STATUS.SUCCESS,
                        title: deleteResult.data.message,
                    });
                } else {
                    toast.fire({
                        icon: TOAST_STATUS.ERROR,
                        title: deleteResult.data.message,
                    });
                }
            } catch (err) {
                const error = getAxiosError(err);
                const message = error?.message || 'Server Error';
                toast.fire({
                    icon: TOAST_STATUS.ERROR,
                    title: message,
                });
            }
        };
        modalActions.addModal(MODAL_TYPES.CONFIRMATION_MODAL, {
            header: 'Delete Confirm',
            text: 'Are you sure to delete this person?',
            handleAction: handleRemoveItem,
        });
    };

    const suffixCols = (data: IPersonType[], setTableData: Dispatch<SetStateAction<IPersonType[]>>) => {
        return (
            <Column
                key="Action"
                body={(rowData: IPersonType) => (
                    <>
                        <Button link color="secondary" size="m" iconOnly to={ROUTE_CONSTANTS.PERSONS.PEOPLE.SHOW.SHOW_BY_PERSON_ID(rowData.id).ABSOLUTE}>
                            <ArrowRightIcon />
                        </Button>
                        {checkPermissionAccess(PERMISSION_TYPES.PERSON_EDIT) && (
                            <Button link color="secondary" size="m" iconOnly to={ROUTE_CONSTANTS.PERSONS.PEOPLE.UPDATE.EDIT_BY_PERSON_ID(rowData.id).ABSOLUTE}>
                                <EditIcon />
                            </Button>
                        )}
                        {checkPermissionAccess(PERMISSION_TYPES.PERSON_DELETE) && (
                            <Button color="secondary" iconOnly size="m" onClick={() => handleRemove(rowData.id, setTableData)}>
                                <DeleteIcon />
                            </Button>
                        )}
                    </>
                )}
            />
        );
    };

    return (
        <>
            <Helmet>
                <title>Teaser | People List</title>
            </Helmet>
            <DashboardTitle
                title={'People List'}
                titleSuffix={
                    checkPermissionAccess(PERMISSION_TYPES.PERSON_CREATE) ? (
                        <Button size="s" link color="primary" to={ROUTE_CONSTANTS.PERSONS.PEOPLE.ADD_NEW.ROOT.ABSOLUTE}>
                            Add New Person
                        </Button>
                    ) : (
                        <></>
                    )
                }
            />
            <DashboardContentWrapper>
                {checkPermissionAccess(PERMISSION_TYPES.PERSON_LIST_VIEW) && (
                    <Table getTableData={service} cellRender={personsDataCellRender} suffixCols={suffixCols} />
                )}
            </DashboardContentWrapper>
        </>
    );
};

export default PersonsList;
