import React, { useEffect, useState } from 'react'
import GoogleMapReact from 'google-map-react';

const defaultProps = {
    center: {
        lat: 40.74881,
        lng: -73.98577
    },
    zoom: 18
};
interface ICenter {
    lat: number;
    lng: number;
}
interface IGoogleMapProps {
    width?: string;
    height?: string;
    center: {
        lat: number | null;
        lng: number | null;
    }
}

const GoogleMap: React.FC<IGoogleMapProps> = ({ width, height, center }) => {
    const [location, setLocation] = useState<ICenter>()

    const renderMarkers = (map: any, maps: any) => {
        let marker = new maps.Marker({
            position: { lat: location?.lat, lng: location?.lng },
            map,
            title: 'location'
        });
        return marker;
    };

    useEffect(() => {
        if (center.lat && center.lng) {
            setLocation({
                lat: Number(center.lat),
                lng: Number(center.lng)
            })
        } else {
            setLocation(defaultProps.center)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '8px', overflow: 'hidden', width, height }}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: "AIzaSyAmFJM7W7v0_VLAkz4tOJUY-B2DBjlaHsc" }}
                center={location}
                defaultZoom={defaultProps.zoom}
                draggable={true}
                onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
            >
            </GoogleMapReact>
        </div>
    )
}

export default GoogleMap