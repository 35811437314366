import { createContext, FC, useContext, useState } from 'react';
import { ITotalSubtotalContext, ITProps } from '../types';

export const TotalSubtotalContext = createContext<ITotalSubtotalContext>({} as ITotalSubtotalContext);

const TotalSubtotalTabsProvider: FC<ITProps> = ({ children, selectedTotalSubtotal }) => {
    const [sidePanelShow, setSidePanelShow] = useState<boolean>(false);
    const [formulaString, setFormulaString] = useState<string>('');
    const [relatedItems, setRelatedItems] = useState<string>();
    const [finalResult, setFinalResult] = useState<number>();
    const [itemMapping, setItemMapping] = useState<any>({});
    const [customValue, setCustomValue] = useState<any>(selectedTotalSubtotal.is_custom_value ? selectedTotalSubtotal.value : 0);

    return (
        <TotalSubtotalContext.Provider
            value={{
                selectedTotalSubtotal,
                sidePanelShow,
                setSidePanelShow,
                formulaString,
                setFormulaString,
                relatedItems,
                setRelatedItems,
                finalResult,
                setFinalResult,
                itemMapping,
                setItemMapping,
                customValue,
                setCustomValue,
            }}
        >
            {children}
        </TotalSubtotalContext.Provider>
    );
};

const useTotalSubtotalContext = () => {
    const context = useContext(TotalSubtotalContext);
    if (!context) {
        throw new Error('You are outside of this context area!');
    }
    return context;
};

export default TotalSubtotalTabsProvider;
export { useTotalSubtotalContext };
