import React from 'react';

interface IProps {
    type?: 'mini' | 'large';
    width?: number;
    height?: number;
}

const Logo: React.FC<IProps> = (props) => {
    const { type = 'large'} = props;
    if (type === 'large') {
        const {width = 83, height = 82 } = props;
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 83 82">
                <path
                    fill="url(#paint0_linear_1928_73781)"
                    d="M56.309 12.527H26.698c-7.548 0-13.667 6.12-13.667 13.667v29.611c0 7.548 6.119 13.667 13.667 13.667h29.611c7.548 0 13.667-6.119 13.667-13.667V26.194c0-7.548-6.12-13.667-13.667-13.667z"
                ></path>
                <path
                    stroke="#3E3F44"
                    strokeWidth="0.8"
                    d="M62 7.283H21c-7.296 0-13.21 5.915-13.21 13.211v41c0 7.297 5.914 13.211 13.21 13.211h41c7.296 0 13.211-5.914 13.211-13.21v-41c0-7.297-5.915-13.212-13.21-13.212z"
                    opacity="0.5"
                ></path>
                <path
                    stroke="#3E3F44"
                    strokeWidth="0.5"
                    d="M68.838.285H14.17C6.781.285.789 6.276.789 13.667v54.667c0 7.39 5.991 13.382 13.382 13.382h54.667c7.39 0 13.382-5.992 13.382-13.382V13.667c0-7.39-5.992-13.382-13.382-13.382z"
                    opacity="0.2"
                ></path>
                <path
                    fill="url(#paint1_linear_1928_73781)"
                    fillRule="evenodd"
                    d="M48.592 47.494H32.773a58.912 58.912 0 002.84 4.375h10.451l2.528-4.375z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="url(#paint2_linear_1928_73781)"
                    fillRule="evenodd"
                    d="M50.206 44.71l2.526-4.375H29.31v-2.784h25.025l2.628-4.555-30.276-.006a58.884 58.884 0 004.556 11.718l18.963.003z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="url(#paint3_linear_1928_73781)"
                    fillRule="evenodd"
                    d="M37.742 54.654a59.365 59.365 0 004.093 4.556c.879-1.519 1.756-3.037 2.632-4.556h-6.725z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="url(#paint4_linear_1928_73781)"
                    fillRule="evenodd"
                    d="M41.832 59.214a46.729 46.729 0 01-3.096-34.166 12.912 12.912 0 00-8.739 3.1 12.3 12.3 0 00-3.31 4.83A58.818 58.818 0 0041.835 59.21l-.003.003z"
                    clipRule="evenodd"
                ></path>
                <defs>
                    <linearGradient id="paint0_linear_1928_73781" x1="57.562" x2="21.402" y1="14.52" y2="69.472" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#E6E6E9"></stop>
                        <stop offset="1" stopColor="#F4F4F6"></stop>
                    </linearGradient>
                    <linearGradient id="paint1_linear_1928_73781" x1="58.304" x2="56.38" y1="35.237" y2="54.469" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#D01C7C"></stop>
                        <stop offset="0.077" stopColor="#BC1C77"></stop>
                        <stop offset="0.31" stopColor="#851C6B"></stop>
                        <stop offset="0.527" stopColor="#5A1D61"></stop>
                        <stop offset="0.721" stopColor="#3B1D5A"></stop>
                        <stop offset="0.885" stopColor="#281D56"></stop>
                        <stop offset="1" stopColor="#221E55"></stop>
                    </linearGradient>
                    <linearGradient id="paint2_linear_1928_73781" x1="52.724" x2="48.651" y1="27.927" y2="48.713" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#D01C7C"></stop>
                        <stop offset="0.077" stopColor="#BC1C77"></stop>
                        <stop offset="0.31" stopColor="#851C6B"></stop>
                        <stop offset="0.527" stopColor="#5A1D61"></stop>
                        <stop offset="0.721" stopColor="#3B1D5A"></stop>
                        <stop offset="0.885" stopColor="#281D56"></stop>
                        <stop offset="1" stopColor="#221E55"></stop>
                    </linearGradient>
                    <linearGradient id="paint3_linear_1928_73781" x1="61.946" x2="48.418" y1="39.999" y2="62.529" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#D01C7C"></stop>
                        <stop offset="0.077" stopColor="#BC1C77"></stop>
                        <stop offset="0.31" stopColor="#851C6B"></stop>
                        <stop offset="0.527" stopColor="#5A1D61"></stop>
                        <stop offset="0.721" stopColor="#3B1D5A"></stop>
                        <stop offset="0.885" stopColor="#281D56"></stop>
                        <stop offset="1" stopColor="#221E55"></stop>
                    </linearGradient>
                    <linearGradient id="paint4_linear_1928_73781" x1="34.261" x2="34.261" y1="24.599" y2="57.403" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#D01C7C"></stop>
                        <stop offset="1" stopColor="#221E55"></stop>
                    </linearGradient>
                </defs>
            </svg>
        );
    } else {
        const {width = 23, height = 26 } = props;
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 23 26">
                <path
                    fill="url(#paint0_linear_2119_78823)"
                    fillRule="evenodd"
                    d="M16.758 16.441H5.172a43.145 43.145 0 002.08 3.205h7.655l1.851-3.205z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="url(#paint1_linear_2119_78823)"
                    fillRule="evenodd"
                    d="M17.89 14.403l1.85-3.205H2.586V9.16h18.33l1.925-3.336L.664 5.818a43.129 43.129 0 003.337 8.584l13.89.001z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="url(#paint2_linear_2119_78823)"
                    fillRule="evenodd"
                    d="M8.734 21.693a43.502 43.502 0 002.998 3.337 3481.38 3481.38 0 001.928-3.337H8.734z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="url(#paint3_linear_2119_78823)"
                    fillRule="evenodd"
                    d="M11.758 25.03A34.228 34.228 0 019.489.003a9.457 9.457 0 00-6.4 2.271A9.01 9.01 0 00.663 5.812 43.083 43.083 0 0011.76 25.027l-.002.003z"
                    clipRule="evenodd"
                ></path>
                <defs>
                    <linearGradient id="paint0_linear_2119_78823" x1="23.872" x2="22.464" y1="7.463" y2="21.551" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#D01C7C"></stop>
                        <stop offset="0.077" stopColor="#BC1C77"></stop>
                        <stop offset="0.31" stopColor="#851C6B"></stop>
                        <stop offset="0.527" stopColor="#5A1D61"></stop>
                        <stop offset="0.721" stopColor="#3B1D5A"></stop>
                        <stop offset="0.885" stopColor="#281D56"></stop>
                        <stop offset="1" stopColor="#221E55"></stop>
                    </linearGradient>
                    <linearGradient id="paint1_linear_2119_78823" x1="19.735" x2="16.752" y1="2.11" y2="17.335" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#D01C7C"></stop>
                        <stop offset="0.077" stopColor="#BC1C77"></stop>
                        <stop offset="0.31" stopColor="#851C6B"></stop>
                        <stop offset="0.527" stopColor="#5A1D61"></stop>
                        <stop offset="0.721" stopColor="#3B1D5A"></stop>
                        <stop offset="0.885" stopColor="#281D56"></stop>
                        <stop offset="1" stopColor="#221E55"></stop>
                    </linearGradient>
                    <linearGradient id="paint2_linear_2119_78823" x1="26.463" x2="16.554" y1="10.959" y2="27.461" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#D01C7C"></stop>
                        <stop offset="0.077" stopColor="#BC1C77"></stop>
                        <stop offset="0.31" stopColor="#851C6B"></stop>
                        <stop offset="0.527" stopColor="#5A1D61"></stop>
                        <stop offset="0.721" stopColor="#3B1D5A"></stop>
                        <stop offset="0.885" stopColor="#281D56"></stop>
                        <stop offset="1" stopColor="#221E55"></stop>
                    </linearGradient>
                    <linearGradient id="paint3_linear_2119_78823" x1="6.212" x2="6.212" y1="-0.325" y2="23.703" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#D01C7C"></stop>
                        <stop offset="1" stopColor="#221E55"></stop>
                    </linearGradient>
                </defs>
            </svg>
        );
    }
};

export default Logo;
