import { ISelectBox } from 'src/api/types/base-types';
import { COMPANY_TYPE } from '../enums/company-type';

export const companyTypeOptions: ISelectBox[] = [
    {
        label: COMPANY_TYPE.PUBLIC,
        value: COMPANY_TYPE.PUBLIC,
    },
    {
        label: COMPANY_TYPE.PRIVATE,
        value: COMPANY_TYPE.PRIVATE,
    },
    {
        label: COMPANY_TYPE.NON_PROFILE,
        value: COMPANY_TYPE.NON_PROFILE,
    },
];
