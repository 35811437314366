import React, { useEffect, useState } from 'react';
import FormGenerator from 'src/components/Kit/FormGenerator/index';
import { useForm } from 'react-hook-form';
import { generateDefaultValueFromFormSchema } from 'src/utils/generate-default-value-from-form-schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { financialReportValidationSchema } from './validation-schema';
import Button from 'src/components/Kit/Button';
import { useNavigate, useParams } from 'react-router-dom';
import InitLoading from 'src/components/App/Loading/InitLoading';
import { getAxiosError } from 'src/utils/get-axios-error';
import { toast } from 'src/utils/toast';
import { TOAST_STATUS } from 'src/constants/toast-status';
import { CreateFinancialReportSchemaType, createFinancialReportSchema } from './schema';
import { IFinancialReportFormData } from './types';
import { companyService } from 'src/api/services/company';
import { ICreateFinancialReportPayload, IUpdateFinancialReportPayload } from 'src/api/types/company';
import dayjs from 'dayjs';
import ROUTE_CONSTANTS from 'src/Routes/constants/route-constants';
import pdfIcon from 'src/assets/Images/pdf-icon.svg';
import { COMPANY_TYPE_ROUTE } from 'src/pages/Company/Add/enums/company-type-route';
import { IParams } from 'src/types/params';
import { FINANCIAL_REPORT_TYPE } from 'src/enums/upload-entities.ts';

const Add: React.FC = () => {
    const navigate = useNavigate();
    const { companyType, companyId, id } = useParams<IParams>();
    const isEditingRule = Boolean(id);
    const [loading, setLoading] = useState<boolean>(false);
    const [initialLoading, setInitialLoading] = useState<boolean>(true);
    const [formSchema, setFormSchema] = useState<CreateFinancialReportSchemaType>(createFinancialReportSchema);

    const {
        control,
        formState: { errors },
        handleSubmit,
        reset,
        setValue,
    } = useForm<IFinancialReportFormData>({
        defaultValues: generateDefaultValueFromFormSchema(formSchema),
        mode: 'all',
        shouldFocusError: false,
        resolver: yupResolver(financialReportValidationSchema),
    });

    const handleGetValues = async () => {
        setInitialLoading(true);
        if (isEditingRule) await getDetails();
        try {
            let languagesResponse = await companyService(companyType as COMPANY_TYPE_ROUTE).getFinancialReportLanguages();
            const languagesData = Object.entries(languagesResponse.data.data.languages).map(([value, label]) => ({
                label,
                value,
            }));

            setFormSchema((formSchema) => ({
                ...formSchema,
                language: { ...formSchema.language, data: languagesData },
            }));
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: message,
            });
        } finally {
            setInitialLoading(false);
        }
    };

    const getDetails = async () => {
        try {
            let res = await companyService(companyType as COMPANY_TYPE_ROUTE).getFinancialReport(Number(companyId), Number(id));
            reset({
                year_end: dayjs.unix(res.data.data.year_end).toDate(),
                language: Number(res.data.data.language),
            });

            const onDelete = (fieldName: keyof Omit<CreateFinancialReportSchemaType, 'year_end' | 'language'>) => {
                setFormSchema((formSchema) => ({
                    ...formSchema,
                    [fieldName]: { ...formSchema[fieldName], filePreview: undefined },
                }));
                setValue(`delete_${fieldName}`, true);
            };

            setFormSchema((formSchema) => ({
                ...formSchema,
                ...(res.data.data.first_quarter && {
                    first_quarter: {
                        ...formSchema.first_quarter,
                        filePreview: {
                            name: res.data.data.first_quarter.split('/').pop(),
                            icon: pdfIcon,
                            download: true,
                            url: res.data.data.first_quarter,
                            onDelete: () => onDelete('first_quarter'),
                        },
                    },
                }),
                ...(res.data.data.half_quarter && {
                    half_quarter: {
                        ...formSchema.half_quarter,
                        filePreview: {
                            name: res.data.data.half_quarter.split('/').pop(),
                            icon: pdfIcon,
                            download: true,
                            url: res.data.data.half_quarter,
                            onDelete: () => onDelete('half_quarter'),
                        },
                    },
                }),
                ...(res.data.data.third_quarter && {
                    third_quarter: {
                        ...formSchema.third_quarter,
                        filePreview: {
                            name: res.data.data.third_quarter.split('/').pop(),
                            icon: pdfIcon,
                            download: true,
                            url: res.data.data.third_quarter,
                            onDelete: () => onDelete('third_quarter'),
                        },
                    },
                }),
                ...(res.data.data.full_year && {
                    full_year: {
                        ...formSchema.full_year,
                        filePreview: {
                            name: res.data.data.full_year.split('/').pop(),
                            icon: pdfIcon,
                            download: true,
                            url: res.data.data.full_year,
                            onDelete: () => onDelete('full_year'),
                        },
                    },
                }),
                ...(res.data.data.annual && {
                    annual: {
                        ...formSchema.annual,
                        filePreview: {
                            name: res.data.data.annual.split('/').pop(),
                            icon: pdfIcon,
                            download: true,
                            url: res.data.data.annual,
                            onDelete: () => onDelete('annual'),
                        },
                    },
                }),
            }));
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: message,
            });
        }
    };

    const onSubmit = async (_val: IFinancialReportFormData) => {
        let targetApi;
        const year_end_date = new Date(_val.year_end);
        const payload: ICreateFinancialReportPayload = {
            year_end: dayjs(new Date(year_end_date.getFullYear(), year_end_date.getMonth(), 15).getTime()).unix(),
            language: Number(_val.language),
            ...(_val.first_quarter && {
                first_quarter: {
                    type: FINANCIAL_REPORT_TYPE.FINANCIAL_REPORT_FIRST_QUARTER,
                    id: (_val.first_quarter as { id: number; uuid: string }).id,
                },
            }),
            ...(_val.half_quarter && {
                half_quarter: { type: FINANCIAL_REPORT_TYPE.FINANCIAL_REPORT_HALF_QUARTER, id: (_val.half_quarter as { id: number; uuid: string }).id },
            }),
            ...(_val.third_quarter && {
                third_quarter: { type: FINANCIAL_REPORT_TYPE.FINANCIAL_REPORT_THIRD_QUARTER, id: (_val.third_quarter as { id: number; uuid: string }).id },
            }),
            ...(_val.full_year && {
                full_year: { type: FINANCIAL_REPORT_TYPE.FINANCIAL_REPORT_FULL_YEAR, id: (_val.full_year as { id: number; uuid: string }).id },
            }),
            ...(_val.annual && { annual: { type: FINANCIAL_REPORT_TYPE.FINANCIAL_REPORT_ANNUAL, id: (_val.annual as { id: number; uuid: string }).id } }),
        };

        const editPayload: IUpdateFinancialReportPayload = {
            ...payload,
            ...(_val.delete_first_quarter && { delete_first_quarter: _val.delete_first_quarter }),
            ...(_val.delete_half_quarter && { delete_half_quarter: _val.delete_half_quarter }),
            ...(_val.delete_third_quarter && { delete_third_quarter: _val.delete_third_quarter }),
            ...(_val.delete_full_year && { delete_full_year: _val.delete_full_year }),
            ...(_val.delete_annual && { delete_annual: _val.delete_annual }),
        };

        if (isEditingRule)
            targetApi = await companyService(companyType as COMPANY_TYPE_ROUTE).updateFinancialReport(Number(companyId), Number(id), editPayload);
        else targetApi = await companyService(companyType as COMPANY_TYPE_ROUTE).createNewFinancialReport(Number(companyId), payload);

        try {
            setLoading(true);
            const response = await targetApi;
            toast.fire({
                icon: response.data.success ? TOAST_STATUS.SUCCESS : TOAST_STATUS.ERROR,
                title: response.data.message,
            });
            navigate(ROUTE_CONSTANTS.COMPANIES.TYPE.SHOW.FINANCIAL_REPORT.ROOT.BY_COMPANY_ID(companyType as COMPANY_TYPE_ROUTE, Number(companyId)).ABSOLUTE);
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: message,
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        handleGetValues();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (initialLoading) return <InitLoading />;

    return (
        <div className="grid justify-content-center my-8">
            <div className="xs:col:12 md:col-8 xl:col-5">
                <span className="block text-2xl font-semibold">{isEditingRule ? 'Update' : 'Add New'} Financial Report</span>
                <div className="mt-5">
                    <FormGenerator
                        onSubmit={handleSubmit(onSubmit)}
                        control={control}
                        errors={errors}
                        schema={formSchema}
                        noBackgroundLayout
                        buttons={
                            <>
                                <Button disabled={loading} loading={loading} color="primary">
                                    {isEditingRule ? 'Update' : 'Create'}
                                </Button>
                                <Button
                                    type="button"
                                    onClick={() =>
                                        navigate(
                                            ROUTE_CONSTANTS.COMPANIES.TYPE.SHOW.FINANCIAL_REPORT.ROOT.BY_COMPANY_ID(
                                                companyType as COMPANY_TYPE_ROUTE,
                                                Number(companyId)
                                            ).ABSOLUTE
                                        )
                                    }
                                >
                                    Cancel
                                </Button>
                            </>
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default Add;
