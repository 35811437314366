import { urlRegex } from 'src/constants/regex';
import { getMaxError, getMinError, getRequireError } from 'src/utils/get-yup-errors';
import { string, object, number, array } from 'yup';

export const parentCompanyFinancialValidationSchema = object().shape({
    company_source: number()
        .transform((value) => (isNaN(value) || value === null || value === undefined ? undefined : value))
        .required(getRequireError('Company Source')),
    company_name: string().required(getRequireError('Company Name')),
    report_date: string().required(getRequireError('Report Date')),
    financial_year_end: string().required(getRequireError('Financial Year Date')),
    pages: array()
        .of(
            string()
                .transform((value) => (isNaN(value) || value === null || value === undefined ? null : value))
                .min(1, getMinError(1, 'Pages'))
                .max(2000, getMaxError(2000, 'Pages'))
        )

        .nullable(true),
    source: string().required(getRequireError('Source')).nullable(),
    language: array().of(string()).required(getRequireError('language')).nullable(),
    source_link: string().matches(urlRegex, 'Enter correct url!').max(1000, getMaxError(1000, 'Source')).required(getRequireError('Source Link')),
    pdf_file: object().required(getRequireError('PDF File')).nullable(),
});
