import { AUTHORITY } from '../../enums/authority';

export const authorityDropdownOptions = [
    { label: AUTHORITY.KUWAIT_MINISTRY_OF_COMMERCE, value: '1' },
    { label: AUTHORITY.SECURITIES_AND_COMMODITIES_AUTHORITY_UAE, value: '2' },
    { label: AUTHORITY.DUBAI_INTERNATIONAL_FINANCIAL_CENTER, value: '3' },
    { label: AUTHORITY.DUBAI_MULTI_COMMODITIES_CENTRE, value: '4' },
    { label: AUTHORITY.CAPITAL_MARKET_AUTHORITY_KUWAIT, value: '5' },
    { label: AUTHORITY.CAPITAL_MARKET_AUTHORITY_OMAN, value: '6' },
    { label: AUTHORITY.CAPITAL_MARKET_AUTHORITY_SAUDI_ARABIA, value: '7' },
    { label: AUTHORITY.SAUDI_ARABIAN_GENERAL_INVESTMENT_AUTHORITY, value: '8' },
    { label: AUTHORITY.CENTRAL_BANK_OF_BAHRAIN, value: '9' },
    { label: AUTHORITY.CENTRAL_BANK_OF_KUWAIT, value: '10' },
    { label: AUTHORITY.JEBEL_ALI_FREE_ZONE, value: '11' },
    { label: AUTHORITY.HAMRIYAH_FREE_ZONE, value: '12' },
    { label: AUTHORITY.AJMAN_FREE_ZONE_AUTHORITY, value: '13' },
    { label: AUTHORITY.SHARJAH_AIRPORT_INTERNATIONAL_FREE_ZONE, value: '14' },
    { label: AUTHORITY.ABU_DHABI_AIRPORT_FREE_ZONE, value: '15' },
    { label: AUTHORITY.MASDAR_CITY_FREE_ZONE, value: '16' },
    { label: AUTHORITY.RAK_MARITIME_CITY_FREE_ZONE, value: '17' },
    { label: AUTHORITY.ABU_DHABI_GLOBAL_MARKET_FINANCIAL_SERVICES_REGULATORY_AUTHORITY, value: '18' },
    { label: AUTHORITY.QATAR_FINANCIAL_CENTER, value: '19' },
    { label: AUTHORITY.RAS_AL_KHAIMAH_INVESTMENT_AUTHORITY, value: '20' },
    { label: AUTHORITY.DEPARTMENT_OF_ECONOMIC_DEVELOPMENT_OF_THE_GOVERNMENT_OF_DUBAI_AND_CENTRAL_BANK_OF_UAE, value: '21' },
    { label: AUTHORITY.QATAR_FINANCIAL_CENTRE_REGULATORY_AUTHORITY, value: '22' },
    { label: AUTHORITY.DUBAI_DEVELOPMENT_AUTHORITY, value: '23' },
    { label: AUTHORITY.MINISTER_OF_INDUSTRY_COMMERCE_AND_TOURISM, value: '24' },
    { label: AUTHORITY.LABOUR_MARKET_REGULATORY_AUTHORITY, value: '25' },
    { label: AUTHORITY.CAPITAL_MARKETS_SUPERVISION_DIRECTORATE, value: '26' },
    { label: AUTHORITY.PUBLIC_AUTHORITY_FOR_INDUSTRY, value: '27' },
    { label: AUTHORITY.KUWAIT_DIRECT_INVESTMENT_PROMOTION_AUTHORITY, value: '28' },
    { label: AUTHORITY.PUBLIC_AUTHORITY_FOR_SOCIAL_INSURANCE_SCHEME, value: '29' },
    { label: AUTHORITY.QATAR_INVESTMENT_AUTHORITY, value: '30' },
    { label: AUTHORITY.GENERAL_AUTHORITY_OF_ZAKAT_AND_TAX_CUSTOMS_AUTHORITY, value: '31' },
    { label: AUTHORITY.SAUDI_CENTRAL_BANK, value: '32' },
    { label: AUTHORITY.ECONOMIC_CITY_AND_SPECIAL_ZONES_AUTHORITY, value: '33' },
    { label: AUTHORITY.SMALL_AND_MEDIUM_ENTERPRISES_GENERAL_AUTHORITY, value: '34' },
    { label: AUTHORITY.GENERAL_AUTHORITY_FOR_STATISTICS, value: '35' },
    { label: AUTHORITY.DIRIYAH_GATE_DEVELOPMENT_AUTHORITY, value: '36' },
    { label: AUTHORITY.GENERAL_AUTHORITY_FOR_INVESTMENT, value: '37' },
    { label: AUTHORITY.THE_HIGHER_SHARIAH_AUTHORITY, value: '38' },
    { label: AUTHORITY.DUBAI_FINANCIAL_SERVICES_AUTHORITY, value: '39' },
    { label: AUTHORITY.FINANCIAL_SERVICES_REGULATORY_AUTHORITY, value: '40' },
    { label: AUTHORITY.DUBAI_LAND_DEPARTMENT_AUTHORITY, value: '41' },
    { label: AUTHORITY.INSURANCE_AUTHORITY, value: '42' },
    { label: AUTHORITY.ABU_DHABI_INVESTMENT_AUTHORITY, value: '43' },
    { label: AUTHORITY.ABU_DHABI_ACCOUNTABILITY_AUTHORITY, value: '44' },
    { label: AUTHORITY.SHARJAH_INVESTMENT_AND_DEVELOPMENT_AUTHORITY, value: '45' },
    { label: AUTHORITY.FUJAIRAH_FREE_ZONE_AUTHORITY, value: '46' },
    { label: AUTHORITY.DUBAI_AIRPORT_FREE_ZONE_AUTHORITY, value: '47' },
];
