import React, { Dispatch, SetStateAction, useState } from 'react';
import DashboardTitle from 'src/components/App/DashboardTitle/index';
import ROUTE_CONSTANTS from 'src/Routes/constants/route-constants';
import Table from 'src/components/App/Table/index';
import Button from 'src/components/Kit/Button';
import { Column } from 'primereact/column';
import { companyService } from 'src/api/services/company';
import { Helmet } from 'react-helmet-async';
import { COMPANY_STATUS, IBaseCompanyList } from 'src/api/types/company';
import DashboardContentWrapper from 'src/layouts/DashboardContentWrapper';
import ArrowRightIcon from 'src/assets/Icons/ArrowRightIcon';
import { companyDataCellRender } from './companyDataCellRender';
import StatusDropDown from 'src/components/Kit/StatusDropDown';
import { DropdownChangeParams } from 'primereact/dropdown';
import { getAxiosError } from 'src/utils/get-axios-error';
import { toast } from 'src/utils/toast';
import { TOAST_STATUS } from 'src/constants/toast-status';
import { approvedCompanyStatusDropDownOptions, statusDropDownOptions } from './constants/status-dropdown-options';
import { useCheckPermissionAccess } from 'src/hooks/useCheckPermissionAccess';
import { PERMISSION_TYPES } from 'src/enums/permissions';
import EditIcon from 'src/assets/Icons/EditIcon';
import { IDataTablePayload } from 'src/api/types/table';
import MoreIcon from 'src/assets/Icons/MoreIcon';
import style from './CompanyList.module.scss';
import { companyDataColumnsRender } from './companyDataColumnsRender';
import { COMPANY_TYPE_ROUTE } from '../Add/enums/company-type-route';
import { checkCompanyType } from 'src/utils/check-company-type';
import { useParams } from 'react-router-dom';
import { IParams } from 'src/types/params';
import { companyTitleByType } from 'src/constants/company/company-title-by-type';
import { COMPANY_TYPE } from '../Add/enums/company-type';

const CompanyList: React.FC = () => {
    const { companyType } = useParams<IParams>();
    const { checkPermissionAccess } = useCheckPermissionAccess();
    const disableStatusDropdown = !(
        checkPermissionAccess(checkCompanyType(PERMISSION_TYPES.COMPANY_INFORMATION_APPROVE, companyType as COMPANY_TYPE_ROUTE)) ||
        checkPermissionAccess(checkCompanyType(PERMISSION_TYPES.COMPANY_INFORMATION_NOT_APPROVE, companyType as COMPANY_TYPE_ROUTE))
    );
    const [statusLoadingId, setStatusLoadingId] = useState<number | undefined>(undefined);
    const pluralTitle = companyTitleByType[companyType as COMPANY_TYPE_ROUTE].plural;
    const singularTitle = companyTitleByType[companyType as COMPANY_TYPE_ROUTE].singular;

    const service = async (queryParams?: IDataTablePayload) =>
        companyService(companyType as COMPANY_TYPE_ROUTE)
            .getAll(queryParams)
            .then((data) => ({
                ...data,
                data: {
                    ...data.data,
                    meta: {
                        ...data.data.meta,
                        columns: companyDataColumnsRender,
                    },
                },
            }));
    const suffixCols = (data: IBaseCompanyList[], setTableData: Dispatch<SetStateAction<IBaseCompanyList[]>>) => {
        return (
            <Column
                key="Action"
                frozen
                alignFrozen="right"
                header="More"
                style={{ width: 160 }}
                body={(bodyData: IBaseCompanyList) => {
                    const selectedValue = statusDropDownOptions.find((item) => item.value === String(bodyData.company_status));
                    const isApprovedCompany = bodyData.company_status === COMPANY_STATUS.APPROVED && bodyData.slug;
                    return (
                        <div className={style.companyListActions}>
                            {checkPermissionAccess(checkCompanyType(PERMISSION_TYPES.COMPANY_INFORMATION_EDIT, companyType as COMPANY_TYPE_ROUTE)) && (
                                <Button
                                    link
                                    color="secondary"
                                    iconOnly
                                    size="m"
                                    to={ROUTE_CONSTANTS.COMPANIES.TYPE.SHOW.EDIT.ROOT.BY_COMPANY_ID(companyType as COMPANY_TYPE_ROUTE, bodyData.id).ABSOLUTE}
                                >
                                    <EditIcon />
                                </Button>
                            )}
                            {checkPermissionAccess(checkCompanyType(PERMISSION_TYPES.COMPANY_INFORMATION_SINGLE_VIEW, companyType as COMPANY_TYPE_ROUTE)) && (
                                <Button
                                    link
                                    color="secondary"
                                    iconOnly
                                    size="m"
                                    to={ROUTE_CONSTANTS.COMPANIES.TYPE.SHOW.SHOW_BY_COMPANY_ID(companyType as COMPANY_TYPE_ROUTE, bodyData.id).ABSOLUTE}
                                >
                                    <ArrowRightIcon />
                                </Button>
                            )}
                            <StatusDropDown
                                loading={statusLoadingId === bodyData.id}
                                dropDownProps={{
                                    valueTemplate: () => <MoreIcon width={24} style={{ padding: '0 5px' }} />,
                                    options: isApprovedCompany ? approvedCompanyStatusDropDownOptions(bodyData.slug) : statusDropDownOptions,
                                    onChange: (event) => !disableStatusDropdown && handleChangeStatus(event, bodyData, setTableData),
                                    placeholder: selectedValue?.label || 'Select Status',
                                    value: selectedValue?.value,
                                    disabled: companyType === COMPANY_TYPE.NON_PROFILE || disableStatusDropdown || statusLoadingId === bodyData.id,
                                    optionDisabled: (option) => {
                                        if (option.value === String(COMPANY_STATUS.APPROVED)) {
                                            return !checkPermissionAccess(
                                                checkCompanyType(PERMISSION_TYPES.COMPANY_INFORMATION_APPROVE, companyType as COMPANY_TYPE_ROUTE)
                                            );
                                        }
                                        if (option.value === String(COMPANY_STATUS.NOT_APPROVED)) {
                                            return !checkPermissionAccess(
                                                checkCompanyType(PERMISSION_TYPES.COMPANY_INFORMATION_NOT_APPROVE, companyType as COMPANY_TYPE_ROUTE)
                                            );
                                        }
                                        return false;
                                    },
                                }}
                            />
                        </div>
                    );
                }}
            />
        );
    };

    const handleChangeStatus = async (event: DropdownChangeParams, data: IBaseCompanyList, setData: Dispatch<SetStateAction<IBaseCompanyList[]>>) => {
        setStatusLoadingId(data.id);
        try {
            const response = await companyService(companyType as COMPANY_TYPE_ROUTE).changeStatus(data.id, { status: Number(event.target.value) });
            if (response.data.success) {
                setData((prev: IBaseCompanyList[]) =>
                    prev.map((item: IBaseCompanyList) => (item.id === data.id ? { ...item, company_status: event.target.value } : item))
                );
                toast.fire({
                    icon: TOAST_STATUS.SUCCESS,
                    title: response.data.message,
                });
            } else {
                toast.fire({
                    icon: TOAST_STATUS.WARNING,
                    title: response.data.message,
                });
            }
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: message,
            });
        } finally {
            setStatusLoadingId(undefined);
        }
    };

    return (
        <>
            <Helmet>
                <title>Teaser | {pluralTitle}</title>
            </Helmet>
            <DashboardTitle
                title={`${pluralTitle} List`}
                titleSuffix={
                    checkPermissionAccess(checkCompanyType(PERMISSION_TYPES.COMPANY_INFORMATION_CREATE, companyType as COMPANY_TYPE_ROUTE)) && (
                        <Button
                            size="s"
                            link
                            color="primary"
                            to={ROUTE_CONSTANTS.COMPANIES.TYPE.ADD_NEW.ADD_NEW_COMPANY_BY_TYPE(companyType as COMPANY_TYPE_ROUTE).ABSOLUTE}
                        >
                            Add New {singularTitle}
                        </Button>
                    )
                }
            />
            <DashboardContentWrapper>
                {checkPermissionAccess(checkCompanyType(PERMISSION_TYPES.COMPANY_INFORMATION_LIST_VIEW, companyType as COMPANY_TYPE_ROUTE)) && (
                    <div className={style.companyList} key={companyType}>
                        <Table getTableData={service} cellRender={companyDataCellRender(companyType as COMPANY_TYPE_ROUTE)} suffixCols={suffixCols} />
                    </div>
                )}
            </DashboardContentWrapper>
        </>
    );
};

export default CompanyList;
