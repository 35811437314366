import { urlRegex } from 'src/constants/regex';
import { getMaxError, getMinError, getRequireError } from 'src/utils/get-yup-errors';
import { string, object} from 'yup';

export const newsValidationSchema = object().shape({
    title: string().required(getRequireError('Title')).min(2, getMinError(2, 'Title')).max(120, getMaxError(120, 'Title')),
    date: string().nullable().required(getRequireError('Date')),
    source_id: string().required(getRequireError('Company Source')),
    source_url: string()
        .nullable()
        .when({
            is: (source_url: string) => source_url && source_url.length > 0,
            then: string().matches(urlRegex, 'Enter correct url!'),
        }),
});
