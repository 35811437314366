import React from 'react';
import styles from './CompanyFinancialInformation.module.scss';
import { IBaseFinancialInformationValueByTypeResponse, RATIO_NUMBER_TYPE } from 'src/api/types/company';
import InitLoading from 'src/components/App/Loading/InitLoading';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { financialValues } from 'src/utils/financial-values';
import Button from 'src/components/Kit/Button';
import { COMPANY_FINANCIAL_INFORMATION_STATE } from '../enums';
import { ITEM_TYPE_ENUM, ITEM_VALUE_TYPE_OPTIONS_ENUM } from 'src/enums/company/financialInformation/financialItem';
import { financialInformationLegitFractions } from '../constants';
import { useCheckPermissionAccess } from 'src/hooks/useCheckPermissionAccess';
import { PERMISSION_TYPES } from 'src/enums/permissions';
import { financialItemTypeDictionary } from './constants/financialItemTypeDictionary';
import { financialRowColorDetector } from './constants/financialRowColorDetector';
import { ITableColumnData } from 'src/api/types/table';
import { financialActions, useFinancial } from '../../../../../context/companies';
import CompanyFinancialTypes from './financial-types';
import { COMPANY_TYPE_ROUTE } from 'src/pages/Company/Add/enums/company-type-route';
import { useParams } from 'react-router-dom';
import { IParams } from 'src/types/params';
import { checkCompanyType } from 'src/utils/check-company-type';

interface IValueType {
    [key: string]: any;
}

const CompanyFinancialInformationViewMode: React.FC = () => {
    const { checkPermissionAccess } = useCheckPermissionAccess();
    const { companyType } = useParams<IParams>();
    const { companyDetails, columns, financialRowsByTypeLoading, financialRowsByType } = useFinancial((global) => ({
        companyDetails: global.company,
        columns: global.financialColumns,
        financialRowsByTypeLoading: global.loadings.financialRowsByType,
        financialRowsByType: global.financialRowsByType,
    }));
    const canEdit =
        checkPermissionAccess(checkCompanyType(PERMISSION_TYPES.COMPANY_FI_EDIT, companyType as COMPANY_TYPE_ROUTE)) ||
        checkPermissionAccess(checkCompanyType(PERMISSION_TYPES.COMPANY_FI_EDIT_APPROVED_DATA, companyType as COMPANY_TYPE_ROUTE));

    const renderTableValues = (data: IValueType, column: ITableColumnData) => {
        if (data.value_type === ITEM_VALUE_TYPE_OPTIONS_ENUM.NUMERIC) {
            if (data[column.field]) {
                return (
                    <span>
                        {column.showCurrency && data['number_type'] === RATIO_NUMBER_TYPE.CURRENCY && companyDetails?.selectedCurrency?.short_name}
                        {financialValues(data[column.field], { fixed: financialInformationLegitFractions })}
                    </span>
                );
            } else {
                return '-';
            }
        } else {
            return data[column.field] || '';
        }
    };

    const onClickEdit = () => financialActions.setFinancialInformationState(COMPANY_FINANCIAL_INFORMATION_STATE.EDIT_MODE);

    const getColoredRow = (type: ITEM_TYPE_ENUM) => ({
        backgroundColor: financialRowColorDetector[type],
        display: 'block',
        width: '100%',
        height: '100%',
        lineHeight: '33px',
        padding: '8px',
        justifyContent: 'center',
    });

    return (
        <div className={styles.container}>
            <div className={styles.financialDataWrapper}>
                <CompanyFinancialTypes />
                {financialRowsByTypeLoading ? (
                    <InitLoading />
                ) : (
                    <DataTable
                        value={financialRowsByType}
                        scrollDirection="both"
                        scrollable
                        scrollHeight="calc(100vh - 275px)"
                        data-cy={'financial-values'}
                    >
                        <Column
                            style={{ width: '40px', padding: 0, boxShadow: 'unset' }}
                            headerStyle={{ background: '#f0f2f4' }}
                            field="index"
                            header={<div style={{ padding: '10px' }}>#</div>}
                            frozen
                            body={(data: IBaseFinancialInformationValueByTypeResponse, props) => (
                                <span style={getColoredRow(data.type)}>{props.rowIndex + 1}</span>
                            )}
                        />
                        <Column
                            field="type"
                            header="Item"
                            headerStyle={{ padding: '0 8px', background: '#f0f2f4' }}
                            style={{ width: '90px', padding: 0, boxShadow: 'unset' }}
                            data-cy={'ITEM'}
                            body={(data: IBaseFinancialInformationValueByTypeResponse) => (
                                <span style={getColoredRow(data.type)}>{financialItemTypeDictionary[data.type]}</span>
                            )}
                            frozen
                        />
                        <Column
                            field="name"
                            header="Title"
                            frozen
                            style={{ width: '380px', padding: '0', boxShadow: 'unset' }}
                            headerStyle={{ background: '#f0f2f4' }}
                            body={(data: IBaseFinancialInformationValueByTypeResponse) => (
                                <div
                                    style={{
                                        backgroundColor: financialRowColorDetector[data.type],
                                        width: '100%',
                                        height: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        padding: '0 8px',
                                    }}
                                >
                                    {data.name}
                                </div>
                            )}
                        />
                        <Column
                            field="Category"
                            header="Category"
                            frozen
                            style={{ width: '140px', padding: '0', boxShadow: 'unset' }}
                            headerStyle={{ background: '#f0f2f4' }}
                            body={(data: IBaseFinancialInformationValueByTypeResponse) => (
                                <div
                                    style={{
                                        backgroundColor: financialRowColorDetector[data.type],
                                        width: '100%',
                                        height: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        padding: '0 8px',
                                    }}
                                >
                                    {data.category}
                                </div>
                            )}
                        />
                        <Column
                            field="Sub-Category"
                            header="Sub-Category"
                            frozen
                            style={{ width: '140px', padding: '0', boxShadow: 'inset -1px 0 0 #E6E6E9' }}
                            headerStyle={{ background: '#f0f2f4' }}
                            body={(data: IBaseFinancialInformationValueByTypeResponse) => (
                                <div
                                    style={{
                                        backgroundColor: financialRowColorDetector[data.type],
                                        width: '100%',
                                        height: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        padding: '0 8px',
                                    }}
                                >
                                    {data.sub_category}
                                </div>
                            )}
                        />
                        {columns?.map((column, index) => (
                            <Column
                                key={index}
                                style={{
                                    minWidth: '250px',
                                    padding: '0',
                                    flex: 1,
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                }}
                                headerStyle={{ background: '#f0f2f4' }}
                                field={column.field}
                                header={column.header}
                                body={(data: IBaseFinancialInformationValueByTypeResponse) => (
                                    <div style={getColoredRow(data.type)}>{renderTableValues(data, column)}</div>
                                )}
                            />
                        ))}
                    </DataTable>
                )}
            </div>
            <div className={styles.footerWrapper}>
                {canEdit && (
                    <Button color="primary" size="s" onClick={onClickEdit} data-cy={'edit-financial-btn'}>
                        <span className={'px-4'}>Edit</span>
                    </Button>
                )}
            </div>
        </div>
    );
};

export default CompanyFinancialInformationViewMode;
