interface IStatusCodeType {
    [key: string]: number;
}

export const HTTP_STATUS_CODES: IStatusCodeType = {
    SUCCESS: 200,
    CREATED: 201,
    SUCCESS_NO_CONTENT: 204,
    VALIDATION_ERROR: 400,
    UN_AUTHORIZED: 401,
    NOT_FOUND: 404,
    FORBIDDEN: 403,
    UNPROCESSABLE_ENTITY_MANUAL: 406,
    UNPROCESSABLE_ENTITY: 422,
    SERVER_ERROR: 500,
    CACHED_RESPONSE: 700,
};
