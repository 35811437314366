import React, { useEffect, useState } from 'react';
import FormGenerator from 'src/components/Kit/FormGenerator/index';
import { useForm } from 'react-hook-form';
import { generateDefaultValueFromFormSchema } from 'src/utils/generate-default-value-from-form-schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { createValidationSchema } from './validation-schema';
import Button from 'src/components/Kit/Button';
import { useNavigate, useParams } from 'react-router-dom';
import InitLoading from 'src/components/App/Loading/InitLoading';
import { financialInformationService } from 'src/api/services/financial-information-service';
import { IFinancialInformationStoreCategoryPayload } from 'src/api/types/financial-information';
import { createFinancialInformationCategorySchema, CreateFinancialInformationCategorySchemaFields } from './schema';
import { getAxiosError } from 'src/utils/get-axios-error';
import { toast } from 'src/utils/toast';
import { TOAST_STATUS } from 'src/constants/toast-status';
import { useGetFinancialDataTypes } from 'src/hooks/company/useGetFinancialDataTypes';
import { IOptionTypeInCategoryAndSubcategory } from './types';
import { useCheckPermissionAccess } from 'src/hooks/useCheckPermissionAccess';
import { PERMISSION_TYPES } from 'src/enums/permissions';
import { IFormGeneratorGeneralSchemaType } from 'src/types/form-generator-schema-type';

interface IParams {
    [id: string]: string;
}
interface IFormData {
    name: string;
    arabic_name: string;
    financial_information_type: string[];
}

interface IProps {
    parent: 'category' | 'sub-category';
}

const Add: React.FC<IProps> = ({ parent }) => {
    const { checkPermissionAccess } = useCheckPermissionAccess();
    const navigate = useNavigate();
    const { financialDataTypes } = useGetFinancialDataTypes()
    const { id } = useParams<IParams>();
    const isEditingRule = Boolean(id);
    const [loading, setLoading] = useState<boolean>(false);
    const [initialLoading, setInitialLoading] = useState<boolean>(true);
    const [formSchema, setFormSchema] = useState<Record<CreateFinancialInformationCategorySchemaFields, IFormGeneratorGeneralSchemaType>>(createFinancialInformationCategorySchema);
    
    const {
        control,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm<IFormData>({
        defaultValues: generateDefaultValueFromFormSchema(formSchema),
        mode: 'all',
        resolver: yupResolver(createValidationSchema()),
    });

    const handleGetValues = async () => {
        if (isEditingRule) await getDetails();
            setFormSchema((formSchema) => ({
                ...formSchema,
                name: {...formSchema.name, props: {...formSchema.name.props, disabled: true}},
                financial_information_type: { ...formSchema.financial_information_type, data: financialDataTypes as IOptionTypeInCategoryAndSubcategory[]},
            }));
           
            setInitialLoading(false);
    };

    const getDetails = async () => {
        try {
            let res = await financialInformationService[parent === 'category' ? 'getCategory' : 'getSubCategory'](Number(id));
            reset({
                name: res.data.data.name,
                financial_information_type: res.data.data.financial_type_ids.map(item=> String(item)),
            });
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: message,
            });
        }
    };

    const onSubmit = async (_val: IFormData) => {
        let targetApi;
        let payload: IFinancialInformationStoreCategoryPayload = {
            financial_type_ids: _val.financial_information_type.map(item => Number(item)),
            name: _val.name,
            arabic_name: _val.arabic_name,
        };
        if (isEditingRule) targetApi = financialInformationService[parent === 'category' ? 'updateCategory' : 'updateSubCategory'](Number(id), payload);
        else targetApi = financialInformationService[parent === 'category' ? 'createNewCategory' : 'createNewSubCategory'](payload);

        try {
            setLoading(true);
            const response = await targetApi;
            toast.fire({
                icon: response.data.success ? TOAST_STATUS.SUCCESS : TOAST_STATUS.ERROR,
                title: response.data.message,
            });
            navigate(-1);
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: message,
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if(financialDataTypes) handleGetValues();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [financialDataTypes]);

    
    if(isEditingRule) {
        if(parent === 'category') {
            !checkPermissionAccess(PERMISSION_TYPES.SETTING_FI_CATEGORY_EDIT) && <></>;
        } else {
            !checkPermissionAccess(PERMISSION_TYPES.SETTING_FI_SUB_CATEGORY_EDIT) && <></>;
        }
    } else {
        if(parent === 'category') {
            !checkPermissionAccess(PERMISSION_TYPES.SETTING_FI_CATEGORY_CREATE) && <></>;
        } else {
            !checkPermissionAccess(PERMISSION_TYPES.SETTING_FI_SUB_CATEGORY_CREATE) && <></>;
        }
    }

    if (initialLoading) return <InitLoading />;

    return (
        <div className="grid justify-content-center my-8">
            <div className="xs:col:12 md:col-8 xl:col-5">
                <span className="block text-2xl font-semibold">{isEditingRule ? 'Update' : 'Add New'} {parent === 'category' ? 'Category' : 'Sub Category'}</span>
                <div className="mt-5">
                    <FormGenerator
                        onSubmit={handleSubmit(onSubmit)}
                        control={control}
                        errors={errors}
                        schema={formSchema}
                        noBackgroundLayout
                        buttons={
                            <>
                                <Button disabled={loading} loading={loading} color="primary">
                                    {isEditingRule ? 'Update' : 'Create'}
                                </Button>
                                <Button type="button" onClick={() => navigate(-1)}>
                                    Cancel
                                </Button>
                            </>
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default Add;
