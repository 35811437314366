import { useParams } from 'react-router-dom';
import { useAsync } from '../../../../../hooks/useAsync';
import { IParams } from 'src/types/params';
import { COMPANY_TYPE_ROUTE } from 'src/pages/Company/Add/enums/company-type-route';
import { ICommentShowResponse } from 'src/api/types/company';
import { companyService } from 'src/api/services/company';

export const useEditingMode = () => {
    const { commentId, companyId, companyType } = useParams<IParams>();
    const isEditingRule = Boolean(commentId);
    const { data, isLoading, isIdle } = useAsync<ICommentShowResponse, undefined>(
        () => {
            return new Promise(async (resolve, reject) => {
                if (!commentId || !isEditingRule) return reject('');
                try {
                    const response = await companyService(companyType as COMPANY_TYPE_ROUTE).showComment(Number(companyId), Number(commentId));
                    return resolve(response.data.data);
                } catch (e) {}
            });
        },
        {
            immediate: true,
        }
    );

    return {
        isEditingRule,
        completedData: data,
        isLoading: isLoading,
        companyType,
        companyId: String(companyId),
        commentId: String(commentId),
    };
};
