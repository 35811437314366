import { IPersonDetail } from 'src/api/types/persons';
import { timestampToDateFormat, timestampToDynamicDateFormat } from 'src/utils/timestamp-to-date-format';

export const personDetailDataCellRender = {
    updated_at: (data: IPersonDetail) => <span>{timestampToDateFormat(data.updated_at)}</span>,
    created_at: (data: IPersonDetail) => <span>{timestampToDateFormat(data.created_at)}</span>,
    join_date: (data: IPersonDetail) => (
        <span>{data.join_date && data.join_date_format ? timestampToDynamicDateFormat(data.join_date, data.join_date_format) : '-'}</span>
    ),
    end_date: (data: IPersonDetail) => (
        <span>{data.end_date && data.end_date_format ? timestampToDynamicDateFormat(data.end_date, data.end_date_format) : 'Current'}</span>
    ),
};
