import { RadioButton } from 'primereact/radiobutton'
import React from 'react'
import { IFinancialInformationRow } from 'src/pages/Company/Show/CompanyFinancialInformation/types'
import styles from './TargetItemCard.module.scss'


interface ITProps {
    child: IFinancialInformationRow;
    handleSelectItem: (child: IFinancialInformationRow) => void
}
const TargetItemCard: React.FC<ITProps> = ({ child, handleSelectItem }) => {

    return (
        <div className={styles.targetItemsCard} onClick={() => handleSelectItem(child)}>
            <RadioButton 
                checked={false}
            />
            <span>{child.name && child.name[0].name}</span>
        </div>
    )
}
export default TargetItemCard