import React, { useReducer } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import style from '../SidebarMenu.module.scss';
import ArrowDownIcon from 'src/assets/Icons/ArrowDownIcon';
import ArrowUpIcon from 'src/assets/Icons/ArrowUpIcon';
import { MenuProps } from 'src/types/menu';

const MenuItem: React.FC<MenuProps> = ({ href, icon, children, title }) => {
    const { pathname } = useLocation();
    const initState = pathname?.includes(href || '');

    const [showSubMenu, toggleSubMenu] = useReducer((prev) => !prev, initState);

    const ArrowIcon = showSubMenu ? ArrowUpIcon : ArrowDownIcon;

    return (
        <li className={showSubMenu ? style.active : ''}>
            <NavLink to={href || '#'} onClick={children?.length ? toggleSubMenu : undefined} className={({ isActive }) => (isActive ? style.active : '')}>
                {icon}
                <span>{title}</span>
            </NavLink>
            {children?.length && (
                <>
                    <ArrowIcon onClick={toggleSubMenu} />
                    <ul data-title={title}>{children}</ul>
                </>
            )}
        </li>
    );
};

export default MenuItem;
