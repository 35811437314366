import { getMaxError, getMinError, getRequireError } from 'src/utils/get-yup-errors';
import { string, object, array } from 'yup';

export const commentsValidationSchema = object().shape({
    topic: string().min(2, getMinError(2, 'Topic')).max(80, getMaxError(80, 'Topic')).required(getRequireError('Topic')),
    comment: string(),
    sources: array().of(
        object().shape({
            title: string(),
            link: string(),
        })
    ),
});
