import React, { useEffect, useState } from 'react';
import style from './AddUpdate.module.scss';
import DashboardContentWrapper from 'src/layouts/DashboardContentWrapper';
import { toast } from 'src/utils/toast';
import { TOAST_STATUS } from 'src/constants/toast-status';
import { useNavigate, useParams } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Checkbox } from 'primereact/checkbox';
import { roleService } from 'src/api/services/roles';
import Button from 'src/components/Kit/Button';
import InitLoading from 'src/components/App/Loading/InitLoading';
import { IPermissionOption, IPermissionOptionCategoryItem } from './types';
import { getAxiosError } from 'src/utils/get-axios-error';
import DashboardTitle from 'src/components/App/DashboardTitle';
import { useCheckPermissionAccess } from 'src/hooks/useCheckPermissionAccess';
import { PERMISSION_TYPES } from 'src/enums/permissions';

interface IParams {
    [id: string]: string;
}

const AddUpdate: React.FC = () => {
    const { checkPermissionAccess } = useCheckPermissionAccess();
    const navigate = useNavigate();
    const { id } = useParams<IParams>();
    const isEditingRule = Boolean(id);
    const [loading, setLoading] = useState<boolean>(false);
    const [updateLoading, setUpdateLoading] = useState<boolean>(true);
    const [selectedPermissions, setSelectedPermissions] = useState<number[]>([]);
    const [permissionOptions, setPermissionsOptions] = useState<IPermissionOption>({} as IPermissionOption);
    const [name, setName] = useState<string>('');
    const [nameError, setNameError] = useState<string>('');
    const [roleError, setRoleError] = useState<string>('');

    const onChangePermission = (permissionId: number) => {
        let tempSelectedPermissions = structuredClone(selectedPermissions);

        if (tempSelectedPermissions.includes(permissionId)) {
            tempSelectedPermissions = tempSelectedPermissions.filter((i: number) => i !== permissionId);
        } else {
            const permissionWithRelatedItems = permissionOptions.dependencies.find((item) => item.id === permissionId);
            if (permissionWithRelatedItems) {
                const checkRelatedItemsChecked = permissionWithRelatedItems.related_permission_id.every((item) => {
                    return tempSelectedPermissions.includes(item);
                });

                if (!checkRelatedItemsChecked) {
                    tempSelectedPermissions = Array.from(new Set([...tempSelectedPermissions, ...permissionWithRelatedItems.related_permission_id]));
                }
            }
            tempSelectedPermissions = [...tempSelectedPermissions, permissionId];
        }
        setSelectedPermissions(tempSelectedPermissions);
    };

    const handleSelectAll = (permissionIds: number[]) => {
        setSelectedPermissions((prev) => Array.from(new Set([...prev, ...permissionIds])));
    };
    const handleDeSelectAll = (permissionIds: number[]) => {
        setSelectedPermissions((prev) => prev.filter((id) => !permissionIds.includes(id)));
    };

    const checkValidation = () => {
        // TODO: handle show errors in a label.
        if (name === '') {
            setNameError('Name is mandatory');
            setRoleError('');
            return false;
        }
        if (selectedPermissions.length === 0) {
            setRoleError('Select at least one role');
            setNameError('');
            return false;
        }

        setRoleError('');
        setNameError('');
        return true;
    };

    const onSubmit = async () => {
        if (!checkValidation()) return;
        let targetApi;
        const data = {
            name,
            permissions: selectedPermissions,
        };

        if (isEditingRule) targetApi = roleService.update(Number(id), data);
        else targetApi = roleService.createNew(data);
        try {
            setLoading(true);
            let res = await targetApi;
            toast.fire({
                icon: TOAST_STATUS.SUCCESS,
                title: res.data.message,
            });
            navigate(-1);
            setLoading(false);
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: message,
            });
            setLoading(false);
        }
    };

    const getDetails = async () => {
        try {
            let res = await roleService.getOne(Number(id));
            setSelectedPermissions(res.data.data.permissions);
            setName(res.data.data.name);
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: message,
            });
        }
    };

    const getFieldOptions = async () => {
        setUpdateLoading(true);
        try {
            if (isEditingRule) await getDetails();
            const res = await roleService.getPermissions();
            setPermissionsOptions(res.data.data);
            setUpdateLoading(false);
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: message,
            });
            setUpdateLoading(false);
        }
    };

    useEffect(() => {
        getFieldOptions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isEditingRule && !checkPermissionAccess(PERMISSION_TYPES.ROLE_EDIT)) return <></>;
    if (!isEditingRule && !checkPermissionAccess(PERMISSION_TYPES.ROLE_CREATE)) return <></>;

    if (updateLoading) return <InitLoading />;

    const recursivelyRenderChildren = (childrenData: IPermissionOptionCategoryItem) => {
        const { key, parent, title } = childrenData;
        const children = permissionOptions.categories.filter((item) => item.parent === key);
        const items = permissionOptions.items.filter(({ category_key, parent_category_key }) => category_key === key && parent_category_key === parent);
        const selectAllChecked = items.every(({ id }) => selectedPermissions.includes(id));
        const itemsIds = items.map(({ id }) => id);
        return (
            <Accordion key={key} title={title} style={{ width: '100%', padding: '0' }}>
                <AccordionTab header={title}>
                    {children.length > 0 && children.map((childItem) => recursivelyRenderChildren(childItem))}
                    {items.length > 0 && (
                        <>
                            <div className={style.accordionItem} onClick={() => (selectAllChecked ? handleDeSelectAll(itemsIds) : handleSelectAll(itemsIds))}>
                                <Checkbox value={id} checked={selectAllChecked} />
                                <label>{selectAllChecked ? 'Deselect All' : 'Select All'}</label>
                            </div>
                            {items.map(({ id, title }, index) => (
                                <div className={style.accordionItem} key={id} onClick={() => onChangePermission(id)}>
                                    <Checkbox value={id} checked={selectedPermissions.includes(id)} />
                                    <label>{title}</label>
                                </div>
                            ))}
                        </>
                    )}
                </AccordionTab>
            </Accordion>
        );
    };

    return (
        <>
            <DashboardTitle
                title={isEditingRule ? 'Update Role' : 'Add New Role'}
                titleSuffix={
                    <div style={{ marginLeft: 'auto' }}>
                        <Button size="s" type="button" onClick={() => navigate(-1)}>
                            Cancel
                        </Button>
                        <Button size="s" disabled={loading} loading={loading} color="primary" onClick={onSubmit}>
                            Update
                        </Button>
                    </div>
                }
            />
            <DashboardContentWrapper>
                {nameError ? <p className="form-input-error">{nameError}</p> : null}
                <p className="form-input-error">{roleError}</p>
                <div className={style.roleInputContainer}>
                    <span className="block text-base font-semibold">Role Name &nbsp;</span>
                    <InputText
                        style={{ width: '100%', maxWidth: '410px' }}
                        value={name}
                        className="p-inputtext"
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Role Name"
                        required
                    />
                </div>
                <span className="block text-base font-semibold mb-3 mt-3">Permission &nbsp;</span>
                {permissionOptions.categories.filter((item) => item.parent === null).map((item) => recursivelyRenderChildren(item))}
            </DashboardContentWrapper>
        </>
    );
};

export default AddUpdate;
