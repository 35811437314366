import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import ROUTE_CONSTANTS from 'src/Routes/constants/route-constants';
import { useUser, useClearUser } from 'src/context/global-context';
import { authService } from 'src/api/services/auth';
import { ACCESS_TOKEN } from '../../../../constants/access-token';
import { toast } from 'src/utils/toast';
import styles from './../Header.module.scss';
import { TOAST_STATUS } from 'src/constants/toast-status';
import { Avatar } from 'primereact/avatar';
import { OverlayPanel } from 'primereact/overlaypanel';
import { classNames } from 'primereact/utils';

const HeaderProfile: React.FC = () => {
    const user = useUser();
    const clearUser = useClearUser();
    const navigate = useNavigate();
    const profileOverlayPanelRef = useRef<any>(null);


    const logOut = async () => {
        try {
            await authService.logout();
            localStorage.removeItem(ACCESS_TOKEN);
            clearUser();
            navigate(ROUTE_CONSTANTS.AUTH.LOGIN.ABSOLUTE);
        } catch (err) {
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: 'Server Error!',
            });
        }
    };

    if (!user) {
        return <></>;
    }

    return (
        <>
            <div onClick={(e) => profileOverlayPanelRef.current.toggle(e)} className={styles.profile}>
                <div>
                    <Avatar size='normal' shape="circle" />
                </div>
                <span className='ml-2'>{user?.username}</span>
                <i className="pi pi-chevron-down ml-2"></i>
            </div>
            <OverlayPanel ref={profileOverlayPanelRef} className={styles.overlayPanel}>
                <div className={styles.overlayBodyItem} onClick={logOut}>
                    <i className={classNames("ts-logout", styles.item)}></i>
                    <span style={{marginLeft: '10px'}}>Logout</span>
                </div>
            </OverlayPanel>
        </>

    );
};

export default HeaderProfile;
