import { DATE_TYPE, FORM_INPUTS } from 'src/types/form';
import { IFormGeneratorSchemaType } from 'src/types/form-generator-schema-type';

export type AssignPersonSchemaFields = 'type' | 'person_id' | 'company_name' | 'person_position_id' | 'join_date' | 'end_date';
export type AssignPersonSchemaType = Record<AssignPersonSchemaFields, IFormGeneratorSchemaType>;

export const assignPersonSchema: AssignPersonSchemaType = {
    type: {
        placeholder: 'Type',
        type: FORM_INPUTS.SELECT,
        props: {
            required: true,
        },
    },
    person_id: {
        placeholder: 'Person',
        type: FORM_INPUTS.SELECT,
        props: {
            required: true,
            filter: true,
        },
    },
    company_name: {
        placeholder: 'Company',
        type: FORM_INPUTS.TEXT,
        props: {
            disabled: true,
        },
    },
    person_position_id: {
        placeholder: 'Position',
        type: FORM_INPUTS.SELECT,
        props: {
            required: true,
            filter: true,
            disabled: true,
        },
    },
    join_date: {
        placeholder: 'Join Date',
        type: FORM_INPUTS.DATE_DYNAMIC,
        defaultValue: {
            date: undefined,
            dateType: DATE_TYPE.DATE,
        },
        props: {
            maxDate: new Date(),
        },
    },
    end_date: {
        placeholder: 'End Date',
        type: FORM_INPUTS.DATE_DYNAMIC,
        defaultValue: {
            date: undefined,
            dateType: DATE_TYPE.DATE,
        },
        props: {
            maxDate: new Date(),
        },
    },
} as const;
