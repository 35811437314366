import { Dispatch, SetStateAction, createContext, useContext, useState } from 'react';
import { IFunctionalComponentProps } from 'src/types/functional-component';
import { IMultiStepFormContext } from '../types/multi-step-form-context';
import { StepConfig } from '../types/step-config';
import { IMultiStepFormValues } from '../types/form-fields-values';
import { IFormFieldsData } from '../types/form-fields-data';

interface IProps extends IFunctionalComponentProps {
    onCancel: () => void;
    onComplete: (
        formState: IMultiStepFormValues,
        setStepsData: Dispatch<SetStateAction<StepConfig[]>>,
        setLoading: Dispatch<SetStateAction<boolean>>,
        setActiveStepIndex: Dispatch<SetStateAction<number>>
    ) => void;
    title: string;
    subTitle?: string;
    steps: StepConfig[];
}

export const MultiStepFormContext = createContext<IMultiStepFormContext>({} as IMultiStepFormContext);

const MultiStepFormProvider: React.FC<IProps> = ({ title, subTitle, steps, onCancel, onComplete, children }) => {
    const [formValues, setFormValues] = useState<IMultiStepFormValues>({});
    const [formImages, setFormImages] = useState<{ id: string; url: string }[]>([]);
    const [stepsData, setStepsData] = useState(steps);
    const [formFieldsData, setFormFieldsData] = useState<IFormFieldsData>({});
    const [activeStepIndex, setActiveStepIndex] = useState(0);
    const [loading, setLoading] = useState<boolean>(false);

    const checkValidationForms = (currentState: StepConfig[], isValid: boolean) => {
        const otherFormsValidationStatusArray = currentState
            .filter(({ id }) => id !== currentState.length - 1 && id !== activeStepIndex)
            .map(({ hasError, opened }) => !hasError && opened);
        return [...otherFormsValidationStatusArray, isValid].every((item) => item);
    };

    const contextValue = {
        title,
        subTitle,
        formValues,
        setFormValues,
        setFormImages,
        formImages,
        stepsData,
        setStepsData,
        activeStepIndex,
        setActiveStepIndex,
        onCancel,
        loading,
        formFieldsData,
        setFormFieldsData,
        checkValidationForms,
        onComplete: () => {
            onComplete(formValues, setStepsData, setLoading, setActiveStepIndex);
        },
    };

    return <MultiStepFormContext.Provider value={contextValue}>{children}</MultiStepFormContext.Provider>;
};

const useMultiStepFormContext = (): IMultiStepFormContext => {
    const context = useContext(MultiStepFormContext);

    if (!context) {
        throw new Error('useMultiStepFormContext must be used within a MultiStepFormProvider');
    }

    return context;
};

export { MultiStepFormProvider, useMultiStepFormContext };
