import { IUserRequest } from 'src/api/types/user';
import { timestampToDateFormat } from 'src/utils/timestamp-to-date-format';
import style from './UserRequestList.module.scss';

export const userRequestCellRender = {
    email: (data: IUserRequest) => (
        <>
            <p className={style.userRequestListEmail}>{data.email}</p>
            {data.is_having_request_before ? <small className={style.userRequestListBadge}>Has Request</small> : ''}
        </>
    ),
    requested_at: (data: IUserRequest) => <>{timestampToDateFormat(data.requested_at)}</>,
    approved_at: (data: IUserRequest) => <>{timestampToDateFormat(data.approved_at)}</>,
    rejected_at: (data: IUserRequest) => <>{timestampToDateFormat(data.rejected_at)}</>,
    updated_at: (data: IUserRequest) => <>{timestampToDateFormat(data.updated_at)}</>,
    created_at: (data: IUserRequest) => <>{timestampToDateFormat(data.created_at)}</>,
};
