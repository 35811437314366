import React, { Dispatch, SetStateAction, useState } from 'react';
import DashboardTitle from 'src/components/App/DashboardTitle';
import Table from 'src/components/App/Table/index';
import Button from 'src/components/Kit/Button';
import { Helmet } from 'react-helmet-async';
import { Column } from 'primereact/column';
import ROUTE_CONSTANTS from 'src/Routes/constants/route-constants';
import { positionService } from 'src/api/services/position-service';
import { toast } from 'src/utils/toast';
import { TOAST_STATUS } from 'src/constants/toast-status';
import { IPosition } from 'src/api/types/position';
import DashboardContentWrapper from 'src/layouts/DashboardContentWrapper';
import DeleteIcon from 'src/assets/Icons/DeleteIcon';
import { modalActions } from 'src/context/modals';
import { MODAL_TYPES } from 'src/types/modals';
import { getAxiosError } from 'src/utils/get-axios-error';
import { useCheckPermissionAccess } from 'src/hooks/useCheckPermissionAccess';
import { PERMISSION_TYPES } from 'src/enums/permissions';
import { cellRender } from './cellRender';
import { IDataTablePayload } from 'src/api/types/table';

const service = async (queryParams?: IDataTablePayload) => positionService.getAll(queryParams);
const List: React.FC = () => {
    const { checkPermissionAccess } = useCheckPermissionAccess();
    const [findPositionUsageLoading, setFindPositionUsageLoading] = useState<number>();

    const handleRemove = (id: number, textMessage: string, setTableData: Dispatch<SetStateAction<IPosition[]>>) => {
        const handleRemoveItem = async () => {
            try {
                const deleteResult = await positionService.delete(id);
                if (deleteResult.data.success) {
                    setTableData((tableData: IPosition[]) => tableData.filter((item: IPosition) => item.id !== id));
                    toast.fire({
                        icon: TOAST_STATUS.SUCCESS,
                        title: deleteResult.data.message,
                    });
                } else {
                    toast.fire({
                        icon: TOAST_STATUS.ERROR,
                        title: deleteResult.data.message,
                    });
                }
            } catch (err) {
                const error = getAxiosError(err);
                const message = error?.message || 'Server Error';
                toast.fire({
                    icon: TOAST_STATUS.ERROR,
                    title: message,
                });
            }
        };
        modalActions.addModal(MODAL_TYPES.CONFIRMATION_MODAL, {
            header: 'Delete Confirm',
            text: textMessage,
            handleAction: handleRemoveItem,
        });
    };

    const suffixCols = (data: IPosition[], setTableData: Dispatch<SetStateAction<IPosition[]>>) => {
        const handleDeleteItem = async (rowData: IPosition) => {
            let message = ''
            setFindPositionUsageLoading(rowData.id);
            try {
                let res = await positionService.checkPositionUsed(rowData.id);
                if (res.data.data.hasActiveCompany) {
                    message = 'This position used in some companies. if you delete this position all people in these companies are removed from the company, Are you confirm that?'
                } else {
                    message = 'This position does not assign to any company, Please confirm if you want to delete it!';
                }
                handleRemove(rowData.id, message, setTableData);
                setFindPositionUsageLoading(undefined);
            } catch (err) {
                const error = getAxiosError(err);
                const message = error?.message || 'Server Error';
                toast.fire({
                    icon: TOAST_STATUS.ERROR,
                    title: message,
                });
                setFindPositionUsageLoading(undefined);
            }
        };
        return (
            <Column
                key="Action"
                body={(rowData: IPosition) => (
                    <>
                        {
                            checkPermissionAccess(PERMISSION_TYPES.POSITION_DELETE) &&
                            <Button
                                color="secondary"
                                iconOnly
                                size="m"
                                loading={findPositionUsageLoading === rowData.id}
                                disabled={findPositionUsageLoading === rowData.id}
                                onClick={() => handleDeleteItem(rowData)}
                            >
                                <DeleteIcon />
                            </Button>
                        }
                    </>
                )}
            />
        );
    };

    return (
        <>
            <Helmet>
                <title>Teaser | Position List</title>
            </Helmet>
            <DashboardTitle
                title={'Position List'}
                titleSuffix={
                    checkPermissionAccess(PERMISSION_TYPES.POSITION_CREATE)
                    ? <Button size="s" link color="primary" to={ROUTE_CONSTANTS.PERSONS.POSITION.ADD_NEW.ROOT.ABSOLUTE}>
                        Add New Position
                    </Button>
                    : <></>
                }
            />
            <DashboardContentWrapper>
                {checkPermissionAccess(PERMISSION_TYPES.POSITION_LIST_VIEW) && <Table getTableData={service} cellRender={cellRender} suffixCols={suffixCols} />}
            </DashboardContentWrapper>
        </>
    );
};

export default List;
