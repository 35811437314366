import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import FormGenerator from 'src/components/Kit/FormGenerator/index';
import { useMultiStepFormContext } from 'src/components/Kit/MultiStepForm/context';
import { IFormGeneratorGeneralSchemaType } from 'src/types/form-generator-schema-type';
import { OtherInformationSchemaFields, otherInformationSchema } from '../../../types/other-information-form-fields-schema';
import { ContactInformationSchemaFields, contactInformationSchema } from '../../../types/contact-information-form-fields-schema';
import { FORM_INPUTS } from 'src/types/form';
import style from './ConfirmationCompanyForm.module.scss';
import { generateDefaultValueFromFormSchema } from 'src/utils/generate-default-value-from-form-schema';
import { BasicInformationSchemaFields, basicInformationSchema } from '../../../types/basic-information-form-fields-schema';
import { IBasicInformationFormSchema } from '../../../types/basic-information-form-schema';

type ConfirmationSchemaFields = Record<
    BasicInformationSchemaFields | OtherInformationSchemaFields | ContactInformationSchemaFields,
    IFormGeneratorGeneralSchemaType
>;

const ConfirmationCompanyForm: React.FC = () => {
    const { formValues, formFieldsData } = useMultiStepFormContext();
    const [contactInformationSchemaState, setContactInformationSchemaState] =
        useState<Record<ContactInformationSchemaFields, IFormGeneratorGeneralSchemaType>>(contactInformationSchema);
    const confirmationFormSchema = { ...basicInformationSchema, ...otherInformationSchema, ...contactInformationSchemaState };

    const {
        control,
        setValue,
        formState: { errors },
    } = useForm<IBasicInformationFormSchema>({
        defaultValues: generateDefaultValueFromFormSchema(confirmationFormSchema),
        mode: 'all',
    });

    useEffect(() => {
        Object.entries(formValues).forEach(([key, value]) => {
            let label = value;
            if (key === 'currency_id' || key === 'country_id' || key === 'stock_exchange_id') {
                label = formFieldsData[key]?.length ? formFieldsData[key].find((item) => item.value === value)?.label || value : value;
            }
            setValue(key as keyof IBasicInformationFormSchema, label);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formValues]);

    const updateSchema = (prev: ConfirmationSchemaFields) => {
        const updatedObject = JSON.parse(JSON.stringify(prev));
        for (const key in updatedObject) {
            const prevKey = key as keyof ConfirmationSchemaFields;
            if (prev[prevKey].type === FORM_INPUTS.SELECT) {
                updatedObject[prevKey].type = FORM_INPUTS.TEXT;
            }
            updatedObject[prevKey].props = { ...prev[prevKey].props, disabled: true, required: false };
        }

        return updatedObject;
    };

    return (
        <div className={style.confirmationCompanyForm}>
            <div className={style.confirmationCompanyFormHeader}>
                <h3>Basic Information</h3>
            </div>
            <div className="grid justify-content-center">
                <div className="xs:col:12 md:col-6">
                    <FormGenerator
                        control={control}
                        errors={errors}
                        schema={updateSchema(basicInformationSchema as ConfirmationSchemaFields)}
                        noBackgroundLayout
                    />
                </div>
            </div>
            <div className={style.confirmationCompanyFormHeader}>
                <h3>Other Information</h3>
            </div>
            <div className="grid justify-content-center">
                <div className="xs:col:12 md:col-6">
                    <FormGenerator
                        control={control}
                        errors={errors}
                        schema={updateSchema(otherInformationSchema as ConfirmationSchemaFields)}
                        noBackgroundLayout
                    />
                </div>
            </div>
            <div className={style.confirmationCompanyFormHeader}>
                <h3>Contact Information</h3>
            </div>
            <div className="grid justify-content-center">
                <div className="xs:col:12 md:col-6">
                    <FormGenerator
                        control={control}
                        errors={errors}
                        schema={updateSchema(contactInformationSchemaState as ConfirmationSchemaFields)}
                        noBackgroundLayout
                    />
                </div>
            </div>
        </div>
    );
};

export default ConfirmationCompanyForm;
