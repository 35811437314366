import React, { useLayoutEffect, useState } from 'react';
import FormGenerator from 'src/components/Kit/FormGenerator/index';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { createValidationSchema, updateValidationSchema } from './validation-schema';
import { userSchema, TUserSchema } from './schema';
import Button from 'src/components/Kit/Button';
import { roleService } from 'src/api/services/roles';
import { userService } from 'src/api/services/user';
import { useNavigate, useParams } from 'react-router-dom';
import InitLoading from 'src/components/App/Loading/InitLoading';
import { ICreateUserPayload, IUpdateUserPayload } from 'src/api/types/user';
import { getAxiosError } from 'src/utils/get-axios-error';
import { toast } from 'src/utils/toast';
import { TOAST_STATUS } from 'src/constants/toast-status';
import { useCheckPermissionAccess } from 'src/hooks/useCheckPermissionAccess';
import { PERMISSION_TYPES } from 'src/enums/permissions';

interface IParams {
    [id: string]: string;
}

interface IFormData {
    full_name: string;
    email: string;
    username: string;
    role_id: string | number;
    password: string;
}

const AddUpdate: React.FC = () => {
    const { checkPermissionAccess } = useCheckPermissionAccess();
    const navigate = useNavigate();
    const { id } = useParams<IParams>();
    const isEditingRule = Boolean(id);
    const [loading, setLoading] = useState<boolean>(false);
    const [initialLoading, setInitialLoading] = useState<boolean>(true);
    const [formSchema, setFormSchema] = useState<TUserSchema>();

    const {
        control,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm<IFormData>({
        defaultValues: undefined,
        mode: 'all',
        resolver: yupResolver(isEditingRule ? updateValidationSchema : createValidationSchema),
    });

    const onSubmit = async (_val: ICreateUserPayload) => {
        let targetApi;
        let payload: ICreateUserPayload;

        if (isEditingRule) {
            targetApi = userService.update(Number(id), _val as IUpdateUserPayload);
        } else {
            payload = {
                ..._val,
                password: _val.password,
                username: _val.username,
            };
            targetApi = userService.createNew(payload);
        }

        try {
            setLoading(true);
            await targetApi;
            setLoading(false);
            navigate(-1);
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: message,
            });
            setLoading(false);
        }
    };

    const handleGetRoles = async (tempFormSchema: TUserSchema) => {
        let userData;

        if (isEditingRule) {
            userData = await getDetails();
            reset({
                full_name: userData?.full_name,
                email: userData?.email,
                username: userData?.username,
                role_id: userData?.roles[0]?.id || undefined,
            });
        }

        if (isEditingRule && !userData?.roles[0]?.id) {
            delete tempFormSchema['password'];
            delete tempFormSchema['username'];
            delete tempFormSchema['role_id'];
            setFormSchema(tempFormSchema);
            setInitialLoading(false);
            return;
        }

        try {
            setInitialLoading(true);
            let rolesResponse = await roleService.selectBox();
            const tempRoles = rolesResponse.data.data.map(({ label, value }) => ({
                label,
                value: String(value),
            }));

            tempFormSchema = {
                ...tempFormSchema,
                role_id: { ...tempFormSchema.role_id, data: tempRoles },
            };

            if (isEditingRule) {
                delete tempFormSchema['username'];
                delete tempFormSchema['password'];
            }

            setFormSchema(tempFormSchema);
            setInitialLoading(false);
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: message,
            });
            setInitialLoading(false);
        }
    };

    const getDetails = async () => {
        try {
            let res = await userService.getOne(Number(id));

            if (!res.data.data) throw new Error('Error in getting details of user');
            else return res.data.data;
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: message,
            });
        }
    };

    useLayoutEffect(() => {
        let tempFormSchema: TUserSchema = structuredClone(userSchema);
        handleGetRoles(tempFormSchema);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isEditingRule && !checkPermissionAccess(PERMISSION_TYPES.USER_EDIT)) return null;
    if (!isEditingRule && !checkPermissionAccess(PERMISSION_TYPES.USER_CREATE)) return null;
    if (initialLoading) return <InitLoading />;

    return (
        <div className="grid justify-content-center my-8">
            <div className="xs:col:12 md:col-8 xl:col-5">
                <span className="block text-2xl font-semibold">{isEditingRule ? 'Update User' : 'Add New User'}</span>
                <div className="mt-5">
                    <FormGenerator
                        onSubmit={handleSubmit(onSubmit)}
                        control={control}
                        errors={errors}
                        schema={formSchema || {}}
                        noBackgroundLayout
                        buttons={
                            <>
                                <Button disabled={loading} loading={loading} color="primary">
                                    {isEditingRule ? 'Update User' : 'Create User'}
                                </Button>
                                <Button type="button" onClick={() => navigate(-1)}>
                                    Cancel
                                </Button>
                            </>
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default AddUpdate;
