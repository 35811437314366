import { SVGProps } from 'react';

const ArrowRightDoubleIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none" {...props}>
            <path d="M14 16L18 12L14 8" stroke={props.stroke || 'currentColor' || '#323232'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7 17L12 12L7 7" stroke={props.stroke || 'currentColor' || '#323232'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default ArrowRightDoubleIcon;
