import { SVGProps } from 'react';

const ArrowUpIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
            <path d="M16 14L12 10L8 14" stroke={props.stroke || 'currentColor' || '#3861FB'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default ArrowUpIcon;
