import React, { useEffect, useState } from 'react';
import { otherInformationValidationSchema } from '../../../validationSchemas/other-information-validation-schema';
import { useForm } from 'react-hook-form';
import { generateDefaultValueFromFormSchema } from 'src/utils/generate-default-value-from-form-schema';
import { yupResolver } from '@hookform/resolvers/yup';
import FormGenerator from 'src/components/Kit/FormGenerator/index';
import { toast } from 'src/utils/toast';
import { TOAST_STATUS } from 'src/constants/toast-status';
import { getAxiosError } from 'src/utils/get-axios-error';
import { useMultiStepFormContext } from 'src/components/Kit/MultiStepForm/context';
import { IFormGeneratorGeneralSchemaType } from 'src/types/form-generator-schema-type';
import { OtherInformationSchemaFields, otherInformationSchema } from '../../../types/other-information-form-fields-schema';
import { IOtherInformationFormSchema } from '../../../types/other-information-form-schema';
import { stockExchangeService } from 'src/api/services/stock-exchanges-service';
import { COMPANY_TYPE } from '../../enums/company-type';
import { StepConfig } from 'src/components/Kit/MultiStepForm/types/step-config';
import { sectorService } from 'src/api/services/sector-service';
import { legalStructuresService } from 'src/api/services/legal-structures';
import { ISelectBox } from 'src/api/types/base-types';
import { useCheckPermissionAccess } from 'src/hooks/useCheckPermissionAccess';
import { checkCompanyType } from 'src/utils/check-company-type';
import { COMPANY_TYPE_ROUTE } from '../../enums/company-type-route';
import { PERMISSION_TYPES } from 'src/enums/permissions';
import { companyTypeOptions } from '../../constants/company-type-options';

const OtherInformationCompanyForm: React.FC = () => {
    const { checkPermissionAccess } = useCheckPermissionAccess();
    const { setStepsData, setFormValues, formValues, activeStepIndex, formFieldsData, setFormFieldsData } = useMultiStepFormContext();
    const [formSchema, setFormSchema] = useState<Record<OtherInformationSchemaFields, IFormGeneratorGeneralSchemaType>>(otherInformationSchema);
    const availableTypes = companyTypeOptions.filter((item) =>
        checkPermissionAccess(checkCompanyType(PERMISSION_TYPES.COMPANY_INFORMATION_EDIT, String(item.value).toLocaleLowerCase() as COMPANY_TYPE_ROUTE))
    );

    const {
        control,
        getValues,
        setValue,
        watch,
        formState: { errors, isValid, isDirty },
    } = useForm<IOtherInformationFormSchema>({
        defaultValues: generateDefaultValueFromFormSchema(otherInformationSchema),
        mode: 'all',
        resolver: yupResolver(otherInformationValidationSchema),
    });

    const fetchSectorSelectBoxData = async (search?: string) => {
        const currentCompanyType = getValues('type')!;

        const sectorOptionsResponse = await sectorService.selectBox({
            company_type: currentCompanyType || null,
            ...(search ? { text: search } : {}),
        });

        const data = sectorOptionsResponse.data.data.map(({ label, value }) => ({
            label,
            value: String(value),
        }));

        return data;
    };

    const fetchStockExchangeSelectBoxData = async (search: string) => {
        const stockExchangeOptionsResponse = await stockExchangeService.selectBox({
            text: search,
        });

        const data = stockExchangeOptionsResponse.data.data.map(({ label, value }) => ({
            label,
            value: String(value),
        }));

        return data;
    };

    const fetchLegalStructuresSelectBoxData = async (search: string) => {
        const optionsResponse = await legalStructuresService.selectBox({
            text: search,
        });

        const data = optionsResponse.data.data.map(({ label, value }) => ({
            label,
            value: String(value),
        }));

        return data;
    };

    const handleUpdateDropdownOptions = async (fieldName: OtherInformationSchemaFields, getNewOptions: any) => {
        // TODO: how to set this function type? now we have IGetNewOptionsFunction but not working
        setFormSchema((prev) => ({
            ...prev,
            [fieldName]: {
                ...formSchema[fieldName],
                data: [],
                props: {
                    ...formSchema[fieldName].props,
                    loading: true,
                },
            },
        }));

        const data = await getNewOptions;

        setFormSchema((prev) => ({
            ...prev,
            [fieldName]: {
                ...formSchema[fieldName],
                data,
                props: {
                    ...formSchema[fieldName].props,
                    loading: false,
                    disabled: false,
                    onFilter,
                },
            },
        }));
    };

    const onFilter = (fieldName: string, value: string) => {
        switch (fieldName) {
            case 'sector_id':
                handleUpdateDropdownOptions(fieldName, fetchSectorSelectBoxData(value));
                break;
            case 'stock_exchange_id':
                handleUpdateDropdownOptions(fieldName, fetchStockExchangeSelectBoxData(value));
                break;
            case 'legal_structure_id':
                handleUpdateDropdownOptions(fieldName, fetchLegalStructuresSelectBoxData(value));
                break;
        }
    };

    const getFieldOptions = async () => {
        let stockExchangesData: ISelectBox[] = [];
        let sectorsData: ISelectBox[] = [];
        let legalStructuresData: ISelectBox[] = [];
        try {
            if (formFieldsData['stock_exchange_id']?.length && formFieldsData['sector_id']?.length) {
                stockExchangesData = formFieldsData.stock_exchange_id;
                sectorsData = formFieldsData.sector_id;
                legalStructuresData = formFieldsData.legal_structure_id;
            } else {
                const [stockExchangeRes, sectorRes, legalStructuresRes] = await Promise.all([
                    stockExchangeService.selectBox(),
                    sectorService.selectBox({ company_type: null }),
                    legalStructuresService.selectBox(),
                ]);
                stockExchangesData = stockExchangeRes.data.data.map(({ label, value }) => ({
                    label,
                    value: String(value),
                }));
                sectorsData = sectorRes.data.data.map(({ label, value }) => ({
                    label,
                    value: String(value),
                }));
                legalStructuresData = legalStructuresRes.data.data.map(({ label, value }) => ({
                    label,
                    value: String(value),
                }));
                setFormFieldsData((prev) => ({
                    ...prev,
                    stock_exchange_id: stockExchangesData,
                    sector_id: sectorsData,
                    legal_structure_id: legalStructuresData,
                }));
            }

            const newFormSchemaState = {
                ...formSchema,
                stock_exchange_id: {
                    ...formSchema.stock_exchange_id,
                    data: stockExchangesData,
                    props: { ...formSchema.stock_exchange_id.props, disabled: false, onFilter },
                },
                sector_id: {
                    ...formSchema.sector_id,
                    data: sectorsData,
                    props: { ...formSchema.sector_id.props, disabled: false, onFilter },
                },
                legal_structure_id: {
                    ...formSchema.legal_structure_id,
                    data: legalStructuresData,
                    props: { ...formSchema.legal_structure_id.props, disabled: false, onFilter },
                },
                type: {
                    ...formSchema.type,
                    props: { ...formSchema.type.props, hidden: availableTypes.length === 1 },
                    data: availableTypes,
                },
            };
            
            if (availableTypes.length === 1) {
                setValue('type', availableTypes[0].value as COMPANY_TYPE);
            }

            setFormSchema(newFormSchemaState);
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: message,
            });
        }
    };

    const handleUpdateSteps = () => {
        setStepsData((prevStepsData: StepConfig[]) => {
            return prevStepsData.map((step: StepConfig) => {
                if (isValid) {
                    // Hint: we let admin to push next step button and go forward with the form
                    if (step.id === activeStepIndex + 1) {
                        return { ...step, disabled: false };
                    } else return step;
                } else {
                    // Hint: current step has problem, so we disable all next steps till this step become fine
                    if (step.id > activeStepIndex) {
                        return { ...step, disabled: true };
                    } else return step;
                }
            });
        });
    };

    useEffect(() => {
        handleUpdateSteps();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isValid]);

    useEffect(() => {
        return () => {
            const currentValues = getValues();
            const deletedKeys = Object.keys(currentValues).filter(
                (k) => currentValues[k as keyof IOtherInformationFormSchema] === null || currentValues[k as keyof IOtherInformationFormSchema] === ''
            );

            setFormValues((prev) => {
                const newFormValue = { ...prev, ...currentValues };
                Object.keys(newFormValue).forEach((k) => deletedKeys.includes(k) && delete newFormValue[k as keyof IOtherInformationFormSchema]);
                return newFormValue;
            });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getFieldOptions();
        if (Object.keys(formValues).length) {
            // Hint: this condition is for those moments we are coming back from next steps.
            Object.entries(formValues).forEach(([key, value]) =>
                setValue(key as keyof IOtherInformationFormSchema, value as string | number | undefined, { shouldValidate: true })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isDirty) {
            setStepsData((prevStepsData: StepConfig[]) => {
                return prevStepsData.map((step: StepConfig) => {
                    if (step.id === activeStepIndex) {
                        return { ...step, hasError: false, errors: {} };
                    } else return step;
                });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDirty]);

    useEffect(() => {
        const subscription = watch(async (value, { name }) => {
            const currentValues = getValues();
            const deletedKeys = Object.keys(currentValues).filter(
                (k) => currentValues[k as keyof IOtherInformationFormSchema] === null || currentValues[k as keyof IOtherInformationFormSchema] === ''
            );

            setFormValues((prev) => {
                const newFormValue = { ...prev, ...currentValues };
                Object.keys(newFormValue).forEach((k) => deletedKeys.includes(k) && delete newFormValue[k as keyof IOtherInformationFormSchema]);
                return newFormValue;
            });

            if (name === 'type' && value.type) {
                setValue('sector_id', undefined);
                const sectors = await fetchSectorSelectBoxData();

                setFormSchema((prev) => ({
                    ...prev,
                    sector_id: {
                        ...prev.sector_id,
                        data: sectors,
                    },
                    ISIN: {
                        ...prev.ISIN,
                        props: { ...prev.ISIN.props, required: value.type === COMPANY_TYPE.PUBLIC },
                    },
                    issued_shared: {
                        ...prev.issued_shared,
                        props: { ...prev.issued_shared.props, required: value.type === COMPANY_TYPE.PUBLIC },
                    },
                    stock_exchange_id: {
                        ...prev.stock_exchange_id,
                        props: { ...prev.stock_exchange_id.props, required: value.type === COMPANY_TYPE.PUBLIC },
                    },
                }));
            }
        });
        return () => subscription.unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch]);

    return (
        <div className="grid justify-content-center">
            <div className="xs:col:12 md:col-6">
                <FormGenerator control={control} errors={errors} schema={formSchema} noBackgroundLayout />
            </div>
        </div>
    );
};

export default OtherInformationCompanyForm;
