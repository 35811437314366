import { CHAMBER } from '../../enums/chamber';

export const chamberDropdownOptions = [
    { label: CHAMBER.CHAMBER_OF_COMMERCE_KUWAIT, value: '1' },
    { label: CHAMBER.BAHRAIN_CHAMBER_OF_COMMERCE_SIJILAT, value: '2' },
    { label: CHAMBER.SAUDI_CHAMBER_OF_COMMERCE, value: '3' },
    { label: CHAMBER.DUBAI_CHAMBER_OF_COMMERCE, value: '4' },
    { label: CHAMBER.ABU_DHABI_CHAMBER_OF_COMMERCE, value: '5' },
    { label: CHAMBER.SHARJAH_CHAMBER, value: '6' },
    { label: CHAMBER.FUJAIRAH_CHAMBER, value: '7' },
    { label: CHAMBER.AJMAN_CHAMBER, value: '8' },
    { label: CHAMBER.UM_ALQAIWAIN_CHAMBER_OF_COMMERCE, value: '9' },
    { label: CHAMBER.RIYADH_CHAMBER_OF_COMMERCE, value: '10' },
    { label: CHAMBER.JEDDAH_CHAMBER_OF_COMMERCE, value: '11' },
    { label: CHAMBER.MAKKAH_CHAMBER, value: '12' },
    { label: CHAMBER.JORDAN_CHAMBER_OF_COMMERCE, value: '13' },
    { label: CHAMBER.QATAR_CHAMBER_OF_COMMERCE, value: '14' },
    { label: CHAMBER.OMAN_CHAMBER_OF_COMMERCE, value: '15' },
    { label: CHAMBER.AMMAN_CHAMBER, value: '16' },
];
