import { useEffect, useState } from 'react';
import { FinancialInformationRatioDataContext } from 'src/api/types/company';
import { useWindowSize } from 'src/hooks/useWindowSize';
import { generateValue } from 'src/utils/generate-ratio-formula';
import style from './Formula.module.scss';
import { classNames } from 'primereact/utils';
import { financialValuesStyle } from 'src/utils/financial-values-style';

interface IProps {
    numerator?: FinancialInformationRatioDataContext;
    denominator?: FinancialInformationRatioDataContext | null;
    equalValue: string | null;
    equalName: string;
    equalPrefix?: string | number | undefined;
    equalSuffix?: string | number | undefined;
    showPercent?: boolean;
    currency?: string | null;
}

const RatioFormula: React.FC<IProps> = ({
    numerator,
    denominator,
    equalValue,
    equalName,
    equalPrefix = '',
    equalSuffix = '',
    showPercent = false,
    currency = '',
}) => {
    const { width } = useWindowSize();
    const [showFormulaState, setShowFormulaState] = useState(width > 576 ? false : true);

    useEffect(() => {
        setShowFormulaState((prev) => (width > 576 && prev === false ? false : true));
    }, [width]);

    return (
        <div className={style.wrapper}>
            <span className={classNames(!showFormulaState && style.active)} onClick={() => setShowFormulaState((prev) => !prev)}>
                Show Formula
            </span>
            <div className={style.wrapperFormula}>
                <div className={style.wrapperFormulaValue}>
                    <span>{showFormulaState && equalValue ? financialValuesStyle(equalValue, { prefix: equalPrefix, suffix: equalSuffix }) : equalName}</span>
                    <small>=</small>
                </div>
                <div className={style.wrapperFormulaFraction}>
                    <div className="numerator">{numerator && generateValue(numerator, showFormulaState, currency)}</div>
                    {denominator && <div className="denominator">{generateValue(denominator, showFormulaState, currency)}</div>}
                </div>
                {showPercent && (
                    <div className={style.wrapperFormulaPercent}>
                        <small>×</small> 100
                    </div>
                )}
            </div>
        </div>
    );
};

export default RatioFormula;
