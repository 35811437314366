import * as Yup from 'yup';
import { object, string } from 'yup';
import { transactionInformationValidationSchema } from './transaction-row/validation-schema';

export const shareHolderValidationSchema = object().shape({
    'share-holder-type': string().required('ShareHolder Type is required'),
    'share-holder-name': string().required('ShareHolder Name is required'),
    transaction_information: Yup.array()
        .min(1, 'At least one transaction information object is required')
        .of(transactionInformationValidationSchema),
});



