import React from 'react';
import styles from './Header.module.scss';
import HeaderBreadcrumbs from './HeaderBreadcrumb/index';
import HeaderProfile from './HeaderProfile';


const Header: React.FC = () => {
    return (
        <header className={styles['main-header']}>
            <HeaderBreadcrumbs />
            <HeaderProfile />
        </header>
    );
};

export default Header;
