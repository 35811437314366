export enum FORM_INPUTS {
    FIELD_SET = 'filed_set',
    SELECT = 'select',
    MULTI_SELECT = 'multi_select',
    RADIO = 'radio',
    TEXT = 'text',
    FILE = 'file',
    NUMBER = 'number',
    PASSWORD = 'password',
    TEXTAREA = 'textarea',
    EDITOR = 'editor',
    HIDDEN = 'hidden',
    TAG = 'tag',
    CHIPS = 'chips',
    DATE = 'date',
    DATE_DYNAMIC = 'date_dynamic',
    GEOGRAPHY_LOCATION = 'geography_location',
    IMAGE_UPLOADER = 'image_uploader',
    REPEATER = 'repeater',
}

export enum DATE_TYPE {
    DATE = '1',
    YEAR = '2',
    MONTH = '3',
}
