import { SVGProps } from 'react';

const DeleteIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none" {...props}>
            <path
                d="M18.6006 6V18.75C18.6006 19.993 17.5736 21 16.3316 21H8.83159C7.58859 21 6.60059 19.993 6.60059 18.75V6"
                stroke={props.stroke ? '#3861FB' : undefined}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M20.1006 6H5.10059" stroke={props.stroke ? '#3861FB' : undefined} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.6006 3H14.6006" stroke={props.stroke ? '#3861FB' : undefined} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default DeleteIcon;
