export const formEditorConfig = {
    height: 300,
    menubar: false,
    language: 'en',
    plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help wordcount',
        'directionality',
    ],
    toolbar:
        'undo redo | formatselect | ' +
        'bold italic backcolor | alignleft aligncenter alignright alignjustify |' +
        'ltr rtl | bullist numlist outdent indent | ' +
        'removeformat ',
    content_style: 'body { font-family:DM Sans, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji; font-size:14px }',
}