import React, { Dispatch, SetStateAction } from 'react';
import styles from './List.module.scss';
import DashboardTitle from 'src/components/App/DashboardTitle';
import Table from 'src/components/App/Table/index';
import Button from 'src/components/Kit/Button';
import { Helmet } from 'react-helmet-async';
import { Column } from 'primereact/column';
import ROUTE_CONSTANTS from 'src/Routes/constants/route-constants';
import { toast } from 'src/utils/toast';
import { TOAST_STATUS } from 'src/constants/toast-status';
import DashboardContentWrapper from 'src/layouts/DashboardContentWrapper';
import DeleteIcon from 'src/assets/Icons/DeleteIcon';
import EditIcon from 'src/assets/Icons/EditIcon';
import { getAxiosError } from 'src/utils/get-axios-error';
import { modalActions } from 'src/context/modals';
import { MODAL_TYPES } from 'src/types/modals';
import { useCheckPermissionAccess } from 'src/hooks/useCheckPermissionAccess';
import { PERMISSION_TYPES } from 'src/enums/permissions';
import { useNavigate } from 'react-router-dom';
import { generateQueryString } from 'src/utils/urlUtils';
import { cellRender } from './cellRender';
import { legalStructuresService } from 'src/api/services/legal-structures';
import { ILegalStructuresListResponse } from 'src/api/types/legal-structures';
import { IDataTablePayload } from '../../../../api/types/table';

const service = async (queryParams?: IDataTablePayload) => legalStructuresService.getAll(queryParams);

const List: React.FC = () => {
    const navigate = useNavigate()
    const { checkPermissionAccess } = useCheckPermissionAccess();
    const handleRemove = (rowData: ILegalStructuresListResponse, setTableData: Dispatch<SetStateAction<ILegalStructuresListResponse[]>>) => {
        const handleRemoveItem = async () => {
            try {
                const deleteResult = await legalStructuresService.delete(rowData.id);
                if (deleteResult.data.success) {
                    setTableData((tableData: ILegalStructuresListResponse[]) => tableData.filter((item: ILegalStructuresListResponse) => item.id !== rowData.id));
                    toast.fire({
                        icon: TOAST_STATUS.SUCCESS,
                        title: deleteResult.data.message,
                    });
                } else {
                    toast.fire({
                        icon: TOAST_STATUS.ERROR,
                        title: deleteResult.data.message,
                    });
                }
            } catch (err) {
                const error = getAxiosError(err);
                const message = error?.message || 'Server Error';
                toast.fire({
                    icon: TOAST_STATUS.ERROR,
                    title: message,
                });
            }
        };

        const handleGoToCompaniesList = () => {
            modalActions.clearModals()
            navigate(ROUTE_CONSTANTS.COMPANIES.ROOT.ABSOLUTE + '?' + generateQueryString({ legal_structure_id: String(rowData.id) }))
        }

        if (rowData.companies_count > 0) {
            modalActions.addModal(MODAL_TYPES.CONFIRMATION_MODAL, {
                header: 'Delete is prohibited',
                children: <div className={styles.deleteModal}>
                    <span className={styles.mainText}>
                        {`This Legal structures is used in ${rowData.companies_count} ${rowData.companies_count < 2 ? 'company' : 'companies'} . To delete this Legal structures first edit the ${rowData.companies_count < 2 ? 'company' : 'companies'} and remove this Legal structures from it.`}
                    </span>
                    <span className={styles.secondaryText}>
                        By clicking on this button I will be redirected to the company list page, which is based on the companies that have used our desired Legal structures.
                    </span>
                    <div className={styles.actionWrapper}>
                        <Button
                            color="primary"
                            onClick={handleGoToCompaniesList}
                        >
                            <span>Go to companies list</span>
                        </Button>
                    </div>
                </div>,
                text: '',
            });
        } else {
            modalActions.addModal(MODAL_TYPES.CONFIRMATION_MODAL, {
                header: 'Delete Confirm',
                text: 'Are you sure to delete this Legal structures?',
                handleAction: handleRemoveItem,
            });
        }
    };

    const suffixCols = (data: ILegalStructuresListResponse[], setTableData: Dispatch<SetStateAction<ILegalStructuresListResponse[]>>) => {
        return (
            <Column
                key="Action"
                body={(rowData: ILegalStructuresListResponse) => (
                    <>
                        {checkPermissionAccess(PERMISSION_TYPES.SETTING_LEGAL_STRUCTURES_EDIT) &&
                            <Button
                                link
                                color="secondary"
                                size="m"
                                iconOnly
                                to={ROUTE_CONSTANTS.SETTINGS.LEGAL_STRUCTURES.UPDATE.EDIT_BY_ID(rowData.id).ABSOLUTE}
                            >
                                <EditIcon />
                            </Button>
                        }
                        {checkPermissionAccess(PERMISSION_TYPES.SETTING_LEGAL_STRUCTURES_DELETE) &&
                            <Button color="secondary" iconOnly size="m" onClick={() => handleRemove(rowData, setTableData)}>
                                <DeleteIcon />
                            </Button>
                        }
                    </>
                )}
            />
        );
    };

    return (
        <>
            <Helmet>
                <title>Teaser | Legal Structures List</title>
            </Helmet>
            <DashboardTitle
                title={'Legal Structures List'}
                titleSuffix={
                    checkPermissionAccess(PERMISSION_TYPES.SETTING_LEGAL_STRUCTURES_CREATE)
                        ? <Button size="s" link color="primary" to={ROUTE_CONSTANTS.SETTINGS.LEGAL_STRUCTURES.ADD_NEW.ROOT.ABSOLUTE}>
                            Add New Legal Structures
                        </Button>
                        : null
                }
            />
            <DashboardContentWrapper>
                {checkPermissionAccess(PERMISSION_TYPES.SETTING_LEGAL_STRUCTURES_LIST_VIEW) &&
                    <Table getTableData={service} cellRender={cellRender} suffixCols={suffixCols} />
                }
            </DashboardContentWrapper>
        </>
    );
};

export default List;
