export const CompanyLogoPlaceholder = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="54" height="62" viewBox="0 0 54 62" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M39.0778 40.5708H10.864C12.5176 43.3822 14.2219 45.9844 15.9282 48.3733H34.5701C36.072 45.7718 37.5745 43.171 39.0778 40.5708Z" fill="#DFDEDE" />
            <path fillRule="evenodd" clipRule="evenodd" d="M41.9479 35.608L46.4534 27.8035H4.67825V22.8388H49.3136L54 14.7133L0 14.7031C1.9548 21.9401 4.67882 28.947 8.12549 35.6039L41.9479 35.608Z" fill="#DFDEDE" />
            <path fillRule="evenodd" clipRule="evenodd" d="M19.7151 53.342C22.0073 56.1743 24.4441 58.8863 27.0158 61.4674C28.5827 58.7589 30.1475 56.0505 31.7104 53.342H19.7151Z" fill="#DFDEDE" />
            <path fillRule="evenodd" clipRule="evenodd" d="M27.0132 61.4746C17.5121 42.5959 15.5378 20.8127 21.4899 0.533404C15.7889 0.383041 10.2349 2.3533 5.90317 6.06277C3.27067 8.41742 1.24557 11.3729 0 14.6779C4.76609 32.3661 14.0808 48.4979 27.0173 61.4684L27.0132 61.4746Z" fill="#E4E4E4" />
        </svg>
    )
}
