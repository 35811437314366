import { arabicCharactersRegex } from 'src/constants/regex';
import { getMaxError, getMinError } from 'src/utils/get-yup-errors';
import { string, object } from 'yup';

export const validationSchema = () =>
    object().shape({
        name: string()
            .required('Name is required')
            .min(2, getMinError(2, 'Name'))
            .max(80, getMaxError(80, 'Name')),
        arabic_name: string().when({
            is: (val: string) => val && val.length > 0,
            then: string().min(2, getMinError(2, 'Arabic Name'))
                .max(80, getMaxError(80, 'Arabic Name'))
                .matches(arabicCharactersRegex, 'Arabic Name must only accept Arabic characters')
        })
    });