import { SVGProps } from 'react';

const TeaserIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
            <path
                d="M3.18164 13.1252H4.44581C4.74081 13.1252 5.02331 13.0085 5.23164 12.8002V12.8002C5.43997 12.5918 5.55664 12.3093 5.55664 12.0143V7.56933C5.55664 7.27433 5.43997 6.99183 5.23164 6.7835V6.7835C5.02331 6.57516 4.74081 6.4585 4.44581 6.4585H3.38831"
                stroke={props.stroke || 'currentColor' || '#323232'}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.6128 6.4585H15.5561C15.2611 6.4585 14.9786 6.57516 14.7703 6.7835V6.7835C14.562 6.99183 14.4453 7.27433 14.4453 7.56933V12.0143C14.4453 12.3093 14.562 12.5918 14.7703 12.8002V12.8002C14.9786 13.0085 15.2611 13.1252 15.5561 13.1252H16.8203"
                stroke={props.stroke || 'currentColor' || '#323232'}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.4622 12.2061H13.7455C13.5072 12.2061 13.2713 12.2569 13.0547 12.3561L9.54883 13.9527"
                stroke={props.stroke || 'currentColor' || '#323232'}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.87164 7.22326C8.45164 6.97909 7.94414 6.91826 7.47581 7.08659L5.55664 7.77743"
                stroke={props.stroke || 'currentColor' || '#323232'}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.5793 7.03006L12.1826 5.97506C11.6693 5.74923 11.0759 5.79673 10.6051 6.10173L8.4301 7.5109C7.73844 7.9584 7.65927 8.94006 8.26927 9.4934V9.4934C8.64344 9.83256 9.1851 9.91423 9.6426 9.7009L10.9393 9.0959"
                stroke={props.stroke || 'currentColor' || '#323232'}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z"
                stroke={props.stroke || 'currentColor' || '#323232'}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.9377 9.09521L11.596 9.69271C12.3235 10.3519 12.326 11.4935 11.6027 12.1569L9.92182 13.6952C9.28766 14.2769 8.31432 14.2785 7.67766 13.701L6.52266 12.6535C6.24932 12.406 5.90099 12.2669 5.53516 12.2369"
                stroke={props.stroke || 'currentColor' || '#323232'}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default TeaserIcon;
