import React from 'react';
import DashboardTitle from 'src/components/App/DashboardTitle';
import Table from 'src/components/App/Table/index';
import { Helmet } from 'react-helmet-async';
import { personService } from 'src/api/services/person-service';
import DashboardContentWrapper from 'src/layouts/DashboardContentWrapper';
import { useCheckPermissionAccess } from 'src/hooks/useCheckPermissionAccess';
import { PERMISSION_TYPES } from 'src/enums/permissions';
import { useParams } from 'react-router-dom';
import { personDetailDataCellRender } from './personsDataCellRender';

interface IParams {
    [key: string]: string;
}

const PersonShow: React.FC = () => {
    const { checkPermissionAccess } = useCheckPermissionAccess();
    const { id } = useParams<IParams>();

    const service = async () => personService.usage(Number(id));

    return (
        <>
            <Helmet>
                <title>Teaser | People Related Company</title>
            </Helmet>
            <DashboardTitle title="People Related Company" />
            <DashboardContentWrapper>
                {checkPermissionAccess(PERMISSION_TYPES.PERSON_LIST_VIEW) && <Table getTableData={service} cellRender={personDetailDataCellRender} />}
            </DashboardContentWrapper>
        </>
    );
};

export default PersonShow;
