import { API_ROUTES } from 'src/constants/api-routes';
import { AxiosInstance } from '../index';
import { IAxiosResponse } from '../types/axios-response';
import { ISelectBox, IDropDownPayload } from '../types/base-types';

export const currencyService = {
    selectBox(params?: IDropDownPayload) {
        return AxiosInstance.get<IAxiosResponse<ISelectBox[]>>(API_ROUTES.CURRENCY.ROOT, { params });
    },
};
