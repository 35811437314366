import React, { useCallback, useEffect, useState } from 'react';
import { Column } from 'primereact/column';
import { DataTable, DataTableExpandedRows } from 'primereact/datatable';
import DashboardContentWrapper from 'src/layouts/DashboardContentWrapper';
import { Accordion, AccordionEventParams, AccordionTab } from 'primereact/accordion';
import ArrowUpIcon from 'src/assets/Icons/ArrowUpIcon';
import ArrowDownIcon from 'src/assets/Icons/ArrowDownIcon';
import style from './CompanyFinancialRatio.module.scss';
import RatioFormula from './RatioFormula';
import { companyService } from 'src/api/services/company';
import { useParams } from 'react-router-dom';
import { getAxiosError } from 'src/utils/get-axios-error';
import { toast } from 'src/utils/toast';
import { TOAST_STATUS } from 'src/constants/toast-status';
import {
    IFinancialInformationRatioDatesResponse,
    IFinancialInformationRatioResponse,
    IFinancialInformationRatioTableData,
    RATIO_NUMBER_TYPE,
    RATIO_VALUE_TYPE,
    ratioValueTypeSymbol,
} from 'src/api/types/company';
import InitLoading from 'src/components/App/Loading/InitLoading';
import { classNames } from 'primereact/utils';
import { financialValuesStyle } from 'src/utils/financial-values-style';
import CompanyFinancialRatioEmptyState from './EmptyState';
import { useNonProfilePolicy } from '../useNonProfilePolicy';
import { COMPANY_TYPE_ROUTE } from '../../Add/enums/company-type-route';
import { IParams } from 'src/types/params';
import PopoverMessage from 'src/components/Kit/PopoverMessage';
import { POPOVER_STATUS } from 'src/components/Kit/PopoverMessage/enums/popover-status';

const CompanyFinancialRatio: React.FC = () => {
    useNonProfilePolicy();
    const { companyType, companyId } = useParams<IParams>();
    const [ratioDatesLoading, setRatioDatesLoading] = useState(false);
    const [accordionIndexLoading, setAccordionIndexLoading] = useState<number | undefined>();
    const [ratioDates, setRatioDates] = useState<IFinancialInformationRatioDatesResponse[]>();
    const [tableData, setTableData] = useState<IFinancialInformationRatioTableData>();
    const [expandedRows, setExpandedRows] = useState<any[] | DataTableExpandedRows>();

    const generateRatioString = useCallback((year: number, quarter?: number, separate: string = ' - ') => {
        return String(year) + (quarter ? separate + 'Q' + String(quarter) : '');
    }, []);

    const fetchRatioData = async (year: number, quarter?: number) => {
        try {
            const response = await companyService(companyType as COMPANY_TYPE_ROUTE).getFinancialInformationRatios(Number(companyId), {
                year,
                ...(quarter && { quarter }),
            });
            if (response.status) {
                const key = generateRatioString(year, quarter, '_');
                setTableData((prev) => ({ ...prev, [key]: response.data.data }));
            }
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: message,
            });
        } finally {
            setAccordionIndexLoading(undefined);
        }
    };

    const fetchRatioDatesData = async () => {
        try {
            setRatioDatesLoading(true);
            const response = await companyService(companyType as COMPANY_TYPE_ROUTE).getFinancialInformationRatiosDates(Number(companyId));
            if (response.status) {
                setRatioDates(response.data.data);
            }
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: message,
            });
        } finally {
            setRatioDatesLoading(false);
        }
    };

    const rowExpansionTemplate = (data: IFinancialInformationRatioResponse) => {
        const { related_items, formula, name, value, value_type, currency_short_name } = data;
        const formulaProps = {
            equalName: name,
            equalValue: value,
            denominator: formula.denominator,
            numerator: formula.numerator,
            showFormula: Boolean(!value),
            currency: currency_short_name,
            showPercent: value_type === RATIO_VALUE_TYPE.PERCENTAGE,
            ...(value_type === RATIO_VALUE_TYPE.CURRENCY ? { equalPrefix: currency_short_name! } : { equalSuffix: ratioValueTypeSymbol[value_type] }),
        };

        return (
            <div className={style.companyFinancialRatioExpanded}>
                <div>
                    <RatioFormula {...formulaProps} />
                </div>
                <div className={style.companyFinancialRatioRelatedItems}>
                    {related_items.map((item, index) => (
                        <div key={item.name} className={style.companyFinancialRatioRelatedItem}>
                            <span>
                                {item.value !== null
                                    ? financialValuesStyle(String(item.value), {
                                          ...(item.meta?.number_type === RATIO_NUMBER_TYPE.CURRENCY && { prefix: currency_short_name! }),
                                      })
                                    : '-'}
                            </span>
                            <span id={`popover-${index}`} className={item.meta?.message ? style.hasError : ''}>
                                {item.name}
                            </span>
                            {item.meta?.message && (
                                <PopoverMessage
                                    target={`#popover-${index}`}
                                    title="Calculating Error"
                                    status={POPOVER_STATUS.ERROR}
                                    message={item.meta.message}
                                    description={
                                        item.meta?.items && (
                                            <ul>
                                                <li>Category: {item.meta.items.category || '-'}</li>
                                                <li>Sub Category: {item.meta.items.sub_category || '-'}</li>
                                                <li>Item Type: {item.meta.items.item_type || '-'}</li>
                                            </ul>
                                        )
                                    }
                                />
                            )}
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const handleOpenAccordion = (e: AccordionEventParams) => {
        if (ratioDates) {
            const payloadYear = ratioDates[e.index].year;
            const payloadQuarter = ratioDates[e.index].quarter;
            if (!tableData || !tableData[generateRatioString(payloadYear, payloadQuarter, '_')]?.length) {
                setAccordionIndexLoading(e.index);
                fetchRatioData(payloadYear, payloadQuarter);
            }
        }
    };

    const expandButtonTemplate = (
        <small>
            Show Detail
            <ArrowDownIcon />
        </small>
    );

    const collapseButtonTemplate = (
        <small>
            Less Detail
            <ArrowUpIcon />
        </small>
    );

    const rowClass = (rowData: IFinancialInformationRatioResponse) => ({
        'red-bg': !rowData.value,
    });

    useEffect(() => {
        fetchRatioDatesData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (ratioDatesLoading) return <InitLoading />;

    if (!ratioDates?.length) return <CompanyFinancialRatioEmptyState />;

    return (
        <DashboardContentWrapper>
            <div className={style.companyFinancialRatioAccordion}>
                <Accordion multiple expandIcon={expandButtonTemplate} collapseIcon={collapseButtonTemplate} onTabOpen={handleOpenAccordion}>
                    {ratioDates.map((ratioDate, index) => {
                        const accordionTableData = tableData ? tableData[generateRatioString(ratioDate.year, ratioDate.quarter, '_')] : undefined;
                        return (
                            <AccordionTab
                                className={classNames(accordionIndexLoading === index && 'loading')}
                                key={index}
                                header={generateRatioString(ratioDate.year, ratioDate.quarter)}
                            >
                                {Boolean(accordionTableData) && (
                                    <DataTable
                                        key={index}
                                        value={accordionTableData}
                                        rowClassName={rowClass}
                                        rowExpansionTemplate={rowExpansionTemplate}
                                        expandedRows={expandedRows}
                                        collapsedRowIcon="pi pi-chevron-down"
                                        expandedRowIcon="pi pi-chevron-up"
                                        onRowToggle={(e) => setExpandedRows(e.data)}
                                    >
                                        <Column field="name" header="Item" />
                                        <Column
                                            field="value"
                                            header="Amount"
                                            body={(rowData: IFinancialInformationRatioResponse) =>
                                                financialValuesStyle(rowData.value, {
                                                    ...(rowData.value_type === RATIO_VALUE_TYPE.CURRENCY &&
                                                        rowData.currency_short_name && { prefix: rowData.currency_short_name }),
                                                    ...(rowData.value_type !== RATIO_VALUE_TYPE.CURRENCY && {
                                                        suffix: ratioValueTypeSymbol[rowData.value_type],
                                                    }),
                                                })
                                            }
                                        />
                                        <Column field="type" header="Category" />
                                        <Column
                                            header="Status"
                                            body={(rowData: IFinancialInformationRatioResponse) =>
                                                rowData.value ? <div className="valid">valid</div> : <div className="invalid">invalid</div>
                                            }
                                        />
                                        <Column expander header="Detail" className="detail" />
                                    </DataTable>
                                )}
                            </AccordionTab>
                        );
                    })}
                </Accordion>
            </div>
        </DashboardContentWrapper>
    );
};

export default CompanyFinancialRatio;
