import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FormGenerator from 'src/components/Kit/FormGenerator/index';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { generateDefaultValueFromFormSchema } from 'src/utils/generate-default-value-from-form-schema';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from 'src/components/Kit/Button';
import { createCommentsSchema } from './schema';
import { ICommentsFormData } from './types';
import { companyService } from 'src/api/services/company';
import { COMPANY_TYPE_ROUTE } from 'src/pages/Company/Add/enums/company-type-route';
import { commentsValidationSchema } from './validation-schema';
import { useAsync } from 'src/hooks/useAsync';
import { ICreateCommentPayload } from 'src/api/types/company';
import { useFormFields } from 'src/hooks/useFormFields';
import { toast } from 'src/utils/toast';
import { TOAST_STATUS } from 'src/constants/toast-status';
import { getAxiosError } from 'src/utils/get-axios-error';
import { useEditingMode } from './useUpdate';
import { useCheckPermissionAccess } from 'src/hooks/useCheckPermissionAccess';
import { checkCompanyType } from 'src/utils/check-company-type';
import { PERMISSION_TYPES } from 'src/enums/permissions';
import ROUTE_CONSTANTS from 'src/Routes/constants/route-constants';

const CommentsAddUpdate: React.FC = () => {
    const navigate = useNavigate();
    const { checkPermissionAccess } = useCheckPermissionAccess();

    const { formFields, setFormFields, updateFormData, updateFormProps, updateFormLoading, updateFormType } = useFormFields(createCommentsSchema);
    const { companyId, completedData, isEditingRule, isLoading: completedDataIsLoading, commentId, companyType } = useEditingMode();

    const {
        control,
        formState: { errors },
        handleSubmit,
        watch,
        reset,
        setValue,
    } = useForm<ICommentsFormData>({
        defaultValues: generateDefaultValueFromFormSchema(formFields),
        mode: 'all',
        shouldFocusError: false,
        resolver: yupResolver(commentsValidationSchema),
    });

    const fieldArrayActions = useFieldArray({
        control,
        name: 'sources',
    });

    const watchFieldArray = watch('sources');

    const { run, isLoading } = useAsync<unknown, ICreateCommentPayload>((payload) => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await (isEditingRule
                    ? companyService(companyType as COMPANY_TYPE_ROUTE).updateComment(Number(companyId), Number(commentId), payload)
                    : companyService(companyType as COMPANY_TYPE_ROUTE).createComment(Number(companyId), payload));
                if (response.data.success) {
                    toast.fire({
                        icon: TOAST_STATUS.SUCCESS,
                        title: response.data.message,
                    });
                    navigate(-1);
                    return resolve(response.data.data);
                } else {
                    return reject(response.data.errors);
                }
            } catch (e) {
                toast.fire({
                    icon: TOAST_STATUS.ERROR,
                    title: getAxiosError(e).message,
                });
                return Promise.reject(getAxiosError(e));
            }
        });
    });

    const onSubmit = async (_val: ICommentsFormData) => {
        run(_val);
    };

    useEffect(() => {
        setFormFields((prev) => ({
            ...prev,
            sources: {
                ...prev.sources,
                props: { ...prev.sources.props, ...fieldArrayActions },
            },
        }));

        if (completedData) {
            reset({
                ...completedData,
                ...(completedData.sources?.length ? { sources: completedData.sources } : {}),
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [completedData]);

    useMemo(() => {
        setFormFields((prev) => ({
            ...prev,
            sources: {
                ...prev.sources,
                ...fieldArrayActions,
            },
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchFieldArray]);

    useEffect(() => {
        if (!checkPermissionAccess(checkCompanyType(PERMISSION_TYPES.COMPANY_COMMENT_EDIT, companyType as COMPANY_TYPE_ROUTE))) {
            navigate(ROUTE_CONSTANTS.ROOT.ABSOLUTE);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="grid justify-content-center my-8">
            <div className="xs:col:12 md:col-8 xl:col-5">
                <span className="block text-2xl font-semibold">{isEditingRule ? 'Update' : 'Add New'} Comment</span>
                <div className="mt-5">
                    <FormGenerator
                        onSubmit={handleSubmit(onSubmit)}
                        control={control}
                        loading={isLoading}
                        errors={errors}
                        schema={formFields}
                        noBackgroundLayout
                        buttons={
                            <>
                                <Button disabled={isLoading} loading={isLoading} color="primary">
                                    {isEditingRule ? 'Update' : 'Create'}
                                </Button>
                                <Button type="button" onClick={() => navigate(-1)}>
                                    Cancel
                                </Button>
                            </>
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default CommentsAddUpdate;
