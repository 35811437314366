import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import FormGenerator from 'src/components/Kit/FormGenerator/index';
import { useForm } from 'react-hook-form';
import { generateDefaultValueFromFormSchema } from 'src/utils/generate-default-value-from-form-schema';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from 'src/components/Kit/Button';
import { CreateCompanyNewsSchemaFields, createCompanyNewsSchema } from './schema';
import { newsValidationSchema } from './validation-schema';
import { useFormFields } from 'src/hooks/useFormFields';
import { useEditingMode } from './useUpdate';
import { toast } from 'src/utils/toast';
import { TOAST_STATUS } from 'src/constants/toast-status';
import { getAxiosError } from 'src/utils/get-axios-error';
import { useAsync } from 'src/hooks/useAsync';
import { ICompanyNewsFormData } from './type';
import { IPaginatedCompanyResponse } from 'src/api/types/company';
import { IDataTableResponse } from 'src/api/types/table';
import { ISelectBox } from 'src/api/types/base-types';
import dayjs from 'dayjs';
import { ICreateNewsPayload } from 'src/api/types/news';
import { newsService } from 'src/api/services/news';
import { newsSourceService } from 'src/api/services/news-source';
import pdfIcon from 'src/assets/Images/pdf-icon.svg';

const CompanyNewsAddUpdate: React.FC = () => {
    const navigate = useNavigate();
    const { formFields, setFormFields, updateFormData, updateFormProps, updateFormLoading } = useFormFields(createCompanyNewsSchema);
    const { companyId, isEditingRule, isLoading: completedDataIsLoading, completedData, id, companyType } = useEditingMode();
    const companyResponseMeta = useRef<IDataTableResponse<unknown>['meta']['pagination']>();

    const {
        control,
        formState: { errors },
        handleSubmit,
        reset,
        setValue,
    } = useForm<ICompanyNewsFormData>({
        defaultValues: generateDefaultValueFromFormSchema(formFields),
        mode: 'all',
        shouldFocusError: false,
        resolver: yupResolver(newsValidationSchema),
    });

    const { run, isLoading } = useAsync<unknown, ICreateNewsPayload>((payload) => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await (isEditingRule ? newsService.update(Number(id), payload) : newsService.create(payload));
                if (response.data.success) {
                    toast.fire({
                        icon: TOAST_STATUS.SUCCESS,
                        title: response.data.message,
                    });
                    navigate(-1);
                    return resolve(response.data.data);
                } else {
                    return reject(response.data.errors);
                }
            } catch (e) {
                toast.fire({
                    icon: TOAST_STATUS.ERROR,
                    title: getAxiosError(e).message,
                });
                return Promise.reject(getAxiosError(e));
            }
        });
    });

    const handleUpdateDropdownOptions = async (fieldName: CreateCompanyNewsSchemaFields, getNewOptions: any, lazy?: boolean) => {
        if (lazy && companyResponseMeta.current) {
            if (companyResponseMeta.current.last_page === companyResponseMeta.current.current_page) return;
        }

        setFormFields((prev) => ({
            ...prev,
            [fieldName]: {
                ...prev[fieldName],
                data: [...(lazy ? prev[fieldName].data || [] : [])],
                props: {
                    ...prev[fieldName].props,
                    loading: true,
                },
            },
        }));

        const response = await getNewOptions();
        companyResponseMeta.current = response.data.meta;

        setFormFields((prev) => ({
            ...prev,
            [fieldName]: {
                ...prev[fieldName],
                data: [
                    ...(lazy ? prev[fieldName].data || [] : []),
                    ...response.data.data.map(({ label, value }: any) => ({
                        label,
                        value: String(value),
                    })),
                ],
                props: {
                    ...prev[fieldName].props,
                    loading: false,
                    disabled: false,
                    onFilter,
                    onLazy,
                },
            },
        }));
    };

    const onFilter = (fieldName: string, value: string) => {
        switch (fieldName) {
            case 'source_id': {
                handleUpdateDropdownOptions(fieldName, () =>
                    newsSourceService.list({
                        ...(value ? { text: value } : {}),
                    })
                );
                break;
            }
        }
    };

    const onLazy = (fieldName: string) => {
        switch (fieldName) {
            case 'source_id': {
                handleUpdateDropdownOptions(
                    fieldName,
                    () =>
                        newsSourceService.list({
                            page: (companyResponseMeta.current?.current_page || 1) + 1,
                        }),
                    true
                );
                break;
            }
        }
    };

    const getSourceList = useAsync<ISelectBox[], IPaginatedCompanyResponse>((payload) => {
        return new Promise(async (resolve, reject) => {
            try {
                updateFormLoading('source_id', true);
                const response = await newsSourceService.list(payload);
                if (response.data.success) {
                    updateFormProps('source_id', {
                        onFilter,
                        onLazy,
                        disabled: false,
                    });
                    updateFormData(
                        'source_id',
                        response.data.data.map(({ label, value }) => ({ label, value: String(value) }))
                    );
                    return resolve(response.data.data);
                } else {
                    return reject(response.data.errors);
                }
            } catch (e) {
                toast.fire({
                    icon: TOAST_STATUS.ERROR,
                    title: getAxiosError(e).message,
                });
                return Promise.reject(getAxiosError(e));
            } finally {
                updateFormLoading('source_id', false);
            }
        });
    });

    const onSubmit = async (_val: ICompanyNewsFormData) => {
        run({
            entity: {
                id: Number(companyId),
                type: 'company',
            },
            title: _val.title,
            date: dayjs.tz(new Date(_val.date).toUTCString()).unix(),
            source_id: Number(_val.source_id),
            url: _val.source_url,
            language: _val.language?.length ? Number(_val.language[0]) : null,
            media_id: (_val.media_id as { id: number; uuid: string })?.id || null,
            content: _val.content,
        });
    };

    useEffect(() => {
        if (completedData) {
            const isSelectedSource = Boolean(completedData.source_id);
            isSelectedSource && getSourceList.run({ selected_item: completedData?.source_id });

            updateFormProps('source_id', { disabled: false });

            setFormFields((formSchema) => ({
                ...formSchema,
                ...(completedData && {
                    media_id: {
                        ...formSchema.media_id,
                        ...(completedData.media.uuid && {
                            filePreview: {
                                name: completedData.media.uuid,
                                icon: pdfIcon,
                                download: true,
                                url: completedData.media.uuid,
                                onDelete: () => {
                                    setValue('media_id', undefined);
                                    setFormFields((formSchema) => ({
                                        ...formSchema,
                                        media_id: {
                                            ...formSchema.media_id,
                                            filePreview: undefined,
                                        },
                                    }));
                                },
                            },
                        }),
                    },
                }),
            }));

            reset({
                entity: {
                    id: Number(companyId),
                    type: 'company',
                },
                title: completedData.title,
                date: dayjs.unix(completedData.date).toDate(),
                source_id: Number(completedData.source_id),
                source_url: completedData.url,
                media_id: completedData.media || null,
                content: completedData.content,
                language: completedData.language ? [String(completedData.language)] : undefined
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [completedDataIsLoading]);

    useEffect(() => {
        getSourceList.run({});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="grid justify-content-center my-8">
            <div className="xs:col:12 md:col-8 xl:col-5">
                <span className="block text-2xl font-semibold">{isEditingRule ? 'Update' : 'Add New'} News</span>
                <div className="mt-5">
                    <FormGenerator
                        onSubmit={handleSubmit(onSubmit)}
                        control={control}
                        // loading={isLoading}
                        errors={errors}
                        schema={formFields}
                        noBackgroundLayout
                        buttons={
                            <>
                                <Button disabled={isLoading} loading={isLoading} color="primary">
                                    {isEditingRule ? 'Update' : 'Create'}
                                </Button>
                                <Button type="button" onClick={() => navigate(-1)}>
                                    Cancel
                                </Button>
                            </>
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default CompanyNewsAddUpdate;
