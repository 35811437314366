import { arabicCharactersRegex } from 'src/constants/regex';
import { getMaxError, getMinError, getRequireError } from 'src/utils/get-yup-errors';
import { string, object } from 'yup';

export const basicInformationValidationSchema = object().shape({
    logo_id: object().required('Logo is required'),
    name: string()
        .min(3, getMinError(3, 'Name'))
        .max(255, getMaxError(3, 'Name'))
        .required(getRequireError('Name')),
    slug: string().min(3, getMinError(3, 'Slug')).max(255, getMaxError(3, 'Slug')).required(getRequireError('Slug')),
    short_name: string().when({
        is: (string: string) => string && string.length > 0,
        then: string()
            .min(3, getMinError(3, 'Short Name'))
            .max(255, getMaxError(3, 'Short Name')),
        otherwise: string().nullable(),
    }),
    arabic_short_name: string().when({
        is: (string: string) => string && string.length > 0,
        then: string()
            .min(3, getMinError(3, 'Arabic Short Name'))
            .max(255, getMaxError(3, 'Arabic Short Name'))
            .matches(arabicCharactersRegex, 'Arabic Short Name must only accept Arabic characters'),
        otherwise: string().nullable(),
    }),
    arabic_description: string().when({
        is: (val: string) => val && val.length > 0,
        then: string().matches(arabicCharactersRegex, 'Arabic Description must only accept Arabic characters'),
    }),
    arabic_name: string()
        .min(3, getMinError(3, 'Arabic Name'))
        .max(255, getMaxError(3, 'Arabic Name'))
        .required(getRequireError('Arabic Name'))
        .matches(arabicCharactersRegex, 'Arabic Name must only accept Arabic characters')
        .nullable(),
    previous_arabic_name: string().when({
        is: (string: string) => string && string.length > 0,
        then: string()
            .min(2, getMinError(2, 'Previous Arabic Name'))
            .max(200, getMaxError(200, 'Previous Arabic Name'))
            .matches(arabicCharactersRegex, 'Previous Arabic Name must only accept Arabic characters'),
        otherwise: string().nullable(),
    }),
    previous_name: string().when({
        is: (string: string) => string && string.length > 0,
        then: string()
            .min(2, getMinError(2, 'Previous English Name'))
            .max(200, getMaxError(200, 'Previous English Name')),
        otherwise: string().nullable(),
    }),
    currency_id: string().required('The Currency is required').nullable(),
    country_id: string().required('The Country is required').nullable(),
});
