export default class TokenParser {

    extractStatementRanges(tokens) {
        let statementRanges = [];
        let nestingLevel = 0;
        let currentRange = null;
        let rangesInAllLevels = [];

        for (let index = 0; index < tokens.length; index++) {
            let token = tokens[index];

            if (token === '(') {
                if (nestingLevel === 0) {
                    currentRange = { from: index };
                    rangesInAllLevels.push(currentRange);
                }
                nestingLevel++;
                continue;
            }

            if (token === ')') {
                nestingLevel--;

                if (nestingLevel < 0) {
                    throw new Error('Mismatched parentheses: Unexpected closing parenthesis ")" without opening parenthesis "(".');
                }

                if (nestingLevel === 0) {
                    if (currentRange === null) {
                        throw new Error('Mismatched parentheses: Unexpected closing parenthesis ")" without opening parenthesis "(".');
                    }

                    currentRange.to = index;
                    statementRanges.push(currentRange);
                    let lastKey = rangesInAllLevels.length - 1;
                    rangesInAllLevels[lastKey] = currentRange;
                    currentRange = null;
                }
            }
        }

        if (nestingLevel > 0) {
            throw new Error('Mismatched parentheses: Unclosed opening parenthesis "(".');
        }

        return statementRanges;
    }

    normalizingRawTokens(rawTokens) {
        
        let values = [];

        for (let key = 0; key < rawTokens.length; key++) {
            let token = rawTokens[key];
            let tokenIsInNestedStatement = false;
            let statementRanges = this.extractStatementRanges(rawTokens);

            for (let tokenRange of statementRanges) {
                if (key === tokenRange.from) {
                    let statementTokensBetweenParentheses = this.getStatementTokensBetweenParentheses(rawTokens, tokenRange);
                    values.push({
                        tokens: statementTokensBetweenParentheses,
                        range: tokenRange
                    });
                }

                if (key >= tokenRange.from && key <= tokenRange.to) {
                    tokenIsInNestedStatement = true;
                }
            }

            if (!tokenIsInNestedStatement) {
                values.push(token);
            }
        }

        if (values.length === 0) {
            throw new Error('Invalid expression: No values found between parentheses!');
        }

        return values;
    }

    getStatementTokensBetweenParentheses(tokens, tokenRange) {
        return tokens.slice(tokenRange.from + 1, tokenRange.to);
    }
}
