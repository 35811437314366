import { IFormGeneratorGeneralSchemaType } from '../types/form-generator-schema-type';

type FormSchema<T extends string> = Record<T, IFormGeneratorGeneralSchemaType>;
type ModifierFunction<T extends IFormGeneratorGeneralSchemaType , A extends any> = (
    field: T,
    additionalData: A
) => IFormGeneratorGeneralSchemaType

export function updateFormField<T extends string>(
    schema: FormSchema<T>,
    key: T,
    modifier: ModifierFunction<any, any>,
    additionalData: any
): FormSchema<T> {
    return {
        ...schema,
        [key]: modifier(schema[key], additionalData),
    };
}

export const modifyFormLoading: ModifierFunction<IFormGeneratorGeneralSchemaType , boolean> = (field, additionalData) => ({
    ...field,
    props: {
        ...field.props,
        loading: additionalData,
    },
});


export const modifyFormData: ModifierFunction<IFormGeneratorGeneralSchemaType , any> = (field, additionalData) => ({
    ...field,
    data: additionalData,
    props: {
        ...field.props,
        loading: false,
    },
});

export const modifyFormType: ModifierFunction<IFormGeneratorGeneralSchemaType , any> = (field, additionalData) => ({
    ...field,
    type: additionalData,
});

export const modifyFormProps: ModifierFunction<IFormGeneratorGeneralSchemaType , Record<string, any>> = (field, additionalData) => ({
    ...field,
    props: {
        ...field.props,
        ...additionalData
    },
});
