import { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import App from './App';
import { BrowserRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import ROUTE_CONSTANTS from './Routes/constants/route-constants';

if (window?.REACT_APP_API_URL) {
    const ignoreErrors = [
        'Request failed with status code 422', 
        'Request failed with status code 500', 
        'Network Error', 
        'Request failed with status code 403',
        'timeout of 10000ms exceeded',
        'Request aborted',
        'e.focus is not a function' // I added this as it happens when some mandatory dropdown in forms are not filled and admin trigger submit button and form wants to be make a focus on that dropdown but it can not. 
    ];
    Sentry.init({
        dsn: 'https://d7ef303d5de84826bb0f4cc4b2e4c41e@o469240.ingest.sentry.io/4504433398906880',
        environment: window?.ENV,
        ignoreErrors,
        beforeSend(event, hint) {
            if (event && event.message && ignoreErrors.includes(event.message)) {
                return null;
            }
            return event;
        },
        integrations: [
            new Sentry.BrowserTracing({
                // See docs for support of different versions of variation of react router
                // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
                routingInstrumentation: Sentry.reactRouterV6Instrumentation(useEffect, useLocation, useNavigationType, createRoutesFromChildren, matchRoutes),
            }),
            new Sentry.Replay(),
            new Sentry.Feedback({
                colorScheme: "light",
                
              }),
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        tracesSampleRate: 0.1,

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
}    

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <BrowserRouter basename={ROUTE_CONSTANTS.ROOT.ABSOLUTE}>
        <App />
    </BrowserRouter>
);
