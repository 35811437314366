import { arabicCharactersRegex } from 'src/constants/regex';
import { getMaxError, getMinError } from 'src/utils/get-yup-errors';
import { string, object } from 'yup';

export const validationSchema = () =>
    object().shape({
        name: string().required('Name is required'),
        arabic_name: string()
            .when({
                is: (arabic_name: string) => arabic_name.length > 0,
                then:
                    string()
                        .min(3, getMinError(3, 'Arabic Name'))
                        .max(255, getMaxError(3, 'Arabic Name'))
                        .matches(arabicCharactersRegex, 'Arabic Name must only accept Arabic characters'),
            }),
        type: string().required('Type is required'),
    });