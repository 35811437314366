import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const useQueryParams = () => {
    const { search, pathname } = useLocation();
    const navigate = useNavigate();
    const utils = useMemo(() => {
        const queryParamsInstance = new URLSearchParams(search);
        return {
            setParam(key: string, value: string, redirect = false) {
                if (queryParamsInstance.get(key)) queryParamsInstance.delete(key);
                queryParamsInstance.append(key, value);
                const queryString = queryParamsInstance.toString();
                if (redirect) navigate(pathname + '?' + queryString);
                return queryString;
            },
            getParam(key: string) {
                return queryParamsInstance.get(key);
            },
            removeParam(key: string | string[], redirect = false) {
                if (typeof key === 'string') {
                    queryParamsInstance.delete(key);
                } else {
                    key.forEach((keyItem) => queryParamsInstance.delete(keyItem));
                }
                const queryString = queryParamsInstance.toString();
                if (redirect) navigate(pathname + '?' + queryString);
                return queryString;
            },
            clearParams(redirect = false) {
                Array.from(queryParamsInstance.keys())?.forEach((key) => {
                    queryParamsInstance.delete(key);
                });

                const queryString = queryParamsInstance.toString();
                if (redirect) navigate(pathname);
                return queryString;
            },
            getParams(except: string | string[] = []) {
                let keyArray = Array.from(queryParamsInstance.keys());
                if (except.length) {
                    if (typeof except === 'string') {
                        keyArray = keyArray.filter((keyItem) => keyItem !== except);
                    } else {
                        keyArray = keyArray.filter((keyItem) => !except.includes(keyItem));
                    }
                }
                return keyArray.reduce((acc, val) => ({ ...acc, [val]: queryParamsInstance.get(val) }), {});
            },
        };
    }, [search, pathname, navigate]);

    return utils;
};

export default useQueryParams;
