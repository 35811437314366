export enum DROPDOWNS_FILTER {
    COUNTRY = 'country',
    CURRENCY = 'currency',
    LEGAL_STRUCTURE = 'legal_structure',
    ROLE = 'role',
    PERSON = 'person',
    POSITION = 'position',
    SECTOR = 'sector',
    STOCK_EXCHANGE = 'stock_exchange',
    FINANCIAL_INFORMATION_CATEGORY = 'financial_information_category',
    FINANCIAL_INFORMATION_SUB_CATEGORY = 'financial_information_sub_category',
    FINANCIAL_INFORMATION_TYPE = 'financial_information_type',
    NEWS_SOURCE = 'news_source',
}
