import { useNavigate } from 'react-router-dom';
import Button from 'src/components/Kit/Button';
import style from './NotFound.module.scss';

const NotFound = () => {
    const navigate = useNavigate();

    return (
        <div className={style['not-found']}>
            <span>Oops!</span>
            <h1>404</h1>
            <h3>You seem lost!</h3>
            <h6>The page you’re looking for doesn’t exist.</h6>
            <div>
                <Button type="button" onClick={() => navigate(-1)}>
                    Go Back
                </Button>
            </div>
        </div>
    );
};
export default NotFound;
