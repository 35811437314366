import { API_ROUTES } from 'src/constants/api-routes';
import { AxiosInstance } from '../index';
import { IAxiosResponse } from '../types/axios-response';
import { ILegalStructuresListResponse, TCreateLegalStructuresPayload, TUpdateLegalStructuresPayload } from '../types/legal-structures';
import { ISelectBox, IDropDownPayload } from '../types/base-types';
import { IDataTableResponse, IDataTablePayload } from 'src/api/types/table';

export const legalStructuresService = {
    getAll(params?: IDataTablePayload) {
        return AxiosInstance.get<IDataTableResponse<ILegalStructuresListResponse>>(API_ROUTES.LEGAL_STRUCTURES_SERVICE.GET_ALL_STOCK_EXCHANGES, { params });
    },
    getOne(id: number) {
        return AxiosInstance.get<IAxiosResponse<ILegalStructuresListResponse>>(API_ROUTES.LEGAL_STRUCTURES_SERVICE.GET_ONE(id));
    },
    createNew(data: TCreateLegalStructuresPayload) {
        return AxiosInstance.post<IAxiosResponse<ILegalStructuresListResponse>>(API_ROUTES.LEGAL_STRUCTURES_SERVICE.ADD_NEW, data);
    },
    update(id: number, data: TUpdateLegalStructuresPayload) {
        return AxiosInstance.put<IAxiosResponse<{}>>(API_ROUTES.LEGAL_STRUCTURES_SERVICE.UPDATE(id), data);
    },
    delete(id: number) {
        return AxiosInstance.delete<IAxiosResponse<{}>>(API_ROUTES.LEGAL_STRUCTURES_SERVICE.DELETE(id));
    },
    selectBox(params?: IDropDownPayload) {
        return AxiosInstance.get<IAxiosResponse<ISelectBox[]>>(API_ROUTES.LEGAL_STRUCTURES_SERVICE.GET_ALL_DROPDOWN_OPTIONS, { params });
    },
};
