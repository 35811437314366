import { ModalProps } from 'src/types/modals';
import Button from '../Button';
import style from './ConfirmDialog.module.scss';
import { ReactNode, useState } from 'react';

export interface ConfirmDialogProps {
    text: string;
    children?: ReactNode;
    handleAction?: () => Promise<void> | void;
    autoClose?: boolean;
    actionButtonText?: string;
    cancelButtonText?: string;
    disableCancelButton?: boolean;
    actionButtonColor?: 'primary' | 'secondary' | 'error' | 'success' | 'tertiary' | 'danger';
}

const ConfirmDialog: React.FC<ModalProps<ConfirmDialogProps>> = ({
    data: { children, text, handleAction, actionButtonText, actionButtonColor, cancelButtonText, disableCancelButton = false, autoClose = true },
    closeModal,
}) => {
    const [loading, setLoading] = useState<boolean>();

    const handleClick = async () => {
        setLoading(true);
        handleAction && (await handleAction());
        setLoading(false);
        autoClose && closeModal();
    };

    return (
        <div className={style.wrapper}>
            <div className={style.body}>{children ? children : <span>{text}</span>}</div>
            <div className={style.footer}>
                {!disableCancelButton && (
                    <Button disabled={false} color="secondary" onClick={() => closeModal()}>
                        {cancelButtonText || 'No, Canceled'}
                    </Button>
                )}
                <Button disabled={loading} color={actionButtonColor || 'danger'} loading={loading} onClick={handleAction ? handleClick : () => closeModal()}>
                    {actionButtonText || "Yes, I'm Sure"}
                </Button>
            </div>
        </div>
    );
};

export default ConfirmDialog;
