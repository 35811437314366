import { ControllerRenderProps, FieldError, FieldValues } from 'react-hook-form';
import { classNames } from 'primereact/utils';
import Avatar from '../../Avatar';
import { ICropper } from 'src/types/cropper';
import style from './ImageUploader.module.scss';
import { IFileStoreResponse } from '../../../../api/types/file';
import { RedError } from 'src/assets/Icons/RedError';
interface IProps {
    field: ControllerRenderProps<FieldValues, string>;
    label: string;
    placeholder: string;
    className: string;
    errorMessage: FieldError;
    cropper?: ICropper;
    entityType?: string;
    props?: { required?: boolean; disabled?: boolean; acceptedRatio?: number; imageConditions?: string };
}

const ImageUploader = ({ field, className, label, placeholder, errorMessage, cropper, entityType, props = {} }: IProps) => {
    return (
        <div className={classNames('form-field col-12', style.imageUploader, className ? className : '')}>
            <Avatar
                size="md"
                isUploader
                url={field?.value?.url}
                onAvatarChanged={(id: IFileStoreResponse['id'], url?: string) => field.onChange({ id, url })}
                cropper={cropper}
                acceptedRatio={props.acceptedRatio}
                customStyle={{
                    width: '78px',
                    height: '78px',
                }}
            />
            <div className={style.imageUploaderContent}>
                <label>
                    {label} {props.required ? <span className="required">*</span> : ''}
                </label>
                <p>{placeholder}</p>
                {props.imageConditions ? <p>{props.imageConditions}</p> : ''}
            </div>
            {errorMessage ? (
                <div className={'form-input-error'}>
                    <RedError />
                    <span>{errorMessage.message}</span>
                </div>
            ) : null}
        </div>
    );
};

export default ImageUploader;
