import { transactionRelatedParty } from 'src/constants/company/holding/transaction-related-party';
import { transactionStatus } from 'src/constants/company/holding/transaction-status';
import { transactionType } from 'src/constants/company/holding/transaction-type';
import { FORM_INPUTS } from 'src/types/form';
import { IFormGeneratorSchemaType } from 'src/types/form-generator-schema-type';

export type CreateTransactionSchemaFields = 'status' | 'related_party' | 'shares' | 'ownership_stake' | 'holding_type';
export type CreateTransactionSchemaType = Record<CreateTransactionSchemaFields, IFormGeneratorSchemaType>;

export const createTransactionSchema: CreateTransactionSchemaType = {
    status: {
        placeholder: 'Status',
        type: FORM_INPUTS.SELECT,
        className: 'lg:col-6',
        data: transactionStatus,
        props: {
            required: true,
        },
    },
    related_party: {
        placeholder: 'Related Party',
        type: FORM_INPUTS.SELECT,
        className: 'lg:col-6',
        data: transactionRelatedParty,
        props: {
            required: false,
        },
    },
    shares: {
        placeholder: 'Shares',
        type: FORM_INPUTS.NUMBER,
        className: 'lg:col-6',
        props: {
            required: false,
        },
    },
    ownership_stake: {
        placeholder: 'Ownership Stake',
        type: FORM_INPUTS.NUMBER,
        className: 'lg:col-6',
        props: {
            required: false,
            minFractionDigits: 0,
            maxFractionDigits: 2,
            min: 0.01,
            max: 100,
        },
    },
    holding_type: {
        placeholder: 'Holding Type',
        type: FORM_INPUTS.SELECT,
        className: 'lg:col-6',
        data: transactionType,
        props: {
            required: true,
        },
    },
};
