import Button from 'src/components/Kit/Button';
import Tab from 'src/components/Kit/Tab';
import { useEditCompanyContext } from '../context';
import { editCompanyTabsSchema } from './tab-data';
import InitLoading from 'src/components/App/Loading/InitLoading';
import style from './EditCompanyTabs.module.scss';
import { ITabConfigProps } from 'src/components/Kit/Tab/types';

const EditCompanyTabs = () => {
    const { onComplete, onCancel, loading, formValues, initLoading, saveDisabled } = useEditCompanyContext();
    const tabClassName: ITabConfigProps['className'] = {
        tabs: style.editCompanyTabs,
        activeTabs: style.active,
        disableTabs: style.disable,
    };
    
    return (
        <div className={style.editCompany}>
            <div className={style.editCompanyHeader}>
                <div className={style.editCompanyTitle}>
                    <h3>Edit Company</h3>
                    <p>{formValues.name as string}</p>
                </div>
                <div className={style.editCompanyButtons}>
                    <Button onClick={onCancel}>Cancel</Button>
                    <Button onClick={onComplete} disabled={saveDisabled || loading} loading={loading} color="primary">
                        Save
                    </Button>
                </div>
            </div>
            <div className={style.formWrapper}>
                {!initLoading ? <Tab config={editCompanyTabsSchema} className={tabClassName} /> : <InitLoading />}
            </div>
        </div>
    );
};

export default EditCompanyTabs;
