import { useMemo } from 'react';
import { useUserPermissions } from 'src/context/global-context';

export const useCheckPermissionAccess = () => {
    const userPermissions = useUserPermissions();

    const checkPermission = useMemo(
        () => ({
            checkPermissionAccess: (permission: string): boolean => {
                return !!userPermissions.find((userPermission) => userPermission === permission);
            },
        }),
        [userPermissions]
    );

    return checkPermission;
};
