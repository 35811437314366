export enum MUTATION_FINANCIAL_STEP {
    LANDING = 0,
    SELECT_TEMPLATE_DATA = 1,
    SELECT_CURRENCY = 2,
    SELECT_FINANCIAL_DATA = 3,
    VIEW_DRAFT_DATA = 4,
}
export const selectedFinancialDataTypeName = [
    'Statement of profit or loss',
    'Statement of financial position',
    'Statement of cash flows',
    'Capital',
    'Auditor Opinion',
];

export enum SIDE_PANEL_OPTIONS {
    ADD_NEW_DATE = 'ADD_NEW_DATE',
    ADD_NEW_ITEM = 'ADD_NEW_ITEM',
}

export enum DATE_TYPE_OPTIONS {
    QUARTER = 'QUARTER',
    ANNUAL = 'ANNUAL',
}

export enum TABS {
    FORMULA = 'FORMULA',
    CUSTOM_VALUE = 'CUSTOM_VALUE',
}

export enum COMPANY_FINANCIAL_INFORMATION_STATE {
    EDIT_MODE = 'EDIT_MODE',
    VIEW_MODE = 'VIEW_MODE',
}

export enum NEW_ITEM_POSITION {
    BEFORE = 'BEFORE',
    AFTER = 'AFTER',
}

export enum PREVENT_ALERT_SHOWN_LIST {
    APPROVED = 'list-of-approved-financial-data',
    NOT_APPROVED = 'list-of-not-approved-financial-data',
}

export enum FINANCIAL_INFORMATION_ROW_INPUT_TYPE {
    VALUE = 'value',
    TYPE = 'type',
    NAME = 'name',
    IS_CUSTOM_VALUE = 'is_custom_value',
    FIND_MY_CHILDREN = 'find_my_children',
}
