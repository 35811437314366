import { FORM_INPUTS } from 'src/types/form';
import { IFormGeneratorSchemaType } from 'src/types/form-generator-schema-type';

export type createPositionSchemaFields = 'name' | 'arabic_name' | 'type'
export type createPositionSchemaType = Record<createPositionSchemaFields, IFormGeneratorSchemaType>;
export const createPositionSchema: createPositionSchemaType = {
    name: {
        placeholder: 'Name',
        type: FORM_INPUTS.TEXT,
        props: {
            required: true
        }
    },
    arabic_name: {
        label: 'Arabic Name',
        placeholder: 'Arabic Name',
        type: FORM_INPUTS.TEXT,
        props: {
            required: false,
        },
    },
    type: {
        placeholder: 'Type',
        type: FORM_INPUTS.SELECT,
        props: {
            required: true
        }
    },
};
