import { FORM_INPUTS } from 'src/types/form';
import { IFormGeneratorSchemaType } from 'src/types/form-generator-schema-type';

interface Schema {
    [key: string]: IFormGeneratorSchemaType;
}

export const generateDefaultValueFromFormSchema = (schema: Schema) => {
    return Object.entries(schema).reduce((prev: any, [key, value]) => {
        return value.type === FORM_INPUTS.REPEATER
            ? {
                  ...prev,
                  [key]: Array(value.childrenInitCount)
                      .fill('')
                      .map((_) =>
                          Object.entries(value.childrenSchema as any).reduce(
                              (childrenPrev, [childrenKey, childrenValue]) => ({
                                  ...childrenPrev,
                                  [childrenKey]: (childrenValue as IFormGeneratorSchemaType).defaultValue || '',
                              }),
                              {}
                          )
                      ),
              }
            : { ...prev, [key]: value.defaultValue || '' };
    }, {});
};
