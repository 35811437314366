import { FORM_INPUTS } from 'src/types/form';
import { IFormGeneratorSchemaType } from 'src/types/form-generator-schema-type';

export type CreateShareHolderSchemaFields = 'share-holder-type' | 'share-holder-name';
export type CreateShareHolderSchemaType = Record<CreateShareHolderSchemaFields, IFormGeneratorSchemaType>;

export const createShareHolderSchema: CreateShareHolderSchemaType = {
    'share-holder-type': {
        placeholder: 'Shareholder Type',
        type: FORM_INPUTS.SELECT,
        className: 'lg:col-6',
        data: [],
        props: {
            required: true,
            loading: true,
        },
    },
    'share-holder-name': {
        placeholder: 'Shareholder Name',
        type: FORM_INPUTS.SELECT,
        className: 'lg:col-6',
        props: {
            required: true,
            loading: true,
            filter: true,
        },
    },
};
