import { IInnerMenuConfig } from './types/IInnerMenuConfig';
import { NavLink } from 'react-router-dom';
import { MouseEvent, useRef, useState } from 'react';
import style from './InnerMenu.module.scss';
import { classNames } from 'primereact/utils';
import { useOnScreen } from 'src/hooks/useOnScreen';
import Button from 'src/components/Kit/Button';
import { useCheckPermissionAccess } from 'src/hooks/useCheckPermissionAccess';
import { useFinancialCompany } from '../../../context/companies';

interface IProps {
    data: IInnerMenuConfig[];
}

const InnerMenu: React.FC<IProps> = ({ data }) => {
    const { checkPermissionAccess } = useCheckPermissionAccess();
    const tabRef = useRef<HTMLLIElement>(null);
    const isLastElementVisible = useOnScreen(tabRef);
    const [activeLines, setActiveLines] = useState<number>(1);

    const handleClickNewLine = () => setActiveLines((prev) => (prev += 1));

    return (
        <div className={style.innerMenu}>
            <ul style={{ height: `${activeLines * 48}px` }}>
                {data
                    .filter(({ permission }) => (permission ? checkPermissionAccess(permission) : true))
                    .map(({ id, title, route, disabled }) => {
                        return (
                            <li key={id} ref={tabRef}>
                                <NavLink
                                    className={({ isActive }) => classNames(isActive && !disabled ? style.active : '', disabled ? style.disabled : '')}
                                    onClick={(e: MouseEvent<HTMLElement>) => disabled && e.preventDefault()}
                                    to={route || ''}
                                >
                                    {title}
                                </NavLink>
                            </li>
                        );
                    })}
            </ul>
            {!isLastElementVisible && (
                <div className={style['more-button-container']}>
                    <li className={style['more-button-wrapper']}>
                        <Button onClick={handleClickNewLine} color="secondary" size="xs">
                            View more
                        </Button>
                    </li>
                </div>
            )}
        </div>
    );
};

export default InnerMenu;
