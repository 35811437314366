import React, { useCallback, useRef, useState } from 'react';

interface UseDragScrollConfig {
    sensitivity?: number;
    momentum?: number | null;
}

const interactiveElementsTags = ['BUTTON', 'A', 'INPUT'];
const defaults = { sensitivity: 3, momentum: 0.75 };

export function useDragScroll<T extends HTMLElement>(config: UseDragScrollConfig = defaults) {
    const { sensitivity = defaults.sensitivity, momentum = defaults.momentum } = config;
    const htmlRef = useRef<T>(null);
    let momentumID = useRef(0);
    let velX = useRef(0);
    let velY = useRef(0);
    const [scrollState, setScrollState] = useState({
        isScrolling: false,
        x: 0,
        left: 0,
        y: 0,
        top: 0,
    });

    // Momentum related logic
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const cancelMomentumTracking = () => {
        cancelAnimationFrame(momentumID.current);
    };

    const momentumLoop = useCallback(() => {
        const dom = htmlRef.current;

        if (dom && momentum) {
            dom.scrollLeft = dom.scrollLeft + velX.current;
            dom.scrollTop = dom.scrollTop + velY.current;
            velX.current *= momentum;
            velY.current *= momentum;
            if (Math.abs(velX.current) > 0.5 || Math.abs(velY.current) > 0.5) {
                momentumID.current = requestAnimationFrame(momentumLoop);
            }
        }
    }, [momentum]);

    const beginMomentumTracking = useCallback(() => {
        cancelMomentumTracking();
        momentumID.current = requestAnimationFrame(momentumLoop);
    }, [momentumLoop]);

    // Mouse Events
    const onMouseDown = useCallback(
        (event: React.MouseEvent<T>) => {
            const dom = htmlRef.current;
            const target = event.target as HTMLElement;

            velX.current = 0;
            velY.current = 0;

            if (dom && !interactiveElementsTags.includes(target.tagName)) {
                const { clientX, clientY } = event;
                const { scrollLeft, scrollTop } = dom;
                setScrollState((prev) => ({
                    ...prev,
                    isScrolling: true,
                    left: scrollLeft,
                    top: scrollTop,
                    // Get the current mouse position
                    x: clientX,
                    y: clientY,
                }));
            }
            cancelMomentumTracking();
        },
        [cancelMomentumTracking]
    );

    const cancelScrolling = useCallback(() => {
        if (scrollState.isScrolling) {
            setScrollState((prev) => ({ ...prev, isScrolling: false }));
        }
        if (momentum) {
            beginMomentumTracking();
        }
    }, [scrollState.isScrolling, beginMomentumTracking, momentum]);

    const onMouseUp = cancelScrolling;
    const onMouseLeave = cancelScrolling;

    const onMouseMove = useCallback(
        (event: React.MouseEvent<T>) => {
            event.preventDefault();
            const dx = event.clientX - scrollState.x;
            const dy = event.clientY - scrollState.y;

            if (scrollState.isScrolling && htmlRef.current) {
                const sl = htmlRef.current.scrollLeft;
                const st = htmlRef.current.scrollTop;
                htmlRef.current.scrollTop = scrollState.top - dy * sensitivity;
                htmlRef.current.scrollLeft = scrollState.left - dx * sensitivity;
                velX.current = htmlRef.current.scrollLeft - sl;
                velY.current = htmlRef.current.scrollTop - st;
            }
        },
        [scrollState.isScrolling, scrollState.x, scrollState.y, scrollState.top, scrollState.left, sensitivity]
    );

    return [htmlRef, { onMouseDown, onMouseUp, onMouseMove, onMouseLeave }, scrollState.isScrolling] as const;
}
