import { ISelectBox } from "src/api/types/base-types";
import { PARENT_COMPANY_FINANCIAL_REPORT_DATE } from "../enums/parent-company-financial-report-date";

export const parentCompanyFinancialReportDate: ISelectBox[] = [
    { label: PARENT_COMPANY_FINANCIAL_REPORT_DATE.Q1, value: '1' },
    { label: PARENT_COMPANY_FINANCIAL_REPORT_DATE.Q2, value: '2' },
    { label: PARENT_COMPANY_FINANCIAL_REPORT_DATE.Q3, value: '3' },
    { label: PARENT_COMPANY_FINANCIAL_REPORT_DATE.Q4, value: '4' },
    { label: PARENT_COMPANY_FINANCIAL_REPORT_DATE.FULL_YEAR, value: '5' },
    { label: PARENT_COMPANY_FINANCIAL_REPORT_DATE.ANNUAL, value: '6' },
];