import React, { ReactNode, useState } from 'react'
import styles from './PopupAlert.module.scss'
import RedWarning from 'src/assets/Icons/RedWarning';
import YellowWarning from 'src/assets/Icons/YellowWarning';
import { Checkbox } from 'primereact/checkbox';
import Button from '../Button';
import { POPUP_ALERT_TYPES } from 'src/components/Kit/PopupAlert/popup-alert-types';
import { colorDictionary } from './colors';

interface IProps {
    customStyle?: Record<string, string>;
    title: string;
    message: ReactNode;
    type: POPUP_ALERT_TYPES;
    onButtonClick: (preventShownAgain: boolean) => void
}

const PopupAlert: React.FC<IProps> = ({ customStyle, title, message, type, onButtonClick }) => {
    const [checkBox, setCheckBox] = useState<boolean>(true)

    const svgIconDetector = () => {
        switch (type) {
            case POPUP_ALERT_TYPES.DANGER:
                return <RedWarning />
            case POPUP_ALERT_TYPES.INFO:
                return <YellowWarning />
            default:
                return null
        }
    }

    const buttonTextColorDetector = () => {
        switch (type) {
            case POPUP_ALERT_TYPES.DANGER:
                return '#fff'
            case POPUP_ALERT_TYPES.INFO:
                return '#000'
            default:
                return '#000'
        }
    }

    return (
        <div 
            className={styles.container} 
            style={{
                ...customStyle, 
                borderBottomColor: colorDictionary[type]
            }}
        >
            <div>
                {svgIconDetector()}
            </div> 
            <div className={styles.body}>
                <div>
                    <h3 className={styles.title} style={{color: colorDictionary[type]}}>{title}</h3>
                    {message}
                </div>
                <div className={styles.actionWrapper}>
                    <div className={styles.checkBoxWrapper} onClick={() => setCheckBox(prev => !prev)}>
                        <Checkbox checked={checkBox}/>
                        <span className={styles.checkboxText}>Don’t show me again</span>
                    </div>
                    <div className={styles.buttonWrapper}>
                        <Button 
                            onClick={() => onButtonClick(checkBox)}
                            customStyle={{
                                backgroundColor: colorDictionary[type],
                            }}
                        >
                            <span className={styles.text} style={{color: buttonTextColorDetector() }}>OK, Got it</span>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PopupAlert
