import { ISelectBox } from 'src/api/types/base-types';
import { IGenderDictType } from './types';

export const genderOption: ISelectBox[] = [
    { label: 'Male', value: '1' },
    { label: 'Female', value: '0' },
];

export const genderDict: IGenderDictType = {
    Male: '1',
    Female: '0',
};
