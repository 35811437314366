import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import FormGenerator from 'src/components/Kit/FormGenerator/index';
import { useForm } from 'react-hook-form';
import { generateDefaultValueFromFormSchema } from 'src/utils/generate-default-value-from-form-schema';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from 'src/components/Kit/Button';
import { CreateParentCompanyFinancialSchemaFields, createParentCompanyFinancialSchema } from './schema';
import { IParentCompanyFinancialFormData } from './types';
import { FORM_INPUTS } from 'src/types/form';
import { companyService } from 'src/api/services/company';
import { COMPANY_TYPE_ROUTE } from 'src/pages/Company/Add/enums/company-type-route';
import { parentCompanyFinancialValidationSchema } from './validation-schema';
import { useAsync } from 'src/hooks/useAsync';
import { ICreateParentCompanyFinancialPayload, IPaginatedCompanyResponse } from 'src/api/types/company';
import { PARENT_COMPANY_FINANCIAL_COMPANY_SOURCE } from './enums/parent-company-financial-company-source';
import { useFormFields } from 'src/hooks/useFormFields';
import { ISelectBox } from 'src/api/types/base-types';
import { toast } from 'src/utils/toast';
import { TOAST_STATUS } from 'src/constants/toast-status';
import { getAxiosError } from 'src/utils/get-axios-error';
import dayjs from 'dayjs';
import { useEditingMode } from './useUpdate';
import pdfIcon from 'src/assets/Images/pdf-icon.svg';
import { IDataTableResponse } from 'src/api/types/table';

const ParentCompanyAddUpdate: React.FC = () => {
    const navigate = useNavigate();
    const { formFields, setFormFields, updateFormData, updateFormProps, updateFormLoading, updateFormType } = useFormFields(createParentCompanyFinancialSchema);
    const { companyId, completedData, isEditingRule, isLoading: completedDataIsLoading, parentCompanyId, companyType } = useEditingMode();
    const companyResponseMeta = useRef<IDataTableResponse<unknown>['meta']['pagination']>();

    const {
        control,
        formState: { errors },
        handleSubmit,
        watch,
        reset,
        setValue,
    } = useForm<IParentCompanyFinancialFormData>({
        defaultValues: generateDefaultValueFromFormSchema(formFields),
        mode: 'all',
        shouldFocusError: false,
        resolver: yupResolver(parentCompanyFinancialValidationSchema),
    });

    const { run, isLoading } = useAsync<unknown, ICreateParentCompanyFinancialPayload>((payload) => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await (isEditingRule
                    ? companyService(companyType as COMPANY_TYPE_ROUTE).updateParentCompanyFinancial(Number(companyId), Number(parentCompanyId), payload)
                    : companyService(companyType as COMPANY_TYPE_ROUTE).createParentCompanyFinancial(Number(companyId), payload));
                if (response.data.success) {
                    toast.fire({
                        icon: TOAST_STATUS.SUCCESS,
                        title: response.data.message,
                    });
                    navigate(-1);
                    return resolve(response.data.data);
                } else {
                    return reject(response.data.errors);
                }
            } catch (e) {
                toast.fire({
                    icon: TOAST_STATUS.ERROR,
                    title: getAxiosError(e).message,
                });
                return Promise.reject(getAxiosError(e));
            }
        });
    });

    const handleUpdateDropdownOptions = async (fieldName: CreateParentCompanyFinancialSchemaFields, getNewOptions: any, lazy?: boolean) => {
        if (lazy && companyResponseMeta.current) {
            if (companyResponseMeta.current.last_page === companyResponseMeta.current.current_page) return;
        }

        setFormFields((prev) => ({
            ...prev,
            [fieldName]: {
                ...prev[fieldName],
                data: [...(lazy ? prev[fieldName].data || [] : [])],
                props: {
                    ...prev[fieldName].props,
                    loading: true,
                },
            },
        }));

        const response = await getNewOptions();
        companyResponseMeta.current = response.data.meta;

        setFormFields((prev) => ({
            ...prev,
            [fieldName]: {
                ...prev[fieldName],
                data: [
                    ...(lazy ? prev[fieldName].data || [] : []),
                    ...response.data.data.map(({ label, value }: any) => ({
                        label,
                        value: String(value),
                    })),
                ],
                props: {
                    ...prev[fieldName].props,
                    loading: false,
                    disabled: false,
                    onFilter,
                    onLazy,
                },
            },
        }));
    };

    const onFilter = (fieldName: string, value: string) => {
        switch (fieldName) {
            case 'company_name': {
                handleUpdateDropdownOptions(fieldName, () =>
                    companyService(companyType as COMPANY_TYPE_ROUTE).selectBox({
                        ...(value ? { text: value } : {}),
                    })
                );
                break;
            }
        }
    };

    const onLazy = (fieldName: string) => {
        switch (fieldName) {
            case 'company_name': {
                handleUpdateDropdownOptions(
                    fieldName,
                    () =>
                        companyService(companyType as COMPANY_TYPE_ROUTE).selectBox({
                            page: (companyResponseMeta.current?.current_page || 1) + 1,
                        }),
                    true
                );
                break;
            }
        }
    };

    const getCompanyList = useAsync<ISelectBox[], IPaginatedCompanyResponse>((payload) => {
        return new Promise(async (resolve, reject) => {
            try {
                updateFormLoading('company_name', true);
                const response = await companyService(companyType as COMPANY_TYPE_ROUTE).selectBox(payload);
                if (response.data.success) {
                    updateFormProps('company_name', {
                        onFilter,
                        onLazy,
                    });
                    updateFormData(
                        'company_name',
                        response.data.data.map(({ label, value }) => ({ label, value: String(value) }))
                    );
                    return resolve(response.data.data);
                } else {
                    return reject(response.data.errors);
                }
            } catch (e) {
                toast.fire({
                    icon: TOAST_STATUS.ERROR,
                    title: getAxiosError(e).message,
                });
                return Promise.reject(getAxiosError(e));
            } finally {
                updateFormLoading('company_name', false);
            }
        });
    });

    const onSubmit = async (_val: IParentCompanyFinancialFormData) => {
        const yearEndDate = new Date(_val.financial_year_end);
        run({
            company_parent_financial_id:
                String(_val.company_source) === PARENT_COMPANY_FINANCIAL_COMPANY_SOURCE.SELECT_FROM_EXISTING_COMPANIES ? Number(_val.company_name) : null,
            company_parent_financial_name:
                String(_val.company_source) === PARENT_COMPANY_FINANCIAL_COMPANY_SOURCE.WRITE_THE_COMPANY_NAME ? _val.company_name : null,
            file_source: Number(_val.source),
            source_link: _val.source_link,
            languages: _val.language,
            report_date: Number(_val.report_date),
            uuid: _val.pdf_file!.uuid,
            year_end: dayjs(new Date(yearEndDate.getFullYear(), yearEndDate.getMonth(), 15).getTime()).unix(),
            pages: _val.pages ? _val.pages.map((page) => String(page)) : null,
        });
    };

    useEffect(() => {
        if (completedData) {
            const isSelectedCompanies = Boolean(completedData.company_parent_financial_id);
            isSelectedCompanies && getCompanyList.run({ selected_item: completedData?.company_parent_financial_id });

            updateFormProps('company_name', { disabled: false });
            updateFormType('company_name', isSelectedCompanies ? FORM_INPUTS.SELECT : FORM_INPUTS.TEXT);

            setFormFields((formSchema) => ({
                ...formSchema,
                ...(completedData.pdf_file_uuid && {
                    pdf_file: {
                        ...formSchema.pdf_file,
                        filePreview: {
                            name: completedData.pdf_file_uuid,
                            icon: pdfIcon,
                            download: true,
                            url: completedData.pdf_file_uuid,
                            onDelete: () => {
                                setValue('pdf_file', undefined);
                                setFormFields((formSchema) => ({
                                    ...formSchema,
                                    ...(completedData.pdf_file_uuid && {
                                        pdf_file: {
                                            ...formSchema.pdf_file,
                                            filePreview: undefined,
                                        },
                                    }),
                                }));
                            },
                        },
                    },
                }),
            }));

            reset({
                pages: completedData.pages?.map((item) => String(item)),
                report_date: completedData.report_date,
                source_link: completedData.source_link,
                company_source: isSelectedCompanies
                    ? PARENT_COMPANY_FINANCIAL_COMPANY_SOURCE.SELECT_FROM_EXISTING_COMPANIES
                    : PARENT_COMPANY_FINANCIAL_COMPANY_SOURCE.WRITE_THE_COMPANY_NAME,
                company_name: isSelectedCompanies ? String(completedData.company_parent_financial_id) : completedData.company_parent_financial_name,
                source: String(completedData.file_source),
                financial_year_end: dayjs.unix(completedData.year_end).toDate(),
                language: completedData.languages.map((item) => String(item)),
                pdf_file: { id: undefined, uuid: completedData.pdf_file_uuid },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [completedDataIsLoading]);

    useEffect(() => {
        const subscription = watch((value, { name }) => {
            if (name === 'company_source') {
                const isSelectList = value.company_source === PARENT_COMPANY_FINANCIAL_COMPANY_SOURCE.SELECT_FROM_EXISTING_COMPANIES;
                updateFormType('company_name', isSelectList ? FORM_INPUTS.SELECT : FORM_INPUTS.TEXT);
                updateFormProps('company_name', { disabled: false });
                setValue('company_name', '');
                if (isSelectList) {
                    getCompanyList.run({});
                }
            }
        });
        return () => subscription.unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch]);

    return (
        <div className="grid justify-content-center my-8">
            <div className="xs:col:12 md:col-8 xl:col-5">
                <span className="block text-2xl font-semibold">{isEditingRule ? 'Update' : 'Add New'} Parent Company</span>
                <div className="mt-5">
                    <FormGenerator
                        onSubmit={handleSubmit(onSubmit)}
                        control={control}
                        loading={isLoading}
                        errors={errors}
                        schema={formFields}
                        noBackgroundLayout
                        buttons={
                            <>
                                <Button disabled={isLoading} loading={isLoading} color="primary">
                                    {isEditingRule ? 'Update' : 'Create'}
                                </Button>
                                <Button type="button" onClick={() => navigate(-1)}>
                                    Cancel
                                </Button>
                            </>
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default ParentCompanyAddUpdate;
