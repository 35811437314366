import React, { useEffect, useState } from 'react';
import DashboardContentWrapper from 'src/layouts/DashboardContentWrapper';
import FormGenerator from 'src/components/Kit/FormGenerator/index';
import { useForm } from 'react-hook-form';
import { generateDefaultValueFromFormSchema } from 'src/utils/generate-default-value-from-form-schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { validationSchema } from './validation-schema';
import { createSchemaType, createSectorSchema } from './schema';
import Button from 'src/components/Kit/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'src/utils/toast';
import { TOAST_STATUS } from 'src/constants/toast-status';
import InitLoading from 'src/components/App/Loading/InitLoading';
import { TCreateSectorPayload } from 'src/api/types/sectors';
import { getAxiosError } from 'src/utils/get-axios-error';
import { useCheckPermissionAccess } from 'src/hooks/useCheckPermissionAccess';
import { PERMISSION_TYPES } from 'src/enums/permissions';
import { sectorService } from 'src/api/services/sector-service';
import { ICompanyTypeResponse } from 'src/api/types/sectors';
import { ISelectBox } from 'src/api/types/base-types';

interface IParams {
    [id: string]: string;
}

const AddUpdate: React.FC = () => {
    const { checkPermissionAccess } = useCheckPermissionAccess();
    const navigate = useNavigate();
    const { id } = useParams<IParams>();
    const isEditingRule = Boolean(id);
    const hasCreatePermission = checkPermissionAccess(PERMISSION_TYPES.SETTING_SECTOR_CREATE)
    const hasUpdatePermission = checkPermissionAccess(PERMISSION_TYPES.SETTING_SECTOR_EDIT)
    const [loading, setLoading] = useState<boolean>(false);
    const [initialLoading, setInitialLoading] = useState<boolean>(true);
    const [formSchema, setFormSchema] = useState<createSchemaType>(createSectorSchema);

    const {
        control,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm<TCreateSectorPayload>({
        defaultValues: generateDefaultValueFromFormSchema(formSchema),
        mode: 'all',
        resolver: yupResolver(validationSchema()),
    });

    const handleGetValues = async () => {
        let isSectorUsed = false

        setInitialLoading(true);
        if (isEditingRule) isSectorUsed =  await getDetails();
        
        try {
            let res = await sectorService.getAllCompanyTypes();

            const companyTypes: ISelectBox[] = res.data.data.map((c: ICompanyTypeResponse) => ({
                label: c.label,
                value: c.value,
            }));

            setFormSchema((prev) => ({
                ...prev,
                company_type: { ...prev.company_type, data: companyTypes, props: { ...prev.company_type.props, disabled: isSectorUsed }},
            }));
            setInitialLoading(false);
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: message,
            });
            setInitialLoading(false);
        }
    };

    const onSubmit = async (_val: TCreateSectorPayload) => {
        let targetApi;
        const { name, arabic_name, company_type } = _val;
        const tempData = {
            name,
            arabic_name,
            company_type
        };

        if (isEditingRule && hasUpdatePermission) targetApi = sectorService.update(Number(id), tempData);
        else if(hasCreatePermission) targetApi = sectorService.createNew(tempData)
        try {
            setLoading(true);
            await targetApi;
            toast.fire({
                icon: TOAST_STATUS.SUCCESS,
                title: isEditingRule ? 'Sector has been updated successfully' : 'Sector has been created successfully',
            });
            setLoading(false);
            navigate(-1);
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: message,
            });
            setLoading(false);
        }
    };

    const getDetails = async () => {
        try {
            let res = await sectorService.getOne(Number(id));
            reset({
                name: res.data.data.name,
                arabic_name: res.data.data.arabic_name || '',
                company_type: res.data.data.company_type,
            });
            return res.data.data.companies_count > 0 // Hint: in terms this sector used in some company and we can not update company type
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: message,
            });
            setInitialLoading(false)
            navigate(-1);
            throw new Error('Could not get details')
        }
    };

    useEffect(() => {
        handleGetValues();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if(isEditingRule && !hasUpdatePermission) return <></>;
    if(!isEditingRule && !hasCreatePermission) return <></>;

    if (initialLoading) return <InitLoading />;
    
    return (
        <DashboardContentWrapper>
            <div className="grid justify-content-center my-8">
                <div className="xs:col:12 md:col-8 xl:col-5">
                    <span className="block text-2xl font-semibold">{isEditingRule ? 'Update Sector' : 'Add New Sector'}</span>
                    <div className="mt-5">
                        <FormGenerator
                            onSubmit={handleSubmit(onSubmit)}
                            control={control}
                            errors={errors}
                            schema={formSchema}
                            noBackgroundLayout
                            buttons={
                                <>
                                    <Button disabled={loading} loading={loading} color="primary">
                                        {isEditingRule ? 'Update' : 'Create'}
                                    </Button>
                                    <Button type="button" onClick={() => navigate(-1)}>
                                        Cancel
                                    </Button>
                                </>
                            }
                        />
                    </div>
                </div>
            </div>
        </DashboardContentWrapper>
    );
};

export default AddUpdate;
