import { ISelectBox } from 'src/api/types/base-types';
import { RATIO_NUMBER_TYPE } from 'src/api/types/company';
import { ITEM_TYPE_ENUM, ITEM_VALUE_TYPE_OPTIONS_ENUM } from 'src/enums/company/financialInformation/financialItem';

export const ITEM_TYPE_OPTION: ISelectBox[] = [
    {
        label: 'Title',
        value: ITEM_TYPE_ENUM.TITLE,
    },
    {
        label: 'Subtitle',
        value: ITEM_TYPE_ENUM.SUBTITLE,
    },
    {
        label: 'Item',
        value: ITEM_TYPE_ENUM.ITEM,
    },
    {
        label: 'Subtotal',
        value: ITEM_TYPE_ENUM.SUBTOTAL,
    },
    {
        label: 'Total',
        value: ITEM_TYPE_ENUM.TOTAL,
    },
];

export const ITEM_VALUE_TYPE_OPTIONS: ISelectBox[] = [
    {
        label: 'Numeric',
        value: ITEM_VALUE_TYPE_OPTIONS_ENUM.NUMERIC,
    },
    {
        label: 'String',
        value: ITEM_VALUE_TYPE_OPTIONS_ENUM.STRING,
    },
];

export const NUMERIC_TYPE_OPTIONS: ISelectBox[] = [
    {
        label: 'Currency',
        value: RATIO_NUMBER_TYPE.CURRENCY,
    },
    {
        label: 'Number',
        value: RATIO_NUMBER_TYPE.NUMBER,
    },
];
