import { Navigate, Outlet, useParams } from 'react-router-dom';
import DashboardContentWrapper from 'src/layouts/DashboardContentWrapper/index';
import InitLoading from 'src/components/App/Loading/InitLoading';
import { CompanyProvider } from '../context';
import InnerMenu from 'src/components/App/InnerMenu';
import { companyInnerMenuConfig } from './inner-menu';
import { useFinancial } from '../../../../context/companies';
import ROUTE_CONSTANTS from '../../../../Routes/constants/route-constants';
import { COMPANY_TYPE_ROUTE } from '../../Add/enums/company-type-route';
import { IParams } from 'src/types/params';

const CompanyShowProvider = () => {
    return (
        <CompanyProvider >
            <CompanyLayout />
        </CompanyProvider>
    );
};

const CompanyLayout = () => {
    const { companyId, companyType } = useParams<IParams>();
    const isLoading = useFinancial((global) => global.companyIsLoading);
    if (!companyId) return Navigate({ to: ROUTE_CONSTANTS.NOT_FOUND.ABSOLUTE, replace: true });

    const menuItems = companyInnerMenuConfig(companyType as COMPANY_TYPE_ROUTE, parseInt(companyId)).filter((menuItem) => !menuItem.disabled);

    return (
        <DashboardContentWrapper noPadding>
            {isLoading ? (
                <InitLoading />
            ) : (
                <>
                    <InnerMenu data={menuItems} />
                    <Outlet />
                </>
            )}
        </DashboardContentWrapper>
    );
};

export default CompanyShowProvider;
