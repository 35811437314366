import { FileUploadProps } from 'primereact/fileupload';
import { classNames } from 'primereact/utils';
import { useRef, useState } from 'react';
import { ControllerRenderProps, FieldError, FieldValues } from 'react-hook-form';
import DeleteIcon from 'src/assets/Icons/DeleteIcon';
import { TOAST_STATUS } from 'src/constants/toast-status';
import { toast } from 'src/utils/toast';
import style from './FormFile.module.scss';
import { Link } from 'react-router-dom';
import { mediaService } from 'src/api/services/media';
import { getAxiosError } from 'src/utils/get-axios-error';
import Button from '../../Button';
import { modalActions } from '../../../../context/modals';
import { MODAL_TYPES } from '../../../../types/modals';
import { FILE_UPLOAD_TYPE } from '../../FileDialog/constants';
import { base64ToFile } from '../../../../utils/base64-to-file';
import { RedError } from 'src/assets/Icons/RedError';

interface IProps {
    field: ControllerRenderProps<FieldValues, string>;
    label: string;
    placeholder: string;
    className: string;
    errorMessage: FieldError;
    props?: FileUploadProps & { required?: boolean };
    filePreview?: {
        name?: string;
        icon?: string;
        download?: boolean;
        url: string;
        onDelete?: () => void;
    };
}

const FormFile = ({ field, label, placeholder, className, errorMessage, props = {}, filePreview }: IProps) => {
    const [selectedFile, setSelectedFile] = useState<File>();

    const fileName = selectedFile?.name ? selectedFile?.name.slice(0, 30) + '...' : props?.chooseLabel;

    const clearFileInput = () => {
        field.onChange(undefined);
        setSelectedFile(undefined);
    };

    const openFileDialog = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();
        modalActions.addModal(MODAL_TYPES.FILE_MODAL, {
            header: 'Document Library',
            onFileSelected: async (token, file, fileStorage) => {
                field.onChange(token);
                if (file) {
                    setSelectedFile(file);
                    return;
                }
                if (fileStorage) {
                    const convertedFile = await base64ToFile(fileStorage.url as string, fileStorage.file_name, 'pdf');
                    setSelectedFile(convertedFile);
                }
            },
            fileType: FILE_UPLOAD_TYPE.DOCUMENT,
        });
    };

    const UploadComponent = () => {
        return (
            <div className={classNames(style.fileUploader, 'form-field')}>
                {filePreview && <div className={style.fileUploaderTooltip}>To upload a new file, please delete the old file first</div>}
                <label className={field.name}>
                    {label || placeholder} {props.required ? <span className="required">*</span> : ''}
                </label>
                <Button id={field.name} color={'secondary'} disabled={!!filePreview} className={style.fileBtn} onClick={openFileDialog} {...field}>
                    <div></div>
                    <div>{fileName}</div>
                    <span>+</span>
                </Button>
                {selectedFile && <DeleteIcon className={style.fileUploaderClear} onClick={clearFileInput} />}
                {errorMessage ? (
                    <div className={'form-input-error'}>
                        <RedError />
                        <span>{errorMessage.message}</span>
                    </div>
                ) : null}
            </div>
        );
    };
    const PreviewComponent = () => {
        const elementRef = useRef<HTMLAnchorElement>(null);

        const handleGetLink = async () => {
            try {
                let res = await mediaService.getViewPDFLink(filePreview!.url);
                const link: string = res.data.data;

                if (elementRef.current) {
                    elementRef.current.href = link;
                    elementRef.current.target = '_blank';
                }
                elementRef.current?.click();
            } catch (err) {
                const error = getAxiosError(err);
                const message = error?.message || 'Server Error';
                toast.fire({
                    icon: TOAST_STATUS.ERROR,
                    title: message,
                });
            }
        };

        return (
            <div className={classNames(style.fileUploader, 'form-field')}>
                <label className={field.name}>Preview</label>
                <div className={style.fileUploaderFilePreview}>
                    <div className={style.downloaderElement} onClick={handleGetLink}>
                        {filePreview!.icon && <img src={filePreview!.icon} alt="icon" />}
                        <span>{filePreview!.name || 'No Name'}</span>
                    </div>
                    <Link ref={elementRef} to={''} target="_blank" className="fileIcon hidden" download={filePreview!.download}></Link>
                    <DeleteIcon className={style.fileUploaderFilePreviewClear} onClick={filePreview!.onDelete} />
                </div>
            </div>
        );
    };

    return (
        <div className={classNames('col-12', className ? className : '')}>
            <>
                {filePreview ? (
                    <div className="grid">
                        <div className="col-7">
                            <UploadComponent />
                        </div>
                        <div className="col-5">
                            <PreviewComponent />
                        </div>
                    </div>
                ) : (
                    <UploadComponent />
                )}
            </>
        </div>
    );
};

export default FormFile;
