import { SVGProps } from 'react';

const CustomizeIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19 21H5C3.895 21 3 20.105 3 19V5C3 3.895 3.895 3 5 3H19C20.105 3 21 3.895 21 5V19C21 20.105 20.105 21 19 21Z"
                stroke={props.stroke || "currentColor" || "#212121"}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M3 9H21" stroke={props.stroke || "currentColor" || "#212121"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M3 15H21" stroke={props.stroke || "currentColor" || "#212121"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12 9V21" stroke={props.stroke || "currentColor" || "#212121"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default CustomizeIcon;
