import { AxiosError } from 'axios';
import { IAxiosResponse } from 'src/api/types/axios-response';
import { IAxiosErrorResponse } from '../api';

export function getAxiosError(err: any): IAxiosResponse<{}> {
    const error = err as AxiosError<{ message: string }>;
    if (error.isAxiosError) {
        if (error.response?.data) {
            return error.response?.data as IAxiosResponse;
        }
        return {
            message: error.message,
            success: false,
            data: [],
        };
    } else {
        return {
            message: error.message,
            success: false,
            data: [],
        };
    }
}

/*
* @Input : {
    "transaction.0.status": [
        "The transaction.0.status field is required."
    ],
    "transaction.0.ownership_stake": [
        "The transaction.0.ownership_stake field must be between 0.01 and 100."
    ]
  };
  *
* @Output : {
      '0': [
        'The status field is required.',
        'The ownership_stake field must be between 0.01 and 100.'
      ]
    }
*
* */
export function getErrorsByRow(errors: IAxiosErrorResponse['errors']): Record<string, string[]> {
    const byRowErrors: Record<string, string[]> = {};

    Object.entries(errors).forEach(([field, fieldErrors]) => {
        const [_, rowIndex] = field.split('.');
        fieldErrors = fieldErrors.map((err) => err.replace(`${_}.${rowIndex}.`, ''));
        byRowErrors[rowIndex] = [...(byRowErrors[rowIndex] || []), ...fieldErrors];
    });

    return byRowErrors;
}
