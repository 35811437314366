import { API_ROUTES } from 'src/constants/api-routes';
import { AxiosInstance } from '../index';
import { IAxiosResponse } from '../types/axios-response';
import { IDividendsTemplates, IDividendsDates, IDividendResponse } from '../types/dividends';
import { COMPANY_TYPE_ROUTE } from '../../pages/Company/Add/enums/company-type-route';
import { companyTypeParam } from '../../constants/company/company-type-param';

export const dividendsService = (companyTypeRoute: COMPANY_TYPE_ROUTE) => {
    const companyType = companyTypeParam[companyTypeRoute];

    function getRouteWithCompanyType(route: string) {
        let typeRoute = `/companies/${companyType}`;
        return route.replace('companies', typeRoute);
    }

    return {
        getDividendsTemplates() {
            return AxiosInstance.get<IAxiosResponse<IDividendsTemplates[]>>(getRouteWithCompanyType(API_ROUTES.DIVIDENDS.GET_TEMPLATES));
        },
        getDividendsDates(templateID: number) {
            return AxiosInstance.get<IAxiosResponse<IDividendsDates[]>>(getRouteWithCompanyType(API_ROUTES.DIVIDENDS.GET_TEMPLATES_FIELDS(templateID)));
        },
        getOne(companyID: number, dividendsID: number) {
            return AxiosInstance.get<IAxiosResponse<IDividendResponse>>(getRouteWithCompanyType(API_ROUTES.DIVIDENDS.DIVIDENDS_EDIT(companyID, dividendsID)));
        },
        delete(companyID: number, dividendsID: number) {
            return AxiosInstance.delete<IAxiosResponse>(getRouteWithCompanyType(API_ROUTES.DIVIDENDS.DELETE(companyID, dividendsID)));
        },
    };
};
