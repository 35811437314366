import { API_ROUTES } from 'src/constants/api-routes';
import { AxiosInstance } from '../index';
import { IAxiosResponse } from '../types/axios-response';
import { IGetListPayload, ISelectBox } from '../types/base-types';

export const regionService = {
    selectBox(params?: IGetListPayload) {
        return AxiosInstance.get<IAxiosResponse<ISelectBox[]>>(API_ROUTES.COUNTRIES.GET_LIST, { params });
    },
};
