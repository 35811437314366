import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { generateDefaultValueFromFormSchema } from 'src/utils/generate-default-value-from-form-schema';
import { yupResolver } from '@hookform/resolvers/yup';
import FormGenerator from 'src/components/Kit/FormGenerator/index';
import { useMultiStepFormContext } from 'src/components/Kit/MultiStepForm/context';
import { ContactInformationSchemaFields, contactInformationSchema } from '../../../types/contact-information-form-fields-schema';
import { IContactInformationFormSchema } from '../../../types/contact-information-form-schema';
import { contactInformationValidationSchema } from '../../../validationSchemas/contact-information-validation-schema';
import { StepConfig } from 'src/components/Kit/MultiStepForm/types/step-config';
import { IFormGeneratorGeneralSchemaType } from 'src/types/form-generator-schema-type';

const ContactInformationCompanyForm: React.FC = () => {
    const { setStepsData, setFormValues, formValues, activeStepIndex } = useMultiStepFormContext();
    const [formSchema, setFormSchema] = useState<Record<ContactInformationSchemaFields, IFormGeneratorGeneralSchemaType>>(contactInformationSchema);
    
    const {
        control,
        getValues,
        setValue,
        watch,
        formState: { errors, isValid, isDirty },
    } = useForm<any>({
        defaultValues: generateDefaultValueFromFormSchema(formSchema),
        mode: 'all',
        resolver: yupResolver(contactInformationValidationSchema),
    });
    
    const handleUpdateSteps = () => {
        setStepsData((prevStepsData: StepConfig[]) => {
            return prevStepsData.map((step: StepConfig) => {
                if (isValid) {
                    // Hint: we let admin to push next step button and go forward with the form
                    if (step.id === activeStepIndex + 1) {
                        return { ...step, disabled: false };
                    } else return step;
                } else {
                    // Hint: current step has problem, so we disable all next steps till this step become fine
                    if (step.id > activeStepIndex) {
                        return { ...step, disabled: true };
                    } else return step;
                }
            });
        });
    };
    
    useEffect(() => {
        handleUpdateSteps();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isValid]);

    useEffect(() => {
        if (Object.keys(formValues).length) {
            // Hint: this condition is for those moments we are coming back from next steps.
            Object.entries(formValues).forEach(([key, value]) => setValue(key as keyof IContactInformationFormSchema, value, { shouldValidate: true }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    useEffect(() => {
        if (isDirty) {
            setStepsData((prevStepsData: StepConfig[]) => {
                return prevStepsData.map((step: StepConfig) => {
                    if (step.id === activeStepIndex) {
                        return { ...step, hasError: false, errors: {} };
                    } else return step;
                });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDirty]);

    useEffect(() => {
        const subscription = watch((value, { name }) => {
            const currentValues = getValues();
            
            const deletedKeys = Object.keys(currentValues).filter(
                (k) => currentValues[k as keyof IContactInformationFormSchema] === null || currentValues[k as keyof IContactInformationFormSchema] === ''
            );

            setFormValues((prev) => {
                const newFormValue = { ...prev, ...currentValues };
                Object.keys(newFormValue).forEach((k) => deletedKeys.includes(k) && delete newFormValue[k as keyof IContactInformationFormSchema]);
                return newFormValue;
            });

            const handleDetectLatAndLngIsRequired = () => {
                const lat = currentValues.latitude
                const lng = currentValues.longitude

                if(lng || lat) return true // Hint: if any of these two fields does have value, both of them become mandatory, else they are optional.
                else return false
            }

            setFormSchema((prev) => ({
                ...prev,
                latitude: {
                    ...prev.latitude,
                    props: { ...prev.latitude.props, required: handleDetectLatAndLngIsRequired() },
                },
                longitude: {
                    ...prev.longitude,
                    props: { ...prev.longitude.props, required: handleDetectLatAndLngIsRequired() },
                },
            }));
        });
        return () => subscription.unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch]);

    return (
        <div className="grid justify-content-center">
            <div className="xs:col:12 md:col-6">
                <FormGenerator control={control} errors={errors} schema={formSchema} noBackgroundLayout />
            </div>
        </div>
    );
};

export default ContactInformationCompanyForm;
