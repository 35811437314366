import { FC, useEffect, useState } from 'react';
import Button from 'src/components/Kit/Button';
import styles from './SelectCurrency.module.scss';
import { Dropdown, DropdownChangeParams } from 'primereact/dropdown';
import { toast } from 'src/utils/toast';
import { TOAST_STATUS } from 'src/constants/toast-status';
import { getAxiosError } from 'src/utils/get-axios-error';
import { currencyService } from 'src/api/services/currency';
import { companyService } from 'src/api/services/company';
import { MUTATION_FINANCIAL_STEP } from '../../../enums';
import { ISelectBox } from 'src/api/types/base-types';
import { financialActions, useFinancial, useFinancialCompanyId } from '../../../../../../../context/companies';
import { dropDownDate } from 'src/constants/dropdown-date';
import { useParams } from 'react-router-dom';
import { COMPANY_TYPE_ROUTE } from 'src/pages/Company/Add/enums/company-type-route';
import { IParams } from 'src/types/params';
import { IFinancialInformationSelectedCurrency } from 'src/pages/Company/Show/types';

interface Props {
    isModal?: boolean;
    closeModal?: () => void;
}

const SelectCurrency: FC<Props> = ({ isModal = false, closeModal }) => {
    const companyId = useFinancialCompanyId();
    const { companyType } = useParams<IParams>();
    const { companyDetail, financialYearEnd, currency, canChangeBasics } = useFinancial((global) => ({
        companyDetail: global.company,
        financialYearEnd: global.financialYearEnd,
        canChangeBasics: isModal ? true : global.canChangeBasic,
        currency: global.financialSelectedCurrency,
    }));
    const [currencyOptions, setCurrencyOptions] = useState<ISelectBox[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [temporaryData, setTemporaryData] = useState<{ financialYearEnd: string | undefined; currency: IFinancialInformationSelectedCurrency | undefined }>({
        financialYearEnd,
        currency,
    });

    const fetchData = async (): Promise<void> => {
        try {
            const response = await currencyService.selectBox();
            setCurrencyOptions(response.data.data);
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: message,
            });
        }
    };

    const handleSubmit = async () => {
        if (!currency || !financialYearEnd) return;
        if (!canChangeBasics) {
            // It happens when admin has financial data and can not change template any more.
            financialActions.setMutationStep(MUTATION_FINANCIAL_STEP.SELECT_FINANCIAL_DATA);
            return;
        }

        setLoading(true);
        const tempData = {
            month: isModal ? Number(temporaryData.financialYearEnd) : Number(financialYearEnd),
            currency_id: isModal ? Number(temporaryData.currency?.id) : currency.id,
        };

        try {
            await companyService(companyType as COMPANY_TYPE_ROUTE).saveSelectedYearEndAndCurrency(companyId, tempData);

            if (isModal && temporaryData.financialYearEnd && temporaryData?.currency && companyDetail) {
                const currency = {
                    id: Number(temporaryData.currency.id),
                    short_name: temporaryData.currency.short_name,
                };
                financialActions.setFinancialSelectedCurrency(currency);

                financialActions.setFinancialYearEnd(temporaryData.financialYearEnd);
                financialActions.editCompany({ ...companyDetail, selectedCurrency: currency });
            }

            if (!isModal) {
                financialActions.setMutationStep(MUTATION_FINANCIAL_STEP.SELECT_FINANCIAL_DATA);
            }
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: message,
            });
        } finally {
            setLoading(false);
            if (isModal && closeModal) {
                closeModal();
            }
        }
    };

    const handleSaveCurrency = (currencyId: number) => {
        const selectedCurrency = currencyOptions.find(({ value }) => value === currencyId);
        if (selectedCurrency) {
            if (isModal) {
                setTemporaryData((prev) => ({ ...prev, currency: { id: Number(selectedCurrency.value), short_name: selectedCurrency.label } }));
            } else {
                financialActions.setFinancialSelectedCurrency({
                    id: Number(selectedCurrency?.value),
                    short_name: selectedCurrency?.label,
                });
            }
        }
    };

    const handleSaveYearEnd = (e: DropdownChangeParams) => {
        if (isModal) {
            setTemporaryData((prev) => ({ ...prev, financialYearEnd: e.value }));
        } else {
            financialActions.setFinancialYearEnd(e.value);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className={styles['financial-information__select-currency']}>
            {!isModal && <h3>Select currency & Financial year-end</h3>}
            <p>Specify the currency of this company currency section</p>
            <div className="form-field mt-3">
                <label>
                    Select Currency<span className="required">*</span>
                </label>
                <Dropdown
                    data-cy={'currency-selectbox'}
                    optionLabel="label"
                    optionValue="value"
                    value={isModal ? temporaryData.currency?.id : currency?.id}
                    options={currencyOptions}
                    onChange={(e) => handleSaveCurrency(e.value)}
                    placeholder="Currency"
                    disabled={!currencyOptions?.length || !canChangeBasics}
                    required
                />
            </div>
            <div className="form-field mt-5">
                <label>
                    Select Financial Year End<span className="required">*</span>
                </label>
                <Dropdown
                    data-cy={'yearend-selectbox'}
                    optionLabel="label"
                    optionValue="value"
                    value={isModal ? temporaryData.financialYearEnd : financialYearEnd}
                    options={dropDownDate}
                    onChange={handleSaveYearEnd}
                    placeholder="Financial Year End"
                    disabled={!canChangeBasics}
                    required
                />
            </div>
            <div className={styles.buttonsWrapper}>
                <Button
                    onClick={() => (isModal ? closeModal?.() : financialActions.setMutationStep(MUTATION_FINANCIAL_STEP.SELECT_TEMPLATE_DATA))}
                    size="l"
                    color="secondary"
                >
                    {isModal ? 'Cancel' : 'Back'}
                </Button>
                <Button
                    data-cy={'next-financial-form'}
                    customStyle={{ flex: 1 }}
                    disabled={!currency || !financialYearEnd || loading}
                    loading={loading}
                    position="right"
                    onClick={handleSubmit}
                    size="l"
                    color="primary"
                >
                    {isModal ? 'Submit' : 'Next'}
                </Button>
            </div>
        </div>
    );
};

export default SelectCurrency;
