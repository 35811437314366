import { FORM_INPUTS } from 'src/types/form';
import { IFormGeneratorSchemaType } from 'src/types/form-generator-schema-type';

export type CreateNewsSourceSchemaFields =
    | 'source_name'
    | 'arabic_source_name'
    | 'country_id'
    | 'website'
    | 'logo';
export type CreateNewsSourceSchemaType = Record<CreateNewsSourceSchemaFields, IFormGeneratorSchemaType>;

export const createNewsSourceSchema: CreateNewsSourceSchemaType = {
    logo: {
        placeholder: 'Logo',
        type: FORM_INPUTS.IMAGE_UPLOADER,
        props: {
            required: false,
        },
    },
    source_name: {
        placeholder: 'Source Name',
        type: FORM_INPUTS.TEXT,
        props: {
            required: true,
            min: 2,
            max: 80,
        },
    },
    arabic_source_name: {
        placeholder: 'Arabic Source Name',
        type: FORM_INPUTS.TEXT,
        props: {
            required: false,
            min: 2,
            max: 80,
        },
    },
    country_id: {
        label: 'Country',
        placeholder: 'Select Country',
        type: FORM_INPUTS.SELECT,
        props: {
            required: true,
            disabled: true,
            filter: true,
            lazy: true,
        },
    },
    website: {
        placeholder: 'Website',
        type: FORM_INPUTS.TEXT,
        props: {
            required: false,
        },
    },
};
