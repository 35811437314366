import React, { useEffect, useState } from 'react';
import FormGenerator from 'src/components/Kit/FormGenerator/index';
import { useForm } from 'react-hook-form';
import { generateDefaultValueFromFormSchema } from 'src/utils/generate-default-value-from-form-schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { dividendsValidationSchema } from './validation-schema';
import Button from 'src/components/Kit/Button';
import { useNavigate, useParams } from 'react-router-dom';
import InitLoading from 'src/components/App/Loading/InitLoading';
import { getAxiosError } from 'src/utils/get-axios-error';
import { toast } from 'src/utils/toast';
import { TOAST_STATUS } from 'src/constants/toast-status';
import { createDividendsSchema, CreateDividendsSchemaType } from './schema';
import { IDividendsFormData } from './types';
import ROUTE_CONSTANTS from 'src/Routes/constants/route-constants';
import { FORM_INPUTS } from 'src/types/form';
import { companyService } from 'src/api/services/company';
import { dividendsService } from 'src/api/services/dividends';
import { ICreateDividendsPayload, IDividendsTemplates } from 'src/api/types/dividends';
import dayjs from 'dayjs';
import { COMPANY_TYPE_ROUTE } from 'src/pages/Company/Add/enums/company-type-route';
import { IParams } from 'src/types/params';

const Add: React.FC = () => {
    const { companyId, companyType, id } = useParams<IParams>();
    const navigate = useNavigate();
    const isEditingRule = Boolean(id);
    const [loading, setLoading] = useState<boolean>(false);
    const [initialLoading, setInitialLoading] = useState<boolean>(true);
    const [updateFormLoading, setUpdateFormLoading] = useState<boolean>(false);
    const [formSchema, setFormSchema] = useState<CreateDividendsSchemaType>(createDividendsSchema);

    const {
        control,
        formState: { errors },
        handleSubmit,
        watch,
        reset,
        getValues,
    } = useForm<IDividendsFormData>({
        defaultValues: generateDefaultValueFromFormSchema(formSchema),
        mode: 'all',
        shouldFocusError: false,
        resolver: yupResolver(dividendsValidationSchema),
    });

    const onSubmit = async (_val: IDividendsFormData) => {
        let targetApi;

        const tempData = {
            financial_year: Number(dayjs(_val.financial_year).format('YYYY')),
            se_template: Number(_val.se_template),
            dates: [],
        } as ICreateDividendsPayload;

        if (_val.cash_dividends || _val.cash_dividends === 0) tempData['cash_dividends'] = _val.cash_dividends;
        if (_val.bonus_shares || _val.bonus_shares === 0) tempData['bonus_shares'] = _val.bonus_shares;

        Object.keys(_val).forEach((key) => {
            if (!Object.keys(tempData).includes(key)) {
                if (_val[key]) {
                    tempData['dates'].push({
                        id: Number(key),
                        value: dayjs.tz(new Date(_val[key]).toUTCString()).unix(),
                    });
                }
            }
        });

        if (isEditingRule) targetApi = companyService(companyType as COMPANY_TYPE_ROUTE).updateDividends(Number(companyId), Number(id), tempData);
        else targetApi = companyService(companyType as COMPANY_TYPE_ROUTE).createDividends(Number(companyId), tempData);
        try {
            setLoading(true);
            await targetApi;
            toast.fire({
                icon: TOAST_STATUS.SUCCESS,
                title: 'Dividends has been created successfully',
            });
            navigate(ROUTE_CONSTANTS.COMPANIES.TYPE.SHOW.DIVIDENDS.ROOT.BY_COMPANY_ID(companyType as COMPANY_TYPE_ROUTE, Number(companyId)).ABSOLUTE);
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: message,
            });
        } finally {
            setLoading(false);
        }
    };

    const handleGetValues = async () => {
        if (isEditingRule) await getDetails();
        try {
            let res = await dividendsService(companyType as COMPANY_TYPE_ROUTE).getDividendsTemplates();
            setFormSchema((formSchema) => ({
                ...formSchema,
                se_template: {
                    ...formSchema.se_template,
                    data: res.data.data.map((temp: IDividendsTemplates) => ({ value: String(temp.id), label: temp.label })),
                },
            }));
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: message,
            });
        } finally {
            setInitialLoading(false);
        }
    };

    const getDetails = async () => {
        try {
            let res = await dividendsService(companyType as COMPANY_TYPE_ROUTE).getOne(Number(companyId), Number(id));

            await fetchTemplateFields(res.data.data.se_template.id);

            const tempValues = {
                financial_year: dayjs(dayjs(String(res.data.data.financial_year)).format()).toDate(),
                cash_dividends: res.data.data.cash_dividends,
                bonus_shares: res.data.data.bonus_shares,
                se_template: res.data.data.se_template.id,
            } as any;

            for (let myDate of res.data.data.dates) {
                tempValues[` ${myDate.id}`] = dayjs.unix(Number(myDate.value)).toDate();
            }

            reset(tempValues);
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: message,
            });
        }
    };

    const fetchTemplateFields = async (id: number) => {
        try {
            setUpdateFormLoading(true);
            let res = await dividendsService(companyType as COMPANY_TYPE_ROUTE).getDividendsDates(id);

            let newFields = {} as CreateDividendsSchemaType;
            res.data.data.forEach((newField) => {
                newFields = {
                    ...newFields,
                    [' ' + newField.id]: {
                        placeholder: newField.label,
                        parentID: id,
                        type: FORM_INPUTS.DATE,
                        props: {
                            required: false,
                        },
                    },
                };
            });

            setFormSchema((formSchema) => {
                Object.keys(formSchema).forEach(
                    (key) => ['financial_year', 'se_template', 'cash_dividends', 'bonus_shares'].includes(key) || delete formSchema[key]
                );
                return { ...formSchema, ...newFields };
            });
            reset({
                financial_year: getValues('financial_year'),
                se_template: getValues('se_template'),
                cash_dividends: getValues('cash_dividends'),
                bonus_shares: getValues('bonus_shares'),
            });
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: message,
            });
        } finally {
            setUpdateFormLoading(false);
        }
    };

    useEffect(() => {
        handleGetValues();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const subscription = watch((value, { name }) => {
            if (name === 'se_template' && value) {
                fetchTemplateFields(Number(value[name]));
            }
        });
        return () => subscription.unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch]);

    if (initialLoading) return <InitLoading />;

    return (
        <div className="grid justify-content-center my-8">
            <div className="xs:col:12 md:col-8 xl:col-5">
                <span className="block text-2xl font-semibold">{isEditingRule ? 'Update' : 'Add New'} Dividends</span>
                <div className="mt-5">
                    <FormGenerator
                        onSubmit={handleSubmit(onSubmit)}
                        control={control}
                        loading={updateFormLoading}
                        errors={errors}
                        schema={formSchema}
                        noBackgroundLayout
                        buttons={
                            <>
                                <Button disabled={loading} loading={loading} color="primary">
                                    {isEditingRule ? 'Update' : 'Create'}
                                </Button>
                                <Button
                                    type="button"
                                    onClick={() =>
                                        navigate(
                                            ROUTE_CONSTANTS.COMPANIES.TYPE.SHOW.DIVIDENDS.ROOT.BY_COMPANY_ID(companyType as COMPANY_TYPE_ROUTE, Number(companyId))
                                                .ABSOLUTE
                                        )
                                    }
                                >
                                    Cancel
                                </Button>
                            </>
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default Add;
