import { FORM_INPUTS } from 'src/types/form';
import { IFormGeneratorSchemaType } from 'src/types/form-generator-schema-type';

export type CreateHoldingSchemaFields = 'entity_type' | 'entity_id';
export type CreateHoldingSchemaType = Record<CreateHoldingSchemaFields, IFormGeneratorSchemaType>;

export const createHoldingSchema: CreateHoldingSchemaType = {
    entity_type: {
        placeholder: 'Transaction Type',
        type: FORM_INPUTS.SELECT,
        className: 'lg:col-6',
        data: [],
        props: {
            required: true,
        },
    },
    entity_id: {
        placeholder: 'Company',
        type: FORM_INPUTS.SELECT,
        className: 'lg:col-6',
        props: {
            required: true,
            loading: true,
            filter: true,
        },
    },
};
