import React, { useState } from 'react';
import styles from './AddNewItemForm.module.scss';
import Button from 'src/components/Kit/Button';
import { RadioButton } from 'primereact/radiobutton';
import GeneralFormErrorMessage from 'src/components/App/GeneralFormErrorMessage';
import FormFieldWrapper from 'src/components/Kit/FormFieldWrapper';
import FormFooterActions from 'src/components/Kit/FormFooterActions';
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { validationSchema } from './validation-schema';
import { companyService } from 'src/api/services/company';
import { getAxiosError } from 'src/utils/get-axios-error';
import { TOAST_STATUS } from 'src/constants/toast-status';
import { toast } from 'src/utils/toast';
import { Dropdown } from 'primereact/dropdown';
import { useGetFinancialDataTypes } from 'src/hooks/company/useGetFinancialDataTypes';
import { ITEM_TYPE_ENUM, ITEM_VALUE_TYPE_OPTIONS_ENUM } from 'src/enums/company/financialInformation/financialItem';
import { IFinancialInformationCreateItemPayload } from 'src/api/types/company';
import { IFormData } from './types';
import { useFinancialSelectedType } from '../../../../../../../../../context/companies';
import { NUMERIC_TYPE_OPTIONS } from 'src/constants/company/financialInformation/financialItem';
import SearchableDropdown from 'src/components/Kit/SearchableDropdown';
import { DROPDOWNS_FILTER } from 'src/components/App/Table/enums';
import { useParams } from 'react-router-dom';
import { COMPANY_TYPE_ROUTE } from 'src/pages/Company/Add/enums/company-type-route';
import { IParams } from 'src/types/params';

interface IProps {
    itemType: ITEM_TYPE_ENUM | undefined;
    handleCreateNewItem?: any;
    itemId: number | undefined;
}
const AddNewItemForm: React.FC<IProps> = ({ itemType, handleCreateNewItem, itemId }) => {
    const { companyType } = useParams<IParams>();
    // Notice that if selectedFinancialDataTypeId is undefined it means that we are in compact mode.
    const selectedFinancialDataTypeId = useFinancialSelectedType();
    const { financialDataTypes } = useGetFinancialDataTypes();
    const [apiError, setApiError] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [activeItemValueType, setActiveItemValueType] = useState<ITEM_VALUE_TYPE_OPTIONS_ENUM>();
    const [activeItemDataType, setActiveItemDataType] = useState<number>();
    const typeIdParam = selectedFinancialDataTypeId || activeItemDataType;
    const {
        control,
        formState: { errors },
        handleSubmit,
        setValue,
    } = useForm<IFormData>({
        defaultValues: {
            name: '',
            item_type: itemType,
            item_value_type: null,
            category: undefined,
            subcategory: undefined,
            item_data_type: undefined,
            number_type: undefined,
        },
        mode: 'all',
        resolver: yupResolver(validationSchema(selectedFinancialDataTypeId)),
    });

    const onFormSubmit = async (data: any) => {
        const tempData = {
            name: data.name,
            arabic_name: data.arabic_name,
            type: data.item_type,
            type_id: selectedFinancialDataTypeId || data.item_data_type,
            ...(data.category?.length ? { category_id: data.category } : {}),
            ...(data.subcategory?.length ? { sub_category_id: data.subcategory } : {}),
        } as IFinancialInformationCreateItemPayload;

        switch (itemType) {
            case ITEM_TYPE_ENUM.TITLE:
            case ITEM_TYPE_ENUM.SUBTITLE:
                tempData.value_type = null;
                break;
            case ITEM_TYPE_ENUM.TOTAL:
            case ITEM_TYPE_ENUM.SUBTOTAL:
            case ITEM_TYPE_ENUM.ITEM:
                tempData.value_type = data.item_value_type;
                if (data.category && data.category[0]?.id) tempData.category_id = data.category[0].id;
                if (data.subcategory && data.subcategory[0]?.id) tempData.sub_category_id = data.subcategory[0].id;
                if (Number(data.item_value_type) === ITEM_VALUE_TYPE_OPTIONS_ENUM.NUMERIC && data.number_type) tempData.number_type = data.number_type;
                break;
            default:
                break;
        }

        setLoading(true);
        try {
            let res = await companyService(companyType as COMPANY_TYPE_ROUTE).financialInformationCreateItem(tempData);
            toast.fire({
                icon: TOAST_STATUS.SUCCESS,
                title: 'Item created successfully!',
            });

            const tempResponse = {
                value: [{ id: res.data.data.id, name: res.data.data.name, value_type: res.data.data.value_type, number_type: res.data.data.number_type }],
            };
            handleCreateNewItem(tempResponse, itemId, res.data.data.number_type);
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: message,
            });
            setApiError(message);
        } finally {
            setLoading(false);
        }
    };

    const handleChangeItemValueType = (value: ITEM_VALUE_TYPE_OPTIONS_ENUM, field: ControllerRenderProps<IFormData, 'item_value_type'>) => {
        field.onChange(value);
        setActiveItemValueType(value);
        setValue('number_type', null);
    };

    const handleChangeItemDataType = (value: number, field: ControllerRenderProps<IFormData, 'item_data_type'>) => {
        field.onChange(value);
        setValue('category', []);
        setValue('subcategory', []);
        setActiveItemDataType(value);
    };

    return (
        <div className={styles.financialDataSidePanelBody}>
            <div className={styles.actionWrapper}>
                {apiError && <GeneralFormErrorMessage message={apiError} />}
                <form noValidate data-cy="login-form" onSubmit={handleSubmit(onFormSubmit)}>
                    {[ITEM_TYPE_ENUM.ITEM, ITEM_TYPE_ENUM.TOTAL, ITEM_TYPE_ENUM.SUBTOTAL].includes(itemType as ITEM_TYPE_ENUM) && (
                        <>
                            <FormFieldWrapper>
                                <Controller
                                    name="item_value_type"
                                    control={control}
                                    render={({ field }) => (
                                        <>
                                            <div className={styles.radioButtonsWrapper}>
                                                <div onClick={() => handleChangeItemValueType(ITEM_VALUE_TYPE_OPTIONS_ENUM.NUMERIC, field)}>
                                                    <RadioButton
                                                        name={'Numeric'}
                                                        value={ITEM_VALUE_TYPE_OPTIONS_ENUM.NUMERIC}
                                                        checked={field.value === ITEM_VALUE_TYPE_OPTIONS_ENUM.NUMERIC}
                                                    />
                                                    <span className="ml-1">Numeric</span>
                                                </div>
                                                <div onClick={() => handleChangeItemValueType(ITEM_VALUE_TYPE_OPTIONS_ENUM.STRING, field)}>
                                                    <RadioButton
                                                        className="ml-3"
                                                        value={ITEM_VALUE_TYPE_OPTIONS_ENUM.STRING}
                                                        name={'String'}
                                                        checked={field.value === ITEM_VALUE_TYPE_OPTIONS_ENUM.STRING}
                                                    />
                                                    <span className="ml-1">String</span>
                                                </div>
                                            </div>
                                            <p className="form-input-error" data-cy="item_value_type-error">
                                                {errors?.item_value_type?.message && errors?.item_value_type?.message.toString()}
                                            </p>
                                        </>
                                    )}
                                />
                            </FormFieldWrapper>

                            {activeItemValueType === ITEM_VALUE_TYPE_OPTIONS_ENUM.NUMERIC && (
                                <FormFieldWrapper>
                                    <label className="mt-4" htmlFor="number_type">
                                        Numeric Type <span className="required">*</span>
                                    </label>
                                    <Controller
                                        name="number_type"
                                        control={control}
                                        render={({ field }) => (
                                            <>
                                                <Dropdown
                                                    optionLabel="label"
                                                    optionValue="value"
                                                    value={field.value}
                                                    options={NUMERIC_TYPE_OPTIONS}
                                                    onChange={(e) => field.onChange(e.value)}
                                                    placeholder="Numeric Type"
                                                />
                                                <p className="form-input-error">{errors?.number_type?.message}</p>
                                            </>
                                        )}
                                    />
                                </FormFieldWrapper>
                            )}
                        </>
                    )}
                    <FormFieldWrapper>
                        <label className={classNames(itemType === ITEM_TYPE_ENUM.ITEM && 'mt-4')} htmlFor="name">
                            Name <span className="required">*</span>
                        </label>
                        <Controller
                            name="name"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <InputText
                                        type="text"
                                        {...field}
                                        id="name"
                                        data-cy="name-input"
                                        style={{ width: '100%' }}
                                        className={classNames(
                                            'p-inputtext',
                                            { 'form-input--has-error': !!errors.name },
                                            { 'form-input--fill': field.value.length }
                                        )}
                                    />
                                    {errors?.name?.message && (
                                        <p className="form-input-error" data-cy="name-error">
                                            {errors?.name?.message}
                                        </p>
                                    )}
                                </>
                            )}
                        />
                    </FormFieldWrapper>
                    <FormFieldWrapper>
                        <label className={classNames(itemType === ITEM_TYPE_ENUM.ITEM && 'mt-4')} htmlFor="arabic_name">
                            Arabic Name
                        </label>
                        <Controller
                            name="arabic_name"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <InputText
                                        type="text"
                                        {...field}
                                        id="arabic_name"
                                        data-cy="arabic_name-input"
                                        style={{ width: '100%' }}
                                        className={classNames(
                                            'p-inputtext',
                                            { 'form-input--has-error': !!errors.arabic_name },
                                            { 'form-input--fill': field.value?.length }
                                        )}
                                    />
                                    {errors?.arabic_name?.message && (
                                        <p className="form-input-error" data-cy="arabic_name-error">
                                            {errors?.arabic_name?.message}
                                        </p>
                                    )}
                                </>
                            )}
                        />
                    </FormFieldWrapper>
                    {selectedFinancialDataTypeId === undefined && (
                        <FormFieldWrapper>
                            <label className="mt-4" htmlFor="item_data_type">
                                Financial Data Type
                            </label>
                            <Controller
                                name="item_data_type"
                                control={control}
                                render={({ field }) => (
                                    <>
                                        <Dropdown
                                            optionLabel="label"
                                            optionValue="value"
                                            value={field.value}
                                            options={financialDataTypes}
                                            onChange={(e) => handleChangeItemDataType(e.value, field)}
                                            placeholder="Financial Data Type"
                                        />
                                        {errors?.item_data_type?.message && (
                                            <p className="form-input-error" data-cy="item_data_type-error">
                                                {errors?.item_data_type?.message.toString()}
                                            </p>
                                        )}
                                    </>
                                )}
                            />
                        </FormFieldWrapper>
                    )}
                    {itemType !== ITEM_TYPE_ENUM.TITLE && itemType !== ITEM_TYPE_ENUM.SUBTITLE && (
                        <>
                            <FormFieldWrapper>
                                <label className="mt-4" htmlFor="category">
                                    Select category
                                </label>
                                <Controller
                                    name="category"
                                    control={control}
                                    render={({ field }) => (
                                        <>
                                            <SearchableDropdown
                                                config={{
                                                    reference: DROPDOWNS_FILTER.FINANCIAL_INFORMATION_CATEGORY,
                                                    searchable: true,
                                                    ...(typeIdParam && {
                                                        params: {
                                                            company_financial_information_type_id: typeIdParam,
                                                        },
                                                    }),
                                                    dropdownProps: {
                                                        disabled: !typeIdParam,
                                                    },
                                                }}
                                                defaultValue={field.value}
                                                label="Category"
                                                name="category"
                                                onChange={(e) => field.onChange(e)}
                                            />
                                            {errors?.category?.message && (
                                                <p className="form-input-error" data-cy="category-error">
                                                    {errors?.category?.message.toString()}
                                                </p>
                                            )}
                                        </>
                                    )}
                                />
                            </FormFieldWrapper>
                            <FormFieldWrapper>
                                <label className="mt-4" htmlFor="subcategory">
                                    Select Subcategory
                                </label>
                                <Controller
                                    name="subcategory"
                                    control={control}
                                    render={({ field }) => (
                                        <>
                                            <SearchableDropdown
                                                config={{
                                                    reference: DROPDOWNS_FILTER.FINANCIAL_INFORMATION_SUB_CATEGORY,
                                                    searchable: true,
                                                    ...(typeIdParam && {
                                                        params: {
                                                            company_financial_information_type_id: typeIdParam,
                                                        },
                                                    }),
                                                    dropdownProps: {
                                                        disabled: !typeIdParam,
                                                    },
                                                }}
                                                defaultValue={field.value}
                                                label="Sub Category"
                                                name="subcategory"
                                                onChange={(e) => field.onChange(e)}
                                            />
                                            {errors?.subcategory?.message && (
                                                <p className="form-input-error" data-cy="subcategory-error">
                                                    {errors?.subcategory?.message.toString()}
                                                </p>
                                            )}
                                        </>
                                    )}
                                />
                            </FormFieldWrapper>
                        </>
                    )}

                    <FormFooterActions>
                        <div className="mt-4">
                            <Button
                                disabled={loading}
                                loading={loading}
                                customStyle={{ width: '100%', marginBottom: '1rem', height: '40px', display: 'flex', alignItems: 'center' }}
                                color="primary"
                                size="s"
                                onClick={() => handleSubmit(onFormSubmit)}
                            >
                                Save
                            </Button>
                        </div>
                    </FormFooterActions>
                </form>
            </div>
        </div>
    );
};

export default AddNewItemForm;
