import React, { Dispatch, SetStateAction } from 'react';
import MultiStepForm from 'src/components/Kit/MultiStepForm';
import { MultiStepFormProvider } from 'src/components/Kit/MultiStepForm/context';
import { addCompanyStepsSchema } from './steps-schema';
import { useNavigate, useParams } from 'react-router-dom';
import { ICreateCompanyPayload } from 'src/api/types/company';
import { companyService } from 'src/api/services/company';
import { toast } from 'src/utils/toast';
import { TOAST_STATUS } from 'src/constants/toast-status';
import { StepConfig } from 'src/components/Kit/MultiStepForm/types/step-config';
import { companyStepFormsTitle } from './constants/company-step-forms-title';
import dayjs from 'dayjs';
import { COMPANY_TYPE_ROUTE } from './enums/company-type-route';
import { IParams } from 'src/types/params';

const CompanyForm: React.FC = () => {
    const navigate = useNavigate();
    const { companyType } = useParams<IParams>();

    const onFormSubmit = async (
        formState: any,
        setStepsData: Dispatch<SetStateAction<StepConfig[]>>,
        setLoading: Dispatch<SetStateAction<boolean>>,
        setActiveStepIndex: Dispatch<SetStateAction<number>>
    ) => {
        const tempData = { ...formState } as any;
        delete tempData['location'];
        delete tempData['logo_id'];
        delete tempData['inception_date'];
        delete tempData['stock_exchange_id'];
        tempData['logo_id'] = formState.logo_id.id;
        tempData['country_id'] = Number(formState.country_id);
        tempData['currency_id'] = Number(formState.currency_id);
        if (formState.legal_structure_id) tempData['legal_structure_id'] = Number(formState.legal_structure_id);
        if (formState.stock_exchange_id) tempData['stock_exchange_id'] = Number(formState.stock_exchange_id);
        if (formState.inception_date) tempData['inception_date'] = Number(dayjs(formState.inception_date).format('YYYY'));
        if (formState.chamber_id) tempData['chamber_id'] = Number(formState.chamber_id);
        if (formState.authority_id) tempData['authority_id'] = Number(formState.authority_id);
        if (formState.registration_date) tempData['registration_date'] = dayjs.tz(new Date(formState.registration_date).toUTCString()).unix();
        if (formState.license_date) tempData['license_date'] = dayjs.tz(new Date(formState.license_date).toUTCString()).unix();
        if (formState.location) {
            tempData['latitude'] = formState.location.lat;
            tempData['longitude'] = formState.location.lng;
        }

        try {
            setLoading(true);
            const response = await companyService(companyType as COMPANY_TYPE_ROUTE).createNewCompany(tempData as ICreateCompanyPayload);
            toast.fire({
                icon: response.data.success ? TOAST_STATUS.SUCCESS : TOAST_STATUS.ERROR,
                title: response.data.message,
            });
            navigate(-1);
        } catch (err: any) {
            Object.entries(err.response.data.errors).forEach(([step, errorItems]) => {
                setStepsData((prev) =>
                    prev.map((item) => {
                        if (companyStepFormsTitle[item.id] === step) {
                            setActiveStepIndex(item.id);
                            return { ...item, hasError: true, errors: errorItems as { [key: string]: string[] } };
                        }
                        if (item.id === prev.length - 1) {
                            return { ...item, disabled: true };
                        }
                        return item;
                    })
                );
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <MultiStepFormProvider
                title="Add Company"
                subTitle="Complete steps to add a new company"
                onCancel={() => navigate(-1)}
                onComplete={onFormSubmit}
                steps={addCompanyStepsSchema}
            >
                <MultiStepForm />
            </MultiStepFormProvider>
        </>
    );
};

export default CompanyForm;
