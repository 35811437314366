import { Navigate, Outlet } from "react-router-dom";
import ROUTE_CONSTANTS from "src/Routes/constants/route-constants";
import { useCheckPermissionAccess } from "src/hooks/useCheckPermissionAccess";

interface IProtectedRouteProps {
    permissions: string[];
    children: JSX.Element;
    redirectPath?: string;
}

const ProtectedRoute:React.FC<IProtectedRouteProps> = ({ 
    permissions,
    children,
    redirectPath = ROUTE_CONSTANTS.FORBIDDEN.ABSOLUTE 
}) => {
    const { checkPermissionAccess } = useCheckPermissionAccess();
    
    if(permissions.length === 0) {
        return children ? children : <Outlet/>;
    }

    let allPermissionsAreValid = permissions.every(permission => {
        return checkPermissionAccess(permission);
    });

    if(!allPermissionsAreValid) {
        return <Navigate to={redirectPath} replace/>;
    }

    return children ? children : <Outlet/>;
}

export default ProtectedRoute;