import { Dispatch, SetStateAction } from 'react';
import DashboardTitle from 'src/components/App/DashboardTitle';
import Table from 'src/components/App/Table/index';
import Button from 'src/components/Kit/Button';
import { Helmet } from 'react-helmet-async';
import ROUTE_CONSTANTS from 'src/Routes/constants/route-constants';
import DashboardContentWrapper from 'src/layouts/DashboardContentWrapper';
import { financialInformationService } from 'src/api/services/financial-information-service';
import { IFinancialInformationCategoryPayload, IFinancialInformationCategoryResponse } from 'src/api/types/financial-information';
import { Column } from 'primereact/column';
import EditIcon from 'src/assets/Icons/EditIcon';
import DeleteIcon from 'src/assets/Icons/DeleteIcon';
import { modalActions } from 'src/context/modals';
import { MODAL_TYPES } from 'src/types/modals';
import { toast } from 'src/utils/toast';
import { TOAST_STATUS } from 'src/constants/toast-status';
import { getAxiosError } from 'src/utils/get-axios-error';
import { useCheckPermissionAccess } from 'src/hooks/useCheckPermissionAccess';
import { PERMISSION_TYPES } from 'src/enums/permissions';
import { cellRender } from './cellRender';

const service = async (queryParams?: IFinancialInformationCategoryPayload) => financialInformationService.getAllCategories(queryParams);
const List: React.FC = () => {
    const { checkPermissionAccess } = useCheckPermissionAccess();
    const handleRemove = (id: number, setTableData: Dispatch<SetStateAction<IFinancialInformationCategoryResponse[]>>) => {
        const handleRemoveItem = async () => {
            try {
                const deleteResult = await financialInformationService.deleteCategory(id);
                if (deleteResult.data.success) {
                    setTableData((tableData: IFinancialInformationCategoryResponse[]) =>
                        tableData.filter((item: IFinancialInformationCategoryResponse) => item.id !== id)
                    );
                    toast.fire({
                        icon: TOAST_STATUS.SUCCESS,
                        title: deleteResult.data.message,
                    });
                } else {
                    toast.fire({
                        icon: TOAST_STATUS.ERROR,
                        title: deleteResult.data.message,
                    });
                }
            } catch (err) {
                const error = getAxiosError(err);
                const message = error?.message || 'Server Error';
                toast.fire({
                    icon: TOAST_STATUS.ERROR,
                    title: message,
                });
            }
        };
        modalActions.addModal(MODAL_TYPES.CONFIRMATION_MODAL, {
            header: 'Delete Item',
            text: 'Are you sure to delete this Category?',
            handleAction: handleRemoveItem,
        });
    };

    const suffixCols = (data: IFinancialInformationCategoryResponse[], setTableData: Dispatch<SetStateAction<IFinancialInformationCategoryResponse[]>>) => {
        return (
            <Column
                key="Action"
                body={(rowData: IFinancialInformationCategoryResponse) => (
                    <>
                        {
                            checkPermissionAccess(PERMISSION_TYPES.SETTING_FI_CATEGORY_EDIT) &&
                            <Button
                                link
                                color="secondary"
                                iconOnly
                                size="m"
                                to={ROUTE_CONSTANTS.SETTINGS.FINANCIAL_INFORMATION.CATEGORIES.UPDATE.EDIT_BY_CATEGORY_ID(rowData.id).ABSOLUTE}
                            >
                                <EditIcon />
                            </Button>
                        }
                        {
                            checkPermissionAccess(PERMISSION_TYPES.SETTING_FI_CATEGORY_DELETE) &&
                            <Button
                                color="secondary"
                                iconOnly
                                size="m"
                                disabled={!rowData.deletable}
                                onClick={() => (rowData.deletable ? handleRemove(rowData.id, setTableData) : undefined)}
                            >
                                <DeleteIcon />
                            </Button>
                        }
                    </>
                )}
            />
        );
    };

    return (
        <>
            <Helmet>
                <title>Teaser | Financial Information Category List</title>
            </Helmet>
            <DashboardTitle
                title={'Financial Information Category List'}
                titleSuffix={
                    checkPermissionAccess(PERMISSION_TYPES.SETTING_FI_CATEGORY_CREATE)
                        ? <Button size="s" link color="primary" to={ROUTE_CONSTANTS.SETTINGS.FINANCIAL_INFORMATION.CATEGORIES.ADD_NEW.ROOT.ABSOLUTE}>
                            Add New Category
                        </Button>
                        : <></>
                }
            />
            <DashboardContentWrapper>
                {checkPermissionAccess(PERMISSION_TYPES.SETTING_FI_CATEGORY_LIST_VIEW) && <Table getTableData={service} cellRender={cellRender} suffixCols={suffixCols} />}
            </DashboardContentWrapper>
        </>
    );
};

export default List;
