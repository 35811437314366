import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '.';

interface IProps {
    href?: string;
}

const LogoLink: React.FC<IProps> = ({ href = '/' }) => {
    return (
        <Link to={href}>
            <Logo />
        </Link>
    );
};

export default LogoLink;
