import { classNames } from 'primereact/utils';
import { useMultiStepFormContext } from './context';
import style from './MultiStepForm.module.scss';
import Button from '../Button';
import { useEffect } from 'react';

const MultiStepForm: React.FC = () => {
    const {
        title,
        subTitle,
        activeStepIndex,
        onCancel,
        onComplete,
        setActiveStepIndex,
        stepsData,
        setStepsData,
        loading
    } = useMultiStepFormContext();
    const isLastStep = activeStepIndex === stepsData.length - 1;
    const isFirstStep = activeStepIndex === 0;

    const handleNext = () => {
        setActiveStepIndex((prevIndex) => prevIndex + 1);
    };

    const handlePrevious = () => {
        setActiveStepIndex((prevIndex) => prevIndex - 1);
    };

    const handleClickStep = (id: number) => {
        setActiveStepIndex(id);
    };

    useEffect(() => {
        setStepsData((prev) => {
            return prev.map((item) => (item.id === activeStepIndex ? { ...item, opened: true } : item));
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeStepIndex]);
    
    return (
        <div className={style.multiStepForm}>
            <div className={style.multiStepFormHeader}>
                <div className={style.multiStepFormTitle}>
                    <h3>{title}</h3>
                    <p>{subTitle}</p>
                </div>
                <div className={style.multiStepFormButtons}>
                    {isFirstStep ? <Button onClick={onCancel}>Cancel</Button> : <Button onClick={handlePrevious}>Back</Button>}
                    {isLastStep ? (
                        <Button onClick={onComplete} disabled={loading} loading={loading} color="primary">
                            Confirm & Save
                        </Button>
                    ) : (
                        <Button 
                            onClick={handleNext} 
                            color="primary" 
                            disabled={stepsData[activeStepIndex + 1].disabled}
                        >
                            Next
                        </Button>
                    )}
                </div>
            </div>
            <div className={style.multiStepFormController}>
                <ul>
                    {stepsData.map(({ title, disabled, id, hasError }) => (
                        <li
                            key={id}
                            className={classNames(activeStepIndex === id ? style.active : '', disabled ? style.disabled : '', hasError ? style.hasError : '')}
                            onClick={() => (!disabled ? handleClickStep(id) : undefined)}
                        >
                            <small>{id + 1}</small>
                            <span>{title}</span>
                        </li>
                    ))}
                </ul>
            </div>
            
            {Object.keys(stepsData[activeStepIndex].errors).length > 0 && (
                <div className={style.multiStepFormErrors}>
                    <h4>Invalid data</h4>
                    <ul>
                        {Object.entries(stepsData[activeStepIndex].errors!).map(([fieldName, errors]) => {
                            if (errors.length > 1) {
                                return errors.map((errorItem, index) => (
                                    <li key={index}>
                                        {errorItem}
                                    </li>
                                ));
                            } else {
                                return (
                                    <li>
                                        {errors}
                                    </li>
                                );
                            }
                        })}
                    </ul>
                </div>
            )}

            <div className={style.multiStepFormContent} key={activeStepIndex}>
                {stepsData[activeStepIndex].content}
            </div>
        </div>
    );
};

export default MultiStepForm;
