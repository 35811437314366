import { Tooltip, TooltipProps } from 'primereact/tooltip';
import style from './PopoverMessage.module.scss';
import { ReactNode } from 'react';
import { classNames } from 'primereact/utils';
import { POPOVER_STATUS } from './enums/popover-status';
import PopoverIcon from 'src/assets/Icons/PopoverIcon';

interface Props {
    target: string;
    title: string;
    message?: string;
    status?: POPOVER_STATUS;
    description?: ReactNode | string;
    tooltipProps?: TooltipProps;
}
const PopoverMessage: React.FC<Props> = ({ title, message, status = POPOVER_STATUS.INFO, target, description, tooltipProps }) => {
    return (
        <Tooltip target={target} autoHide={false} position="top" showDelay={150} className={style.customTooltip} {...tooltipProps}>
            <div className={classNames(style.popover, style[status])}>
                <PopoverIcon />
                <h4>{title}</h4>
                {message ? <p>{message}</p> : ''}
                {description || ''}
            </div>
        </Tooltip>
    );
};

export default PopoverMessage;
