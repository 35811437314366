import { timestampToDateFormat } from 'src/utils/timestamp-to-date-format';
import { INewsResponse } from 'src/api/types/news';
import { parentCompanyFinancialLanguages } from '../CompanyParentCompanyFinancial/AddUpdate/constants/parent-company-financial-languages-dropdown';
import { Tooltip } from 'primereact/tooltip';
import { truncateString } from 'src/utils/string-truncator';

export const newsCellRender = {
    title: (data: INewsResponse) => (
        <>
            <span id={`news-source-title-${data.id}`}>{truncateString(data.title, 20)}</span>
            <Tooltip
                target={`#news-source-title-${data.id}`}
                hideDelay={100}
                content={data.title}
                position="top"
                disabled={data.title.length <= 20}
                showDelay={250}
            />
        </>
    ),
    updated_at: (data: INewsResponse) => <span>{timestampToDateFormat(data.updated_at)}</span>,
    created_at: (data: INewsResponse) => <span>{timestampToDateFormat(data.created_at)}</span>,
    source: (data: INewsResponse) =>
        data.url ? (
            <a href={data.url} target="_blank" rel="noreferrer">
                {data.source}
            </a>
        ) : (
            <span>{data.source}</span>
        ),
    language: (data: INewsResponse) => <span>{parentCompanyFinancialLanguages.find(({ value }) => value === String(data.language))?.label || '-'}</span>,
};
