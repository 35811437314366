import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import FormGenerator from 'src/components/Kit/FormGenerator/index';
import { useForm } from 'react-hook-form';
import { generateDefaultValueFromFormSchema } from 'src/utils/generate-default-value-from-form-schema';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from 'src/components/Kit/Button';
import { CreateNewsSourceSchemaFields, createNewsSourceSchema } from './schema';
import { newsSourceValidationSchema } from './validation-schema';
import { useAsync } from 'src/hooks/useAsync';
import { useFormFields } from 'src/hooks/useFormFields';
import { IGetListPayload, ISelectBox } from 'src/api/types/base-types';
import { toast } from 'src/utils/toast';
import { TOAST_STATUS } from 'src/constants/toast-status';
import { getAxiosError } from 'src/utils/get-axios-error';
import { IDataTableResponse } from 'src/api/types/table';
import { ICreateNewsSourcePayload } from 'src/api/types/news-source';
import { newsSourceService } from 'src/api/services/news-source';
import { regionService } from 'src/api/services/region-service';
import { INewsSourceFormData } from './types';
import { useEditingMode } from './useUpdate';
import InitLoading from 'src/components/App/Loading/InitLoading';

const NewsSourceAddUpdate: React.FC = () => {
    const navigate = useNavigate();
    const { formFields, setFormFields, updateFormData, updateFormProps, updateFormLoading } = useFormFields(createNewsSourceSchema);
    const { id, completedData, isEditingRule, isLoading: completedDataIsLoading } = useEditingMode();
    const newsSourceResponseMeta = useRef<IDataTableResponse<unknown>['meta']['pagination']>();
    const {
        control,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm<INewsSourceFormData>({
        defaultValues: generateDefaultValueFromFormSchema(formFields),
        mode: 'all',
        shouldFocusError: false,
        resolver: yupResolver(newsSourceValidationSchema),
    });

    const { run, isLoading } = useAsync<unknown, ICreateNewsSourcePayload>((payload) => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await (isEditingRule ? newsSourceService.update(Number(id), payload) : newsSourceService.create(payload));
                if (response.data.success) {
                    toast.fire({
                        icon: TOAST_STATUS.SUCCESS,
                        title: response.data.message,
                    });
                    navigate(-1);
                    return resolve(response.data.data);
                } else {
                    return reject(response.data.errors);
                }
            } catch (e) {
                toast.fire({
                    icon: TOAST_STATUS.ERROR,
                    title: getAxiosError(e).message,
                });
                return Promise.reject(getAxiosError(e));
            }
        });
    });

    const handleUpdateDropdownOptions = async (fieldName: CreateNewsSourceSchemaFields, getNewOptions: any, lazy?: boolean) => {
        if (lazy && newsSourceResponseMeta.current) {
            if (newsSourceResponseMeta.current.last_page === newsSourceResponseMeta.current.current_page) return;
        }

        setFormFields((prev) => ({
            ...prev,
            [fieldName]: {
                ...prev[fieldName],
                data: [...(lazy ? prev[fieldName].data || [] : [])],
                props: {
                    ...prev[fieldName].props,
                    loading: true,
                },
            },
        }));

        const response = await getNewOptions();
        newsSourceResponseMeta.current = response.data.meta;

        setFormFields((prev) => ({
            ...prev,
            [fieldName]: {
                ...prev[fieldName],
                data: [
                    ...(lazy ? prev[fieldName].data || [] : []),
                    ...response.data.data.map(({ label, value }: any) => ({
                        label,
                        value: String(value),
                    })),
                ],
                props: {
                    ...prev[fieldName].props,
                    loading: false,
                    disabled: false,
                    onFilter,
                    onLazy,
                },
            },
        }));
    };

    const onFilter = (fieldName: string, value: string) => {
        switch (fieldName) {
            case 'country_id': {
                handleUpdateDropdownOptions(fieldName, () =>
                    regionService.selectBox({
                        ...(value ? { text: value } : {}),
                    })
                );
                break;
            }
        }
    };

    const onLazy = (fieldName: string) => {
        switch (fieldName) {
            case 'country_id': {
                handleUpdateDropdownOptions(
                    fieldName,
                    () =>
                        regionService.selectBox({
                            page: (newsSourceResponseMeta.current?.current_page || 1) + 1,
                        }),
                    true
                );
                break;
            }
        }
    };

    const getCountryList = useAsync<ISelectBox[], IGetListPayload>((payload) => {
        return new Promise(async (resolve, reject) => {
            try {
                updateFormLoading('country_id', true);
                const response = await regionService.selectBox(payload);
                if (response.data.success) {
                    updateFormProps('country_id', {
                        onFilter,
                        onLazy,
                        disabled: false,
                    });
                    updateFormData(
                        'country_id',
                        response.data.data.map(({ label, value }) => ({ label, value: String(value) }))
                    );
                    return resolve(response.data.data);
                } else {
                    return reject(response.data.errors);
                }
            } catch (e) {
                toast.fire({
                    icon: TOAST_STATUS.ERROR,
                    title: getAxiosError(e).message,
                });
                return Promise.reject(getAxiosError(e));
            } finally {
                updateFormLoading('country_id', false);
            }
        });
    });

    const onSubmit = async (_val: INewsSourceFormData) => {
        run({
            name: _val.source_name,
            arabic_name: _val.arabic_source_name,
            country_id: Number(_val.country_id),
            website: _val.website,
            media_id: (_val.logo as { id: number; uuid: string })!.id,
        });
    };

    useEffect(() => {
        getCountryList.run({});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (completedData) {
            const isSelectedCountries = Boolean(completedData.country_id);
            isSelectedCountries && getCountryList.run({ selected_items: [String(completedData?.country_id)] });
            reset({
                logo: completedData.media,
                source_name: completedData.name,
                arabic_source_name: completedData.arabic_name,
                country_id: Number(completedData.country_id),
                website: completedData.website,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [completedDataIsLoading]);

    if (isEditingRule && completedDataIsLoading) return <InitLoading />;

    return (
        <div className="grid justify-content-center my-8">
            <div className="xs:col:12 md:col-8 xl:col-5">
                <span className="block text-2xl font-semibold">{isEditingRule ? 'Update' : 'Add New'} News Source</span>
                <div className="mt-5">
                    <FormGenerator
                        onSubmit={handleSubmit(onSubmit)}
                        control={control}
                        loading={isLoading}
                        errors={errors}
                        schema={formFields}
                        noBackgroundLayout
                        buttons={
                            <>
                                <Button disabled={isLoading} loading={isLoading} color="primary">
                                    {isEditingRule ? 'Update' : 'Create'}
                                </Button>
                                <Button type="button" onClick={() => navigate(-1)}>
                                    Cancel
                                </Button>
                            </>
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default NewsSourceAddUpdate;
