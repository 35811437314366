export enum FILE_SORT {
    NAME_DESC = 1,
    NAME_ASC,
    TIME_DESC,
    TIME_ASC,
}

export enum FILE_UPLOAD_TYPE {
    IMAGE = 1,
    DOCUMENT,
}

export const FILE_SORTING_OPTIONS = [
    { label: 'Newest first', value: FILE_SORT.TIME_DESC },
    { label: 'Oldest first', value: FILE_SORT.TIME_ASC },
    { label: 'Name (A to Z)', value: FILE_SORT.NAME_DESC },
    { label: 'Name (Z to A)', value: FILE_SORT.NAME_ASC },
];
