import { FORM_INPUTS } from 'src/types/form';
import { IFormGeneratorGeneralSchemaType } from 'src/types/form-generator-schema-type';

export type createSchemaFields = 'name' | 'arabic_name' 
export type createSchemaType = Record<createSchemaFields, IFormGeneratorGeneralSchemaType>;
export const createSchema: createSchemaType = {
    name: {
        placeholder: 'Name',
        type: FORM_INPUTS.TEXT,
        props: {
            required: true
        }
    },
    arabic_name: {
        placeholder: 'Arabic Name',
        type: FORM_INPUTS.TEXT,
        props: {
            required: false
        }
    },
};
