import { ModalProps } from 'src/types/modals';
import style from './FinancialInformationCurrencyYearEnd.module.scss';
import SelectCurrency from 'src/pages/Company/Show/CompanyFinancialInformation/AddUpdate/components/SelectCurrency';

export interface Props {}

const FinancialInformationCurrencyYearEnd: React.FC<ModalProps<Props>> = ({ data: {}, closeModal }) => {
    return (
        <div className={style.financialInformationCurrencyYearEndWrapper}>
            <SelectCurrency isModal closeModal={closeModal} />
        </div>
    );
};

export default FinancialInformationCurrencyYearEnd;
