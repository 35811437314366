import React from 'react';
import styles from './Sidebar.module.scss';
import SidebarMenu from '../SidebarMenu';
import { SidebarLogo } from 'src/assets/Images/SidebarLogo';
import { classNames } from 'primereact/utils';
import { MENU_VIEW } from 'src/enums/menu';
import ArrowLeftDoubleIcon from 'src/assets/Icons/ArrowLeftDoubleIcon';
import ArrowRightDoubleIcon from 'src/assets/Icons/ArrowRightDoubleIcon';
import { menuActions, useMenu } from 'src/context/menu';

const Sidebar: React.FC = () => {
    const { menuView } = useMenu();
    const ToggleIcon = menuView === MENU_VIEW.MAXIMIZE ? ArrowLeftDoubleIcon : ArrowRightDoubleIcon;
    return (
        <aside className={classNames(styles.dashboardSidebar, menuView === MENU_VIEW.MINIMIZE ? styles.minimize : '')}>
            <div className={styles.dashboardSidebarLogo}>
                <SidebarLogo />
                {menuView === MENU_VIEW.MAXIMIZE && <span>Teaser Platform</span>}
            </div>
            <div className={styles.dashboardSidebarMenu}>
                <SidebarMenu />
            </div>
            <div className={styles.dashboardSidebarToggle}>
                <ToggleIcon onClick={menuActions.toggleMenuView} />
            </div>
        </aside>
    );
};

export default Sidebar;
