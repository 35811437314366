import React, { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import { Column } from 'primereact/column';
import { useNavigate, useParams } from 'react-router-dom';
import ROUTE_CONSTANTS from 'src/Routes/constants/route-constants';
import DeleteIcon from 'src/assets/Icons/DeleteIcon';
import EditIcon from 'src/assets/Icons/EditIcon';
import Table from 'src/components/App/Table';
import Button from 'src/components/Kit/Button';
import { TOAST_STATUS } from 'src/constants/toast-status';
import { modalActions } from 'src/context/modals';
import DashboardContentWrapper from 'src/layouts/DashboardContentWrapper';
import { MODAL_TYPES } from 'src/types/modals';
import { getAxiosError } from 'src/utils/get-axios-error';
import { toast } from 'src/utils/toast';
import { useCheckPermissionAccess } from 'src/hooks/useCheckPermissionAccess';
import { PERMISSION_TYPES } from 'src/enums/permissions';
import { IDataTablePayload } from 'src/api/types/table';
import { useNonProfilePolicy } from '../useNonProfilePolicy';
import { COMPANY_TYPE_ROUTE } from '../../Add/enums/company-type-route';
import { IParams } from 'src/types/params';
import { newsService } from 'src/api/services/news';
import { INewsResponse } from 'src/api/types/news';
import { newsCellRender } from './cellRender';

const CompanyNewsList: React.FC = () => {
    useNonProfilePolicy();
    const { checkPermissionAccess } = useCheckPermissionAccess();
    const navigate = useNavigate();
    const { companyId, companyType } = useParams<IParams>();
    const service = useCallback(
        async (queryParams?: IDataTablePayload) => newsService.getAll('company', Number(companyId), queryParams),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const handleRemove = (id: number, setTableData: Dispatch<SetStateAction<INewsResponse[]>>) => {
        const handleRemoveItem = async () => {
            try {
                const deleteResult = await newsService.delete(Number(id));
                if (deleteResult.data.success) {
                    setTableData((tData) => tData.filter((data: INewsResponse) => data.id !== id));
                }
                toast.fire({
                    icon: deleteResult.data.success ? TOAST_STATUS.SUCCESS : TOAST_STATUS.ERROR,
                    title: deleteResult.data.message,
                });
            } catch (err) {
                const error = getAxiosError(err);
                const message = error?.message || 'Server Error';
                toast.fire({
                    icon: TOAST_STATUS.ERROR,
                    title: message,
                });
            }
        };
        modalActions.addModal(MODAL_TYPES.CONFIRMATION_MODAL, {
            header: 'Delete Item',
            text: 'Are you sure to delete this news?',
            handleAction: handleRemoveItem,
        });
    };

    useEffect(() => {
        if (!checkPermissionAccess(PERMISSION_TYPES.NEWS_LIST)) {
            navigate(ROUTE_CONSTANTS.ROOT.ABSOLUTE);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const suffixCols = (data: INewsResponse[], setTableData: Dispatch<SetStateAction<INewsResponse[]>>) => {
        return (
            <Column
                key="Action"
                body={(rowData: INewsResponse) => (
                    <>
                        {checkPermissionAccess(PERMISSION_TYPES.NEWS_EDIT) && (
                            <Button
                                link
                                color="secondary"
                                iconOnly
                                size="m"
                                to={
                                    ROUTE_CONSTANTS.COMPANIES.TYPE.SHOW.NEWS.EDIT.EDIT_BY_ID(companyType as COMPANY_TYPE_ROUTE, Number(companyId), rowData.id)
                                        .ABSOLUTE
                                }
                            >
                                <EditIcon />
                            </Button>
                        )}
                        {checkPermissionAccess(PERMISSION_TYPES.NEWS_DELETE) && (
                            <Button color="secondary" iconOnly size="m" onClick={() => handleRemove(rowData.id, setTableData)}>
                                <DeleteIcon />
                            </Button>
                        )}
                    </>
                )}
            />
        );
    };

    return (
        <DashboardContentWrapper
            title="Company News"
            titleSuffix={
                checkPermissionAccess(PERMISSION_TYPES.NEWS_CREATE) ? (
                    <Button
                        size="s"
                        link
                        color="primary"
                        to={ROUTE_CONSTANTS.COMPANIES.TYPE.SHOW.NEWS.ADD_NEW.ADD_NEW_BY_COMPANY_ID(companyType as COMPANY_TYPE_ROUTE, Number(companyId)).ABSOLUTE}
                    >
                        Add New News
                    </Button>
                ) : undefined
            }
        >
            <Table
                getTableData={service}
                cellRender={newsCellRender}
                suffixCols={checkPermissionAccess(PERMISSION_TYPES.NEWS_EDIT) || checkPermissionAccess(PERMISSION_TYPES.NEWS_DELETE) ? suffixCols : undefined}
            />
        </DashboardContentWrapper>
    );
};

export default CompanyNewsList;
