import React, { useCallback, useEffect } from 'react';
import { authService } from 'src/api/services/auth';
import { useGeneralLoading, useSetGeneralLoading, useSetUser, useSetUserPermissions, useUser } from 'src/context/global-context';
import { IFunctionalComponentProps } from 'src/types/functional-component';
import { getAuthorization } from 'src/utils/get-authorization';
import InitLoading from '../../components/App/Loading/InitLoading';
import style from './GeneralLayoutWrapper.module.scss';
import { getAxiosError } from 'src/utils/get-axios-error';
import { toast } from 'src/utils/toast';
import { TOAST_STATUS } from 'src/constants/toast-status';
import { FINANCIAL_INFORMATION_DRAFT, FINANCIAL_INFORMATION_DRAFT_EXPIRE_AFTER } from 'src/constants/financial-information-draft';
import { DraftDataType } from 'src/pages/Company/Show/CompanyFinancialInformation/types';
// import checkSyncingPermissionEnumsWithBackendHelper from 'src/utils/check-syncing-permission-enums-with-backend-helper';

interface IGeneralLayoutWrapperProps extends IFunctionalComponentProps {}

const GeneralLayoutWrapper: React.FC<IGeneralLayoutWrapperProps> = ({ children }) => {
    const user = useUser();
    const setUser = useSetUser();
    const loading = useGeneralLoading();
    const setLoading = useSetGeneralLoading();
    const setUserPermissions = useSetUserPermissions();

    const removeExpiredFinancialLocalStorageData = () => {
        const prevStringDraftData = localStorage.getItem(FINANCIAL_INFORMATION_DRAFT) || '[]';
        const prevDraftData = JSON.parse(prevStringDraftData) as DraftDataType[];

        const expireDate = new Date();
        expireDate.setDate(expireDate.getDate() - FINANCIAL_INFORMATION_DRAFT_EXPIRE_AFTER);

        const newDraftData = prevDraftData.filter((item) => item.createdDate < expireDate.getDate());

        const newStringDraftData = JSON.stringify(newDraftData);
        localStorage.setItem(FINANCIAL_INFORMATION_DRAFT, newStringDraftData);
    };

    const getProfile = useCallback(async () => {
        try {
            setLoading(true);
            const result = await authService.getProfile();
            setUser(result.data.data.user);
            const permissions = result.data.data.permissions.flatMap(({ actions, parent, title }) => {
                const parentString = parent ? `${parent}|` : '';
                return [...actions.map((permissionAction) => parentString + title + `|${permissionAction}`)];
            });

            // checkSyncingPermissionEnumsWithBackendHelper(permissions);
            setUserPermissions(permissions);
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: message,
            });
        } finally {
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        removeExpiredFinancialLocalStorageData();
    }, []);

    useEffect(() => {
        if (!user) {
            const jwt = getAuthorization();
            if (jwt) {
                getProfile();
            }
        }
    }, [user, getProfile]);
    return <div className={style['general-layout-wrapper']}>{loading ? <InitLoading height="100vh" /> : children}</div>;
};

export default GeneralLayoutWrapper;
