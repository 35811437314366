import React, { useEffect, useRef, useState } from 'react';
import style from './CustomSearchDropdown.module.scss';
import { InputText } from 'primereact/inputtext';
import { MultiSelectChangeParams } from 'primereact/multiselect';
import Button from 'src/components/Kit/Button';
import { useDebounce } from 'src/hooks/useDebounce';
import { ProgressSpinner } from 'primereact/progressspinner';
import { IItemName } from '../../../../../types';
import { getAxiosError } from 'src/utils/get-axios-error';
import { toast } from 'src/utils/toast';
import { TOAST_STATUS } from 'src/constants/toast-status';
import { useFinancialSelectedType } from '../../../../../../../../../context/companies';
import { Dropdown } from 'primereact/dropdown';
import "./CustomSearchDropdown.module.scss"

interface IProps {
    value?: IItemName[];
    options?: any;
    handleSelectSearchedItem: (e: any) => void;
    addNewOption?: boolean;
    searchEndPoint: any;
    apiItemType?: number | string; // TODO: this function can enhance by removing any API call concerns inside of this and use it general for searching everywhere by just passing search endpoint
    disabled?: boolean;
    fromAddNew?: boolean | undefined;
    placeholder?: string;
    addNewOptionOnClick?: () => void;
    targetQuery?: string;
}
const CustomSearchDropdown: React.FC<IProps> = ({
        handleSelectSearchedItem,
        addNewOptionOnClick,
        value,
        addNewOption,
        options,
        searchEndPoint,
        apiItemType,
        targetQuery,
        disabled,
        fromAddNew,
        placeholder,
    }) => {
    // TODO: need refactor everywhere used
    const selectedFinancialDataTypeId = useFinancialSelectedType();

    const multiSelectRef = useRef<any>(null);
    const [itemQuery, setItemQuery] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [localOption, setLocalOption] = useState<any>(options || value);
    const [localApiItemType, setApiLocalItemType] = useState<any>(apiItemType);

    const handleSearchItem = (q: string) => {
        setItemQuery(q);
    };

    const fetchItem = useDebounce(async () => {
        if (!fromAddNew && localApiItemType === undefined) return;
        if (itemQuery === '' && value) return;

        const data: any = {
            text: itemQuery, // local state of searched query
        };

        if (targetQuery) {
            data[targetQuery] = localApiItemType;
        } else {
            if (localApiItemType !== undefined) data['type'] = localApiItemType;
            if (selectedFinancialDataTypeId) data['type_id'] = selectedFinancialDataTypeId;
        }

        setLoading(true);
        try {
            let res = await searchEndPoint(data);
            setLocalOption(
                res.data.data.map((i: any) => {
                    let tempItem: any = {
                        id: undefined,
                        name: undefined,
                    };
                    if (i.name) tempItem = { ...i };
                    else if (i.title) tempItem = { id: i.id, name: i.title, value_type: i.value_type, must_show_currency: i.must_show_currency };
                    else if (i.label) tempItem = { id: i.value, name: i.label, value_type: i.value_type, must_show_currency: i.must_show_currency };
                    return tempItem;
                })
            );
            setLoading(false);
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: message,
            });
            setLoading(false);
        }
    }, 500);

    const customOnChange = (e: MultiSelectChangeParams) => {
        handleSelectSearchedItem([e.value]);
    };

    const handleClick = () => {
        if (addNewOptionOnClick) addNewOptionOnClick();
    };


    const onDeselect = () => {
        handleSelectSearchedItem([])
        fetchItem()
    }

    useEffect(() => {
        fetchItem();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemQuery, localApiItemType]);

    useEffect(() => {
        if (localApiItemType !== apiItemType) {
            setLocalOption(undefined);
            setItemQuery('');

            setApiLocalItemType(apiItemType);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [apiItemType]);

    const panelHeaderTemplate = (
        <div className={style.overlayPanelContainer}>
            <div className={style.header}>
                <div className={style.searchBar}>
                    <div className={style.iconSection}>
                        {loading ? <ProgressSpinner style={{ width: '15px', height: '15px' }} /> : <i className="pi pi-search" />}
                    </div>
                    <InputText value={itemQuery} className="p-inputtext" onChange={(e) => handleSearchItem(e.target.value)} placeholder="Search" />
                </div>
                {addNewOption && (
                    <Button color="primary" size="s" onClick={handleClick}>
                        Add New
                    </Button>
                )}
            </div>
        </div>
    );


    return (
        <>
            <Dropdown
                ref={multiSelectRef}
                value={value ? value[0] : null}
                options={localOption}
                optionLabel="name"
                filter
                placeholder={placeholder || ''}
                onChange={customOnChange}
                disabled={disabled}
                filterTemplate={panelHeaderTemplate}

                itemTemplate={(option) => {
                    return (
                        <div className={style.secondColTemplate} onClick={() => {
                            if(value && option.id === value[0].id) onDeselect()
                        }}>
                            <span className={style.templateName}>
                                {option.name}
                            </span>
                            <div className={style.templateBody}>
                                <div>
                                    Category:
                                    <span>{option.category_name}</span>
                                </div>
                                <div>
                                    Subcategory:
                                    <span>{option.sub_category_name}</span>
                                </div>
                            </div>
                        </div>
                    )
                }}
            />
        </>
    );
};

export default CustomSearchDropdown;
