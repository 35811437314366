import { API_ROUTES } from 'src/constants/api-routes';
import { AxiosInstance } from '..';
import { IAxiosResponse } from '../types/axios-response';
import { ICreateNewsPayload, INewsResponse } from 'src/api/types/news';
import { IDataTablePayload, IDataTableResponse } from '../types/table';

export const newsService = {
    getAll(entityType: string, entityId: number, data?: IDataTablePayload) {
        return AxiosInstance.get<IDataTableResponse<INewsResponse>>(API_ROUTES.NEWS.GET_ALL_BY_ENTITY(entityType, entityId), { params: data });
    },
    delete(id: number) {
        return AxiosInstance.delete<IAxiosResponse>(API_ROUTES.NEWS.DELETE(id));
    },
    create(data: ICreateNewsPayload) {
        return AxiosInstance.post<IAxiosResponse>(API_ROUTES.NEWS.ADD_NEW, data);
    },
    update(id: number, data: ICreateNewsPayload) {
        return AxiosInstance.put<IAxiosResponse>(API_ROUTES.NEWS.UPDATE(id), data);
    },
    getOne(id: number) {
        return AxiosInstance.get<IAxiosResponse<INewsResponse>>(API_ROUTES.NEWS.GET_ONE(id));
    },
};
