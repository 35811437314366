import styles from './Accordion.module.scss';
import { IFunctionalComponentProps } from 'src/types/functional-component';
import { ReactNode, useEffect, useRef, useState } from 'react';

interface IProps extends IFunctionalComponentProps {
    title: string;
    titleStyle?: object;
    children: ReactNode;
    headerStyle?: object;
    style?: object;
    suffix?: ReactNode;
    prefix?: ReactNode;
    initialExpanded?: boolean;
}

const Accordion: React.FC<IProps> = ({ title, titleStyle, children, initialExpanded = false, headerStyle, style, suffix, prefix }) => {
    const childElementRef = useRef<HTMLDivElement>(null);
    const [expand, setExpand] = useState<boolean>(initialExpanded);
    const [height, setHeight] = useState<number>();

    const SuffixComponent = suffix ? suffix : <></>;
    const PrefixComponent = prefix ? prefix : <></>;

    useEffect(() => {
        if (childElementRef.current) {
            if (height !== childElementRef.current?.clientHeight) setHeight(childElementRef.current?.clientHeight);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialExpanded]);

    return (
        <div className={styles.wrapper} style={{ ...style }}>
            <div className={styles.header} style={{ ...headerStyle, backgroundColor: expand ? '#EDF6FF' : '' }} onClick={() => setExpand((prev) => !prev)}>
                <span style={titleStyle || {}}>{title}</span>
                <div className={styles.iconsWrapper}>
                    {prefix && <div className={styles.icon}>{PrefixComponent}</div>}
                    <div className={styles.icon}>
                        <i className={expand ? 'pi pi-chevron-up' : 'pi pi-chevron-down'} />
                    </div>
                    {suffix && <div className={styles.icon}>{SuffixComponent}</div>}
                </div>
            </div>
            <div className={styles.contentBody} style={{ height: expand ? height : 0 }}>
                <div ref={childElementRef} className={styles.contentWrapper}>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Accordion;
