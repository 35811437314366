export const transactionStatus = [
    {
        value: '1',
        label: 'Current',
    },
    {
        value: '2',
        label: 'Former',
    },
];
