import { useState } from 'react';
import { AutoComplete, AutoCompleteProps } from 'primereact/autocomplete';
import { ControllerRenderProps, FieldError, FieldValues } from 'react-hook-form';
import { classNames } from 'primereact/utils';
import { RedError } from 'src/assets/Icons/RedError';

interface IFilteredItems {
    label: string;
    value: string;
}
interface IProps {
    field: ControllerRenderProps<FieldValues, string>;
    data: IFilteredItems[];
    label: string;
    placeholder: string;
    className: string;
    errorMessage: FieldError;
    props?: AutoCompleteProps;
}

const FormTags = ({ field, label, placeholder, data, className, errorMessage, props = {} }: IProps) => {
    const [filteredItems, setFilteredItems] = useState<IFilteredItems[]>();

    const completeMethod = (event: { query: string }) => {
        let _filteredItems;
        if (!event.query.trim().length) {
            _filteredItems = [...data];
        } else {
            _filteredItems = data.filter((item: IFilteredItems) => {
                return item.label.toLowerCase().startsWith(event.query.toLowerCase());
            });
        }
        setFilteredItems(_filteredItems);
    };

    return (
        <div className={classNames('col-12', className ? className : '')}>
            <div className="form-field">
                <label className={field.name}>
                    {label || placeholder} {props.required ? <span className="required">*</span> : ''}
                </label>
                <AutoComplete
                    className="form-input"
                    id={field.name}
                    {...props}
                    {...field}
                    placeholder={placeholder}
                    value={field.value ? field.value.map((item: { title: string; id: number }) => ({ label: item.title, value: item.id })) : []}
                    suggestions={filteredItems}
                    completeMethod={completeMethod}
                    field="label"
                    multiple
                    onChange={(e) =>
                        field.onChange({
                            target: {
                                value: e.value.map((item: { label: string; value: number }) => ({ title: item.label, id: item.value })),
                                name: field.name,
                                id: field.name,
                            },
                        })
                    }
                />
                {errorMessage ? (
                    <div className={'form-input-error'}>
                        <RedError />
                        <span>{errorMessage.message}</span>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default FormTags;
