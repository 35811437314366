import { classNames } from 'primereact/utils';
import { FC } from 'react';
import { IFinancialInformationTypes } from 'src/api/types/company';
import style from './Item.module.scss';
import { toast } from 'src/utils/toast';
import { TOAST_STATUS } from 'src/constants/toast-status';
import { ITemplateState } from '../../../../types';
import { financialActions, useFinancial, useFinancialTemplateId } from '../../../../../../../../context/companies';

interface IProps {
    template: ITemplateState;
}
const Item: FC<IProps> = ({ template }) => {
    const { canChangeBasics } = useFinancial((global) => ({
        canChangeBasics: global.canChangeBasic,
    }));
    const selectedTemplateId = useFinancialTemplateId();

    const handleChange = (id: number) => {
        // It happens when admin has financial data and can not change template any more.
        if (!canChangeBasics) {
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: 'You have financial data and can not change template!',
            });
            return;
        }
        financialActions.setFinancialTemplateId(id);
    };
    return (
        <div
            data-cy={`template-${template.id}`}
            className={classNames(style.wrapper, selectedTemplateId === template.id && style.activeWrapper, !canChangeBasics && style.disabled)}
            onClick={() => handleChange(template.id)}
        >
            <div className={style.cardBody}>
                <span className={style.title}>{template.name}</span>

                <div className={style.description}>
                    <span>{template.description}</span>
                    {template.types.length > 0 && (
                        <ol className={style.descItems}>
                            {template.types.map((type: IFinancialInformationTypes) => (
                                <li key={type.id}>{type.name}</li>
                            ))}
                        </ol>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Item;
