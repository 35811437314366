interface IProps {
    placeholder: string;
    props?: {};
}
const FieldSet = ({ placeholder, props = {} }: IProps) => (
    <div className="col-12">
        <div className="form-field-set">
            <h4>{placeholder}</h4>
        </div>
    </div>
);

export default FieldSet;
