import BasicInformationCompanyForm from './BasicInformation';
import ContactInformationCompanyForm from './ContactInformation';
import OtherInformationCompanyForm from './OtherInformation';
import { COMPANY_STEP_FORMS } from '../../Add/enums/company-step-forms';

export const editCompanyTabsSchema = [
    {
        id: 0,
        title: COMPANY_STEP_FORMS.BASIC_INFORMATION,
        content: <BasicInformationCompanyForm />,
    },
    {
        id: 1,
        title: COMPANY_STEP_FORMS.OTHER_INFORMATION,
        content: <OtherInformationCompanyForm />,
    },
    {
        id: 2,
        title: COMPANY_STEP_FORMS.CONTACT_INFORMATION,
        content: <ContactInformationCompanyForm />,
    },
];
