import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { generateDefaultValueFromFormSchema } from 'src/utils/generate-default-value-from-form-schema';
import { yupResolver } from '@hookform/resolvers/yup';
import FormGenerator from 'src/components/Kit/FormGenerator/index';
import { toast } from 'src/utils/toast';
import { TOAST_STATUS } from 'src/constants/toast-status';
import { getAxiosError } from 'src/utils/get-axios-error';
import { IFormGeneratorGeneralSchemaType } from 'src/types/form-generator-schema-type';
import { stockExchangeService } from 'src/api/services/stock-exchanges-service';
import { sectorService } from 'src/api/services/sector-service';
import { COMPANY_TYPE } from '../../../Add/enums/company-type';
import { useEditCompanyContext } from '../../context';
import { OtherInformationSchemaFields, otherInformationSchema } from '../../../types/other-information-form-fields-schema';
import { otherInformationValidationSchema } from '../../../validationSchemas/other-information-validation-schema';
import { IOtherInformationFormSchema } from '../../../types/other-information-form-schema';
import { IEditCompanyFormValues } from '../../types/form-values';
import dayjs from 'dayjs';
import { legalStructuresService } from 'src/api/services/legal-structures';
import { ISelectBox } from 'src/api/types/base-types';
import { useCheckPermissionAccess } from 'src/hooks/useCheckPermissionAccess';
import { checkCompanyType } from 'src/utils/check-company-type';
import { PERMISSION_TYPES } from 'src/enums/permissions';
import { COMPANY_TYPE_ROUTE } from 'src/pages/Company/Add/enums/company-type-route';
import { companyTypeOptions } from 'src/pages/Company/Add/constants/company-type-options';

const OtherInformationCompanyForm: React.FC = () => {
    const { checkPermissionAccess } = useCheckPermissionAccess();
    const { setFormValues, formValues, formFieldsData, setFormFieldsData } = useEditCompanyContext();
    const companyType = formValues.type as string;
    const [formSchema, setFormSchema] = useState<Record<OtherInformationSchemaFields, IFormGeneratorGeneralSchemaType>>(otherInformationSchema);
    const availableTypes = companyTypeOptions.filter((item) =>
        checkPermissionAccess(checkCompanyType(PERMISSION_TYPES.COMPANY_INFORMATION_EDIT, String(item.value).toLocaleLowerCase() as COMPANY_TYPE_ROUTE))
    );
    const {
        control,
        getValues,
        setValue,
        watch,
        formState: { errors },
    } = useForm<IOtherInformationFormSchema>({
        defaultValues: generateDefaultValueFromFormSchema(otherInformationSchema),
        mode: 'all',
        resolver: yupResolver(otherInformationValidationSchema),
    });

    const fetchSectorSelectBoxData = async (search?: string) => {
        const currentCompanyType = getValues('type')!;

        const sectorOptionsResponse = await sectorService.selectBox({
            company_type: currentCompanyType || null,
            ...(search ? { text: search } : {}),
        });

        return sectorOptionsResponse.data.data.map(({ label, value }) => ({
            label,
            value: String(value),
        }));
    };

    const fetchStockExchangeSelectBoxData = async (search: string) => {
        const stockExchangeOptionsResponse = await stockExchangeService.selectBox({
            text: search,
        });

        const data = stockExchangeOptionsResponse.data.data.map(({ label, value }) => ({
            label,
            value: String(value),
        }));

        return data;
    };

    const fetchLegalStructuresSelectBoxData = async (search: string) => {
        const optionsResponse = await legalStructuresService.selectBox({
            text: search,
        });

        const data = optionsResponse.data.data.map(({ label, value }) => ({
            label,
            value: String(value),
        }));

        return data;
    };

    const handleUpdateDropdownOptions = async (fieldName: OtherInformationSchemaFields, getNewOptions: any) => {
        // TODO: how to set this function type? now we have IGetNewOptionsFunction but not working
        setFormSchema((prev) => ({
            ...prev,
            [fieldName]: {
                ...formSchema[fieldName],
                data: [],
                props: {
                    ...formSchema[fieldName].props,
                    loading: true,
                },
            },
        }));

        const data = await getNewOptions;

        setFormSchema((prev) => ({
            ...prev,
            [fieldName]: {
                ...formSchema[fieldName],
                data,
                props: {
                    ...formSchema[fieldName].props,
                    loading: false,
                    disabled: false,
                    onFilter,
                },
            },
        }));
    };

    const onFilter = (fieldName: string, value: string) => {
        switch (fieldName) {
            case 'sector_id':
                handleUpdateDropdownOptions(fieldName, fetchSectorSelectBoxData(value));
                break;
            case 'stock_exchange_id':
                handleUpdateDropdownOptions(fieldName, fetchStockExchangeSelectBoxData(value));
                break;
            case 'legal_structure_id':
                handleUpdateDropdownOptions(fieldName, fetchLegalStructuresSelectBoxData(value));
                break;
        }
    };

    const handleSetFormData = async () => {
        let stockExchangesData: ISelectBox[] = [];
        let sectorsData: ISelectBox[] = [];
        let legalStructuresData: ISelectBox[] = [];

        try {
            if (formFieldsData['stock_exchange_id']?.length && formFieldsData['sector_id']?.length) {
                // Hint: this condition is for those moments we are coming back from next steps. so we do not want to call API again
                stockExchangesData = formFieldsData.stock_exchange_id;
                sectorsData = formFieldsData.sector_id;
                legalStructuresData = formFieldsData.legal_structure_id;
            } else {
                // Hint: In first component did mount we will get data from our backend server.
                const [stockExchangeRes, sectorRes, legalStructuresRes] = await Promise.all([
                    stockExchangeService.selectBox(),
                    sectorService.selectBox({ company_type: companyType }),
                    legalStructuresService.selectBox(),
                ]);

                stockExchangesData = stockExchangeRes.data.data.map(({ label, value }) => ({
                    label,
                    value: String(value),
                }));
                sectorsData = sectorRes.data.data.map(({ label, value }) => ({
                    label,
                    value: String(value),
                }));
                legalStructuresData = legalStructuresRes.data.data.map(({ label, value }) => ({
                    label,
                    value: String(value),
                }));
            }

            let newFormSchemaState = {
                // Hint: change fields mandatory star if company type is public and also attach onFilter method to sector for future searches.
                ...formSchema,
                stock_exchange_id: {
                    ...formSchema.stock_exchange_id,
                    data: stockExchangesData,
                    props: { ...formSchema.stock_exchange_id.props, disabled: false, onFilter, required: companyType === COMPANY_TYPE.PUBLIC },
                },
                sector_id: {
                    ...formSchema.sector_id,
                    data: sectorsData,
                    props: { ...formSchema.sector_id.props, disabled: false, onFilter },
                },
                legal_structure_id: {
                    ...formSchema.legal_structure_id,
                    data: legalStructuresData,
                    props: { ...formSchema.legal_structure_id.props, disabled: false, onFilter },
                },
                ISIN: {
                    ...formSchema.ISIN,
                    props: { ...formSchema.ISIN.props, required: companyType === COMPANY_TYPE.PUBLIC },
                },
                issued_shared: {
                    ...formSchema.issued_shared,
                    props: { ...formSchema.issued_shared.props, required: companyType === COMPANY_TYPE.PUBLIC },
                },
                type: {
                    ...formSchema.type,
                    props: { ...formSchema.type.props, hidden: availableTypes.length === 1 },
                    defaultValue: availableTypes.length === 1 ? String(availableTypes[0].value) : undefined,
                    data: availableTypes,
                },
            };

            if (Object.keys(formValues).length) {
                // Hint: this condition is for those moments we are coming back from next steps. so we do not want to call API again
                if (formValues['inception_date']) {
                    setValue('inception_date', dayjs(formValues['inception_date'] as Date).toDate(), { shouldValidate: true });
                }

                // Hint: simply setting all other values form their proper fields.
                Object.entries(formValues).forEach(([key, value]) => {
                    setValue(key as keyof IOtherInformationFormSchema, value as string | number | undefined, { shouldValidate: true });
                });
            }

            // Hint: doing our set states all in here and re-rendering the page
            setFormFieldsData((prev) => ({
                ...prev,
                stock_exchange_id: stockExchangesData,
                sector_id: sectorsData,
            }));

            setFormSchema(newFormSchemaState);
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: message,
            });
        }
    };

    useEffect(() => {
        // Hint: saving values in our global state when we are going to another tabs,
        return () => {
            const currentValues = getValues();
            const deletedKeys = Object.keys(currentValues).filter(
                (k) => currentValues[k as keyof IOtherInformationFormSchema] === null || currentValues[k as keyof IOtherInformationFormSchema] === ''
            );

            setFormValues((prev) => {
                const newFormValue = { ...prev, ...currentValues };
                Object.keys(newFormValue).forEach((k) => deletedKeys.includes(k) && delete newFormValue[k as keyof IOtherInformationFormSchema]);
                return newFormValue;
            });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // Hint: filling forms with proper data, props and values.
        handleSetFormData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // Hint: doing our changes on fields are related to other fields changes.
        // TODO: we are calling API of getting list of sectors three times, this code needs refactor and cleanup.
        const subscription = watch(async (value, { name }) => {
            if (name) {
                let newData = {};
                if (name === 'inception_date') {
                    newData = {
                        ...newData,
                        inception_date: value.inception_date,
                    };
                } else {
                    newData = { ...newData, [name as keyof IEditCompanyFormValues]: value[name] };
                }
                setFormValues((prev) => ({ ...prev, ...newData }));
            }

            if (name === 'type' && value.type) {
                setValue('sector_id', undefined);
                const sectors = await fetchSectorSelectBoxData();

                setFormSchema((prev) => ({
                    ...prev,
                    sector_id: {
                        ...prev.sector_id,
                        data: sectors,
                    },
                    ISIN: {
                        ...prev.ISIN,
                        props: { ...prev.ISIN.props, required: value.type === COMPANY_TYPE.PUBLIC },
                    },
                    issued_shared: {
                        ...prev.issued_shared,
                        props: { ...prev.issued_shared.props, required: value.type === COMPANY_TYPE.PUBLIC },
                    },
                    stock_exchange_id: {
                        ...prev.stock_exchange_id,
                        props: { ...prev.stock_exchange_id.props, required: value.type === COMPANY_TYPE.PUBLIC },
                    },
                }));
            }
        });
        return () => subscription.unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch]);

    return (
        <div className="grid justify-content-center">
            <div className="xs:col:12 md:col-6">
                <FormGenerator control={control} errors={errors} schema={formSchema} noBackgroundLayout />
            </div>
        </div>
    );
};

export default OtherInformationCompanyForm;
