import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import styles from './FinancialItemRow.module.scss';
import CustomSearchDropdown from '../CustomSearchDropdown';
import { companyService } from 'src/api/services/company';
import { InputText } from 'primereact/inputtext';
import { InputNumberChangeParams } from 'primereact/inputnumber';
import PrefixInputNumber from 'src/components/Kit/PrefixInputNumber';
import { classNames } from 'primereact/utils';
import { useFinancialDataContext } from '../../hooks/useFinancialDataContext';
import { modalActions } from 'src/context/modals';
import { MODAL_TYPES } from 'src/types/modals';
import TotalSubtotalValueSelector from '../TotalSubtotalValueSelector';
import { FINANCIAL_INFORMATION_ROW_INPUT_TYPE, NEW_ITEM_POSITION, SIDE_PANEL_OPTIONS } from '../../../../../enums';
import { financialInformationLegitFractions, ROW_COLOR_DICTIONARY } from '../../../../../constants';
import { IFinancialInformationRow } from '../../../../../types';
import { OverlayPanel } from 'primereact/overlaypanel';
import Button from 'src/components/Kit/Button';
import { getAxiosError } from 'src/utils/get-axios-error';
import { toast } from 'src/utils/toast';
import { TOAST_STATUS } from 'src/constants/toast-status';
import { useCheckPermissionAccess } from 'src/hooks/useCheckPermissionAccess';
import { ITEM_TYPE_OPTION } from 'src/constants/company/financialInformation/financialItem';
import { FINANCIAL_INFORMATION_STATUS, ITEM_TYPE_ENUM, ITEM_VALUE_TYPE_OPTIONS_ENUM } from 'src/enums/company/financialInformation/financialItem';
import { useDebounce } from 'src/hooks/useDebounce';
import { PERMISSION_TYPES } from 'src/enums/permissions';
import ExpressionTokenizer from '../TotalSubtotalValueSelector/components/FormulaComponent/logic/Tokenizers/ExpressionTokenizer';
import SidePanel from 'src/components/App/SidePanel';
import { ISidePanelStateType } from 'src/types/sidePanel';
import AddNewItemForm from '../AddNewItemForm';
import { useFinancial, useFinancialCompanyId } from '../../../../../../../../../context/companies';
import { IFinancialInformationFormulaUsageResponse, RATIO_NUMBER_TYPE } from 'src/api/types/company';
import { COMPANY_TYPE_ROUTE } from 'src/pages/Company/Add/enums/company-type-route';
import { IParams } from 'src/types/params';
import { useParams } from 'react-router-dom';
import { checkCompanyType } from 'src/utils/check-company-type';
import { removeDuplicateItems, sortFormulaArray } from '../../../../../../../../../utils/sort-formula-usage';
import Statement from '../TotalSubtotalValueSelector/components/FormulaComponent/logic/Components/Statement';

interface IRowWithIndex extends IFinancialInformationRow {
    rowOrder: number;
}
interface IProps {
    row: IRowWithIndex;
}

interface IDisabledActions {
    itemType: boolean;
    itemName: boolean;
    itemValue: boolean;
    addNewItem: boolean;
    removeItem: boolean;
}

const FinancialItemRow: React.FC<IProps> = ({ row }) => {
    const { companyType } = useParams<IParams>();
    const addNewItemOverlayRef = useRef<any>();
    const { checkPermissionAccess } = useCheckPermissionAccess();
    const {
        financialDataRows,
        financialDates,
        financialInformationFormStatus,
        uncompletedRows,
        refreshValues,
        setRefreshValues,
        handleUpdateRow,
        handleAddNewRow,
        setFinancialDataRows,
        totalSubtotalCalculator,
    } = useFinancialDataContext();
    const companyId = useFinancialCompanyId();
    const { activeDate, currency } = useFinancial((global) => ({
        activeDate: global.financialActiveDate,
        currency: global.financialSelectedCurrency,
    }));
    const [selectedValue, setSelectedValue] = useState<string | number | null | any>(row.value);
    const [disableActions, setDisableActions] = useState<IDisabledActions>({
        itemType: false,
        itemName: false,
        itemValue: false,
        addNewItem: false,
        removeItem: false,
    });
    const [fieldErrors, setFieldErrors] = useState<{ name?: boolean; value?: boolean; uniqueId: string }>();

    const debouncedHandleUpdateRow = useDebounce(
        (field: FINANCIAL_INFORMATION_ROW_INPUT_TYPE, val: any, index: number) => handleUpdateRow(field, val, index),
        700
    );

    const handleRemove = async () => {
        try {
            let tempRows = structuredClone(financialDataRows) || [];
            let targetItemId = row.name && row.name[0]?.id;
            let rowOrder = row.rowOrder;
            if (!targetItemId) {
                return;
            }

            const localUsedItems = checkUsedItems();
            const globalUsedItems = await companyService(companyType as COMPANY_TYPE_ROUTE).formulaUsage(companyId, targetItemId);
            const usedItems = sortFormulaArray([...localUsedItems, ...globalUsedItems.data.data]);

            if (usedItems.length > 0) {
                modalActions.clearModals();
                setTimeout(() => {
                    modalActions.addModal(MODAL_TYPES.CONFIRMATION_MODAL, {
                        header: 'Alert',
                        children: (
                            <div className={styles.nullItemWarningWrapper}>
                                <span className={styles.bodyText}>
                                    You cannot delete this item. Because this item is used in several formulas To delete this item first remove this item from
                                    the formula on the below list then try again
                                </span>
                                <ol className={styles.list}>
                                    {usedItems.map((usedItem) => (
                                        <>
                                            {usedItem.items.map((financialItem) => (
                                                <li>
                                                    {usedItem.year} {usedItem.quarter && `Quarter ${usedItem.quarter}`} / {financialItem.name}
                                                </li>
                                            ))}
                                        </>
                                    ))}
                                </ol>
                                <div className={styles.actionWrapper}>
                                    <Button color="primary" onClick={() => modalActions.clearModals()}>
                                        <span>OK, Got it.</span>
                                    </Button>
                                </div>
                            </div>
                        ),
                        text: '',
                    });
                }, 200);
                setSelectedValue(row.value);
                return;
            }

            if (!row.notSaved && targetItemId) await companyService(companyType as COMPANY_TYPE_ROUTE).deleteRow(companyId, targetItemId);

            tempRows = tempRows.filter((_: IFinancialInformationRow, index: number) => index !== rowOrder);
            setFinancialDataRows(totalSubtotalCalculator(tempRows));
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: message,
            });
        }
    };
    const handleUpdateRowData = (field: FINANCIAL_INFORMATION_ROW_INPUT_TYPE, val: any) => {
        if (field === FINANCIAL_INFORMATION_ROW_INPUT_TYPE.VALUE) {
            setSelectedValue(val);
            debouncedHandleUpdateRow(field, val, row.rowOrder);
        } else {
            handleUpdateRow(field, val, row.rowOrder, val[0]?.number_type);
        }
    };

    const handleAddNewItemName = () => {
        setSidePanelState({
            type: SIDE_PANEL_OPTIONS.ADD_NEW_ITEM,
            show: true,
            itemType: row.type,
            itemId: row.rowOrder,
        });
    };

    const handleCreateNewItem = (tempResponse: IFinancialInformationRow, itemId: number, number_type?: RATIO_NUMBER_TYPE) => {
        handleUpdateRow(FINANCIAL_INFORMATION_ROW_INPUT_TYPE.NAME, tempResponse.value, itemId, number_type);
        handleCloseSidePanel();
    };

    const [sidePanelState, setSidePanelState] = useState<ISidePanelStateType>({
        type: undefined,
        show: false,
        itemType: undefined,
        itemId: undefined,
    });

    const handleCloseSidePanel = () => {
        setSidePanelState({
            type: undefined,
            show: false,
            itemType: undefined,
            itemId: undefined,
        });
    };

    const onPlusClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (row.rowOrder === 0) {
            addNewItemOverlayRef.current && addNewItemOverlayRef.current.toggle(e);
        } else {
            handleAddNewRow(row.rowOrder, NEW_ITEM_POSITION.AFTER);
        }
    };

    const handleSetMyPermissions = () => {
        if (!checkPermissionAccess(checkCompanyType(PERMISSION_TYPES.COMPANY_FI_EDIT_APPROVED_DATA, companyType as COMPANY_TYPE_ROUTE))) {
            //Hint: it means that I dont have edit on approved permission

            if (
                activeDate?.status === FINANCIAL_INFORMATION_STATUS.NOT_APPROVED &&
                financialDates.find((date) => date.status === FINANCIAL_INFORMATION_STATUS.APPROVED)
            ) {
                // Hint: it means that I'm in not-approved data but there are some approved one in all dates
                // Hint: this happens when admin does not have edit on approved permission and also one of date has been approved.

                // 1. so admin can edit values but can not add or delete any rows
                setDisableActions((obj) => ({
                    ...obj,
                    addNewItem: true, // I can not add new item
                    removeItem: true, // I can not remove some item
                    itemType: true, // I can not change item type
                    itemName: true, // I can not change item name
                }));

                // 2. Also if no rows exist and we are showing a sample row, the whole row is getting disabled.
                // again because admin can only change values and is not able to add even first row
                if (financialDataRows.length === 1 && financialDataRows[0].type === undefined) {
                    // Hint: this condition will check that we have only one row and that row is generic and fake only fow show. so means that we have no financial rows.
                    setDisableActions({
                        itemType: true,
                        itemName: true,
                        itemValue: true,
                        addNewItem: true,
                        removeItem: true,
                    });
                }
            } else if (activeDate?.status === FINANCIAL_INFORMATION_STATUS.APPROVED) {
                // Hint: it means that I'm in approved data. everything should be disabled
                setDisableActions({
                    itemType: true,
                    itemName: true,
                    itemValue: true,
                    addNewItem: true,
                    removeItem: true,
                });
            }
        }
    };

    const checkUsedItems = (): IFinancialInformationFormulaUsageResponse[] => {
        // TODO: we have same logic in context. we can merge them.
        let tokenizer = new ExpressionTokenizer();
        const targetItem = row;
        const totalSubtotals =
            financialDataRows &&
            financialDataRows.filter(
                (row: IFinancialInformationRow) => (row.type === ITEM_TYPE_ENUM.TOTAL || row.type === ITEM_TYPE_ENUM.SUBTOTAL) && !row.is_custom_value
            );
        let usedItems: IFinancialInformationFormulaUsageResponse['items'] = [];

        if (totalSubtotals.length === 0) return [];

        totalSubtotals.forEach((totalSubtotal) => {
            if (totalSubtotal.relatedItems) {
                let tokens = tokenizer.tokenize(totalSubtotal.relatedItems);
                tokens.forEach((token: string) => {
                    if (token[0] === '{') {
                        let item = JSON.parse(token);
                        if (targetItem?.name && Boolean(targetItem.itemId === item.meta.id)) {
                            usedItems.push({
                                id: totalSubtotal.itemId,
                                name: totalSubtotal.name![0]?.name || '',
                            });
                        }
                    }
                });
            }
        });

        usedItems = removeDuplicateItems(usedItems);

        if (usedItems.length)
            return [
                {
                    quarter: activeDate!.quarter,
                    year: activeDate!.year,
                    items: removeDuplicateItems(usedItems),
                },
            ];
        return [];
    };

    const handleConfirmModal = () => {
        modalActions.addModal(MODAL_TYPES.CONFIRMATION_MODAL, {
            header: 'Delete Confirm',
            text: 'Are you sure to delete this Row?',
            handleAction: handleRemove as () => Promise<void>,
        });
    };

    const handleDeleteItem = () => {
        handleConfirmModal();
    };

    const renderThirdDropdown = () => {
        switch (row.type) {
            case ITEM_TYPE_ENUM.TITLE:
            case ITEM_TYPE_ENUM.SUBTITLE:
                return <InputText style={{ width: '100%' }} disabled />;
            case ITEM_TYPE_ENUM.ITEM:
                if (row.name) {
                    if (row.name[0]?.value_type === ITEM_VALUE_TYPE_OPTIONS_ENUM.STRING)
                        return (
                            <>
                                <InputText
                                    value={selectedValue}
                                    style={{ width: '100%' }}
                                    placeholder="value"
                                    disabled={disableActions.itemValue}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        handleUpdateRowData(FINANCIAL_INFORMATION_ROW_INPUT_TYPE.VALUE, e.target.value)
                                    }
                                />
                            </>
                        );
                    else
                        return (
                            <>
                                <PrefixInputNumber
                                    value={selectedValue}
                                    style={{ width: '100%' }}
                                    onChange={(e: InputNumberChangeParams) => handleUpdateRowData(FINANCIAL_INFORMATION_ROW_INPUT_TYPE.VALUE, e.value)}
                                    placeholder="000"
                                    disabled={disableActions.itemValue}
                                    prefix={row.number_type === RATIO_NUMBER_TYPE.CURRENCY && <div>{currency?.short_name}</div>}
                                    maxFractionDigits={financialInformationLegitFractions}
                                />
                            </>
                        );
                } else {
                    return <InputText style={{ width: '100%' }} disabled={true} />;
                }
            case ITEM_TYPE_ENUM.SUBTOTAL:
                if (row.name) {
                    return <TotalSubtotalValueSelector disabled={disableActions.itemValue} row={row} type={ITEM_TYPE_ENUM.SUBTOTAL} />;
                } else {
                    return <InputText style={{ width: '100%' }} disabled={true} />;
                }
            case ITEM_TYPE_ENUM.TOTAL:
                if (row.name) {
                    return <TotalSubtotalValueSelector disabled={disableActions.itemValue} row={row} type={ITEM_TYPE_ENUM.TOTAL} />;
                } else {
                    return <InputText style={{ width: '100%' }} disabled={true} />;
                }
            default:
                return <InputText style={{ width: '100%' }} disabled />;
        }
    };

    useEffect(() => {
        setSelectedValue(row.value);
    }, [row.value]);

    useEffect(() => {
        const rowErrors = uncompletedRows.find((tempRow) => tempRow.uniqueId === row.uniqueId);
        if (rowErrors) {
            setFieldErrors(rowErrors);
        } else {
            setFieldErrors(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uncompletedRows]);

    useLayoutEffect(() => {
        handleSetMyPermissions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [financialInformationFormStatus, activeDate?.status]);

    return (
        <>
            <div className={styles.rowContainer} data-cy={'frow__' + row.itemId} style={{ backgroundColor: ROW_COLOR_DICTIONARY[row.type || 4] }}>
                <div className={styles.selectBoxWrapper}>
                    <div className={styles.rowWrapper} data-cy={'frow__type__' + row.itemId}>
                        <Dropdown
                            optionLabel="label"
                            optionValue="value"
                            value={row.type}
                            options={ITEM_TYPE_OPTION}
                            onChange={(e) => handleUpdateRowData(FINANCIAL_INFORMATION_ROW_INPUT_TYPE.TYPE, e.value)}
                            placeholder="Type"
                            disabled={disableActions.itemType}
                        />
                    </div>
                    <div className={styles.rowWrapper} data-cy={'frow__name__' + row.itemId}>
                        <CustomSearchDropdown
                            value={row.name}
                            handleSelectSearchedItem={(value: any) => handleUpdateRowData(FINANCIAL_INFORMATION_ROW_INPUT_TYPE.NAME, value)}
                            addNewOption={checkPermissionAccess(PERMISSION_TYPES.SETTING_FI_ITEM_CREATE) && true}
                            addNewOptionOnClick={handleAddNewItemName}
                            apiItemType={row.type}
                            disabled={row.type === undefined || disableActions.itemName}
                            searchEndPoint={companyService(companyType as COMPANY_TYPE_ROUTE).getFinancialInformationSearchedItems}
                        />
                        {fieldErrors?.name && <p className={styles.errorMessage}>Name is required</p>}
                    </div>
                    <div className={styles.rowWrapper} data-cy={'frow__value__' + row.itemId}>
                        {renderThirdDropdown()}
                        {fieldErrors?.value && <p className={styles.errorMessage}>Value is required</p>}
                    </div>
                </div>
                <div className={styles.actionsWrapper}>
                    <div
                        className={classNames(disableActions.removeItem ? styles.action__disabled : styles.action)}
                        onClick={() => !disableActions.removeItem && handleDeleteItem()}
                    >
                        <i className="ts-trash" />
                    </div>
                    <div
                        className={classNames(disableActions.addNewItem ? [styles.action__disabled, styles.addNew] : [styles.action, styles.addNew])}
                        onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => !disableActions.addNewItem && onPlusClick(e)}
                    >
                        <i className="ts-plus" />
                    </div>
                    <OverlayPanel ref={addNewItemOverlayRef}>
                        <div className={styles.overlayBody}>
                            <Button
                                onClick={(e) => {
                                    handleAddNewRow(row.rowOrder, NEW_ITEM_POSITION.BEFORE);
                                    addNewItemOverlayRef.current && addNewItemOverlayRef.current.toggle(e);
                                }}
                                customStyle={{ width: '100%', margin: 0 }}
                                color="secondary"
                            >
                                Top
                            </Button>
                            <span>or</span>
                            <Button
                                onClick={(e) => {
                                    handleAddNewRow(row.rowOrder, NEW_ITEM_POSITION.AFTER);
                                    addNewItemOverlayRef.current && addNewItemOverlayRef.current.toggle(e);
                                }}
                                customStyle={{ width: '100%', margin: 0 }}
                                color="secondary"
                            >
                                Bottom
                            </Button>
                        </div>
                    </OverlayPanel>
                </div>
            </div>
            <SidePanel show={sidePanelState.show} onHide={handleCloseSidePanel} title={'Add New'}>
                <AddNewItemForm itemType={sidePanelState.itemType} itemId={sidePanelState.itemId} handleCreateNewItem={handleCreateNewItem} />
            </SidePanel>
        </>
    );
};

export default FinancialItemRow;
