import { API_ROUTES } from 'src/constants/api-routes';
import { AxiosInstance } from '../index';
import { ILoginPayload, IProfilePayload } from '../types/auth';
import { IAxiosResponse } from '../types/axios-response';

export const authService = {
    login(email: string, password: string) {
        return AxiosInstance.post<IAxiosResponse<ILoginPayload>>(API_ROUTES.AUTH.LOGIN, {
            email,
            password,
        });
    },
    logout() {
        return AxiosInstance.post<IAxiosResponse<{}>>(API_ROUTES.AUTH.LOGOUT);
    },
    getProfile() {
        return AxiosInstance.get<IAxiosResponse<IProfilePayload>>(API_ROUTES.AUTH.GET_PROFILE);
    },
};
