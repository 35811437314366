import { classNames } from 'primereact/utils';
import styles from '../../CompanyProfile.module.scss';
import { CompanyLogoPlaceholder } from 'src/assets/Images/CompanyLogoPlaceholder';
import { useFinancialCompany } from '../../../../../../context/companies';

const BasicInformation = () => {
    const companyDetails = useFinancialCompany();

    return (
        <div className={classNames(styles.segmentWrapper, 'grid')}>
            <div className={classNames(styles.row, styles.topBorderRadius, 'col-12')}>
                <div className={styles.logoContainer}>
                    <div className={styles.logoCard}>
                        {companyDetails?.profile_image ?
                            <img src={companyDetails?.profile_image} alt={companyDetails?.name} /> :
                            <CompanyLogoPlaceholder />}
                    </div>
                    <div className={styles.nameWrapper}>
                        <h3>{companyDetails?.name}</h3>
                        <h4>{companyDetails?.short_name}</h4>
                    </div>
                </div>
            </div>
            <div className={classNames(styles.row, styles.noBorderTop, 'col-4')}>
                <div className={styles.segment}>
                    <h4 className={styles.title}>English Name</h4>
                    <h4 className={styles.detail}>{companyDetails?.name ? companyDetails?.name : '_'}</h4>
                </div>
            </div>
            <div className={classNames(styles.row, styles.noBorderTop, styles.noBorderLeft, 'col-4')}>
                <div className={styles.segment}>
                    <h4 className={styles.title}>Arabic Name</h4>
                    <h4 className={styles.detail}>{companyDetails?.arabic_name ? companyDetails?.arabic_name : '_'}</h4>
                </div>
            </div>

            <div className={classNames(styles.row, styles.noBorderTop, styles.noBorderLeft, 'col-4')}>
                <div className={styles.segment}>
                    <h4 className={styles.title}>Short Name</h4>
                    <h4 className={styles.detail}>{companyDetails?.short_name ? companyDetails?.short_name : '_'}</h4>
                </div>
            </div>

            <div className={classNames(styles.row, styles.noBorderTop, 'col-4')}>
                <div className={styles.segment}>
                    <h4 className={styles.title}>Previous English Name</h4>
                    <h4 className={styles.detail}>{companyDetails?.previous_name ? companyDetails?.previous_name : '_'}</h4>
                </div>
            </div>
            <div className={classNames(styles.row, styles.noBorderTop, styles.noBorderLeft, 'col-4')}>
                <div className={styles.segment}>
                    <h4 className={styles.title}>Previous Arabic Name</h4>
                    <h4 className={styles.detail}>{companyDetails?.previous_arabic_name ? companyDetails?.previous_arabic_name : '_'}</h4>
                </div>
            </div>
            <div className={classNames(styles.row, styles.noBorderTop, styles.noBorderLeft,'col-4')}>
                <div className={styles.segment}>
                    <h4 className={styles.title}>Country</h4>
                    <h4 className={styles.detail}>{companyDetails?.country_name ? companyDetails?.country_name : '_'}</h4>
                </div>
            </div>
            <div className={classNames(styles.row, styles.noBorderTop, 'col-4')}>
                <div className={styles.segment}>
                    <h4 className={styles.title}>Currency</h4>
                    <h4 className={styles.detail}>{companyDetails?.currency_name ? companyDetails?.currency_name : '_'}</h4>
                </div>
            </div>
            <div className={classNames(styles.row, styles.noBorderTop, styles.noBorderLeft, 'col-4')}>
                <div className={styles.segment}>
                    <h4 className={styles.title}>Type</h4>
                    <h4 className={styles.detail}>{companyDetails?.type ? companyDetails?.type : '_'}</h4>
                </div>
            </div>
            <div className={classNames(styles.row, styles.bottomBorderRadius, styles.noBorderTop, 'col-12')}>
                <div className={styles.segment}>
                    <h4 className={styles.title}>Description</h4>
                    <h4 className={styles.detail}>{companyDetails?.description ? companyDetails?.description : '_'}</h4>
                </div>
            </div>
        </div>
    );
};

export default BasicInformation;
