import { FORM_INPUTS } from 'src/types/form';
import { IFormGeneratorSchemaType } from 'src/types/form-generator-schema-type';

export type CreateCommentsSchemaFields = 'topic' | 'comment' | 'sources';
export type CreateCommentsSchemaType = Record<CreateCommentsSchemaFields, IFormGeneratorSchemaType>;

export const createCommentsSchema: CreateCommentsSchemaType = {
    topic: {
        placeholder: 'Topic',
        type: FORM_INPUTS.TEXT,
        props: {
            required: true,
            min: 2,
            max: 80,
        },
    },
    comment: {
        placeholder: 'Comment',
        type: FORM_INPUTS.TEXTAREA,
    },
    sources: {
        placeholder: 'Source Section',
        label: 'Source Section',
        type: FORM_INPUTS.REPEATER,
        childrenInitCount: 1,
        childrenSchema: {
            title: {
                placeholder: 'Title',
                type: FORM_INPUTS.TEXT,
                props: {
                    min: 2,
                    max: 200,
                },
            },
            link: {
                placeholder: 'Link',
                type: FORM_INPUTS.TEXT,
                props: {
                    max: 1000,
                },
            },
        },
    },
};
