import { API_ROUTES } from 'src/constants/api-routes';
import { AxiosInstance } from '../index';
import { IAxiosResponse } from '../types/axios-response';
import { ICreatePersonPayload, IPersonType, IPersonResponse } from '../types/persons';
import { IDropDownPayload, ISelectBox } from '../types/base-types';
import { IDataTablePayload, IDataTableResponse } from 'src/api/types/table';

export const personService = {
    getAll(params?: IDataTablePayload) {
        return AxiosInstance.get<IDataTableResponse<IPersonType>>(API_ROUTES.PERSON.GET_ALL, { params });
    },
    createNew(data?: ICreatePersonPayload) {
        return AxiosInstance.post<IAxiosResponse>(API_ROUTES.PERSON.ADD_NEW, data);
    },
    delete(id: number) {
        return AxiosInstance.delete<IAxiosResponse>(API_ROUTES.PERSON.DELETE(id));
    },
    getOne(id: number) {
        return AxiosInstance.get<IAxiosResponse<IPersonResponse>>(API_ROUTES.PERSON.GET_ONE(id));
    },
    update(id: number, data?: any) {
        return AxiosInstance.put<IAxiosResponse>(API_ROUTES.PERSON.UPDATE(id), data);
    },
    selectBox(params?: IDropDownPayload) {
        return AxiosInstance.get<IAxiosResponse<ISelectBox[]>>(API_ROUTES.PERSON.SELECT_BOX, { params });
    },
    usage(id: number) {
        return AxiosInstance.get<IDataTableResponse<any>>(API_ROUTES.PERSON.USAGE(id));
    },
};
