import { SVGProps } from 'react';

const FinancialReportIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <ellipse
                cx="13.3333"
                cy="2.66683"
                rx="1.33333"
                ry="1.33333"
                stroke={props.stroke || 'currentColor' || '#888888'}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14 6V10.6667C14 12.5076 12.5076 14 10.6667 14H5.33333C3.49238 14 2 12.5076 2 10.6667V5.33333C2 3.49238 3.49238 2 5.33333 2H10"
                stroke={props.stroke || 'currentColor' || '#888888'}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M10.666 6L8.66602 8" stroke={props.stroke || 'currentColor' || '#888888'} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.66667 8.00016L7 6.3335" stroke={props.stroke || 'currentColor' || '#888888'} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.00065 6.3335L5.33398 8.00016" stroke={props.stroke || 'currentColor' || '#888888'} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5.33398 9.99984H10.6673" stroke={props.stroke || 'currentColor' || '#888888'} strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default FinancialReportIcon;
