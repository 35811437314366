import * as Yup from 'yup';
import { object, string } from 'yup';
import { transactionInformationValidationSchema } from './transaction-row/validation-schema';

export const holdingValidationSchema = object().shape({
    entity_type: string().required('Transaction Type is required'),
    entity_id: string().required('Company Name is required'),
    transaction_information: Yup.array()
        .min(1, 'At least one transaction information object is required')
        .of(transactionInformationValidationSchema),
});



