import { IFormGeneratorGeneralSchemaType } from '../types/form-generator-schema-type';
import { useState } from 'react';
import { modifyFormData, modifyFormLoading, modifyFormProps, modifyFormType, updateFormField } from '../utils/form-utils';
import { FORM_INPUTS } from 'src/types/form';

type SchemaType<T extends string> = Record<T, IFormGeneratorGeneralSchemaType>;

export function useFormFields<T extends string>(schema: SchemaType<T>) {
    const [formFields, setFormFields] = useState<SchemaType<T>>(schema);

    function updateFormData(key: string, data: any) {
        setFormFields((schema) => updateFormField<T>(schema, key as any, modifyFormData, data));
    }

    function updateFormType(key: string, data: FORM_INPUTS) {
        setFormFields((schema) => updateFormField<T>(schema, key as any, modifyFormType, data));
    }

    function updateFormLoading(key: string, data: boolean) {
        setFormFields((schema) => updateFormField<T>(schema, key as any, modifyFormLoading, data));
    }

    function updateFormProps(key: string, data: Record<string, any>) {
        setFormFields((schema) => updateFormField<T>(schema, key as any, modifyFormProps, data));
    }

    return {
        formFields,
        setFormFields,
        updateFormData,
        updateFormLoading,
        updateFormProps,
        updateFormType
    };
}
