import { Link } from 'react-router-dom';
import { IGetPeopleResponse } from 'src/api/types/company';
import { timestampToDateFormat, timestampToDynamicDateFormat } from 'src/utils/timestamp-to-date-format';

export const peopleCellRender = {
    photo: (data: IGetPeopleResponse) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {' '}
            {data.photo ? (
                <img width={32} height={32} style={{ borderRadius: '8px' }} src={data.photo} alt="avatar" />
            ) : (
                <span
                    style={{
                        fontSize: '9px',
                        color: '#666',
                        width: '32px',
                        height: '32px',
                        display: 'inline-block',
                        textAlign: 'center',
                        lineHeight: '16px',
                        background: '#f5f5f5',
                        borderRadius: '8px',
                    }}
                >
                    No Avatar
                </span>
            )}
        </div>
    ),
    updated_at: (data: IGetPeopleResponse) => <span>{timestampToDateFormat(data.updated_at)}</span>,
    created_at: (data: IGetPeopleResponse) => <span>{timestampToDateFormat(data.created_at)}</span>,
    join_date: (data: IGetPeopleResponse) => <span>{data.join_date ? timestampToDynamicDateFormat(data.join_date, data.join_date_format) : '-'}</span>,
    end_date: (data: IGetPeopleResponse) => <span>{data.end_date ? timestampToDynamicDateFormat(data.end_date, data.end_date_format) : 'Current'}</span>,
    linkedin: (data: IGetPeopleResponse) =>
        data.linkedin ? (
            <Link to={data.linkedin} target="_blank">
                Linkedin
            </Link>
        ) : (
            <span>-</span>
        ),
};
