import { FORMULA_TYPE, FinancialInformationRatioDataContext, RATIO_NUMBER_TYPE, operatorsSymbol } from 'src/api/types/company';
import { financialValuesStyle } from './financial-values-style';
import PopoverMessage from 'src/components/Kit/PopoverMessage';
import { UUIDv4 } from './uuid-generator';
import { POPOVER_STATUS } from 'src/components/Kit/PopoverMessage/enums/popover-status';


export const generateValue = (valueData: FinancialInformationRatioDataContext, showFormula: boolean, prefix: string | null = ''): any => {
    const uuid = UUIDv4();
    if (valueData === null) {
        return null;
    }

    if (valueData.type === FORMULA_TYPE.ITEM) {
        const context = valueData.context;
        const contextName = context.has_abs ? `| ${context.name} |` : context.name;

        return !showFormula ? (
            <span className="item">{contextName}</span>
        ) : (
            <>
                <span className="item">
                    {context.meta?.message || context.meta?.formula ? (
                        <>
                            {context.has_abs ? (
                                <span>
                                    |&nbsp;
                                    <span id={`popover-${uuid}`} className="error">
                                        {context.name}
                                    </span>
                                    &nbsp;|
                                </span>
                            ) : (
                                <span id={`popover-${uuid}`} className="error">
                                    {context.name}
                                </span>
                            )}
                        </>
                    ) : (
                        financialValuesStyle(String(context.value), {
                            isAbs: context.has_abs,
                            ...(context.meta?.number_type === RATIO_NUMBER_TYPE.CURRENCY && prefix && { prefix }),
                        })
                    )}
                    {context.meta?.message && (
                        <PopoverMessage
                            target={`#popover-${uuid}`}
                            title="Calculating Error"
                            status={POPOVER_STATUS.ERROR}
                            message={context.meta.message}
                            description={
                                context.meta?.items && (
                                    <ul>
                                        <li>Category: {context.meta.items.category || '-'}</li>
                                        <li>Sub Category: {context.meta.items.sub_category || '-'}</li>
                                        <li>Item Type: {context.meta.items.item_type || '-'}</li>
                                    </ul>
                                )
                            }
                        />
                    )}
                    {context.meta?.formula && (
                        <PopoverMessage
                            target={`#popover-${uuid}`}
                            title="Calculating Error"
                            status={POPOVER_STATUS.ERROR}
                            message={context.meta.message}
                            description={<div style={{ display: 'flex', flexWrap: 'wrap' }}>{generateValue(context.meta.formula, false, prefix)}</div>}
                        />
                    )}
                </span>
            </>
        );
    }

    if (valueData.type === FORMULA_TYPE.STATEMENT) {
        const operations = valueData.context.operations.map((operation) => operatorsSymbol[operation]);
        const values = valueData.context.values.map((value) => generateValue(value, showFormula, prefix));
        const hasParentheses = valueData.context.has_parentheses;

        return (
            <>
                {hasParentheses && <span className="parentheses-start">(</span>}
                {values.map((item, index) => (
                    <div key={index}>
                        <span className="statement">{item}</span>
                        {operations[index] ? <span className="operation">{operations[index]}</span> : ''}
                    </div>
                ))}
                {hasParentheses && <span className="parentheses-end">)</span>}
            </>
        );
    }
    return '';
};
