import React from 'react';
import './GeneralFormErrorMessage.module.scss';

interface IGeneralFormErrorMessage {
    message: string;
}

const GeneralFormErrorMessage: React.FC<IGeneralFormErrorMessage> = ({ message }) => {
    return (
        <div className="form-error-message">
            <span><i className='ts-warning'></i>{message}</span>
        </div>
    );
};

export default GeneralFormErrorMessage;
