import React, { FC, useState } from 'react';
import styles from './MainComponentFooter.module.scss';
import { useFinancialDataContext } from '../../hooks/useFinancialDataContext';
import { IFinancialActiveDate } from '../../../../../types';
import FinancialDateCard from '../FinancialDateCard';
import Button from 'src/components/Kit/Button';
import StateManagement from '../StateManagement';
import { COMPANY_FINANCIAL_INFORMATION_STATE, MUTATION_FINANCIAL_STEP, SIDE_PANEL_OPTIONS } from '../../../../../enums';
import { toast } from 'src/utils/toast';
import { getAxiosError } from 'src/utils/get-axios-error';
import { TOAST_STATUS } from 'src/constants/toast-status';
import SidePanel from 'src/components/App/SidePanel';
import AddNewFinancialDateForm from '../AddNewFinancialDateForm';
import { ISidePanelStateType } from 'src/types/sidePanel';
import { financialActions, useFinancial } from '../../../../../../../../../context/companies';
import { useAsync } from '../../../../../../../../../hooks/useAsync';
import { modalActions } from 'src/context/modals';
import { MODAL_TYPES } from 'src/types/modals';
import { classNames } from 'primereact/utils';
import { useCheckPermissionAccess } from 'src/hooks/useCheckPermissionAccess';
import { checkCompanyType } from 'src/utils/check-company-type';
import { PERMISSION_TYPES } from 'src/enums/permissions';
import { COMPANY_TYPE_ROUTE } from 'src/pages/Company/Add/enums/company-type-route';
import { useParams } from 'react-router-dom';
import { IParams } from 'src/types/params';

const MainComponentFooter: FC = () => {
    const { companyType, companyId } = useParams<IParams>();
    const { checkPermissionAccess } = useCheckPermissionAccess();
    const { canChangeBasics, financialDates, financialActiveDate } = useFinancial((global) => ({
        canChangeBasics: global.canChangeBasic,
        financialDates: global.financialDates,
        financialActiveDate: global.financialActiveDate,
    }));
    const {
        loadings,
        setLoadings,
        financialDataRows,
        handleGetAllDatesApiCall,
        handleGetRowsFromApi,
        setFinancialDates,
        handleApplyChanges,
        existDraftData,
        setExistDraftData,
        removeDraftData,
        showSavedDraftNotice,
    } = useFinancialDataContext();
    const [sidePanelState, setSidePanelState] = useState<ISidePanelStateType>({
        type: undefined,
        show: false,
        itemType: undefined,
        itemId: undefined,
    });

    const { selectedFinancialDataTypeId } = useFinancial((global) => ({
        selectedFinancialDataTypeId: global.financialTypeSelected,
    }));

    useAsync(
        async () => {
            setLoadings({ financialTypesLoading: true });

            try {
                let typeId: number | undefined = selectedFinancialDataTypeId;
                let dates = await handleGetAllDatesApiCall(typeId);
                if (dates.length > 0) {
                    await handleGetRowsFromApi(typeId, {
                        year: dates[0].year,
                        quarter: dates[0].quarter || null,
                        status: dates[0].status,
                    });
                }

                setFinancialDates(dates);
                financialActions.setFinancialActiveDate(dates[0] || undefined);
            } catch (err) {
                const error = getAxiosError(err);
                const message = error?.message || 'Server Error';
                toast.fire({
                    icon: TOAST_STATUS.ERROR,
                    title: message,
                });
            } finally {
                setLoadings({ financialTypesLoading: false });
            }
        },
        { immediate: true }
    );

    const handleCloseSidePanel = () => {
        setSidePanelState({
            type: undefined,
            show: false,
            itemType: undefined,
            itemId: undefined,
        });
    };

    const handleAddNewDate = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.stopPropagation();
        setSidePanelState({
            type: SIDE_PANEL_OPTIONS.ADD_NEW_DATE,
            show: true,
            itemType: undefined,
            itemId: undefined,
        });
    };

    const handleGoBack = () => {
        if (canChangeBasics) financialActions.mutationStepBack();
        else financialActions.setFinancialInformationState(COMPANY_FINANCIAL_INFORMATION_STATE.VIEW_MODE);
    };

    const handleClickApplyChanges = async () => {
        try {
            await handleApplyChanges();
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: message,
            });
        }
    };

    const handleSaveData = async () => {
        try {
            setLoadings({ saveLoading: true });
            await handleApplyChanges(true);
            setLoadings({ saveLoading: false });
            financialActions.setFinancialInformationState(COMPANY_FINANCIAL_INFORMATION_STATE.VIEW_MODE);
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: message,
            });
            setLoadings({ saveLoading: false });
        }
    };

    const handleUseDraftData = () => {
        setExistDraftData(false)
        financialActions.setMutationStep(MUTATION_FINANCIAL_STEP.VIEW_DRAFT_DATA)
    }

    const handleRemoveDraftData = () => {
        removeDraftData({
            companyId: Number(companyId),
            selectedFinancialDataTypeId,
            year: financialActiveDate?.year,
            quarter: financialActiveDate?.quarter || undefined,
        })
    }

    return (
        <>
            {financialDates.length > 0 ? (
                <div className={styles.footer}>
                    {existDraftData && (
                        <div className={styles.draftWrapper}>
                            <span>You can recover unsaved information. Do you want to view unsaved information?</span>
                            <div>
                                <Button
                                    data-cy={'btn-save'}
                                    onClick={handleUseDraftData}
                                    color="primary"
                                    size="s"
                                >
                                    Yes
                                </Button>
                                <Button
                                    onClick={handleRemoveDraftData}
                                    color="secondary"
                                    size="s"
                                >
                                    No
                                </Button>
                            </div>
                        </div>
                    )}
                    <div className={styles.financialDateWrapper}>
                        <div className={styles.scrollableArea}>
                            {financialDates.map((date: IFinancialActiveDate, index: number) => (
                                <FinancialDateCard key={index} date={date} />
                            ))}
                        </div>
                        <Button
                            data-cy={'btn_add-financial-date'}
                            onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => handleAddNewDate(e)}
                            color="tertiary"
                            size="s"
                            customStyle={{ display: 'flex', alignItems: 'center' }}
                        >
                            <i className="ts-plus" style={{ marginRight: '8px' }} />
                            <span>Add Financial Date</span>
                        </Button>
                    </div>
                    <div className={styles.actionWrapper}>
                        <StateManagement />
                        <div className={styles.buttonsWrapper}>
                            <span className={classNames(styles.buttonsWrapperSaved, showSavedDraftNotice && styles.active)}>
                                <i className="ts-check" />
                                Draft Saved
                            </span>
                            {checkPermissionAccess(checkCompanyType(PERMISSION_TYPES.COMPANY_FI_EDIT_APPROVED_DATA, companyType as COMPANY_TYPE_ROUTE)) && (
                                <Button
                                    onClick={() =>
                                        modalActions.addModal(MODAL_TYPES.FINANCIAL_INFORMATION_CURRENCY_YEAR_END, {
                                            header: 'Update Basic Info',
                                        })
                                    }
                                    color="secondary"
                                    size="s"
                                >
                                    Update Basic Info
                                </Button>
                            )}
                            <Button onClick={handleGoBack} color="secondary" size="s" customStyle={{ display: 'flex', alignItems: 'center' }}>
                                Back
                            </Button>
                            <Button
                                data-cy={'btn-apply'}
                                disabled={loadings.applyChangesLoading || financialDataRows.length === 0}
                                loading={loadings.applyChangesLoading}
                                onClick={() => handleClickApplyChanges()}
                                color="tertiary"
                                size="s"
                                customStyle={{ display: 'flex', alignItems: 'center' }}
                            >
                                Apply Changes
                            </Button>
                            <Button
                                data-cy={'btn-save'}
                                loading={loadings.saveLoading}
                                disabled={loadings.saveLoading}
                                color="primary"
                                size="s"
                                onClick={handleSaveData}
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                </div>
            ) : null}
            <SidePanel show={sidePanelState.show} onHide={handleCloseSidePanel} title={'Add Financial Date'}>
                <AddNewFinancialDateForm handleCloseSidePanel={handleCloseSidePanel} />;
            </SidePanel>
        </>
    );
};

export default MainComponentFooter;
