import { useEffect, useState } from 'react';
import { IAxiosErrorResponse } from '../api';

interface AsyncState<T, P , E> {
    data: T | null;
    error: E | null;
    isLoading: boolean;
    isIdle: boolean;
    run: (payload: P) => void;
    setData: (data : any) => void;
}

export const useAsync = <T, P , E = IAxiosErrorResponse>(
    asyncFunction: (payload: P) => Promise<T>,
    options?: {
        onSuccess?: (response?: T) => void;
        onError?: (error?: E) => void;
        immediate?: boolean;
        defaultValue ?: T
    }
): AsyncState<T, P , E> => {
    const [data, setData] = useState<T | null>(options?.defaultValue || null);
    const [error, setError] = useState<E | null>(null);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [isIdle, setIdle] = useState<boolean>(true);

    const fetchData = async (payload: P) => {
        setLoading(true);
        setIdle(false);
        try {
            const result = await asyncFunction(payload);
            setData(result);
            if (options?.onSuccess) options.onSuccess(result);
        } catch (err) {
            setError(err as E);
            if (options?.onError) options.onError(err as E);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (options?.immediate) fetchData(undefined as any);
    }, [options?.immediate]);

    return { data, error, isLoading, isIdle, run: fetchData , setData};
};
