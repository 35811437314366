import React, { useEffect, useState } from 'react';
import { companyService } from 'src/api/services/company';
import InitLoading from 'src/components/App/Loading/InitLoading';
import Button from 'src/components/Kit/Button';
import { modalActions } from 'src/context/modals';
import CustomSearchDropdown from 'src/pages/Company/Show/CompanyFinancialInformation/AddUpdate/components/SelectFinancialData/components/CustomSearchDropdown';
import styles from './DeleteItemModalBody.module.scss';
import { financialInformationService } from 'src/api/services/financial-information-service';
import { toast } from 'src/utils/toast';
import { TOAST_STATUS } from 'src/constants/toast-status';
import { MultiSelectChangeParams } from 'primereact/multiselect';
import { useParams } from 'react-router-dom';
import { COMPANY_TYPE_ROUTE } from 'src/pages/Company/Add/enums/company-type-route';
import { IParams } from 'src/types/params';
import { IItemName } from 'src/pages/Company/Show/CompanyFinancialInformation/types';

interface IProps {
    item: {
        id: number;
        name: string;
        itemTypeID: number;
    };
    updateTable: (id: number) => void;
}
const DeleteItemModalBody: React.FC<IProps> = ({ item, updateTable }) => {
    const { companyType } = useParams<IParams>();
    const { itemTypeID } = item;
    const [itemHasBeenUsed, setItemHasBeenUsed] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(true);
    const [removeItemLoading, setRemoveItemLoading] = useState<boolean>(false);
    const [selectedItemToReplace, setSelectedItemToReplace] = useState<IItemName[]>([]);
    const [error, setError] = useState<string>();

    const handleSelectItem = (selectedItem: IItemName[]) => {
        const tempSelectedItem = structuredClone(selectedItemToReplace);

        if (!selectedItem[0]) {
            setSelectedItemToReplace([]);
            return;
        }

        if (selectedItem[selectedItem.length - 1].id === item.id) {
            setError('You can not replace item with itself!');
            setSelectedItemToReplace([]);
            return;
        }

        tempSelectedItem[0] = selectedItem[selectedItem.length - 1];
        setSelectedItemToReplace(tempSelectedItem);
        setError(undefined);
    };

    const handleRemoveItem = async (replaceItemId: number) => {
        try {
            setRemoveItemLoading(true);
            const data = {
                replace_item_id: replaceItemId,
            };
            await financialInformationService.deleteItem(item.id, replaceItemId && data);
            toast.fire({
                icon: TOAST_STATUS.SUCCESS,
                title: 'Item removed successfully!',
            });

            updateTable(item.id);
            modalActions.removeLastModal();
            setRemoveItemLoading(false);
        } catch (error) {
            console.error(error);
            setRemoveItemLoading(false);
        }
    };

    const handleCheckItemUsed = async () => {
        try {
            let res = await financialInformationService.checkItemIsUsed(item.id);
            if (res.data.data) setItemHasBeenUsed(res.data.data?.is_used);
            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    };

    useEffect(() => {
        handleCheckItemUsed();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (loading) return <InitLoading height={'100px'} />;
    return (
        <div className={styles.body}>
            {itemHasBeenUsed ? (
                <div className={styles.selectorWrapper}>
                    {error && (
                        <div className={styles.errorMessageWrapper}>
                            <span>{error}</span>
                        </div>
                    )}
                    <h4 className={styles.title}>You are deleting the following item: </h4>
                    <span className={styles.itemName}>{item.name}</span>
                    <p className={styles.description}>Select the item you want to replace with the deleted item from the field below</p>
                    <span className={styles.searchBoxTitle}>Replace new item</span>
                    <CustomSearchDropdown
                        value={selectedItemToReplace}
                        addNewOption={false}
                        handleSelectSearchedItem={(e: MultiSelectChangeParams) => handleSelectItem(e.value)}
                        apiItemType={itemTypeID}
                        disabled={false}
                        searchEndPoint={companyService(companyType as COMPANY_TYPE_ROUTE).getFinancialInformationSearchedItems}
                    />
                </div>
            ) : (
                <span>Are you sure to delete this item?</span>
            )}
            <div className={styles.footer}>
                <Button disabled={false} color="secondary" onClick={() => modalActions.removeLastModal()}>
                    No, Canceled
                </Button>
                <Button
                    disabled={(itemHasBeenUsed && selectedItemToReplace.length === 0) || removeItemLoading}
                    color={'danger'}
                    loading={removeItemLoading}
                    onClick={() => handleRemoveItem(selectedItemToReplace[0]?.id)}
                >
                    {"Yes, I'm Sure"}
                </Button>
            </div>
        </div>
    );
};

export default DeleteItemModalBody;
