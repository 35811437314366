import { FORM_INPUTS } from 'src/types/form';
import { IFormGeneratorSchemaType } from 'src/types/form-generator-schema-type';

export type userSchemaFields = 'full_name' | 'email' | 'username' | 'password' | 'role_id' 

export type TUserSchema = Record<string, IFormGeneratorSchemaType>

export const userSchema: TUserSchema = {
    full_name: {
        placeholder: 'Full Name',
        type: FORM_INPUTS.TEXT,
        props: {
            required: true
        }
    },
    email: {
        placeholder: 'Email',
        type: FORM_INPUTS.TEXT,
        props: {
            required: true
        }
    },
    username: {
        placeholder: 'Username',
        type: FORM_INPUTS.TEXT,
        props: {
            required: true
        }
    },
    password: {
        placeholder: 'Password',
        type: FORM_INPUTS.TEXT,
        props: {
            required: true,
            type: "password"
        }
    },
    role_id: {
        placeholder: 'Select Roles',
        type: FORM_INPUTS.SELECT,
        props: {
            required: true
        }
    }
};

