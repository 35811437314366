import * as qs from 'qs';

const paramsSerializer = {
    serialize: (params: Record<string, any>) => qs.stringify(params, { indices: false, arrayFormat: 'indices' }),
};

export const ApiConfig = {
    baseURL: window?.REACT_APP_API_URL || process.env.REACT_APP_API_URL_lOCAL || 'https://api-teaserplatform.dev.teaser.co/api/admin/', //process.env.REACT_APP_API_URL,
    websiteUrl : window?.WEBSITE_URL || "https://website.dev.teaser.co",
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    },
    timeout: 10 * 1000,
    paramsSerializer,
    withCredentials: false,
};
