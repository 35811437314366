import { arabicCharactersRegex, urlRegex } from 'src/constants/regex';
import { getMaxError, getMinError } from 'src/utils/get-yup-errors';
import { string, object } from 'yup';

export const validationSchema = () =>
    object().shape({
        name: string().required('Name is required'),
        arabic_name: string().required('Arabic Name is required')
            .min(3, getMinError(3, 'Arabic Name'))
            .max(255, getMaxError(3, 'Arabic Name'))
            .matches(arabicCharactersRegex, 'Arabic Name must only accept Arabic characters'),
        gender: string().required('Gender is required'),
        country_id: string().required('Country is required'),
        email: string().when({
            is: (val: string | null) => val && val.length > 0,
            then: string().email(),
        }),
        linkedin: string().when({
            is: (url: string) => url && url.length > 0,
            then: string().matches(urlRegex, 'Enter correct url!'),
        })
    });