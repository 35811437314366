import { ISelectBox } from "src/api/types/base-types";
import { PARENT_COMPANY_FINANCIAL_SOURCE } from "../enums/parent-company-financial-source";

export const parentCompanyFinancialSource: ISelectBox[] = [
    { label: PARENT_COMPANY_FINANCIAL_SOURCE.FINANCIALS, value: '1' },
    { label: PARENT_COMPANY_FINANCIAL_SOURCE.NEWS, value: '2' },
    { label: PARENT_COMPANY_FINANCIAL_SOURCE.WEBSITE, value: '3' },
    { label: PARENT_COMPANY_FINANCIAL_SOURCE.DISCLOSURE, value: '4' },
    { label: PARENT_COMPANY_FINANCIAL_SOURCE.OTHER, value: '5' },
];
