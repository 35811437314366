import { API_ROUTES } from 'src/constants/api-routes';
import { AxiosInstance } from '../index';
import { IAxiosResponse } from '../types/axios-response';
import {
    IFinancialInformationCategoryPayload,
    IFinancialInformationCategoryResponse,
    IFinancialInformationGetCategoryResponse,
    IFinancialInformationItemsResponse,
    IFinancialInformationStoreCategoryPayload,
    IFinancialInformationStoreCategoryResponse,
    IFinancialInformationSubCategoryResponse,
    IFinancialInformationSubCategoryPayload
} from 'src/api/types/financial-information';
import { IDataTableResponse } from '../types/table';
import { IDataTablePayload } from 'src/api/types/table';
import { ISelectBox, IDropDownPayload } from '../types/base-types';

export const financialInformationService = {
    getAllCategories(data?: IFinancialInformationCategoryPayload) {
        return AxiosInstance.get<IDataTableResponse<IFinancialInformationCategoryResponse>>(API_ROUTES.COMPANY.FINANCIAL_INFORMATION_CATEGORIES, {
            params: data,
        });
    },
    categoriesSelectBox(params?: IDropDownPayload) {
        return AxiosInstance.get<IAxiosResponse<ISelectBox[]>>(API_ROUTES.COMPANY.FINANCIAL_INFORMATION_SEARCHED_CATEGORIES, {
            params,
        });
    },
    subCategoriesSelectBox(params?: IFinancialInformationSubCategoryPayload) {
        return AxiosInstance.get<IAxiosResponse<ISelectBox[]>>(API_ROUTES.COMPANY.FINANCIAL_INFORMATION_SEARCHED_SUB_CATEGORIES, {
            params,
        });
    },
    typesSelectBox(params?: IDropDownPayload) {
        return AxiosInstance.get<IAxiosResponse<ISelectBox[]>>(API_ROUTES.COMPANY.FINANCIAL_INFORMATION_TYPES, {
            params,
        });
    },
    getAllSubCategories(data?: IDataTablePayload) {
        return AxiosInstance.get<IDataTableResponse<IFinancialInformationSubCategoryResponse>>(API_ROUTES.COMPANY.FINANCIAL_INFORMATION_SUB_CATEGORIES, {
            params: data,
        });
    },
    createNewCategory(data?: IFinancialInformationStoreCategoryPayload) {
        return AxiosInstance.post<IAxiosResponse<IFinancialInformationStoreCategoryResponse[]>>(API_ROUTES.COMPANY.FINANCIAL_INFORMATION_CATEGORIES, data);
    },
    createNewSubCategory(data?: IFinancialInformationStoreCategoryPayload) {
        return AxiosInstance.post<IAxiosResponse<IFinancialInformationStoreCategoryResponse[]>>(API_ROUTES.COMPANY.FINANCIAL_INFORMATION_SUB_CATEGORIES, data);
    },
    deleteCategory(id: number) {
        return AxiosInstance.delete<IAxiosResponse>(API_ROUTES.COMPANY.FINANCIAL_INFORMATION_SINGLE_CATEGORY(id));
    },
    deleteSubCategory(id: number) {
        return AxiosInstance.delete<IAxiosResponse>(API_ROUTES.COMPANY.FINANCIAL_INFORMATION_SINGLE_SUB_CATEGORY(id));
    },
    updateCategory(id: number, data?: IFinancialInformationStoreCategoryPayload) {
        return AxiosInstance.put<IAxiosResponse>(API_ROUTES.COMPANY.FINANCIAL_INFORMATION_SINGLE_CATEGORY(id), data);
    },
    updateSubCategory(id: number, data?: IFinancialInformationStoreCategoryPayload) {
        return AxiosInstance.put<IAxiosResponse>(API_ROUTES.COMPANY.FINANCIAL_INFORMATION_SINGLE_SUB_CATEGORY(id), data);
    },
    getCategory(id: number) {
        return AxiosInstance.get<IAxiosResponse<IFinancialInformationGetCategoryResponse>>(API_ROUTES.COMPANY.FINANCIAL_INFORMATION_SINGLE_CATEGORY(id));
    },
    getSubCategory(id: number) {
        return AxiosInstance.get<IAxiosResponse<IFinancialInformationGetCategoryResponse>>(API_ROUTES.COMPANY.FINANCIAL_INFORMATION_SINGLE_SUB_CATEGORY(id));
    },
    
    getAllItems(data?: IDataTablePayload) {
        return AxiosInstance.get<IDataTableResponse<IFinancialInformationItemsResponse>>(API_ROUTES.COMPANY.FINANCIAL_INFORMATION_CREATE_ITEM, {
            params: data,
        });
    },
    checkItemIsUsed(id: number) {
        return AxiosInstance.get<IAxiosResponse<any>>(API_ROUTES.COMPANY.IS_ITEM_USED_IN_SOME_COMPANY(id));
    },
    deleteItem(id: number, data?: any) {
        if (data) {
            return AxiosInstance.deleteByBody<IAxiosResponse<any>>(API_ROUTES.COMPANY.DELETE_ITEM(id), data);
        } else {
            return AxiosInstance.delete<IAxiosResponse<any>>(API_ROUTES.COMPANY.DELETE_ITEM(id));
        }
    },
};
