import { classNames } from 'primereact/utils';
import style from '../StatusDropDown.module.scss';
import { statusColor } from '../constants/status-color';
import { DROPDOWN_STATUS } from '../enums/dropdown-status';

interface IStatusDropDownItemProps {
    label?: string;
    status: DROPDOWN_STATUS;
    item?: boolean
}

const StatusDropDownItem: React.FC<IStatusDropDownItemProps> = ({ label, status, item }) => (
    <div className={classNames(style.statusDropDownItem, item ? style.statusDropDownList : '')}>
        <small style={{ backgroundColor: statusColor[status] }}></small>
        <span>{label}</span>
    </div>
);

export default StatusDropDownItem;
