import { SVGProps } from 'react';

const PlusIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none" {...props}>
            <path d="M4 12H20M12 4V20" stroke={props.stroke || 'currentColor' || '#000000'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default PlusIcon;
