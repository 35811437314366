import { SVGProps } from 'react';

const ArrowRightIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M10 16L14 12L10 8" stroke={props.stroke || 'currentColor' || '#3861FB'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default ArrowRightIcon;
