import { arabicCharactersRegex, urlRegex, latitudeRegex, longitudeRegex } from 'src/constants/regex';
import * as yup from 'yup'

export const contactInformationValidationSchema = yup.object().shape({
  email: yup.string().email(),
  website: yup.string().when({
    is: (url: string) => url && url.length > 0,
    then: yup.string().matches(urlRegex, 'Enter correct url!'),
  }),
  twitter: yup.string().when({
    is: (url: string) => url && url.length > 0,
    then: yup.string().matches(urlRegex, 'Enter correct url!'),
  }),
  youtube: yup.string().when({
    is: (url: string) => url && url.length > 0,
    then: yup.string().matches(urlRegex, 'Enter correct url!'),
  }),
  instagram: yup.string().when({
    is: (url: string) => url && url.length > 0,
    then: yup.string().matches(urlRegex, 'Enter correct url!'),
  }),
  linkedin: yup.string().when({
    is: (url: string) => url && url.length > 0,
    then: yup.string().matches(urlRegex, 'Enter correct url!'),
  }),
  arabic_address: yup.string()
    .when({
      is: (arabic_address: string) => arabic_address && arabic_address.length > 0,
      then: yup.string().matches(arabicCharactersRegex, 'Arabic Address must only accept Arabic characters'),
    }),
  latitude: yup.string().test({
      name: 'check-latitude',
      exclusive: false,
      params: {},
      test: function (value) {
        if (value && value.length > 0) {
          const longitude = this.parent.longitude;
          if (!longitude || longitude.length === 0) {
            return this.createError({ path: this.path, message: 'longitude is required!' });;
          }
          if (!latitudeRegex.test(value)) {
            return this.createError({ path: this.path, message: 'Latitude must be a valid value' });
          }
        }
        return true;
      },
    }),
  longitude: yup.string().test({
      name: 'check-longitude',
      exclusive: false,
      params: {},
      test: function (value) {
        if (value && value.length > 0) {
          const latitude = this.parent.latitude;
          if (!latitude || latitude.length === 0) {
            return this.createError({ path: this.path, message: 'Latitude is required!' });;
          }
          if (!longitudeRegex.test(value)) {
            return this.createError({ path: this.path, message: 'Longitude must be a valid value' });
          }
        }
        return true;
      },
    }),
});
