import ROUTE_CONSTANTS from 'src/Routes/constants/route-constants';
import { IInnerMenuConfig } from 'src/components/App/InnerMenu/types/IInnerMenuConfig';
import { PERMISSION_TYPES } from 'src/enums/permissions';
import { checkCompanyType } from 'src/utils/check-company-type';
import { COMPANY_TYPE_ROUTE } from '../../Add/enums/company-type-route';

export const companyInnerMenuConfig = (companyType: COMPANY_TYPE_ROUTE, companyId: number): IInnerMenuConfig[] => {
    const isNonProfileCompany = companyType === COMPANY_TYPE_ROUTE.NON_PROFILE;
    return [
        {
            id: 1,
            route: ROUTE_CONSTANTS.COMPANIES.TYPE.SHOW.PROFILE.ROOT.BY_COMPANY_ID(companyType, companyId).ABSOLUTE,
            title: 'Profile',
            disabled: false,
            permission: checkCompanyType(PERMISSION_TYPES.COMPANY_INFORMATION_SINGLE_VIEW, companyType),
        },
        {
            id: 2,
            route: ROUTE_CONSTANTS.COMPANIES.TYPE.SHOW.FINANCIAL_INFORMATION.ROOT.BY_COMPANY_ID(companyType, companyId).ABSOLUTE,
            title: 'Financial Information',
            disabled: isNonProfileCompany,
            permission: checkCompanyType(PERMISSION_TYPES.COMPANY_FI_LIST_VIEW, companyType),
        },
        {
            id: 3,
            route: ROUTE_CONSTANTS.COMPANIES.TYPE.SHOW.FINANCIAL_REPORT.ROOT.BY_COMPANY_ID(companyType, companyId).ABSOLUTE,
            title: 'Financial Reports',
            disabled: isNonProfileCompany,
            permission: checkCompanyType(PERMISSION_TYPES.COMPANY_FINANCIAL_REPORT_LIST_VIEW, companyType),
        },
        {
            id: 4,
            route: ROUTE_CONSTANTS.COMPANIES.TYPE.SHOW.FINANCIAL_RATIO.ROOT.BY_COMPANY_ID(companyType, companyId).ABSOLUTE,
            title: 'Financial Ratio',
            disabled: isNonProfileCompany,
        },
        {
            id: 5,
            route: ROUTE_CONSTANTS.COMPANIES.TYPE.SHOW.PEOPLE.ROOT.BY_COMPANY_ID(companyType, companyId).ABSOLUTE,
            title: 'People',
            disabled: isNonProfileCompany,
            permission: checkCompanyType(PERMISSION_TYPES.COMPANY_PEOPLE_LIST_VIEW, companyType),
        },
        {
            id: 6,
            route: ROUTE_CONSTANTS.COMPANIES.TYPE.SHOW.DIVIDENDS.ROOT.BY_COMPANY_ID(companyType, companyId).ABSOLUTE,
            title: 'Dividends',
            disabled: isNonProfileCompany,
            permission: checkCompanyType(PERMISSION_TYPES.COMPANY_DIVIDEND_LIST_VIEW, companyType),
        },
        {
            id: 7,
            route: null,
            title: 'Team Management',
            disabled: true,
        },
        {
            id: 8,
            route: ROUTE_CONSTANTS.COMPANIES.TYPE.SHOW.SHAREHOLDERS.ROOT.BY_COMPANY_ID(companyType, companyId).ABSOLUTE,
            title: 'Shareholders',
            disabled: isNonProfileCompany,
            permission: checkCompanyType(PERMISSION_TYPES.COMPANY_SHARE_HOLDER_LIST_VIEW, companyType),
        },
        {
            id: 9,
            route: null,
            title: 'Branches',
            disabled: true,
        },
        {
            id: 10,
            route: ROUTE_CONSTANTS.COMPANIES.TYPE.SHOW.HOLDINGS.ROOT.BY_COMPANY_ID(companyType, companyId).ABSOLUTE,
            title: 'Holdings',
            disabled: isNonProfileCompany,
            permission: checkCompanyType(PERMISSION_TYPES.COMPANY_HOLDING_LIST_VIEW, companyType),
        },
        {
            id: 11,
            route: null,
            title: 'Disclosures',
            disabled: true,
        },
        {
            id: 12,
            route: ROUTE_CONSTANTS.COMPANIES.TYPE.SHOW.NEWS.ROOT.BY_COMPANY_ID(companyType,companyId).ABSOLUTE,
            title: 'News',
            disabled: isNonProfileCompany,
            permission: PERMISSION_TYPES.NEWS_LIST,
        },
        {
            id: 13,
            route: null,
            title: 'Locations',
            disabled: true,
        },
        {
            id: 14,
            route: null,
            title: 'Transactions',
            disabled: true,
        },
        {
            id: 15,
            route: null,
            title: 'Documents',
            disabled: true,
        },
        {
            id: 16,
            route: null,
            title: 'Projects',
            disabled: true,
        },
        {
            id: 17,
            route: null,
            title: 'Publications',
            disabled: true,
        },
        {
            id: 18,
            route: null,
            title: 'Public Offering',
            disabled: true,
        },
        {
            id: 19,
            route: null,
            title: 'Trends',
            disabled: true,
        },
        {
            id: 20,
            route: null,
            title: 'Commercial Brands',
            disabled: true,
        },
        {
            id: 21,
            route: null,
            title: 'Managed Funds',
            disabled: true,
        },
        {
            id: 22,
            route: ROUTE_CONSTANTS.COMPANIES.TYPE.SHOW.COMMENTS.ROOT.BY_COMPANY_ID(companyType, companyId).ABSOLUTE,
            title: 'Comments',
            disabled: false,
            permission: checkCompanyType(PERMISSION_TYPES.COMPANY_COMMENT_LIST_VIEW, companyType),
        },
        {
            id: 23,
            route: ROUTE_CONSTANTS.COMPANIES.TYPE.SHOW.PARENT_COMPANY_FINANCIAL.ROOT.BY_COMPANY_ID(companyType, companyId).ABSOLUTE,
            title: 'Parent Company Financial',
            disabled: false,
            permission: checkCompanyType(PERMISSION_TYPES.COMPANY_PARENT_COMPANY_FINANCIAL_LIST_VIEW, companyType),
        },
        {
            id: 24,
            route: null,
            title: 'Financial News',
            disabled: true,
        },
        {
            id: 25,
            route: null,
            title: 'Quality Assurance',
            disabled: true,
        },
        {
            id: 26,
            route: ROUTE_CONSTANTS.COMPANIES.TYPE.SHOW.FEEDS_CONFIG.ROOT.BY_COMPANY_ID(companyType, companyId).ABSOLUTE,
            title: 'Feeds Config',
            disabled: isNonProfileCompany,
            permission: PERMISSION_TYPES.COMPANY_FEED,
        },
    ];
};
