import { arabicCharactersRegex } from 'src/constants/regex';
import { ITEM_TYPE_ENUM, ITEM_VALUE_TYPE_OPTIONS_ENUM } from 'src/enums/company/financialInformation/financialItem';
import { getMaxError, getMinError } from 'src/utils/get-yup-errors';
import { string, object } from 'yup';

export const validationSchema = (selectedFinancialDataTypeId: number | undefined) =>
    object().shape({
        name: string().required('Name is required'),
        arabic_name: string().when({
            is: (arabic_name: string) => arabic_name && arabic_name.length > 0,
            then: string()
                .min(3, getMinError(3, 'Arabic Name'))
                .max(255, getMaxError(3, 'Arabic Name'))
                .matches(arabicCharactersRegex, 'Arabic Name must only accept Arabic characters'),
        }),
        number_type: string().when('item_value_type', {
            is: String(ITEM_VALUE_TYPE_OPTIONS_ENUM.NUMERIC),
            then: string().nullable().required('Numeric Type is required'),
            otherwise: string().nullable(),
        }),
        item_value_type: string().when('item_type', {
            is: ITEM_TYPE_ENUM.ITEM,
            then: string().nullable().required('Item value type is required'),
            otherwise: string().nullable(),
        }),
        item_data_type: string().when(['selectedFinancialDataTypeId'], () => {
            if (selectedFinancialDataTypeId === undefined) {
                return string().required('Item data type is required');
            }
            return string().notRequired();
        }),
    });
