import React, { useEffect, useState } from 'react';
import { classNames } from 'primereact/utils';
import { companyService } from 'src/api/services/company';
import { toast } from 'src/utils/toast';
import { TOAST_STATUS } from 'src/constants/toast-status';
import CloseIcon from 'src/assets/Icons/CloseIcon';
import { useFinancialDataContext } from '../../hooks/useFinancialDataContext';
import { modalActions } from 'src/context/modals';
import { MODAL_TYPES } from 'src/types/modals';
import { getAxiosError } from 'src/utils/get-axios-error';
import styles from './FinancialDateCard.module.scss';
import { FINANCIAL_DATE_QUARTER_DICTIONARY, FINANCIAL_INFORMATION_STATUS_COLORS } from '../../../../../constants';
import { IFinancialActiveDate } from '../../../../../types';
import { useCheckPermissionAccess } from 'src/hooks/useCheckPermissionAccess';
import { FINANCIAL_INFORMATION_STATUS } from 'src/enums/company/financialInformation/financialItem';
import { PERMISSION_TYPES } from 'src/enums/permissions';
import Button from 'src/components/Kit/Button';
import { financialActions, useFinancial, useFinancialCompanyId, useFinancialSelectedType } from '../../../../../../../../../context/companies';
import { useParams } from 'react-router-dom';
import { IParams } from 'src/types/params';
import { COMPANY_TYPE_ROUTE } from 'src/pages/Company/Add/enums/company-type-route';
import { checkCompanyType } from 'src/utils/check-company-type';

interface IProps {
    date: IFinancialActiveDate;
}

const FinancialDateCard: React.FC<IProps> = ({ date }) => {
    const { companyType } = useParams<IParams>();
    const { checkPermissionAccess } = useCheckPermissionAccess();
    const companyId = useFinancialCompanyId();
    const selectedFinancialDataTypeId = useFinancialSelectedType();
    const { activeDate, financialDates } = useFinancial((global) => ({
        activeDate: global.financialActiveDate,
        financialDates: global.financialDates,
    }));
    const { handleCheckSomeDataNotSaved, handleApplyChanges, handleGetAllDates, handleGetRowsFromApi } = useFinancialDataContext();
    const [disableDeleteCard, setDisableDeleteCard] = useState<boolean>(false);

    const handleGetRowsBasedOnDate = async (activeDate: IFinancialActiveDate) => {
        if (!activeDate) return;
        try {
            financialActions.setLoadings({ financialRowsByDate: true });
            let res = await handleGetRowsFromApi(selectedFinancialDataTypeId, {
                year: activeDate?.year,
                quarter: activeDate?.quarter || null,
                status: activeDate.status,
            });

            if (!res) {
                // TODO: we should write a promise to reject, not throwing message,
                // This condition happens when another admin just delete this date and backend is returning error.
                const errorMessage = { message: 'This date has been removed by another admin' };
                throw errorMessage;
            }
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: message,
            });
            const errorMessage = { message: 'This date has been removed by another admin' };
            throw errorMessage;
        } finally {
            financialActions.setLoadings({ financialRowsByDate: false });
        }
    };

    const handleChangeDate = async () => {
        if (handleCheckActiveYouAreOnActiveDate()) return;
        if (handleCheckSomeDataNotSaved()) {
            modalActions.addModal(MODAL_TYPES.CONFIRMATION_MODAL, {
                header: 'Change Confirm',
                children: (
                    <div className={styles.nullItemWarningWrapper}>
                        <span className={styles.bodyText}>Current page has unsaved data, do you want to save?</span>
                        <div className={styles.actionWrapper}>
                            <Button color="danger" customStyle={{ width: '200px' }} onClick={() => handleConfirmChangeDate(true)}>
                                <span>Discard And Go</span>
                            </Button>
                            <Button color="primary" customStyle={{ width: '200px' }} onClick={() => handleConfirmChangeDate(false)}>
                                <span>Save And Go</span>
                            </Button>
                        </div>
                    </div>
                ),
                text: '',
            });

            return;
        }
        try {
            await handleGetRowsBasedOnDate(date);
            financialActions.setFinancialActiveDate(date);
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: message,
            });
        }
    };

    const handleCheckActiveYouAreOnActiveDate = () => {
        return activeDate?.year === date?.year && activeDate?.quarter === date?.quarter;
    };

    const handleRemoveDate = async (e: any) => {
        e.stopPropagation();

        if (handleCheckActiveYouAreOnActiveDate()) {
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: 'You can not remove active date',
            });
            return;
        }
        modalActions.addModal(MODAL_TYPES.CONFIRMATION_MODAL, {
            header: 'Delete Item',
            text: 'Are you sure to delete this Date?',
            handleAction: handleConfirmDelete,
            actionButtonText: 'Delete',
        });
    };

    const handleConfirmDelete = async () => {
        try {
            await companyService(companyType as COMPANY_TYPE_ROUTE).deleteDate(companyId, { year: date.year, quarter: date.quarter });
            await handleGetAllDates(selectedFinancialDataTypeId);

            toast.fire({
                icon: TOAST_STATUS.SUCCESS,
                title: 'Date Deleted successfully',
            });
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: message,
            });
        }
    };

    const handleConfirmChangeDate = async (discardChanges: boolean) => {
        modalActions.clearModals();
        try {
            if (!discardChanges) await handleApplyChanges();
            await handleGetRowsBasedOnDate(date);
            financialActions.setFinancialActiveDate(date);
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: message,
            });
        }
    };

    const handleSetMyPermissions = () => {
        if (!checkPermissionAccess(checkCompanyType(PERMISSION_TYPES.COMPANY_FI_DELETE_APPROVED_DATA, companyType as COMPANY_TYPE_ROUTE))) {
            // Hint: if financial date is approved and admin does not have delete approved financial date permission.
            // admin can not delete it.

            // IMPORTANT HINT: Im not setting setDisableDeleteCard to false anywhere,
            // cause if a date once become approved, even if in other financial data is not approve,
            // admin should not remove it as it will also remove the approved one without proper permission and its a bug.
            if (date?.status === FINANCIAL_INFORMATION_STATUS.APPROVED) setDisableDeleteCard(true);
        }
        if (!checkPermissionAccess(checkCompanyType(PERMISSION_TYPES.COMPANY_FI_DELETE, companyType as COMPANY_TYPE_ROUTE))) {
            //same logic but for not approved data
            if (date?.status === FINANCIAL_INFORMATION_STATUS.NOT_APPROVED) setDisableDeleteCard(true);
        }
    };

    useEffect(() => {
        handleSetMyPermissions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [financialDates]);

    return (
        <>
            <div className={classNames(styles.financialDateCardWrapper, handleCheckActiveYouAreOnActiveDate() && styles.active)} onClick={handleChangeDate}>
                <div
                    className={'bullet-of-status'}
                    style={{
                        backgroundColor: FINANCIAL_INFORMATION_STATUS_COLORS[date.status],
                    }}
                />
                <span className={styles.text}>
                    {date?.year} / {date?.quarter ? FINANCIAL_DATE_QUARTER_DICTIONARY[date?.quarter] : 'Annual'}
                </span>
                {!disableDeleteCard && (
                    <div onClick={(e: any) => handleRemoveDate(e)} className="mt-1">
                        <CloseIcon />
                    </div>
                )}
            </div>
        </>
    );
};

export default FinancialDateCard;
