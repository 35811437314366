import { FC } from 'react';
import style from './AddUpdate.module.scss';
import { targetComponent } from './components/targetComponent';
import { useFinancial, useFinancialMutationStep } from '../../../../../context/companies';
import { useMutationInitializer } from './hooks/useMutationInitializer';
import InitLoading from '../../../../../components/App/Loading/InitLoading';

const AddUpdateFinancialInformation: FC = () => {
    useMutationInitializer();
    const step = useFinancialMutationStep();
    const isLoadingFinancialTypes = useFinancial((global) => global.financialTypesLoading);

    const Component = targetComponent[step];

    if (isLoadingFinancialTypes) return <InitLoading />;
    
    return (
        <div className={style['financial-information']}>
            <Component />
        </div>
    );
};

export default AddUpdateFinancialInformation;
