import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import GeneralLayoutWrapper from '../GeneralLayoutWrapper/index';
import { useUser } from 'src/context/global-context';
import ROUTE_CONSTANTS from 'src/Routes/constants/route-constants';
import style from './AuthLayoutContent.module.scss';

const AuthLayoutContent: React.FC = () => {
    const user = useUser();
    
    // # if user is authenticated
    if (user) {
        return <Navigate to={ROUTE_CONSTANTS.DASHBOARD.ROOT.ABSOLUTE} />;
    }

    // # if not authenticated
    return (
        <>
            <section className={style['auth-content']}>
                <Outlet />
            </section>
        </>
    );
};

const AuthLayout: React.FC = () => {
    return (
        <GeneralLayoutWrapper>
            <AuthLayoutContent />
        </GeneralLayoutWrapper>
    );
};

export default AuthLayout;
