import { MUTATION_FINANCIAL_STEP } from '../../enums';
import CompanyFinancialInformationDraftViewMode from './DraftView';
import Landing from './Landing';
import SelectCurrency from './SelectCurrency';
import SelectFinancialData from './SelectFinancialData';
import SelectFinancialTemplate from './SelectFinancialTemplate';

export const targetComponent: Record<MUTATION_FINANCIAL_STEP, React.FC> = {
    [MUTATION_FINANCIAL_STEP.LANDING]: Landing,
    [MUTATION_FINANCIAL_STEP.SELECT_TEMPLATE_DATA]: SelectFinancialTemplate,
    [MUTATION_FINANCIAL_STEP.SELECT_CURRENCY]: SelectCurrency,
    [MUTATION_FINANCIAL_STEP.SELECT_FINANCIAL_DATA]: SelectFinancialData,
    [MUTATION_FINANCIAL_STEP.VIEW_DRAFT_DATA]: CompanyFinancialInformationDraftViewMode,
};
