import { API_ROUTES } from 'src/constants/api-routes';
import { AxiosInstance } from '../index';
import { IAxiosResponse } from '../types/axios-response';
import { IStockExchangeListResponse, TCreateStockExchangePayload, TUpdateStockExchangePayload } from '../types/stock-exchanges';
import { ISelectBox, IDropDownPayload } from '../types/base-types';
import { IDataTableResponse, IDataTablePayload } from 'src/api/types/table';

export const stockExchangeService = {
    getAllStockExchange(params?: IDataTablePayload) {
        return AxiosInstance.get<IDataTableResponse<IStockExchangeListResponse>>(API_ROUTES.STOCK_EXCHANGES_SERVICE.GET_ALL_STOCK_EXCHANGES, { params });
    },
    getOne(id: number) {
        return AxiosInstance.get<IAxiosResponse<IStockExchangeListResponse>>(API_ROUTES.STOCK_EXCHANGES_SERVICE.GET_ONE(id));
    },
    createNew(data: TCreateStockExchangePayload) {
        return AxiosInstance.post<IAxiosResponse<IStockExchangeListResponse>>(API_ROUTES.STOCK_EXCHANGES_SERVICE.ADD_NEW, data);
    },
    update(id: number, data: TUpdateStockExchangePayload) {
        return AxiosInstance.put<IAxiosResponse<{}>>(API_ROUTES.STOCK_EXCHANGES_SERVICE.UPDATE(id), data);
    },
    delete(id: number) {
        return AxiosInstance.delete<IAxiosResponse<{}>>(API_ROUTES.STOCK_EXCHANGES_SERVICE.DELETE(id));
    },
    selectBox(params?: IDropDownPayload) {
        return AxiosInstance.get<IAxiosResponse<ISelectBox[]>>(API_ROUTES.STOCK_EXCHANGES_SERVICE.GET_ALL_DROPDOWN_OPTIONS, { params });
    },
};
