import { SVGProps } from 'react';

const UsersIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
            <path
                d="M13.3327 16.6667V15.8333C13.3327 13.9924 11.8403 12.5 9.99935 12.5H4.99935C3.1584 12.5 1.66602 13.9924 1.66602 15.8333V16.6667"
                stroke={props.stroke || 'currentColor' || '#323232'}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <ellipse
                cx="7.49935"
                cy="5.83333"
                rx="3.33333"
                ry="3.33333"
                stroke={props.stroke || 'currentColor' || '#323232'}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.3333 16.6667V15.8333C18.3333 13.9924 16.8409 12.5 15 12.5V12.5"
                stroke={props.stroke || 'currentColor' || '#323232'}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.5 2.5C14.3409 2.5 15.8333 3.99238 15.8333 5.83333C15.8333 7.67428 14.3409 9.16667 12.5 9.16667"
                stroke={props.stroke || 'currentColor' || '#323232'}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default UsersIcon;
