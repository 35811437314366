import { Link } from 'react-router-dom';
import { IFunctionalComponentProps } from 'src/types/functional-component';
import style from './Button.module.scss';
import { HTMLAttributes } from 'react';
import { classNames } from 'primereact/utils';

interface IProps extends IFunctionalComponentProps, HTMLAttributes<HTMLButtonElement> {
    color?: 'primary' | 'secondary' | 'error' | 'success' | 'tertiary' | 'quaternary' | 'danger';
    link?: boolean;
    to?: string;
    size?: 'full' | 'xs' | 's' | 'm' | 'l';
    position?: 'left' | 'right';
    loading?: boolean;
    onClick?: React.MouseEventHandler<HTMLElement>;
    disabled?: boolean;
    type?: 'button' | 'submit' | 'reset' | undefined;
    customStyle?: any;
    iconOnly?: boolean;
}

const Button: React.FC<IProps> = ({
    type,
    customStyle,
    link = false,
    to = '',
    color = 'secondary',
    size = 'm',
    position,
    loading = false,
    children,
    onClick,
    disabled = false,
    iconOnly = false,
    className,
    ...props
}) => {
    const btnClassNames = classNames(
        className,
        style.button,
        style[`button--${color}`],
        style[`button--${size}`],
        loading && style[`button--loading`],
        position && position === 'left' ? style['button--left'] : style['button--right'],
        iconOnly && style['button--icon-only'],
        disabled && style['button--disabled']
    );

    return link ? (
        <Link className={btnClassNames} to={to}>
            {children}
        </Link>
    ) : (
        <button style={{ ...customStyle }} type={type} disabled={disabled} className={btnClassNames} onClick={onClick} {...props}>
            {children}
        </button>
    );
};

export default Button;
