import { Calendar } from 'primereact/calendar';
import { ITableBaseFilterData } from 'src/api/types/table';
import { FILTER_TYPES } from 'src/enums/table';

const DateFilter: React.FC<ITableBaseFilterData<FILTER_TYPES.DATE>> = ({ defaultValue, name, label, onChange, config }) => {

    return (
        <Calendar
            placeholder={`Search in ${label}`}
            value={defaultValue ? new Date(defaultValue) : ''}
            minDate={config.min ? new Date(config.min) : undefined}
            maxDate={config.max ? new Date(config.max) : undefined}
            dateFormat={config.format || undefined}
            view={config.view || undefined}
            onChange={onChange}
        />
    );
};

export default DateFilter;
