import { Calendar, CalendarChangeParams } from 'primereact/calendar';
import { classNames } from 'primereact/utils';
import { ControllerRenderProps, FieldError, FieldValues } from 'react-hook-form';
import { DateDynamicFieldSchema } from '../../../../types/form-generator-schema-type';
import { DATE_TYPE } from '../../../../types/form';
import styles from './FormDynamicDate.module.scss';
import { UseFormSetValue } from 'react-hook-form/dist/types/form';
import { dateToDynamicDateFormat } from '../../../../utils/timestamp-to-date-format';
import { RedError } from 'src/assets/Icons/RedError';

interface IProps {
    field: ControllerRenderProps<FieldValues, string>;
    label: string;
    placeholder: string;
    className: string;
    errorMessage: FieldError;
    props: DateDynamicFieldSchema['props'];
    setValue: UseFormSetValue<any>;
}
const DateItems = [
    {
        label: 'Date',
        value: DATE_TYPE.DATE,
    },
    {
        label: 'Year',
        value: DATE_TYPE.YEAR,
    },
    {
        label: 'Month',
        value: DATE_TYPE.MONTH,
    },
];
const FormDateDynamicPicker = ({ field, label, placeholder, className, errorMessage, props = {}, setValue }: IProps) => {
    const byDateType: Record<DATE_TYPE, string | undefined> = {
        [DATE_TYPE.DATE]: undefined,
        [DATE_TYPE.YEAR]: 'year',
        [DATE_TYPE.MONTH]: 'month',
    };

    const onDateTypeChange = (dateType: DATE_TYPE) => {
        field.onChange({
            ...field.value,
            dateType,
        });
    };

    const onCalenderChange = (e: CalendarChangeParams) => {
        field.onChange({
            ...field.value,
            date: e.target.value,
        });
    };

    return (
        <div className={classNames('col-12 form-field', className ? className : '')}>
            <label className={field.name + '_label'}>
                {label || placeholder} : {props.required ? <span className="required">*</span> : ''}
            </label>
            <div className={classNames(styles.wrapperField)}>
                <div className={styles.dateWrapper}>
                    <span>Select Type Of {label || placeholder}</span>
                    <ul>
                        {DateItems.map((dateItem) => (
                            <li
                                key={`date__${dateItem.value}`}
                                onClick={() => onDateTypeChange(dateItem.value)}
                                className={classNames(dateItem.value === String(field.value?.dateType) && styles.active)}
                            >
                                {dateItem.label}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className={classNames(styles.dateWrapperField)}>
                <Calendar
                    placeholder={placeholder}
                    {...props}
                    {...field}
                    value={field.value?.date}
                    onChange={onCalenderChange}
                    {...(field.value?.date && field.value?.dateType
                        ? {
                              dateFormat: dateToDynamicDateFormat(field.value?.date, field.value?.dateType || DATE_TYPE.DATE),
                          }
                        : {})}
                    view={byDateType[(field.value?.dateType || DATE_TYPE.DATE) as DATE_TYPE]}
                />
                {errorMessage ? (
                    <div className={'form-input-error'}>
                        <RedError />
                        <span>{errorMessage.message}</span>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default FormDateDynamicPicker;
