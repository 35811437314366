import styles from './FileDialog.module.scss';
import { Paginator, PaginatorPageState } from 'primereact/paginator';
import React from 'react';
import Button from '../Button';

type Props = {
    onPageChanged: (pageNumber: PaginatorPageState) => void;
    onClickInsert: () => void;
    canInsert: boolean;
    isLoading: boolean;
    per_page: number;
    first_row: number;
    total_records: number;
};
export const Pagination = (props: Props) => {
    const onPageChange = (e: PaginatorPageState) => {
        props.onPageChanged(e);
    };

    return (
        <div className={styles.pagination}>
            {/*<Paginator className={styles.paginator} rows={40} first={80} totalRecords={160} onPageChange={onPageChange} />*/}
            <Paginator
                className={styles.paginator}
                rows={props.per_page}
                first={props.first_row}
                totalRecords={props.total_records}
                onPageChange={onPageChange}
            />

            <Button loading={props.isLoading} disabled={!props.canInsert} color={'primary'} onClick={props.onClickInsert}>
                Insert Selected
            </Button>
        </div>
    );
};
