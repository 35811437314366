import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import FormFooterActions from 'src/components/Kit/FormFooterActions';
import { Controller, useForm } from 'react-hook-form';
import { ILoginFormData } from './types';
import { yupResolver } from '@hookform/resolvers/yup';
import { loginValidationSchema } from './validation-schema';
import { classNames } from 'primereact/utils';
import { useNavigate } from 'react-router-dom';
import ROUTE_CONSTANTS from 'src/Routes/constants/route-constants';
import { authService } from 'src/api/services/auth';
import { setAuthorization } from 'src/utils/set-authorization';
import GeneralFormErrorMessage from 'src/components/App/GeneralFormErrorMessage';
import { useSetUser, useSetUserPermissions } from 'src/context/global-context';
import { getAxiosError } from 'src/utils/get-axios-error';
import LogoLink from '../../../components/App/Logo/LogoLink';
import style from '../../../layouts/AuthLayout/AuthLayoutContent.module.scss';
import Button from 'src/components/Kit/Button';
import { toast } from 'src/utils/toast';
import { TOAST_STATUS } from 'src/constants/toast-status';
// import checkSyncingPermissionEnumsWithBackendHelper from 'src/utils/check-syncing-permission-enums-with-backend-helper';

const Login: React.FC = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [apiError, setApiError] = useState<string>('');
    const setUser = useSetUser();
    const setUserPermissions = useSetUserPermissions();

    const {
        control,
        formState: { errors, isValid },
        handleSubmit,
    } = useForm<ILoginFormData>({
        defaultValues: {
            email: '',
            password: '',
        },
        mode: 'all',
        resolver: yupResolver(loginValidationSchema()),
    });

    const onFormSubmit = async (data: ILoginFormData) => {
        setLoading(true);
        try {
            const result = await authService.login(data.email, data.password);
            setUser(result.data.data.user);
            const permissions = result.data.data.permissions.flatMap(({ actions, parent, title }) => {
                const parentString = parent ? `${parent}|` : '';
                return [...actions.map((permissionAction) => parentString + title + `|${permissionAction}`)];
            });

            // checkSyncingPermissionEnumsWithBackendHelper(permissions);
            setUserPermissions(permissions);
            setAuthorization(result.data.data.token);
            navigate(ROUTE_CONSTANTS.ROOT.ABSOLUTE);
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: message,
            });
            setApiError(message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <div className={style['auth-content__header']}>
                <LogoLink />
                <h1>Welcome to Teaser</h1>
                <p>Login to access your admin panel</p>
            </div>
            <div className={style['auth-content__form']}>
                <form noValidate data-cy="login-form" onSubmit={handleSubmit(onFormSubmit)}>
                    <div className="form-field">
                        <label htmlFor="email">Email</label>
                        <Controller
                            name="email"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <InputText
                                        autoFocus
                                        type="email"
                                        {...field}
                                        id="email"
                                        data-cy="email-input"
                                        style={{ width: '100%' }}
                                        className={classNames(
                                            'p-inputtext',
                                            { 'form-input--has-error': !!errors.email },
                                            { 'form-input--fill': field.value.length }
                                        )}
                                    />
                                    <p className="form-input-error" data-cy="email-error">
                                        {errors?.email?.message}
                                    </p>
                                </>
                            )}
                        />
                    </div>
                    <div className="form-field mt-4">
                        <label htmlFor="password">Password</label>
                        <Controller
                            name="password"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <InputText
                                        type="password"
                                        {...field}
                                        id="password"
                                        style={{ width: '100%' }}
                                        data-cy="password-input"
                                        className={classNames(
                                            'p-inputtext',
                                            { 'form-input--has-error': !!errors.password },
                                            { 'form-input--fill': field.value.length }
                                        )}
                                    />
                                    <p className="form-input-error" data-cy="password-error">
                                        {errors?.password?.message}
                                    </p>
                                </>
                            )}
                        />
                    </div>
                    {apiError && <GeneralFormErrorMessage message={apiError} />}
                    <FormFooterActions>
                        <div className="mt-4">
                            <Button disabled={!isValid || loading} color="primary" size="full" loading={loading}>
                                Log In
                            </Button>
                        </div>
                    </FormFooterActions>
                </form>
            </div>
        </>
    );
};

export default Login;
