import React from 'react';
import style from './../Header.module.scss';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { NavLink } from 'react-router-dom';
import ROUTE_CONSTANTS from 'src/Routes/constants/route-constants';
import { useCurrentDynamicDirectoryName } from 'src/context/global-context';
const HeaderBreadcrumbs: React.FC = () => {
    const dynamicRouteName = useCurrentDynamicDirectoryName();
    
    const breadcrumbs = useBreadcrumbs([
        { path: ROUTE_CONSTANTS.COMPANIES.TYPE.SHOW.ABSOLUTE, breadcrumb: () => <span>{dynamicRouteName}</span> },
    ]);
    
    return (
        <div className={style['main-header__breadcrumbs']}>
            {breadcrumbs.map(({ match, breadcrumb }) => (
                <NavLink key={match.pathname} to={match.pathname}>
                    {breadcrumb}
                </NavLink>
            ))}
        </div>

    );
};

export default HeaderBreadcrumbs;
