import { useParams } from 'react-router-dom';
import { useAsync } from '../../../../../hooks/useAsync';
import { shareHolderService } from '../../../../../api/services/share-holder-service';
import { IShareHolderShowResponse } from '../../../../../api/types/share-holders';
import { IParams } from 'src/types/params';
import { COMPANY_TYPE_ROUTE } from 'src/pages/Company/Add/enums/company-type-route';

export const useEditingMode = () => {
    const { id , companyId, companyType } = useParams<IParams>();
    const isEditingRule = Boolean(id);
    const { data, isLoading } = useAsync<IShareHolderShowResponse,undefined>(
        () => {
            return new Promise(async (resolve, reject) => {
                if (!id || !isEditingRule) return reject('');
                try {
                    const response = await shareHolderService(companyType as COMPANY_TYPE_ROUTE).getOne(Number(companyId), parseInt(id));
                    return resolve(response.data.data);
                } catch (e) {}
            });
        },
        {
            immediate: true,
        }
    );

    return {
        isEditingRule,
        completedData: data,
        isLoading,
        companyId : String(companyId),
        shareHolderId : String(id)
    };
};