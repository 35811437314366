export enum ITEM_TYPE_ENUM {
    SUBTOTAL = 0,
    TOTAL = 1,
    TITLE = 2,
    SUBTITLE = 3,
    ITEM = 4
};

export enum ITEM_VALUE_TYPE_OPTIONS_ENUM  {
    NUMERIC = 0,
    STRING = 1,
}

export enum FINANCIAL_INFORMATION_STATUS {
    NOT_APPROVED = 1,
    APPROVED = 2,
}