import { useState } from 'react';
import { EditCompanyProvider } from './context';
import { useNavigate, useParams } from 'react-router-dom';
import { IEditCompanyFormValues } from './types/form-values';
import EditCompanyTabs from './Tabs';
import { companyService } from 'src/api/services/company';
import { toast } from 'src/utils/toast';
import { TOAST_STATUS } from 'src/constants/toast-status';
import { IEditCompanyPayload } from 'src/api/types/company';
import dayjs from 'dayjs';
import { COMPANY_TYPE_ROUTE } from '../Add/enums/company-type-route';
import { IParams } from 'src/types/params';

const EditCompany: React.FC = () => {
    const navigate = useNavigate();
    const { companyType } = useParams<IParams>();
    const [loading, setLoading] = useState<boolean>(false);

    const onCancel = () => navigate(-1);

    const onComplete = async (formValues: IEditCompanyFormValues, companyId: number) => {
        const { logo_id, location, ...formValuesPayload } = formValues;
        let peyLoad = {
            ...formValuesPayload,
            ...(logo_id && { logo_id: (logo_id as { id: string; url: string }).id }),
            ...(formValues.country_id && { country_id: Number(formValues.country_id) }),
            ...(formValues.currency_id && { currency_id: Number(formValues.currency_id) }),
            ...(formValues.stock_exchange_id && { stock_exchange_id: Number(formValues.stock_exchange_id) }),
            ...(formValues.sector_id && { sector_id: Number(formValues.sector_id) }),
            ...(formValues.inception_date && { inception_date: Number(dayjs(formValues.inception_date as Date).format('YYYY')) }),
            ...(formValues.registration_date && { registration_date: dayjs.tz(new Date(formValues.registration_date as Date).toUTCString()).unix() }),
            ...(formValues.license_date && { license_date: dayjs.tz(new Date(formValues.license_date as Date).toUTCString()).unix() }),
            ...(location && {
                latitude: formValues.latitude || (location as { lat: string; lng: string }).lat,
                longitude: formValues.longitude || (location as { lat: string; lng: string }).lng,
            }),
        } as IEditCompanyPayload;

        try {
            setLoading(true);
            const response = await companyService(companyType as COMPANY_TYPE_ROUTE).editCompany(companyId, peyLoad);
            toast.fire({
                icon: response.data.success ? TOAST_STATUS.SUCCESS : TOAST_STATUS.ERROR,
                title: response.data.message,
            });
            navigate(-1);
        } catch (err: any) {
            Object.entries(err.response.data.errors).forEach(([tabName, errorItems]) => {
                Object.entries(errorItems as { [key: string]: string[] }).forEach(([fieldName, errors]) => {
                    toast.fire({
                        icon: TOAST_STATUS.ERROR,
                        title: errors[0],
                    });
                });
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <EditCompanyProvider onComplete={onComplete} onCancel={onCancel} loading={loading}>
            <EditCompanyTabs />
        </EditCompanyProvider>
    );
};

export default EditCompany;
