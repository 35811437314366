import { useState, useEffect, useRef } from 'react';
import { ITabConfigProps, ITabConfig } from './types';
import useQueryParams from 'src/hooks/useQueryParams';
import { useOnScreen } from 'src/hooks/useOnScreen';
import Button from 'src/components/Kit/Button';
import { classNames } from 'primereact/utils';
import style from './Tab.module.scss';

const Tab: React.FC<ITabConfigProps> = ({ config, queryName, tabSuffix = null, className }) => {
    const tabRef = useRef<HTMLLIElement>(null);
    const isLastElementVisible = useOnScreen(tabRef);
    const { setParam, getParam } = useQueryParams();
    const [activeTab, setActiveTab] = useState<number | string>(() => getParam(queryName || '') || config.find((tab) => !tab.disabled)?.id || config[0].id);
    const [activeLines, setActiveLines] = useState<number>(1);

    const handleTabs = (tab: ITabConfig) => {
        if (!tab.disabled) setActiveTab(tab.id);
        else return;
    };

    const tabClassNameDetector = (tab: ITabConfig) => {
        if (activeTab === tab.id) return className?.activeTabs || style.active;
        if (tab.disabled) return className?.disableTabs || style.disabled;
        else return '';
    };

    const handleClickNewLine = () => setActiveLines((prev) => (prev += 1));

    useEffect(() => {
        if (queryName) setParam(queryName, String(activeTab), true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTab, queryName]);

    return (
        <>
            <div className={classNames(className?.tabs || style.tabs)}>
                <ul style={{ height: className ? 'auto' : `${activeLines * 48}px` }}>
                    {config?.map((tab) => (
                        <li key={tab.id} onClick={() => handleTabs(tab)} ref={tabRef}>
                            <div className={tabClassNameDetector(tab)}>{tab.title}</div>
                        </li>
                    ))}
                </ul>
                {!isLastElementVisible && (
                    <div className={style['more-button-container']}>
                        <li className={style['more-button-wrapper']}>
                            <Button onClick={handleClickNewLine} color="secondary" size="xs">
                                View more
                            </Button>
                        </li>
                    </div>
                )}
                {tabSuffix && <div className={classNames(className?.suffix || style.tabsSuffix)}>{tabSuffix}</div>}
            </div>
            <div className={classNames(className?.content || style.tabsContent)}>{config.find((tab) => tab.id === activeTab)?.content}</div>
        </>
    );
};

export default Tab;
