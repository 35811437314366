import { API_ROUTES } from 'src/constants/api-routes';
import { AxiosInstance } from '../index';
import { IAxiosResponse } from '../types/axios-response';
import { IBaseRoleData, ICreateUpdateRolePayload, IOneRoleData } from '../types/roles';
import { IPermissionOption } from 'src/pages/UserManagement/Roles/AddUpdate/types';
import { IDataTableResponse, IDataTablePayload } from 'src/api/types/table';
import { ISelectBox, IDropDownPayload } from '../types/base-types';

export const roleService = {
    getAll(params?: IDataTablePayload) {
        return AxiosInstance.get<IDataTableResponse<IBaseRoleData>>(API_ROUTES.ROLES.GET_ALL, { params });
    },
    selectBox(params?: IDropDownPayload) {
        return AxiosInstance.get<IAxiosResponse<ISelectBox[]>>(API_ROUTES.ROLES.SELECT_BOX, { params });
    },
    getPermissions() {
        return AxiosInstance.get<IAxiosResponse<IPermissionOption>>(API_ROUTES.ROLES.GET_PERMISSIONS);
    },
    createNew(data: ICreateUpdateRolePayload) {
        return AxiosInstance.post<IAxiosResponse<{}>>(API_ROUTES.ROLES.ADD_NEW, data);
    },
    update(id: number, data: ICreateUpdateRolePayload) {
        return AxiosInstance.put<IAxiosResponse<{}>>(API_ROUTES.ROLES.UPDATE(id), data);
    },
    delete(id: number) {
        return AxiosInstance.delete<IAxiosResponse<{}>>(API_ROUTES.ROLES.DELETE(id));
    },
    getOne(id: number) {
        return AxiosInstance.get<IAxiosResponse<IOneRoleData>>(API_ROUTES.ROLES.GET_ONE(id));
    },
};
