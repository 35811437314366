import { InputTextarea, InputTextareaProps } from 'primereact/inputtextarea';
import { classNames } from 'primereact/utils';
import { ControllerRenderProps, FieldError, FieldValues } from 'react-hook-form';
import { RedError } from 'src/assets/Icons/RedError';
interface IProps {
    field: ControllerRenderProps<FieldValues, string>;
    label: string;
    placeholder: string;
    className: string;
    errorMessage: FieldError;
    props?: InputTextareaProps & React.RefAttributes<HTMLTextAreaElement>;
}
const FormTextarea = ({ field, label, placeholder, className, errorMessage, props = {} }: IProps) => (
    <div className={classNames('col-12', className ? className : '')}>
        <div className="form-field">
            <label className={field.name}>
                {label || placeholder} {props.required ? <span className="required">*</span> : ''}
            </label>
            <InputTextarea id={field.name} {...props} {...field} placeholder={placeholder} />
            {errorMessage ? (
                <div className={'form-input-error'}>
                    <RedError />
                    <span>{errorMessage.message}</span>
                </div>
            ) : null}
        </div>
    </div>
);

export default FormTextarea;
