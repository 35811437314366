import { IAuthenticatedUser } from 'src/api/types/auth';
import { getAuthorization } from 'src/utils/get-authorization';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface IPageState {
    user?: IAuthenticatedUser;
    setUser: (value: IAuthenticatedUser | undefined) => void;
    userPermissions: string[];
    setUserPermissions: (value: string[]) => void;
    clearUser: () => void;
    generalLoading: boolean;
    setGeneralLoading: (value: boolean) => void;
    currentDynamicDirectoryName: string;
    setCurrentDynamicDirectoryName: (value: string) => void;
}

const useStore = create(
    devtools<IPageState>(
        (set) => ({
            user: undefined,
            setUser: (value: IAuthenticatedUser | undefined) => set({ user: value }),
            clearUser: () => set({ user: undefined }),
            userPermissions: [],
            setUserPermissions: (values: string[]) => set({ userPermissions: values }),
            generalLoading: !!getAuthorization(),
            setGeneralLoading: (value: boolean) => set({ generalLoading: value }),
            currentDynamicDirectoryName: '',
            setCurrentDynamicDirectoryName: (value: string) => set({ currentDynamicDirectoryName: value }),
        }),
        {
            name: 'Global',
        }
    )
);

export const useUser = () => useStore((state) => state.user);
export const useSetUser = () => useStore((state) => state.setUser);
export const useClearUser = () => useStore((state) => state.clearUser);
export const useUserPermissions = () => useStore((state) => state.userPermissions);
export const useSetUserPermissions = () => useStore((state) => state.setUserPermissions);
export const useGeneralLoading = () => useStore((state) => state.generalLoading);
export const useSetGeneralLoading = () => useStore((state) => state.setGeneralLoading);
export const useCurrentDynamicDirectoryName = () => useStore((state) => state.currentDynamicDirectoryName);
export const useSetCurrentDynamicDirectoryName = () => useStore((state) => state.setCurrentDynamicDirectoryName);
