import { Dropdown, DropdownProps } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { SelectItemOptionsType } from 'primereact/selectitem';
import { classNames } from 'primereact/utils';
import React, { ChangeEvent, useState } from 'react';
import { ControllerRenderProps, FieldError, FieldValues } from 'react-hook-form';
import { useDebounce } from 'src/hooks/useDebounce';
import style from './FormSelect.module.scss';
import { RedError } from 'src/assets/Icons/RedError';

interface IProps {
    field: ControllerRenderProps<FieldValues, string>;
    label?: string;
    placeholder: string;
    data: SelectItemOptionsType;
    className: string;
    errorMessage: FieldError;
    props?: Omit<DropdownProps, 'onFilter'> & {
        loading?: boolean;
        onFilter?: (fieldName: string, value: string) => void;
        lazy?: boolean;
        onLazy?: (fieldName: string) => void;
    };
}

const FormSelect = ({ field, label, placeholder, data, className, errorMessage, props = {} }: IProps) => {
    const { loading, onFilter, lazy = false, onLazy, ...dropdownProps } = props;
    const [filterValue, setFilterValue] = useState<string>('');
    const onFilerDebounce = useDebounce(onFilter || (() => {}));
    const onLazyDebounce = useDebounce(onLazy || (() => {}));

    const handleFilterOnChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFilterValue(e.target.value);
        onFilerDebounce(field.name, e.target.value);
    };

    const handleOnLazy = (e: React.UIEvent<HTMLElement>) => {
        const target = e.target as HTMLElement;
        const scrollTop = target.scrollTop;
        const scrollHeight = target.scrollHeight;
        if (scrollTop > scrollHeight - 250) onLazyDebounce(field.name);
    };

    return (
        <div className={classNames('col-12', className ? className : '')}>
            <div className="form-field">
                <label htmlFor={field.name} className={field.name}>
                    {label || placeholder} {props.required ? <span className="required">*</span> : ''}
                </label>
                <Dropdown
                    optionLabel="label"
                    optionValue="value"
                    options={data}
                    disabled={loading}
                    placeholder={placeholder}
                    id={field.name}
                    emptyMessage={loading ? 'Fetching Data ...' : 'No results found'}
                    {...dropdownProps}
                    {...field}
                    virtualScrollerOptions={
                        lazy
                            ? {
                                  itemSize: 40,
                                  onScroll: handleOnLazy,
                              }
                            : undefined
                    }
                    filterTemplate={
                        props.onFilter || !dropdownProps.filterTemplate
                            ? () => (
                                  <div className={classNames(style.formFieldSearch, loading ? style.loading : '')}>
                                      <InputText value={filterValue} placeholder={`Search in ${placeholder}`} onChange={handleFilterOnChange} />
                                  </div>
                              )
                            : dropdownProps.filterTemplate
                    }
                    value={field.value?.toString()}
                />
                {errorMessage ? (
                    <div className={'form-input-error'}>
                        <RedError />
                        <span>{errorMessage.message}</span>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default FormSelect;
