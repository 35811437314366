import { SVGProps } from 'react';

const EditIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.89359 15.619L16.2196 5.29299C16.6096 4.90299 17.2426 4.90299 17.6326 5.29299L19.3086 6.96899C19.6986 7.35899 19.6986 7.99199 19.3086 8.38199L8.98159 18.707C8.79459 18.895 8.54059 19 8.27559 19H5.60059V16.325C5.60059 16.06 5.70559 15.806 5.89359 15.619Z"
                stroke={props.stroke ? '#3861FB' : undefined}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M14.3506 7.16016L17.4406 10.2502" stroke={props.stroke ? '#3861FB': undefined} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default EditIcon;
