import { SVGProps } from 'react';

const DotsIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 10" fill="none" {...props}>
            <path
                d="M2.35789 8.59981C2.35759 8.84825 2.15603 9.04944 1.90759 9.04929C1.65916 9.04914 1.45784 8.84771 1.45784 8.59927C1.45784 8.35083 1.65916 8.14939 1.90759 8.14924C2.15603 8.14909 2.35759 8.35029 2.35789 8.59873V8.59981"
                stroke={props.stroke || "currentColor" || "#888888"}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M2.35789 1.40059C2.35759 1.64903 2.15603 1.85022 1.90759 1.85008C1.65916 1.84993 1.45784 1.64849 1.45784 1.40005C1.45784 1.15161 1.65916 0.950174 1.90759 0.950025C2.15603 0.949876 2.35759 1.15107 2.35789 1.39951V1.40059"
                stroke={props.stroke || "currentColor" || "#888888"}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.56101 8.59981C9.56071 8.84825 9.35915 9.04944 9.11072 9.04929C8.86228 9.04914 8.66096 8.84771 8.66096 8.59927C8.66096 8.35083 8.86228 8.14939 9.11072 8.14924C9.35915 8.14909 9.56071 8.35029 9.56101 8.59873V8.59981"
                stroke={props.stroke || "currentColor" || "#888888"}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.56101 1.40059C9.56071 1.64903 9.35915 1.85022 9.11072 1.85008C8.86228 1.84993 8.66096 1.64849 8.66096 1.40005C8.66096 1.15161 8.86228 0.950174 9.11072 0.950025C9.35915 0.949876 9.56071 1.15107 9.56101 1.39951V1.40059"
                stroke={props.stroke || "currentColor" || "#888888"}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default DotsIcon;
