import { FINANCIAL_INFORMATION_FILTER_TYPE } from 'src/constants/financial-information-filter-type';
import { ITEM_TYPE_ENUM, ITEM_VALUE_TYPE_OPTIONS_ENUM, FINANCIAL_INFORMATION_STATUS } from 'src/enums/company/financialInformation/financialItem';
import { COMPANY_LISTING_STATUS } from 'src/pages/Company/Add/enums/company-listing-status';
import { COMPANY_TYPE } from 'src/pages/Company/Add/enums/company-type';
import { PERSON_TYPE } from 'src/pages/Company/Show/CompanyPeople/enums/person-type';
import { IBaseEntity } from './general';
import { FINANCIAL_REPORT_TYPE } from '../../enums/upload-entities.ts';
import { DATE_TYPE } from '../../types/form';
import { IGetListPayload } from './base-types';

interface ICompanyBaseRequestBody {
    id: number;
}

export interface ISearchItemQueryParams {
    text: string;
    type: number;
}

export enum COMPANY_STATUS {
    APPROVED = 2,
    NOT_APPROVED = 1,
}

export interface IBaseCompany extends IBaseEntity {
    id: number;
    name: string;
    arabic_name: string;
    arabic_address: string;
    short_name: string | null;
    arabic_short_name: string | null;
    description: string;
    arabic_description?: string;
    issued_shares: number;
    sector_id?: number;
    sector_label?: null | string;
    country_id?: number;
    country_name: string;
    type: COMPANY_TYPE;
    company_financial_information_template_id?: number;
    company_financial_information_year_end_date?: string;
    company_financial_information_currency_id?: string | number | undefined;
    legal_structure_id: number;
    legal_structure_name: string;
    listing_status: COMPANY_LISTING_STATUS;
    currency_id: number;
    currency_name: string;
    stock_exchange_id: number;
    stock_exchange_name: string;
    isin: string;
    longitude: string;
    latitude: string;
    phone: string;
    website: string;
    address: string;
    inception_year: number | string | null;
    twitter_link: string;
    instagram_link: string;
    linkedin_link: string;
    youtube_link: string;
    can_change_template_or_currency_or_year_end_date: boolean;
    company_financial_information_currency: {
        short_name: string;
        id: number;
    };
    profile_image: string;
    slug: string;
    shareholder_type: number;
    previous_arabic_name: string;
    previous_name: string;
    parent_id?: number;
    teaser_code: number;
    city_id?: number;
    kcci_no: string;
    founded_in: string;
    number_of_employees: number;
    paid_up_capital: number;
    paid_up_capital_usd: number;
    value_per_share: number;
    issued_shared: number;
    branch_number: number;
    is_approved: number;
    approved_at?: number;
    show_on_website: number;
    company_sector_id?: number;
    email: string;
    company_sub_sector_id?: number;
    company_mapped_sector_id?: number;
    deleted_at?: number;
    deleted_by_id?: number;
    created_by_id?: number;
    updated_by_id?: number;
    financial_information_count: number;
    financial_reports_count: null;
    status: COMPANY_STATUS;
    license_registration: {
        chamber_id: number | null;
        chamber_name: string | null;
        chamber_number: string | null;
        registration_website: string | null;
        registration_date: number | null;
        authority_id: number | null;
        authority_name: string | null;
        license_number: string | null;
        license_website: string | null;
        license_date: number | null;
    } | null;
}

export interface IBaseCompanyList extends ICompanyBaseRequestBody, IBaseEntity {
    name: string;
    arabic_name: string | null;
    arabic_short_name: string | null;
    country_name: string;
    logo: string;
    short_name: string;
    slug: string;
    company_status: COMPANY_STATUS;
    financial_information_count: number | null;
    financial_reports_count: number | null;
    has_company_parent_financial: boolean;
}

export interface IImportExcelPayloadRequest {
    uuid: string;
    currency_id: number;
    company_id: number;
}

export interface ISheetsPayloadRequest {
    filter_type: FINANCIAL_INFORMATION_FILTER_TYPE | undefined;
    from_year: number | undefined;
    to_year: number | undefined;
    to_quarter: number | undefined;
    from_quarter: number | undefined;
}

export interface IExcelResponse extends IBaseEntity {
    id: number;
    file_manager_id: number;
    date_reports: number[] | string[];
    company_id: number;
    is_data_imported_successfully: 1 | 0;
    is_ratio_calculated_successfully: 1 | 0;
    is_descriptive_calculated_successfully: 1 | 0;
    is_market_multiple_calculated_successfully: 1 | 0;
    ratio_fail_reason: string | null;
    import_data_fail_reason: string | null;
    market_multiple_fail_reason: string | null;
    descriptive_fail_reason: string | null;
    deleted_at?: number;
}

export interface ISheetsResponse {
    id: 0;
    name: string;
}
export interface ISheetData {
    value: string;
    fiscal_year: number;
    totality: string | null;
    sub_category: string | null;
    quarter_number: number | null;
    name: string;
    category: null;
    is_title: number;
    is_total: number;
    reason?: string;
}

export interface IAnnualSheetDataResponse {
    [key: string]: ISheetData[];
}
export interface IQuarterSheetDataResponse {
    [key: string]: {
        [key: string]: ISheetData[];
    };
}

export interface IRatioDataResponse {
    name: string;
    reason: string | null;
    value: number;
}
export interface IYearsPayloadRequest {
    from_year?: number;
}
export interface IQuarterPayloadRequest {
    year: number;
}

export interface ICompanyResponse extends IBaseEntity {
    id: number;
    short_name: string;
    slug: string;
    arabic_name: string;
    arabic_short_name: string;
    previous_name: string;
    shareholder_type: number;
    previous_arabic_name: string;
    parent_id?: null;
    teaser_code: number;
    type: number;
    legal_structure_id: number;
    country_id?: null;
    city_id?: null;
    kcci_no: string;
    founded_in: string;
    number_of_employees: number;
    paid_up_capital: number;
    paid_up_capital_usd: number;
    value_per_share: number;
    issued_shares: number;
    branch_number: number;
    is_approved: number;
    approved_at?: null;
    show_on_website: number;
    company_sector_id?: null;
    company_sub_sector_id?: null;
    company_mapped_sector_id?: null;
    name: string;
    deleted_at?: null;
    deleted_by_id?: null;
    created_by_id?: null;
    updated_by_id?: null;
}

export interface ICreateCompanyPayload {
    name: string;
    email?: string;
    phone?: string;
    longitude?: string;
    latitude?: string;
    address?: string;
    website?: string;
    inception_date?: number;
    short_name: string;
    arabic_short_name: string;
    arabic_name: string;
    description?: string;
    arabic_description?: string;
    sector_id?: number;
    currency_id: number;
    ISIN?: string;
    type?: COMPANY_TYPE;
    country_id?: number;
    stock_exchange_id: number;
    legal_structure_id: number;
    listing_status: COMPANY_LISTING_STATUS;
    logo_id?: number;
    issued_shared?: number;
    instagram?: string;
    linkedin?: string;
    twitter?: string;
    youtube?: string;

    slug: string;
    facebook?: string;
    sector_label_id?: number;
    legal_structure: string;
    chamber_id?: number;
    chamber_number?: number;
    registration_website?: string;
    registration_date?: number;
    authority_id?: number;
    license_number?: number;
    license_website?: string;
    license_date?: number;
}
export interface IEditCompanyPayload {
    name?: string;
    email?: string;
    phone?: string;
    longitude?: string;
    latitude?: string;
    address?: string;
    website?: string;
    inception_date?: number;
    short_name?: string;
    arabic_short_name?: string;
    arabic_name?: string;
    description?: string;
    arabic_description?: string;
    sector_id?: number;
    currency_id?: number;
    ISIN?: string;
    type?: COMPANY_TYPE;
    country_id?: number;
    stock_exchange_id?: number;
    legal_structure_id?: number;
    listing_status?: COMPANY_LISTING_STATUS;
    logo_id?: number;
    issued_shared?: number;
    instagram?: string;
    linkedin?: string;
    twitter?: string;
    youtube?: string;
}

export interface IFinancialInformationCreateItemPayload {
    name: string;
    arabic_name: string;
    type: ITEM_TYPE_ENUM;
    type_id: number; //type of active data => 'statement of profit or loss ...'
    value_type: ITEM_VALUE_TYPE_OPTIONS_ENUM | null;
    number_type: number;
    category_id: number | string;
    sub_category_id: number | string;
}

export interface ISheetType {
    id: number;
    name: string;
}
export interface ISheetTypePayloadRequest {
    name: string;
}

export interface IFinancialInformationTypePivot {
    [key: string]: number;
}

export interface IFinancialInformationTypes {
    id: number;
    name: string;
    pivot?: IFinancialInformationTypePivot;
}
export interface ITemplatesResponse extends IBaseEntity {
    financial_information_types: IFinancialInformationTypes[];
    id: number;
    name: string;
    description: string | null;
}

export interface IBaseFinancialInformationValue {
    calculated: 0 | 1;
    itemId: number;
    name: string;
    type: ITEM_TYPE_ENUM;
    value: string;
    value_type: ITEM_VALUE_TYPE_OPTIONS_ENUM;
    related_items: string;
    /* is_custom_value meaning Boolean but 0,1 */
    is_custom_value?: number | null;
    must_show_currency: boolean;
    formula: string;
}

export interface IFinancialInformationFormStatus {
    id: number;
    status: FINANCIAL_INFORMATION_STATUS;
}

export interface IBaseFinancialInformationValueResponse {
    financial_information: IFinancialInformationFormStatus;
    values: IBaseFinancialInformationValue[];
}

export interface IBaseFinancialInformationValueByTypeResponse {
    type: ITEM_TYPE_ENUM;
    company_financial_information_type_id: number;
    must_show_currency: boolean;
    name: string;
    value_type: ITEM_VALUE_TYPE_OPTIONS_ENUM;
    [key: string]: string | number | boolean;
}

export interface IFinancialDateResponse {
    quarter: number | null;
    year: number;
    status: FINANCIAL_INFORMATION_STATUS;
}

export interface IFinancialInformationFormulaUsageResponse {
    year: number;
    quarter: number | null;
    items: {
        id: number;
        name: string;
    }[];
}

export interface ICreateFinancialReportRow {
    type: FINANCIAL_REPORT_TYPE;
    id: number;
}
export interface ICreateFinancialReportPayload {
    year_end: number;
    language: number;
    first_quarter?: ICreateFinancialReportRow;
    half_quarter?: ICreateFinancialReportRow;
    third_quarter?: ICreateFinancialReportRow;
    full_year?: ICreateFinancialReportRow;
    annual?: ICreateFinancialReportRow;
}
export interface IGetFinancialReportLanguagesResponse {
    languages: {
        [key: number]: string;
    };
}

export interface IGetFinancialReportResponse extends IBaseEntity {
    id: number;
    year_end: number;
    language: string;
    first_quarter: string | null;
    half_quarter: string | null;
    third_quarter: string | null;
    full_year: string | null;
    annual: string | null;
}

export interface IUpdateFinancialReportPayload extends ICreateFinancialReportPayload {
    delete_first_quarter?: boolean;
    delete_half_quarter?: boolean;
    delete_third_quarter?: boolean;
    delete_full_year?: boolean;
    delete_annual?: boolean;
}

export interface IAssignPersonToCompanyRequest {
    type: PERSON_TYPE;
    person_id: number;
    person_position_id: number;
    join_date?: number | Date;
    join_date_format?: DATE_TYPE;
    end_date?: number | Date;
    end_date_format?: DATE_TYPE;
}
export interface IGetPeopleResponse extends IBaseEntity {
    id: number;
    photo: string | null;
    arabic_name: string;
    english_name: string;
    type: string;
    position: string;
    linkedin: string | null;
    join_date: number;
    join_date_format: DATE_TYPE;
    end_date: number | null;
    end_date_format: DATE_TYPE;
}

export interface IGetAssignPositionTypesResponse {
    types: { [key: string]: string };
}
export interface IShowPersonResponse {
    id: number;
    type: PERSON_TYPE;
    person_id: number;
    person_name: string;
    person_position_id: number;
    person_position_name: string;
    join_date: number;
    join_date_format?: DATE_TYPE;
    end_date: number | null;
    end_date_format?: DATE_TYPE;
}

export interface IGetCompanyDividendsResponse extends IBaseEntity {
    bonus_shares: string;
    cash_dividends: string;
    financial_year: number;
    id: number;
}

export interface ISearchedItem {
    id: number;
    name: string;
    value_type: ITEM_VALUE_TYPE_OPTIONS_ENUM;
    must_show_currency: boolean;
}

export interface ICreateFinancialInformationResponse extends IBaseEntity {
    company_financial_information_category_id: null | number;
    company_financial_information_sub_category_id: null | number;
    company_financial_information_type_id: null | number;
    id: number;
    name: string;
    type: ITEM_TYPE_ENUM;
    value_type: ITEM_VALUE_TYPE_OPTIONS_ENUM;
    number_type: RATIO_NUMBER_TYPE;
    must_show_currency: boolean;
}

export interface IChangeCompanyStatusPayload {
    status: number;
}

export enum RATIO_NUMBER_TYPE {
    CURRENCY = 1,
    NUMBER = 2,
}

export enum RATIO_VALUE_TYPE {
    CURRENCY = 1,
    PERCENTAGE = 2,
    MULTIPLE = 3,
}

export const ratioValueTypeSymbol = {
    [RATIO_VALUE_TYPE.PERCENTAGE]: '%',
    [RATIO_VALUE_TYPE.MULTIPLE]: 'x',
};

export enum FORMULA_TYPE {
    ITEM = 'item',
    STATEMENT = 'statement',
}

export enum OPERATORS {
    PLUS = 'plus',
    MINUS = 'minus',
    TIMES = 'times',
    DIVISION = 'division',
}

export const operatorsSymbol = {
    [OPERATORS.PLUS]: '+',
    [OPERATORS.MINUS]: '-',
    [OPERATORS.TIMES]: '×',
    [OPERATORS.DIVISION]: '÷',
};
export interface FinancialInformationRatioItemContext {
    name: string;
    value: number;
    has_abs: boolean;
    meta?: {
        items?: {
            category?: string | null;
            item_type?: string | null;
            sub_category?: string | null;
        };
        number_type?: RATIO_NUMBER_TYPE;
        message?: string;
        formula: null | FinancialInformationRatioDataContext;
    };
}
export interface FinancialInformationRatioStatementContext {
    name: string | null;
    has_parentheses: boolean;
    values: FinancialInformationRatioDataContext[];
    operations: OPERATORS[];
}
export type FinancialInformationRatioDataContext =
    | {
          type: FORMULA_TYPE.ITEM;
          context: FinancialInformationRatioItemContext;
      }
    | { type: FORMULA_TYPE.STATEMENT; context: FinancialInformationRatioStatementContext };

export interface FinancialInformationRatioRelatedItems {
    name: string;
    value: number;
    has_abs: boolean;
    meta?: {
        items?: {
            category?: string | null;
            item_type?: string | null;
            sub_category?: string | null;
        };
        number_type?: RATIO_NUMBER_TYPE;
        message?: string;
    };
}
export interface FinancialInformationRatioFormulaData {
    numerator: FinancialInformationRatioDataContext;
    denominator: FinancialInformationRatioDataContext | null;
}

export interface IFinancialInformationRatioDatesResponse {
    year: number;
    quarter?: number;
}

export interface IFinancialInformationRatioResponse {
    name: string;
    type: string;
    year: number;
    value: string | null;
    quarter: number | null;
    company_id: number;
    value_type: RATIO_VALUE_TYPE;
    value_type_name: string;
    currency_short_name: string | null;
    company_financial_information_ratio_id: number;
    company_financial_information_ratio_value_id: number;
    formula: FinancialInformationRatioFormulaData;
    related_items: FinancialInformationRatioRelatedItems[];
}

export interface IFinancialInformationRatioTableData {
    [key: string]: IFinancialInformationRatioResponse[];
}

export interface IGetCompanyParentFinancialResponse extends IBaseEntity {
    id: number;
    parent_name: string;
    year_end: number;
    pages: number;
    languages: string;
    file_source: string;
    source_link: string;
    pdf_file: string;
}

export type IPaginatedCompanyResponse = Omit<IGetListPayload, 'selected_items'> & { selected_item?: number };

export interface ICreateParentCompanyFinancialPayload {
    company_parent_financial_name: string | null;
    company_parent_financial_id: number | null;
    uuid: string;
    source_link: string;
    pages?: string[] | null;
    languages: string[];
    file_source: number;
    year_end: number;
    report_date: number;
}

export interface IParentCompanyFinancialShowResponse {
    company_parent_financial_name?: string;
    company_parent_financial_id?: number;
    year_end: number;
    report_date: number;
    pages?: string[];
    languages: number[];
    file_source: number;
    company: {
        id: number;
        name: string;
    };
    source_link: string;
    pdf_file_uuid: string;
}

export interface ICreateCommentPayload {
    topic: string;
    comment: string;
    sources: { title?: string; link?: string }[];
}

export interface ICommentShowResponse extends ICreateCommentPayload {
    id: number
}

export interface ICommentResponse extends ICreateCommentPayload,IBaseEntity {
    id: number
}