import { arabicCharactersRegex, urlRegex } from 'src/constants/regex';
import { getMaxError, getMinError, getRequireError } from 'src/utils/get-yup-errors';
import { string, object } from 'yup';

export const newsSourceValidationSchema = object().shape({
    source_name: string().required(getRequireError('Source Name')).min(2, getMinError(2, 'Source Name')).max(80, getMaxError(80, 'Source Name')),
    arabic_source_name: string()
        .when({
            is: (arabic_source_name: string) => arabic_source_name && arabic_source_name.length > 0,
            then: string()
                .matches(arabicCharactersRegex, 'Arabic Name must only accept Arabic characters')
                .min(2, getMinError(2, 'Arabic Name'))
                .max(80, getMaxError(80, 'Arabic Name')),
        })
        .nullable(),
    country_id: string().required(getRequireError('Country')).nullable(),
    website: string()
        .when({
            is: (url: string) => url && url.length > 0,
            then: string().matches(urlRegex, 'Enter correct url!'),
        })
        .nullable(),
});
